import Base from "./Base";

export default class UnreportedAbusiveContent extends Base {
  index(params = {}) {
    const q = this._query(params);
    return this._fetch(`/admin/unreported_abusive_content/?${q}`, {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    }).then((response) => response.json());
  }
}

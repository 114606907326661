import React from "react";
import PropTypes from "prop-types";

import UserLink from "../components/UserLink";

export default class ReferralStatsRow extends React.Component {
  static propTypes = {
    api: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    referrals: PropTypes.shape({
      total: PropTypes.number.isRequired,
      unpaid: PropTypes.number.isRequired,
    }).isRequired,
    onUpdate: PropTypes.func,
  };

  static Headers = () => (
    <tr>
      <th className="ReferralStatsRow-referrer">Ambassador</th>
      <th className="ReferralStatsRow-email">Email</th>
      <th width="1">Unpaid</th>
      <th width="1">Total</th>
      <th width="1">Actions</th>
    </tr>
  );

  constructor(props) {
    super(props);
    this.state = { referrals: this.props.referrals };
  }

  render() {
    const s = this.state;

    return (
      <tr>
        <td className="ReferralStatsRow-referrer">
          <UserLink inline {...this.props.user} />
        </td>
        <td className="ReferralStatsRow-email">{this.props.user.email}</td>
        <td>{s.referrals.unpaid}</td>
        <td>{s.referrals.total}</td>

        <td>
          <button
            className="btn btn-primary btn-sm nobreak"
            disabled={s.referrals.unpaid < 10}
            onClick={this._onClick.bind(this)}
          >
            Cash out
          </button>
        </td>
      </tr>
    );
  }

  _onClick() {
    this.props.api.Payments.create({ id: this.props.user.id }).then(() => {
      this.props.api.Referrals.indexStats({ user_id: this.props.user.id }).then(
        (data) => this.setState(data[0], this.props.onUpdate)
      );
    });
  }
}

import React from "react";
import PropTypes from "prop-types";

import BooleanSwitch from "./BooleanSwitch";

export default class CampaignFilterMenopause extends React.Component {
  static propTypes = {
    api: PropTypes.object.isRequired,
    campaign_id: PropTypes.number.isRequired,
    id: PropTypes.number.isRequired,
    negated: PropTypes.bool.isRequired,
    onSave: PropTypes.func.isRequired,
    disabled: PropTypes.bool.isRequired,
  };

  render() {
    const p = this.props;

    return (
      <span className="CampaignFilterMenopause">
        user{" "}
        <BooleanSwitch
          api={p.api}
          campaign_id={p.campaign_id}
          id={p.id}
          value={p.negated}
          onSave={p.onSave}
          disabled={p.disabled}
        >
          {this.props.negated ? "is not" : "is"}
        </BooleanSwitch>{" "}
        menopause
      </span>
    );
  }
}

import React from "react";
import PropTypes from "prop-types";

import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Grid from "@material-ui/core/Grid";

const CheckboxGroupFilterItem = ({ name, label, checked, onChange }) => {
  const handleChange = ({ target: { checked } }) => {
    onChange(name, checked);
  };

  return (
    <Grid item>
      <FormControlLabel
        control={<Checkbox name={name} onChange={handleChange} />}
        label={label}
        checked={checked}
      />
    </Grid>
  );
};

CheckboxGroupFilterItem.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
};

CheckboxGroupFilterItem.defaultProps = {
  onChange: () => {},
};

export default CheckboxGroupFilterItem;

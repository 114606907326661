import React, { Component } from "react";
import PropTypes from "prop-types";

import AutoAnnotationUrlsForm from "./auto_annotation_urls_form";
import styles from "./auto_annotations.module.scss";

import SearchResults from "../../components/SearchResults";

class AutoAnnotations extends Component {
  static propTypes = {
    api: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = { auto_annotation: null };
  }
  render() {
    return (
      <div className={styles.container}>
        <SearchResults api={this.props.api.AutoAnnotations} query={{}}>
          {(results) => (
            <table>
              <thead>
                <tr>
                  <th>Theme</th>
                  <th>Keywords</th>
                </tr>
              </thead>
              <tbody>
                {results.items().map((auto_annotation) => (
                  <tr key={auto_annotation.id}>
                    <td>
                      <button
                        className={styles.themeBtn}
                        onClick={this.setState.bind(
                          this,
                          { auto_annotation: auto_annotation },
                          null
                        )}
                      >
                        {auto_annotation.theme}
                      </button>
                    </td>
                    <td>{auto_annotation.keywords.join(", ")}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </SearchResults>

        <div className="mt-3">
          {this.state.auto_annotation && (
            <AutoAnnotationUrlsForm
              api={this.props.api}
              auto_annotation={this.state.auto_annotation}
            />
          )}
        </div>
      </div>
    );
  }
}

export default AutoAnnotations;

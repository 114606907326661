import React, { Component } from "react";
import PropTypes from "prop-types";

import EventRow from "../events/Row";

class GroupTabLog extends Component {
  render() {
    const { history } = this.props;
    const hasHistory = history.length > 0;
    return (
      <table className="GroupTabLogs-table">
        <thead>
          <tr>
            <th width="1">Time</th>
            <th></th>
            <th>Activity</th>
          </tr>
        </thead>
        <tbody>
          {hasHistory &&
            history.map((h, index) => (
              <EventRow
                key={`eventRow${index}`}
                api={this.props.api}
                userID={h.group.author.id}
                type="history/group"
                data={h}
              />
            ))}
          {!hasHistory && (
            <tr>
              <td colSpan={3}>
                <p className="empty">No more results, sorry</p>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    );
  }
}

GroupTabLog.propTypes = {
  history: PropTypes.array,
};

export default GroupTabLog;

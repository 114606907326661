import React, { useState } from "react";
import { useDeleteImage } from "../hooks/api/useDeleteImage"; // Import the useDeleteMessage hook

const DeleteImageButton = ({ imgUId }) => {
  const [deleted, setDeleted] = useState(false);
  const [error, setError] = useState(null);
  const { mutate: deleteImage, isLoading } = useDeleteImage(
    () => {
      setDeleted(true);
    },
    (error) => {
      console.error("Delete error:", error);
      setError(error);
    }
  );

  return (
    <section style={{ paddingTop: "15px" }}>
      {deleted && <p>Deleted!</p>}
      {error && <p>{error}</p>}
      <button onClick={() => deleteImage(imgUId)} disabled={isLoading}>
        {isLoading ? "Deleting..." : "Delete"}
      </button>
    </section>
  );
};

export default DeleteImageButton;

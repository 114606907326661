import { useMutation, useQueryClient } from "react-query";
import Cookies from "universal-cookie";

const cookies = new Cookies();
const xsrf_token = cookies.get("XSRF-TOKEN");

const baseUrl = `${process.env.REACT_APP_API_HOST}/admin/seo/pages`;

const submit = ({ id, data }) => {
  return fetch(`${baseUrl}/${id}`, {
    method: "PATCH",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      "X-XSRF-Token": xsrf_token,
    },
    body: JSON.stringify(data),
  });
};

const useLandingPageUpdate = () => {
  const queryClient = useQueryClient();

  return useMutation(submit, {
    onSuccess: async (response, variables, context) => {
      queryClient.invalidateQueries(["landingPage", variables.id]);
    },
    onError: (error, variables, context) => {
      console.log(error);
    },
  });
};

export default useLandingPageUpdate;

import React from "react";
import PropTypes from "prop-types";

import PublishedPostsRow from "../components/PublishedPostsRow";

export default class PublishedPosts extends React.Component {
  static propTypes = {
    api: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
    this.props.api.SEO.Posts.index({ mode: "published", limit: 50 }).then(
      (data) => this.setState({ posts: data[0] })
    );
  }

  render() {
    if (!this.state.posts) {
      return <React.Fragment />;
    }

    return (
      <div className="PublishedPosts">
        <table className="PublishedPosts-table">
          <thead>
            <PublishedPostsRow.Headers />
          </thead>

          <tbody>
            {this.state.posts.map((post) => (
              <PublishedPostsRow key={post.uid} post={post} />
            ))}
          </tbody>
        </table>
      </div>
    );
  }
}

import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Button,
  DialogActions,
  DialogContent,
  Dialog,
  TextField,
  CircularProgress,
} from "@material-ui/core";

import DialogTitle from "../DialogTitle";

import usePodShare from "../../hooks/api/usePodShare";

const SharePodLink = ({ id }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const { isLoading, data } = usePodShare(id);

  const handleShareClick = () => {
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const handleCopy = () => {
    var copyText = document.querySelector("#pod-share-url");
    copyText.select();
    document.execCommand("copy");
  };

  return (
    <>
      <button className="badge badge-pill" onClick={handleShareClick}>
        Share{" "}
        <span role="img" aria-label="Share">
          📤
        </span>
      </button>
      <Dialog onClose={handleModalClose} open={modalOpen}>
        <DialogTitle onClose={handleModalClose} style={{ width: "400px" }}>
          Public POD Link
        </DialogTitle>

        <DialogContent dividers>
          {isLoading && (
            <div style={{ textAlign: "center" }}>
              <CircularProgress />
            </div>
          )}

          {!isLoading && (
            <TextField
              id="pod-share-url"
              label="URL"
              fullWidth
              autofocus={true}
              value={data}
            />
          )}
        </DialogContent>

        <DialogActions>
          <Button
            type="submit"
            onClick={handleCopy}
            variant="outlined"
            disabled={isLoading}
          >
            Copy To Clipboard
          </Button>
          <Button type="submit" onClick={handleModalClose} variant="contained">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

SharePodLink.propTypes = {
  id: PropTypes.string.isRequired,
};

export default SharePodLink;

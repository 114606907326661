import React, { Component } from "react";
import UserLink from "../../../../components/UserLink";
import styles from "./ParticipantsTab.module.scss";
export default class ParticipantsTab extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: null,
    };

    this._loadParticipants = this._loadParticipants.bind(this);
  }

  componentDidMount() {
    this._loadParticipants();
  }

  _loadParticipants() {
    const {
      api: { LiveAudioParticipants: LiveAudioParticipantsApi },
      roomId,
    } = this.props;

    LiveAudioParticipantsApi.index({ roomId }).then((data) => {
      const participantsByStatus = data.reduce((result, participant) => {
        const { status, status_on_join } = participant;
        let finalStatus = status !== "left" ? status : status_on_join;

        if (finalStatus === "requested_to_speak") {
          finalStatus = "guest";
        }

        if (!result[finalStatus]) {
          result[finalStatus] = [];
        }

        result[finalStatus].push(participant);
        return result;
      }, {});

      this.setState({ data: participantsByStatus });
    });
  }

  render() {
    const { data } = this.state;
    const capitalize = ([firstLetter, ...restOfWord]) =>
      firstLetter.toUpperCase() + restOfWord.join("");
    if (data == null) {
      return <p>Loading...</p>;
    }
    return (
      <div styles={styles.participantsContainer}>
        {["host", "cohost", "speaker", "guest", "blocked"].map((status) => {
          const participants = data[status];

          if (!participants) {
            return <></>;
          }
          return (
            <section className={styles.statusSection}>
              <h4>{capitalize(status)}s</h4>

              <div>
                {participants.map((participant) => {
                  return (
                    <div>
                      <UserLink {...participant.user} />
                    </div>
                  );
                })}
              </div>
            </section>
          );
        })}
      </div>
    );
  }
}

import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import useTopics from "../../hooks/api/useTopics";

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 120,
    marginTop: 10,
    width: "100%",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const TopicSelector = ({ isOriginal, onSave, value }) => {
  const classes = useStyles();
  const [topic, setTopic] = React.useState(value);
  const { data } = useTopics();

  const handleChange = (event) => {
    onSave(event.target.value);
    setTopic(event.target.value);
  };

  return (
    <div>
      <FormControl
        variant="outlined"
        data-testid="select-topic-form-control"
        className={classes.formControl}
        disabled={!isOriginal}
      >
        <InputLabel id="select-topic-label" data-testid="select-topic-label">
          Select a topic
        </InputLabel>
        <Select
          value={topic}
          onChange={handleChange}
          data-testid="select-topic-select-component"
          label="Select a topic"
        >
          {data?.map((item) =>
            item?.tags.map((tag) => (
              <MenuItem key={tag?.id} value={tag?.id}>
                {item?.group} - {tag?.name}
              </MenuItem>
            ))
          )}
        </Select>
      </FormControl>
    </div>
  );
};

export default TopicSelector;

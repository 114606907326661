import React from "react";
import PropTypes from "prop-types";

import ModalForm from "../../components/ModalForm";
import TextAreaAutoExpand from "../../components/TextAreaAutoExpand";
import Image from "../../components/Image";
import DropArea from "../../components/DropArea";

class Label extends React.Component {
  render() {
    return (
      <div className="PostCopyEditor-block">
        <label className="PostCopyEditor-label">{this.props.children}</label>
      </div>
    );
  }
}
class Field extends React.Component {
  static propTypes = {
    content: PropTypes.string,
    onChange: PropTypes.func,
  };

  render() {
    return (
      <div className="PostCopyEditor-block">
        <TextAreaAutoExpand
          className="PostCopyEditor-field"
          disabled={!this.props.onChange}
          onChange={this._onChange.bind(this)}
          onBlur={this._onChange.bind(this)}
          value={this.props.content}
        />
      </div>
    );
  }

  _onChange(e) {
    if (!this.props.onChange) return;
    this.props.onChange(e.target.value);
  }
}

export default class PostCopyEditor extends React.Component {
  static propTypes = {
    onSave: PropTypes.func.isRequired,
    original: PropTypes.shape({
      title: PropTypes.string.isRequired,
      body: PropTypes.string,
      image: PropTypes.object,
    }).isRequired,
    edited: PropTypes.shape({
      title: PropTypes.string,
      body: PropTypes.string,
      image: PropTypes.object,
    }).isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      title: props.edited.title,
      body: props.edited.body,
      image: props.edited.image,
    };
  }

  render() {
    const p = this.props;
    const s = this.state;

    return (
      <React.Fragment>
        <button
          className="PostCopyEditor"
          onClick={this.setState.bind(this, { modal: true }, null)}
        >
          Edit copy
        </button>
        {this.state.modal && (
          <ModalForm
            title="Edit published post"
            className="PostCopyEditor-modal"
            form={
              <div className="PostCopyEditor-form">
                <Label>Original title</Label>
                <Label>Edited title</Label>
                <Field content={p.original.title} />
                <Field
                  content={s.title}
                  onChange={(v) => this.setState({ title: v })}
                />

                <Label>Original body</Label>
                <Label>Edited body</Label>
                <Field content={p.original.body} />
                <Field
                  content={s.body}
                  onChange={(v) => this.setState({ body: v })}
                />

                <Label>Original image</Label>
                <Label>Edited image</Label>
                <div className="PostCopyEditor-block">
                  <Image
                    url={p.original.image && p.original.image.url}
                    className="SEOPostRow-image"
                    openInNewTab
                  />
                </div>

                <div className="PostCopyEditor-block">
                  <DropArea
                    api={p.api}
                    onComplete={(data) => this.setState({ image: data })}
                  >
                    <Image
                      url={s.image && s.image.url}
                      className="SEOPostRow-image"
                      openInNewTab
                    />
                  </DropArea>
                  {s.image && (
                    <button onClick={this._onRemove.bind(this)}>Remove</button>
                  )}
                </div>
              </div>
            }
            onCancel={this._onCancel.bind(this)}
            actions={
              <span className="PostCopyEditor-actions">
                <button onClick={this._onCancel.bind(this)}>Cancel</button>
                <button onClick={this._onConfirm.bind(this)}>Save</button>
              </span>
            }
          />
        )}
      </React.Fragment>
    );
  }

  _onCancel() {
    this.setState({ modal: false });
  }

  _onConfirm() {
    this.setState({ modal: false }, () =>
      this.props.onSave({
        title: this.state.title,
        body: this.state.body,
        image: this.state.image,
      })
    );
  }

  _onRemove() {
    this.setState({ image: null });
  }
}

import React from "react";
import PropTypes from "prop-types";

import {
  Button,
  DialogActions,
  DialogContent,
  Dialog,
  TextField,
} from "@material-ui/core";

import useInput from "../../hooks/useInput";
import DialogTitle from "../DialogTitle";

const MetaModal = ({
  description: originalDescription,
  onChange,
  onClose,
  open,
  title: originalTitle,
}) => {
  const textInput = useInput(originalTitle);
  const descriptionInput = useInput(originalDescription);

  const handleApply = () => {
    onChange({
      meta_title: textInput.value,
      meta_description: descriptionInput.value,
    });
    onClose();
  };

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle onClose={handleClose}>Meta Settings</DialogTitle>

      <DialogContent dividers>
        <TextField label="Title" fullWidth autofocus={true} {...textInput} />

        <TextField label="Description" fullWidth {...descriptionInput} />
      </DialogContent>

      <DialogActions>
        <Button type="submit" onClick={handleApply} color="primary">
          Apply
        </Button>
      </DialogActions>
    </Dialog>
  );
};

MetaModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};

MetaModal.defaultProps = {
  open: false,
  onClose: () => {},
  onChange: () => {},
};

export default MetaModal;

import React from "react";
import { TableCell, TableRow } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";

import Moment from "../../components/Moment";

import UserChip from "../../components/UserChip";

const UserReferralsRow = ({
  id,
  user,
  referrer,
  initial_referral,
  referral_channel,
  referral_method,
  created_at,
}) => {
  const loading = id ? false : true;
  return (
    <TableRow key={id}>
      <TableCell component="th" scope="row">
        {loading ? <Skeleton variant="text" /> : id}
      </TableCell>
      <TableCell align="right">
        <UserChip {...user} />
      </TableCell>
      <TableCell align="right">
        <UserChip {...referrer} />
      </TableCell>
      <TableCell align="right">
        {loading ? (
          <Skeleton variant="text" />
        ) : initial_referral ? (
          "True"
        ) : (
          "False"
        )}
      </TableCell>
      <TableCell align="right">
        {" "}
        {loading ? <Skeleton variant="text" /> : referral_channel}
      </TableCell>
      <TableCell align="right">
        {" "}
        {loading ? <Skeleton variant="text" /> : referral_method}
      </TableCell>
      <TableCell align="right">
        {loading ? <Skeleton variant="text" /> : <Moment date={created_at} />}
      </TableCell>
    </TableRow>
  );
};

export default UserReferralsRow;

import { useQuery } from "react-query";

import Cookies from "universal-cookie";

const useMedicalSpecialities = () => {
  const cookies = new Cookies();
  const xsrf_token = cookies.get("XSRF-TOKEN");

  const fetchMedicalSpecialities = async () => {
    const url = new URL(
      `${process.env.REACT_APP_API_HOST}/admin/seo/medical_specialities`
    );

    const response = await fetch(url, {
      method: "GET",
      credentials: "include",
      cors: "no-cors",
      headers: {
        "Content-Type": "application/json",
        "X-XSRF-Token": xsrf_token,
      },
    });

    const data = await response.json();
    return data;
  };

  return useQuery(["data"], fetchMedicalSpecialities);
};

export default useMedicalSpecialities;

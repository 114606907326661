import React, { useState } from "react";

import CheckGroup from "./CheckGroup";
import ModalForm from "./ModalForm";
import AlertDialog from "./AlertDialog/AlertDialog";

const validValues = ["ad", "hate", "nudity", "other"];

const CommentSetAbuseForm = (props) => {
  let initialState = validValues.filter((b) => props.abuse.includes(b));
  const [abuse, setAbuse] = useState(initialState);
  const [confirmationModal, setConfirmationModal] = useState(false);

  const _onChange = (e) => {
    const value = e.target.value;
    const abuse = value.length > 0 ? value.split(",") : [];
    setAbuse(abuse);
  };

  const _onSubmit = (action) => {
    if (abuse.includes("hate")) {
      setConfirmationModal(true);
    } else {
      props.api.Comments.update({
        id: props.commentID,
        note: "updating abuse flags",
        comment: {
          abuse,
        },
      }).then(() => {
        props.onCancel();
        props.onComplete();
      });
    }
  };

  const onConfirm = () => {
    props.api.Comments.update({
      id: props.commentID,
      note: "updating abuse flags",
      comment: {
        abuse,
      },
    }).then(() => {
      props.onCancel();
      props.onComplete();
      setConfirmationModal(false);
    });
  };
  return (
    <>
      <ModalForm
        className="CommentSetAbuseForm"
        title="Flag comment abuse"
        onCancel={props.onCancel}
        form={
          <CheckGroup
            name="abuse"
            labels={validValues}
            options={validValues}
            selected={abuse.join(",")}
            onChange={(e) => _onChange(e)}
          />
        }
        actions={
          <button
            type="button"
            className="btn btn-primary"
            onClick={(e) => _onSubmit(e)}
          >
            Save
          </button>
        }
      />
      <AlertDialog
        open={confirmationModal}
        onCloseModal={() => setConfirmationModal(false)}
        onSubmit={() => onConfirm()}
      />
    </>
  );
};

export default CommentSetAbuseForm;

import React from "react";
import PropTypes from "prop-types";

export default class BooleanSwitch extends React.Component {
  static propTypes = {
    api: PropTypes.object.isRequired,
    campaign_id: PropTypes.number.isRequired,
    id: PropTypes.number.isRequired,
    value: PropTypes.bool.isRequired,
    disabled: PropTypes.bool.isRequired,
    onSave: PropTypes.func.isRequired,
  };

  render() {
    return (
      <button
        className="CampaignFilterBooleanSwitch"
        onClick={this._onSwitch.bind(this)}
        disabled={this.props.disabled}
      >
        {this.props.children}
      </button>
    );
  }

  _onSwitch() {
    const p = this.props;
    p.api.CampaignFilters.update(p.campaign_id, p.id, {
      filter: { negated: !p.value },
    }).then(p.onSave);
  }
}

import React, { Component } from "react";
import PropTypes from "prop-types";

import CheckGroup from "./CheckGroup";
import ModalForm from "./ModalForm";

const validValues = ["ad", "hate", "other", "nudity", "link", "dupe"];

export default class PostSetAbuseForm extends Component {
  static propTypes = {
    api: PropTypes.object.isRequired,
    postID: PropTypes.number.isRequired,
    abuse: PropTypes.array.isRequired,
    onCancel: PropTypes.func.isRequired,
    onComplete: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      abuse: validValues.filter((b) => this.props.abuse.includes(b)),
    };
  }

  render() {
    return (
      <ModalForm
        className="PostSetAbuseForm"
        title="Flag post abuse"
        onCancel={this.props.onCancel}
        form={
          <CheckGroup
            name="abuse"
            labels={validValues}
            options={validValues}
            selected={this.state.abuse.join(",")}
            onChange={this._onChange.bind(this)}
          />
        }
        actions={
          <button
            type="button"
            className="btn btn-primary"
            onClick={this._onSubmit.bind(this)}
          >
            Save
          </button>
        }
      />
    );
  }

  _onChange(e) {
    const value = e.target.value;
    const abuse = value.length > 0 ? value.split(",") : [];
    this.setState({ abuse: abuse });
  }

  _onSubmit(action) {
    this.props.api.Posts.update({
      id: this.props.postID,
      note: "updating abuse flags",
      post: {
        abuse: this.state.abuse,
      },
    }).then(() => {
      this.props.onCancel();
      this.props.onComplete();
    });
  }
}

import React, { Component } from "react";
import PropTypes from "prop-types";

import Avatar from "./Avatar";
import Moment from "./Moment";
import StatusButton from "./StatusButton";
import SendMessageForm from "./SendMessageForm";
import UserSetStatusForm from "./UserSetStatusForm";
import UserSetBadgesForm from "./UserSetBadgesForm";
import UserNotesForm from "./UserNoteForm";
import UserEditPayPalForm from "./UserEditPayPalForm";
import UserWarningOverlay from "./UserWarningOverlay";
import UserWipeButton from "./UserWipeButton";
import UserGetAuthTokenButton from "./UserGetAuthTokenButton";
import UserPhoneSwapButton from "./UserPhoneSwapButton";
import UserClearPhoneButton from "./UserClearPhoneButton";
import UserStatusWarning from "./UserStatusWarning";

import Switch from "./Switch";

export default class UserProfile extends Component {
  static propTypes = {
    api: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    onRefresh: PropTypes.func.isRequired,
    triggerStatusButtonClick: PropTypes.bool,
    resetTriggerStatusButtonClick: PropTypes.func,
  };

  constructor(props) {
    super(props);

    this.state = {
      chatOverlay: false,
      statusOverlay: false,
      badgesOverlay: false,
      noteOverlay: false,
      phoneSwapOverlay: false,
      paypalOverlay: false,
    };

    this._onStatusButtonClick = this._onStatusButtonClick.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (this.props.triggerStatusButtonClick) {
      this.props.resetTriggerStatusButtonClick();
      this._onStatusButtonClick();
    }
  }

  displayLoginMethods() {
    const {
      user: { login_methods: loginMethods, gender },
    } = this.props;

    return loginMethods
      .map((loginMethod) => {
        const genderLabel =
          gender && loginMethod === "facebook" ? ` (${gender})` : "";

        return `${loginMethod}${genderLabel}`;
      })
      .join(", ");
  }

  _onStatusButtonClick() {
    this.setState({ statusOverlay: true });
  }

  render() {
    const d = this.props.user;
    const roles = this.props.api.Session.user.roles;

    if (d.id === undefined) {
      return (
        <section className="UserProfile">
          <p>Loading...</p>
        </section>
      );
    }

    return (
      <section className="UserProfile">
        <div className="content">
          <div className="header">
            <button
              onClick={this.setState.bind(this, { noteOverlay: true }, null)}
              className="UserProfile-noteButton"
            >
              Add note
            </button>

            <UserWipeButton
              api={this.props.api}
              user={d}
              onComplete={this.props.onRefresh}
              className="UserProfile-wipeButton"
            />

            <UserPhoneSwapButton api={this.props.api} user={d} />

            <UserGetAuthTokenButton
              api={this.props.api}
              id={d.id}
              className="UserProfile-tokenButton"
            />

            <a
              target="_blank"
              rel="noopener noreferrer"
              href={d.deeplink}
              className="OpenAppButton"
            >
              Open In App
            </a>
            <button
              className="btn btn-outline-secondary float-right UserProfile-chatButton"
              onClick={this.setState.bind(this, { chatOverlay: true }, null)}
            >
              Chat{" "}
              <span role="img" aria-label="speech bubble">
                💬
              </span>
            </button>
            <h1 className="name">
              {d.first_name} {d.last_name}
            </h1>
          </div>

          <dl className="details">
            <dt>ID</dt>
            <dd>#{d.id}</dd>
            <dt>Status</dt>
            <dd>
              <StatusButton
                onClick={this._onStatusButtonClick}
                status={d.account_status}
              >
                {d.account_status}
              </StatusButton>
              <UserStatusWarning userID={this.props.user.id} />
            </dd>

            <dt>Badges</dt>
            <dd>
              {d.badges.map((b, idx) => (
                <React.Fragment key={idx}>
                  <button
                    className={`badge badge-pill ${
                      b === "creator" ? "badge-info" : "badge-secondary"
                    }`}
                    onClick={this.setState.bind(
                      this,
                      { badgesOverlay: true },
                      null
                    )}
                  >
                    {b.replace(/_/, " ")}
                  </button>{" "}
                </React.Fragment>
              ))}
              {d.badges.length === 0 && (
                <button
                  className="badge badge-light"
                  onClick={this.setState.bind(
                    this,
                    { badgesOverlay: true },
                    null
                  )}
                >
                  none
                </button>
              )}
            </dd>

            <dt>Last active</dt>
            <dd>
              <Moment time={d.last_active_at} relative />
            </dd>

            <dt>Registered</dt>
            <dd>
              {d.completed_reg_at ? <Moment time={d.completed_reg_at} /> : "No"}
            </dd>

            <dt>Signed up</dt>
            <dd>
              <Moment time={d.created_at} />
            </dd>

            <dt>Login</dt>
            <dd>{this.displayLoginMethods()}</dd>

            <dt>Current app</dt>
            <dd>{d.current_app_version}</dd>
          </dl>

          <dl className="details">
            <dt>Email</dt>
            <dd>{d.email || <em>unspecified</em>}</dd>

            <dt>Birthday</dt>
            <dd>
              <Moment date={d.birthday} />
            </dd>

            <dt>Languages</dt>
            <dd>{d.languages.map((t) => t.val).join(", ")}</dd>

            <dt>Tags</dt>
            <dd>{d.tags.map((t) => t.val).join(", ")}</dd>

            <dt>ID</dt>
            <dd>{d.id}</dd>

            <dt>UID</dt>
            <dd>{d.uid}</dd>

            {d.phone_number !== null && (
              <React.Fragment>
                <dt>Phone number</dt>
                <dd>
                  {d.phone_number
                    ? `******${d.phone_number.slice(-3)}`
                    : "Unknown"}
                  &nbsp;
                  {d.phone_number ? (
                    <UserClearPhoneButton api={this.props.api} user={d} />
                  ) : (
                    ""
                  )}
                </dd>
              </React.Fragment>
            )}

            {d.badges.find((badge) => badge === "creator") && (
              <React.Fragment>
                <dt>PayPal</dt>
                <dd>
                  <button
                    className="UserEditPayPalButton"
                    onClick={this.setState.bind(
                      this,
                      { paypalOverlay: true },
                      null
                    )}
                  >
                    {d.paypal || "Not Specified"}
                  </button>
                </dd>
              </React.Fragment>
            )}

            {roles.includes("STAFF") && (
              <>
                <dt>
                  <div style={{ margin: "7px 0" }}>Free Trial</div>
                </dt>
                <Switch id={d.id} />
              </>
            )}
          </dl>
        </div>

        <Avatar size={256} url={d.avatar_url} alt={d.first_name} openInNewTab />

        {this.state.chatOverlay && (
          <SendMessageForm
            api={this.props.api}
            userID={this.props.user.id}
            onCancel={this.setState.bind(this, { chatOverlay: false }, null)}
          />
        )}

        {this.state.statusOverlay && (
          <UserSetStatusForm
            api={this.props.api}
            userID={this.props.user.id}
            status={this.props.user.account_status}
            onCancel={this.setState.bind(this, { statusOverlay: false }, null)}
            onComplete={this.props.onRefresh}
          />
        )}

        {this.state.badgesOverlay && (
          <UserSetBadgesForm
            api={this.props.api}
            userID={this.props.user.id}
            badges={this.props.user.badges}
            onCancel={this.setState.bind(this, { badgesOverlay: false }, null)}
            onComplete={this.props.onRefresh}
          />
        )}

        {this.state.noteOverlay && (
          <UserNotesForm
            api={this.props.api}
            userID={this.props.user.id}
            onCancel={this.setState.bind(this, { noteOverlay: false }, null)}
            onComplete={this.props.onRefresh}
          />
        )}

        {this.state.warningOverlay && (
          <UserWarningOverlay
            api={this.props.api}
            userID={this.props.user.id}
            warnings={this.props.user.warnings}
            warned={this.props.user.warned}
            onCancel={this.setState.bind(this, { warningOverlay: false }, null)}
            onComplete={this.props.onRefresh}
          />
        )}

        {this.state.paypalOverlay && (
          <UserEditPayPalForm
            api={this.props.api}
            userID={this.props.user.id}
            paypal={this.props.user.paypal}
            onCancel={this.setState.bind(this, { paypalOverlay: false }, null)}
            onComplete={this.props.onRefresh}
          />
        )}
      </section>
    );
  }
}

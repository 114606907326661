import React from "react";
import PropTypes from "prop-types";

import ReferralStatsRow from "../components/ReferralStatsRow";

export default class ReferralStats extends React.Component {
  static propTypes = {
    api: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.props.api.Referrals.indexStats().then((data) =>
      this.setState({ referrals: data })
    );
  }

  render() {
    if (!this.state.referrals) return <React.Fragment />;

    return (
      <div className="Referrals">
        <table className="Referrals-table">
          <thead>
            <ReferralStatsRow.Headers />
          </thead>
          <tbody>
            {this.state.referrals.map((r) => (
              <ReferralStatsRow key={r.user.id} api={this.props.api} {...r} />
            ))}
          </tbody>
        </table>
      </div>
    );
  }
}

import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import StringEditor from "../../components/StringEditor";
import TextEditor from "../../components/TextEditor";
import TextEditorDroppable from "../../components/TextEditorDroppable";
import { IconButton, Tooltip } from "@material-ui/core";
import { FileCopy } from "@material-ui/icons";

export default class SEOPageCommon extends React.Component {
  static propTypes = {
    page: PropTypes.shape({
      id: PropTypes.number.isRequired,
      slug: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      heading: PropTypes.string,
      status: PropTypes.string.isRequired,
      summary: PropTypes.string.isRequired,
      body: PropTypes.string,
    }).isRequired,
    allowBlankTitle: PropTypes.bool,
    allowBlankSummary: PropTypes.bool,
    api: PropTypes.object.isRequired,
    onUpdate: PropTypes.func.isRequired,
    className: PropTypes.string,
  };

  static defaultProps = {
    allowBlankTitle: false,
    allowBlankSummary: false,
  };
  constructor(props) {
    super(props);

    this.state = {
      intentionallyBlankHeading: props.page.heading === "",
      intentionallyBlankSummary: props.page.summary === "",
    };

    this.handleBlankHeadingChange = this.handleBlankHeadingChange.bind(this);
    this.handleBlankSummaryChange = this.handleBlankSummaryChange.bind(this);
  }

  handleBlankHeadingChange({ target: { checked } }) {
    this.setState({ intentionallyBlankHeading: checked });
    if (checked) {
      this._doUpdate("heading", "");
    }
  }

  handleBlankSummaryChange({ target: { checked } }) {
    this.setState({ intentionallyBlankSummary: checked });

    if (checked) {
      this._doUpdate("summary", "");
    }
  }

  render() {
    const { allowBlankTitle, allowBlankSummary, ...p } = this.props;
    const { intentionallyBlankHeading, intentionallyBlankSummary } = this.state;

    return (
      <div className={classnames("SEOPageCommon", p.className)}>
        <label>Slug</label>

        {p.page.type === "editorial" ? (
          <div className="inlineUrl">
            {process.env.REACT_APP_MAIN_URL}/editorial/
            <StringEditor
              value={p.page.slug}
              format="^[a-z0-9][a-z0-9/-]+$"
              onSave={this._doUpdate.bind(this, "slug")}
            />{" "}
          </div>
        ) : (
          <>
            <StringEditor
              value={p.page.slug}
              format="^[a-z0-9][a-z0-9/-]+$"
              onSave={this._doUpdate.bind(this, "slug")}
            />
          </>
        )}

        {this.props.children}

        <label>Title tag</label>
        <Tooltip title="Copy from heading">
          <IconButton onClick={() => this._copyHeadingToTitle()}>
            <FileCopy />
          </IconButton>
        </Tooltip>

        <StringEditor
          value={p.page.title}
          onSave={this._doUpdate.bind(this, "title")}
        />

        <label>Meta-description (defaults to summary)</label>
        <TextEditor
          api={this.props.api}
          value={p.page.meta_description}
          placeholder={p.page.summary}
          onSave={this._doUpdate.bind(this, "meta_description")}
        />

        <div className="intentionallyBlank">
          <label>Heading (on-page title, defaults to title tag)</label>
          <TextEditor
            api={this.props.api}
            value={intentionallyBlankHeading ? "" : p.page.heading}
            placeholder={intentionallyBlankHeading ? "" : p.page.title}
            disabled={intentionallyBlankHeading}
            onSave={this._doUpdate.bind(this, "heading")}
          />
          {allowBlankTitle && (
            <label className="intentionallyBlankLabel">
              <input
                type="checkbox"
                checked={intentionallyBlankHeading}
                onChange={this.handleBlankHeadingChange}
              />
              <span>Intentionally leave blank</span>
            </label>
          )}
        </div>

        <div className="intentionallyBlank">
          <label>Summary (first few paragraphs)</label>
          <TextEditorDroppable
            api={this.props.api}
            value={intentionallyBlankSummary ? "" : p.page.summary}
            onSave={this._doUpdate.bind(this, "summary")}
            disabled={intentionallyBlankSummary}
            rows={3}
          />
          {allowBlankSummary && (
            <label className="intentionallyBlankLabel">
              <input
                type="checkbox"
                checked={intentionallyBlankSummary}
                onChange={this.handleBlankSummaryChange}
              />
              <span>Intentionally leave blank</span>
            </label>
          )}
        </div>

        <div>
          <label>Body</label>
          <TextEditorDroppable
            api={this.props.api}
            value={p.page.body}
            showTools
            onSave={this._doUpdate.bind(this, "body")}
            rows={10}
          />
          <p>
            To enable a javascript block you need to add `unsafe:do` (including
            quotes) at the beginning and `unsafe:end` (including quote) at the
            end.
          </p>
        </div>
      </div>
    );
  }

  _doUpdate(field, value) {
    this.props.api.SEO.Pages.update(this.props.page.id, {
      [field]: value,
    }).then(this.props.onUpdate.bind(this));
  }

  _copyHeadingToTitle() {
    this._doUpdate("title", this.props.page.heading);
  }
}

import { Typography } from "@material-ui/core";
import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import ButtonWithConfirmation from "../../components/ButtonWithConfirmation";
import StringEditor from "../../components/StringEditor";
import TextEditor from "../../components/TextEditor";
import useAuthor from "../../hooks/api/useAuthor";
import useMedicalSpecialities from "../../hooks/api/useMedicalSpecialities";
import DropArea from "../../components/DropArea";

import styles from "./Author.module.scss";

function SEOAuthor({ api }) {
  const [reload, setReload] = useState(false);

  let { id } = useParams();

  const { data } = useAuthor(id, reload);

  const { data: medicalSpecialities } = useMedicalSpecialities();

  const updateMedicalSpecialities = (id) => {
    const medSpecialities = data?.medical_speciality_ids;
    if (medSpecialities.includes(id)) {
      medSpecialities.splice(medSpecialities.indexOf(id), 1);
    } else {
      medSpecialities.push(id);
    }
    doUpdateAuthor("medical_speciality_ids", medSpecialities);
  };

  const avatarUrl = () => {
    if (!data?.photo?.url) return;
    const url = data?.photo?.url.replace("{width}", "256");
    return `url(${url})`;
  };

  const doUpdate = (field, value) => {
    api.SEO.Authors.update(id, {
      [field]: value,
    }).then(() => {
      setReload(!reload);
    });
  };

  const doUpdateAuthor = (field, value) => {
    api.SEO.Authors.update(id, {
      author: {
        [field]: value,
      },
    }).then(() => {
      setReload(!reload);
    });
  };

  const handleSlugUpdate = (slug) => {
    const processedSlug = `author/${slug}`;
    doUpdateAuthor("slug", processedSlug);
  };

  const previewUrl =
    data?.page?.language === "es"
      ? data?.page?.url?.replace("/author", "/autor")
      : data?.page?.url;

  return (
    <div className="SEOPage">
      <div className="SEOPages-heading">
        <h1 className="SEOPage-title">
          SEO ▸ <Link to={`/seo/authors`}>Author pages</Link>▸ {data?.name}
        </h1>

        <ButtonWithConfirmation
          title="Warning"
          message={
            data?.page?.status === "pending"
              ? "The page will become visible on the website."
              : "The page will be removed from the website, and links may break."
          }
          onClick={() =>
            doUpdateAuthor(
              "status",
              data?.page?.status === "pending" ? "active" : "pending"
            )
          }
          className="SEOPage-action"
        >
          {data?.page?.status}
        </ButtonWithConfirmation>
        <a className="SEOPage-action" href={previewUrl} target="_preview">
          Preview
        </a>
      </div>

      <div className={styles.personalDetails}>
        <Typography variant="h6">Personal Details</Typography>

        <label>Name</label>

        <StringEditor
          value={data?.name}
          onSave={(name) => doUpdate("name", name)}
        />

        <label>Profile Image</label>
        <div className="SEOPageBlog-author">
          <StringEditor value={data?.name}>
            <DropArea
              api={api}
              onComplete={(data) => doUpdate("photo_id", data.id)}
              className="SEOPageBlog-avatar-container"
            >
              <div
                className="SEOPageBlog-avatar"
                style={{ backgroundImage: avatarUrl() }}
                data-test={avatarUrl()}
              />
            </DropArea>
          </StringEditor>
        </div>

        <label>Job Title</label>
        <StringEditor
          value={data?.page?.job_title}
          onSave={(job_title) => doUpdate("job_title", job_title)}
        />

        <label>Medical Specialities</label>
        <div className={styles.medicalSpecialities}>
          {medicalSpecialities?.map((speciality, index) => (
            <label key={index}>
              <input
                type="checkbox"
                id="med-specialities"
                name="med-specialities"
                checked={data?.medical_speciality_ids.includes(speciality.id)}
                onClick={() => updateMedicalSpecialities(speciality.id)}
              />
              {speciality.name}
            </label>
          ))}
        </div>

        <label>Medical Expert</label>
        <div className="SEOBlogLanguageSelect">
          <input
            type="radio"
            value="true"
            id="medical-checked-true"
            onChange={(e) => doUpdate("medical_expert", true)}
            checked={data?.medical_expert === true}
          />
          <label htmlFor="factCheckedTrue">Yes</label>

          <input
            type="radio"
            value="false"
            id="medical-checked-false"
            onChange={(e) => doUpdate("medical_expert", false)}
            checked={data?.medical_expert === false}
          />
          <label htmlFor="factCheckedFalse">No</label>
        </div>
      </div>

      <div className={styles.contactDetails}>
        <Typography variant="h6">Contact Details</Typography>
        <label>E-mail</label>
        <StringEditor
          value={data?.email}
          onSave={(email) => doUpdate("email", email)}
        />
        <label>Phone Number</label>
        <StringEditor
          value={data?.phone_number}
          onSave={(phone_number) => doUpdate("phone_number", phone_number)}
        />
      </div>

      <div className={styles.socialMedia}>
        <Typography variant="h6">Social Media</Typography>
        <label>LinkedIn URL</label>
        <StringEditor
          value={data?.linkedin_url}
          onSave={(linkedin_url) => doUpdate("linkedin_url", linkedin_url)}
        />
        <label>Personal URL</label>
        <StringEditor
          value={data?.personal_url}
          onSave={(personal_url) => doUpdate("personal_url", personal_url)}
        />
        <label>Workplace URL</label>
        <StringEditor
          value={data?.workplace_url}
          onSave={(workplace_url) => doUpdate("workplace_url", workplace_url)}
        />
        <label>Peanut Group URL</label>
        <StringEditor
          value={data?.peanut_group_url}
          onSave={(peanut_group_url) =>
            doUpdate("peanut_group_url", peanut_group_url)
          }
        />
      </div>

      <div className={styles.seo}>
        <Typography variant="h6">SEO</Typography>
        <label>Language</label>
        <div className="SEOBlogLanguageSelect">
          <input
            type="radio"
            value="en"
            id="languageEn"
            onChange={(e) => doUpdate("language", "en")}
            checked={data?.page?.language === "en"}
          />
          <label htmlFor="languageEn">English</label>
          <input
            type="radio"
            value="es"
            id="languageEs"
            onChange={(e) => doUpdate("language", "es")}
            checked={data?.page?.language === "es"}
          />
          <label htmlFor="languageEs">Spanish</label>
        </div>
        <label>Slug</label>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          {data?.page?.language === "es" ? "autor/" : "author/"}
          <StringEditor
            value={data?.page?.slug.replace("author/", "")}
            onSave={handleSlugUpdate}
          />
        </div>
        <label>Title</label>
        <StringEditor
          value={data?.page?.title}
          onSave={(title) => doUpdate("title", title)}
        />
        <label>Meta Title</label>
        <StringEditor
          value={data?.page?.meta_title}
          onSave={(meta_title) => doUpdate("meta_title", meta_title)}
        />
        <label>Meta Description</label>
        <StringEditor
          value={data?.page?.meta_description}
          onSave={(meta_description) =>
            doUpdate("meta_description", meta_description)
          }
        />

        <div className="intentionallyBlank">
          <label>Summary</label>
          <TextEditor
            value={data?.page?.summary}
            onSave={(summary) => doUpdate("summary", summary)}
            disabled={""}
            rows={3}
          />
        </div>

        <div className="intentionallyBlank">
          <label>Body</label>
          <TextEditor
            value={data?.page?.body}
            onSave={(body) => doUpdate("body", body)}
            disabled={""}
            rows={3}
          />
        </div>
      </div>
    </div>
  );
}

export default SEOAuthor;

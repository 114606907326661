import Base from "./Base";

export default class LiveAudioParticipants extends Base {
  index(params) {
    const { roomId, ...editedParams } = params;
    const q = this._query(editedParams);

    return this._fetch(`/admin/audio_rooms/${roomId}/participants?${q}`)
      .then((response) => response.json())
      .then(({ data: { participants } }) => participants);
  }
}

import React, { Component } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { Player, BigPlayButton } from "video-react";

import StatusButton from "./StatusButton";
import CommentSetStatusForm from "./CommentSetStatusForm";
import CommentSetAbuseForm from "./CommentSetAbuseForm";
import Moment from "./Moment";
import UserLink from "./UserLink";
import Image from "./Image";
import Giphy from "./Giphy";

export default class CommentRow extends Component {
  static propTypes = {
    api: PropTypes.object.isRequired,
    data: PropTypes.shape({
      id: PropTypes.number.isRequired,
    }).isRequired,
  };

  static Headers = () => (
    <tr>
      <th>Metadata</th>
      <th className="CommentRow-post">Post</th>
      <th className="CommentRow-author">Author</th>
      <th>Contents</th>
    </tr>
  );

  constructor(props) {
    super(props);
    this.state = {
      data: Object.assign({}, this.props.data),
      statusOverlay: false,
      abuseOverlay: false,
    };
  }

  render() {
    const c = this.state.data;
    const u = c.author;

    return (
      <tr className="CommentRow">
        <td>
          <ul>
            <li>#{c.id}</li>
            <li>
              <Moment time={c.created_at} />
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={c.deeplink}
                className="OpenAppButton"
              >
                Open In App
              </a>
            </li>
            <li>
              <StatusButton
                status={c.status}
                onClick={this.setState.bind(
                  this,
                  { statusOverlay: true },
                  null
                )}
              >
                {c.status}
              </StatusButton>{" "}
              {c.abuse.map((b, idx) => (
                <React.Fragment key={idx}>
                  <button
                    className="badge badge-pill badge-secondary"
                    onClick={this.setState.bind(
                      this,
                      { abuseOverlay: true },
                      null
                    )}
                  >
                    {b.replace(/_/, " ")}
                  </button>{" "}
                </React.Fragment>
              ))}
              {c.abuse.length === 0 && (
                <button
                  className="badge badge-light"
                  onClick={this.setState.bind(
                    this,
                    { abuseOverlay: true },
                    null
                  )}
                >
                  ok
                </button>
              )}
              {this.state.statusOverlay && (
                <CommentSetStatusForm
                  api={this.props.api}
                  commentID={c.id}
                  status={c.status}
                  onCancel={this.setState.bind(
                    this,
                    { statusOverlay: false },
                    null
                  )}
                  onComplete={this._onRefresh.bind(this)}
                />
              )}
              {this.state.abuseOverlay && (
                <CommentSetAbuseForm
                  api={this.props.api}
                  commentID={c.id}
                  abuse={c.abuse}
                  onCancel={this.setState.bind(
                    this,
                    { abuseOverlay: false },
                    null
                  )}
                  onComplete={this._onRefresh.bind(this)}
                />
              )}
            </li>
          </ul>
        </td>

        <td className="CommentRow-post">
          <Link to={`/posts/${c.post_id}`}>#{c.post_id}</Link>
        </td>

        {/*
          FIXME: common with PostItem-author
        */}
        <td className="CommentRow-author">
          <UserLink {...u} />
        </td>

        <td>
          {c.giphy && <Giphy {...c.giphy} />}
          {c.image && (
            <Image
              url={c.image ? c.image.url : "none"}
              className="CommentRow-Image"
            />
          )}

          {c.video && (
            <>
              <Player
                ref={(player) => {
                  this.player = player;
                }}
                playsInline
                poster={c.video.thumbnail_url}
                src={c.video.url}
              >
                <BigPlayButton position="center" />
              </Player>
              <Link to={`/videos/${c.video.id}`}>View Video Details</Link>
              <br />
            </>
          )}
          {c.body}
        </td>
      </tr>
    );
  }

  _onRefresh() {
    this.props.api.Comments.show(this.props.data.id).then((data) =>
      this.setState({ data: data })
    );
  }
}

import Base from "./Base";

export default class StoredVideos extends Base {
  index(params) {
    const q = this._query(params);

    return this._fetch(`/admin/stored_videos?${q}`)
      .then((response) => response.json())
      .then((data) => [data.stored_videos]);
  }

  show(id, params = {}) {
    const q = this._query(params);

    return this._fetch(`/admin/stored_videos/${id}?${q}`)
      .then((response) => response.json())
      .then((data) => data.stored_video);
  }

  update(id, params) {
    return this._fetch(`/admin/stored_videos/${id}`, {
      method: "PATCH",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(params),
    });
  }
}

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import { Chip } from "@material-ui/core";
import UserFilterDialog from "./UserFilterDialog";

const filterLabels = {
  first_name: "First name",
  last_name: "Last name",
  email: "Email",
  id: "ID",
  uid: "UID",
};

const UserFilter = ({ onChange, title = "Filter Users", values }) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [filters, setFilters] = useState(values);

  useEffect(() => {
    setFilters(values);
  }, [values]);

  const handleDialogOpen = () => {
    setDialogOpen(true);
  };
  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleChange = (values) => {
    onChange(values);
  };

  const handleDelete = (key) => {
    const updatedValues = { ...values, [key]: null };
    onChange(updatedValues);
  };

  return (
    <div>
      <div>
        {!Object.values(values).some((value) => value && value.length > 0) && (
          <Chip color="primary" label="All" onClick={handleDialogOpen} />
        )}
        {Object.keys(filters).map((key) => {
          return (
            filters[key] && (
              <Chip
                color="primary"
                label={filters[key]}
                onDelete={() => handleDelete(key)}
              />
            )
          );
        })}
        <Chip label="Select" onClick={handleDialogOpen} variant="outlined" />
      </div>

      <UserFilterDialog
        labels={filterLabels}
        onChange={handleChange}
        onClose={handleDialogClose}
        open={dialogOpen}
        title={title}
        values={filters}
      />
    </div>
  );
};

UserFilter.propTypes = {
  onChange: PropTypes.func.isRequired,
  title: PropTypes.string,
  values: PropTypes.arrayOf(PropTypes.string).isRequired,
};

UserFilter.defaultProps = {
  values: {},
  onChange: () => {},
};

export default UserFilter;

import Base from "../Base";

export default class Posts extends Base {
  index(params) {
    const q = this._query(params);

    return this._fetch(`/admin/seo/posts?${q}`)
      .then((response) => response.json())
      .then((data) => [data.posts, data.cursor]);
  }

  update({ id, ...params }) {
    return this._fetch(`/admin/seo/posts/${id}`, {
      method: "PATCH",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(params),
    }).then((response) => response.json());
  }
}

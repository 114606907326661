import React, { Component } from "react";
import PropTypes from "prop-types";
import clone from "clone";
import classnames from "classnames";

import Moment from "./Moment";
import Avatar from "./Avatar";
import UserLink from "./UserLink";
import StateButton from "./StateButton";
import StatusButton from "./StatusButton";
import UserPhotoSetStatusForm from "./UserPhotoSetStatusForm";

class UserPhotoRowHeaders extends React.Component {
  static defaultProps = {
    mode: "all",
  };

  render() {
    const p = this.props;

    return (
      <tr>
        <th>Metadata</th>
        <th>Photo</th>
        <th className="UserPhotoRow-user">User</th>

        {p.mode === "all" && <th>Gender/Nudity (detected)</th>}
        {p.mode === "gender" && <th>Gender (detected)</th>}
        {p.mode === "nudity" && <th>Nudity (detected)</th>}

        {p.mode === "all" && <th>Confirm gender/nudity</th>}
        {p.mode === "gender" && <th>Confirm gender</th>}
        {p.mode === "nudity" && <th>Confirm nudity</th>}
      </tr>
    );
  }
}

export default class UserPhotoRow extends Component {
  static propTypes = {
    api: PropTypes.object.isRequired,
    onUpdate: PropTypes.func,

    data: PropTypes.shape({
      id: PropTypes.number.isRequired,
      created_at: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
      details: PropTypes.shape({
        detected_nsfw: PropTypes.bool,
        confirmed_nsfw: PropTypes.bool,
        detected_gender: PropTypes.string,
        confirmed_gender: PropTypes.string,
      }),
      user: PropTypes.shape({
        id: PropTypes.number.isRequired,
        uid: PropTypes.string.isRequired,
        first_name: PropTypes.string,
        last_name: PropTypes.string,
      }).isRequired,
    }).isRequired,

    mode: PropTypes.string, // gender, nudity, all
  };

  static defaultProps = {
    onUpdate: () => {},
    mode: "all",
  };

  static Headers = UserPhotoRowHeaders;

  constructor(props) {
    super(props);
    this.state = {
      data: clone(this.props.data),
      isLoading: false,
      statusOverlay: false,
    };
  }

  render() {
    const p = this.state.data;
    const d = this.state.data.details;
    const u = this.state.data.user;

    const c = classnames({
      UserPhotoRow: true,
      isLoading: this.state.isLoading,
    });

    return (
      <tr className={c} key={p.id}>
        <td>
          {p.id}
          <br />
          <Moment relative time={p.created_at} />
          <br />
          <span className="badge badge-pill badge-secondary">
            {this._source(p)}
          </span>
          <br />
          {p.selfie || (
            <StatusButton
              status={p.status === "active"}
              onClick={this.setState.bind(this, { statusOverlay: true }, null)}
            >
              {p.status}
            </StatusButton>
          )}
          {this._renderStatusOverlay()}
        </td>
        <td>
          <Avatar
            alt={u.first_name}
            url={p.url}
            size={128}
            nudity={d.confirmed_nsfw}
            openInNewTab
          />
        </td>
        <td className="UserPhotoRow-user">
          <UserLink {...u} />
        </td>
        {this._renderGender()}
      </tr>
    );
  }

  _renderStatusOverlay() {
    if (!this.state.statusOverlay) return;

    return (
      <UserPhotoSetStatusForm
        api={this.props.api}
        photoID={this.props.data.id}
        status={this.state.data.status}
        onCancel={this.setState.bind(this, { statusOverlay: false }, null)}
        onComplete={this._refresh.bind(this)}
      />
    );
  }

  _source(p) {
    return p.selfie ? "selfie" : p.source;
  }

  _renderGender() {
    const p = this.props;
    const d = this.state.data.details;
    if (!d) {
      return;
    }

    return (
      <>
        <td>
          {(p.mode === "all" || p.mode === "gender") && (
            <p>
              <Gender className="disabled" value={d.detected_gender} />
            </p>
          )}
          {(p.mode === "all" || p.mode === "nudity") && (
            <p>
              <Nudity className="disabled" value={d.detected_nsfw} />
            </p>
          )}
        </td>
        <td>
          {(p.mode === "all" || p.mode === "gender") && (
            <p className="actions">
              {["F", "M", "P", "N"].map((g, idx) => (
                <Gender
                  key={idx}
                  value={g}
                  current={d.confirmed_gender}
                  onClick={this._updateGender.bind(this, g)}
                />
              ))}
            </p>
          )}
          {(p.mode === "all" || p.mode === "nudity") && (
            <p className="actions">
              <Nudity
                value={false}
                current={d.confirmed_nsfw}
                onClick={this._updateNudity.bind(this, false)}
              />
              <Nudity
                value={true}
                current={d.confirmed_nsfw}
                onClick={this._updateNudity.bind(this, true)}
              />
            </p>
          )}
        </td>
      </>
    );
  }

  _refresh() {
    this.setState({ isLoading: true }, () => {
      this.props.api.UserPhotos.show(this.state.data.id).then((data) => {
        this.setState({ isLoading: false, data: data });
        this.props.onUpdate();
      });
    });
  }

  _updateGender(value, e) {
    // XXX move to component
    if (e !== undefined) {
      e.preventDefault();
    }

    this.setState({ isLoading: true }, () => {
      this.props.api.UserPhotos.update(this.state.data.id, {
        confirmed_gender: value,
      }).then((data) => {
        this.setState({ isLoading: false, data: data });
        this.props.onUpdate();
      });
    });
  }

  _updateNudity(value, e) {
    if (e !== undefined) {
      e.preventDefault();
    }

    this.setState({ isLoading: true }, () => {
      this.props.api.UserPhotos.update(this.state.data.id, {
        confirmed_nsfw: value,
      }).then((data) => {
        this.setState({ isLoading: false, data: data });
        this.props.onUpdate();
      });
    });
  }
}

class Gender extends Component {
  static propTypes = {
    current: PropTypes.string,
    value: PropTypes.string,
    onClick: PropTypes.func,
  };

  render() {
    return (
      <StateButton
        className="Gender"
        icon={this._icon.bind(this)}
        {...this.props}
      />
    );
  }

  _icon(gender) {
    switch (gender) {
      case "M":
        return "👨 male";
      case "F":
        return "👩 female";
      case "P":
        return "👶 peanut";
      case "N":
        return "👥 none";
      default:
        return "👤 unknown";
    }
  }
}

class Nudity extends Component {
  static propTypes = {
    current: PropTypes.bool,
    value: PropTypes.bool,
    onClick: PropTypes.func,
  };

  render() {
    return (
      <StateButton
        className="Nudity"
        icon={this._icon.bind(this)}
        {...this.props}
      />
    );
  }

  _icon(value) {
    switch (value) {
      case true:
        return "😈 nudity";
      case false:
        return "😇 safe";
      default:
        return "👤 unknown";
    }
  }
}

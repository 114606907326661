import React, { Component } from "react";
import PropTypes from "prop-types";

import PostItem from "../PostItem";
import SearchResults from "../SearchResults";

class GroupTabPosts extends Component {
  render() {
    return (
      <SearchResults
        api={this.props.api.Posts}
        query={{ group_id: this.props.group_id }}
      >
        {(results) => (
          <table className="Posts-table">
            <thead>
              <PostItem.Headers />
            </thead>

            <tbody>
              {results.items().map((n) => (
                <PostItem key={n.uid} {...n} />
              ))}
            </tbody>

            <tfoot>
              <tr>
                <td>
                  <results.AutoLoad />
                </td>
              </tr>
            </tfoot>
          </table>
        )}
      </SearchResults>
    );
  }
}

GroupTabPosts.propTypes = {
  api: PropTypes.object,
};

export default GroupTabPosts;

import React, { Component } from "react";
import PropTypes from "prop-types";
import Moment from "./Moment";

export default class UserFeatureRow extends Component {
  static propTypes = {
    user_feature: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = { user_feature: props.user_feature };
  }

  static Headers = () => (
    <tr>
      <td>Feature</td>
      <td>Enabled</td>
      <td>Enabled at</td>
      <td>Access until</td>
    </tr>
  );

  render() {
    const d = this.state.user_feature;
    console.log(d);

    return (
      <tr className="DeviceRow" key={d.id}>
        <td>{d.code}</td>
        <td>{d.enabled ? "Yes" : "No"}</td>
        <td>
          <Moment time={d.enabled_at} />
        </td>
        <td>
          <Moment time={d.access_until} />
        </td>
      </tr>
    );
  }
}

import AgoraRTC from "agora-rtc-sdk-ng";
import React, { useEffect } from "react";

const APP_ID = process.env.REACT_APP_AGORA_APP_ID;

function PodListener(props) {
  const { accessToken, roomId, agoraUid } = props;
  const client = AgoraRTC.createClient({ mode: "live", codec: "vp8" });

  client.setClientRole("audience");
  client.on("user-published", async (user, mediaType) => {
    await client.subscribe(user, mediaType);

    if (mediaType === "audio") {
      const audioTrack = user.audioTrack;
      audioTrack.play();
    }
  });

  useEffect(() => {
    async function joinRoom() {
      await client.join(APP_ID, roomId, accessToken, agoraUid);
    }

    joinRoom();

    return () => {
      client.leave();
    };
  }, [accessToken, agoraUid, roomId, client]);

  return (
    <div style={{ textAlign: "right" }}>
      <div className="StatusButton StatusButton--true">Listening Live</div>
    </div>
  );
}

export default PodListener;

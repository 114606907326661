import Base from "./Base";

export default class Comments extends Base {
  index(params) {
    const q = this._query(params);

    return this._fetch(`/admin/pages/comments?${q}`)
      .then((response) => response.json())
      .then((data) => [data.comments]);
  }

  show(id) {
    return this._fetch(`/admin/pages/comments/${id}`)
      .then((response) => response.json())
      .then((data) => data.comment);
  }

  update({ id, ...params }) {
    return this._fetch(`/admin/pages/comments/${id}`, {
      method: "PATCH",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(params),
    });
  }
}

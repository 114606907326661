import Base from "./Base";

export default class Users extends Base {
  index(params) {
    const q = this._query(params);

    return this._fetch(`/admin/cases?${q}`)
      .then((response) => response.json())
      .then((data) => [data.cases]);
  }

  update(id, params) {
    return this._fetch(`/admin/cases/${id}`, {
      method: "PATCH",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(params),
    }).then((response) => response.json());
  }

  claim(id) {
    return this.update(id, { claim: true });
  }

  unclaim(id) {
    return this.update(id, { claim: false });
  }

  showStats() {
    return this._fetch(`/admin/cases/stats`).then((response) =>
      response.json()
    );
  }
}

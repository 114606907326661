import React, { Component } from "react";
import PropTypes from "prop-types";

import ModalForm from "./ModalForm";

export default class UserNoteForm extends Component {
  static propTypes = {
    api: PropTypes.object.isRequired,
    userID: PropTypes.number.isRequired,
    onCancel: PropTypes.func.isRequired,
    onComplete: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = { response: null, noteBody: "" };
  }

  render() {
    return (
      <ModalForm
        className="UserNoteForm"
        title="Add note to user history"
        onCancel={this.props.onCancel}
        form={
          <React.Fragment>
            <label>Note</label>
            <textarea
              className="form-control"
              value={this.state.body}
              onChange={this._onFieldChange.bind(this)}
              disabled={this.state.sent}
              ref={(e) => {
                e && e.focus();
              }}
            />
          </React.Fragment>
        }
        actions={
          <React.Fragment>
            <span>{this.state.response}</span>
            <button
              type="button"
              className="btn btn-primary"
              onClick={this._onSubmit.bind(this)}
            >
              Save
            </button>
          </React.Fragment>
        }
      />
    );
  }

  _onFieldChange(event) {
    const value = event.target.value;
    this.setState({ noteBody: value });
  }

  _onSubmit() {
    this.props.api.Users.addNote(this.props.userID, this.state.noteBody)
      .then(() => {
        this.props.onCancel();
        this.props.onComplete();
      })
      .catch((e) => {
        console.error(e);
        this.setState({ response: "Save failed!" });
      });
  }
}

import React, { Component } from "react";
import PropTypes from "prop-types";

import SearchResults from "./SearchResults";
import DeviceRow from "./DeviceRow";

export default class UserTabDevices extends Component {
  static propTypes = {
    api: PropTypes.object.isRequired,
    userId: PropTypes.string.isRequired,
  };

  render() {
    return (
      <SearchResults
        key="devices"
        api={this.props.api.UserDevices}
        query={{ user_id: this.props.userId }}
      >
        {(results) => (
          <table className="UserTabDevices">
            <thead>
              <DeviceRow.Headers />
            </thead>
            <tbody>
              {results.items().map((d) => (
                <DeviceRow key={d.id} api={this.props.api} device={d} />
              ))}
            </tbody>
            <tfoot>
              <tr>
                <td>
                  <results.AutoLoad />
                </td>
              </tr>
            </tfoot>
          </table>
        )}
      </SearchResults>
    );
  }
}

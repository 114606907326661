import React, { Component } from "react";
import PropTypes from "prop-types";

import ContentLabellingJobForm from "../../components/ContentLabellingJobs/ContentLabellingJobForm";

export default class ContentLabellingJobNew extends Component {
  static propTypes = {
    api: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      job: {
        content_labels: [
          { title: "", description: "" },
          { title: "", description: "" },
        ],
      },
      errors: {},
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(job) {
    this.props.api.ContentLabellingJobs.create(job).then((data) => {
      if (data.errors) {
        this.setState({ errors: data.errors });
      } else {
        this.props.history.push({
          pathname: `/content-labelling-jobs/${data.id}`,
          state: {
            alert: {
              message: "Content Labelling Job was successfully created",
              className: "alert-success",
            },
          },
        });
      }
    });
  }

  render() {
    return (
      <div className="container pt-4 pb-4">
        <h4>Create New Content Labelling Job</h4>
        <ContentLabellingJobForm
          job={this.state.job}
          handleSubmit={this.handleSubmit}
          api={this.props.api}
          errors={this.state.errors}
        />
      </div>
    );
  }
}

import React, { Component } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

import h from "./helpers";

const phrases = {
  status: [
    [/left,invited/, "re-invited to"],
    [/declined,invited/, "re-requested access to"],
    [/invited,declined/, "declined the request to join"],
    [/left,requested/, "re-requested access to"],
    [/rejected,requested/, "re-requested (previously rejected) access to"],
    [/requested,joined/, "approved user's membership of"],
    [/requested,rejected/, "rejected user's membership of"],
    [/joined,left/, "left"],
    [/joined,kicked/, "kicked the user from"],
    [/null,invited/, "invited user"],
    [/null,requested/, "requested access to"],
  ],

  default: "takes no action on group",
};

export default class HistoryGroupMembership extends Component {
  static propTypes = {
    userID: PropTypes.number,
    id: PropTypes.number.isRequired,
    created_at: PropTypes.string.isRequired,
    changeset: PropTypes.object.isRequired,
    notes: PropTypes.string,
    created_by: PropTypes.shape({
      id: PropTypes.number.isRequired,
      first_name: PropTypes.string.isRequired,
    }).isRequired,
    group: PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired,
  };

  static type = "history/pages_group_membership";
  static icon = "👥";

  render() {
    const p = this.props;
    console.log("this.props :>> ", this.props);
    return (
      <tr className="EventHistoryGroupMembership">
        {h.sharedColumns(this)}
        <td>
          {h.renderActor(p.created_by, p.userID)}{" "}
          {h.renderActions(p.changeset, phrases)}{" "}
          <Link to={`/groups/${p.group.id}`}>{p.group.name}</Link>{" "}
          {h.renderNotes(p.notes)}
        </td>
        <td></td>
      </tr>
    );
  }
}

import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import Errors from "../Errors";

const validMimes = ["image/jpeg", "image/png"];

export default class DropArea extends React.Component {
  static propTypes = {
    api: PropTypes.object.isRequired,
    onComplete: PropTypes.func.isRequired,
    disabled: PropTypes.string,
    className: PropTypes.string,
  };

  constructor(props) {
    super(props);
    this.state = { hover: false };
  }

  render() {
    return (
      <div
        className={classnames(
          "DropArea",
          this.state.hover && "DropArea--hover",
          this.props.className
        )}
        onDragEnter={this.setState.bind(this, { hover: true }, null)}
        onDragLeave={this.setState.bind(this, { hover: false }, null)}
        onDrop={this._onDrop.bind(this)}
      >
        {this.props.children}
      </div>
    );
  }

  componentDidMount() {
    if (!window._dragEventsStubbed) {
      window.addEventListener("dragover", (e) => e.preventDefault());
      window.addEventListener("drop", (e) => e.preventDefault());
      window._dragEventsStubbed = true;
    }
  }

  _onDrop(e) {
    e.preventDefault();
    this.setState({ hover: false });

    if (this.props.disabled) {
      Errors.notify("warning", this.props.disabled);
      return;
    }

    const dt = e.dataTransfer;
    if (!dt || dt.files.length !== 1) {
      Errors.notify("warning", "Only one file can be dropped");
      return;
    }

    const file = dt.files[0];
    if (!validMimes.includes(file.type)) {
      Errors.notify("warning", `Invalid file type ${file.type}`);
      return;
    }

    // get size of image
    const pr = new Promise((resolve, reject) => {
      const localUrl = URL.createObjectURL(file);
      const tester = new Image();

      const timeout = setTimeout(() => {
        reject("Not an image");
      }, 1000);

      tester.onload = () => {
        clearTimeout(timeout);
        resolve({ width: tester.width, height: tester.height });
      };

      tester.src = localUrl;
    });

    pr.then((data) => {
      return this.props.api.Images.upload(file, data);
    })
      .then((data) => {
        if (this.props.onComplete) {
          this.props.onComplete(data);
        }
      })
      .catch((e) => {
        console.log("error", e);
        Errors.notify("error", e);
      });
  }
}

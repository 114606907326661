import React, { Component } from "react";
import PropTypes from "prop-types";

import SearchResults from "./SearchResults";
import LiveAudioRoomRow from "./LiveAudioRoomRow";

export default class UserTabLiveAudioRooms extends Component {
  static propTypes = {
    api: PropTypes.object.isRequired,
    participantUserId: PropTypes.string.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      live_audio_rooms: [],
    };
  }

  render() {
    return (
      <SearchResults
        api={this.props.api.LiveAudioRooms}
        query={{
          participant_user_id: this.props.participantUserId,
          order: "desc",
        }}
      >
        {(results) => (
          <table className="UserTabLiveAudioRooms">
            <thead>
              <LiveAudioRoomRow.Headers />
            </thead>
            <tbody>
              {results.items().map((r) => (
                <LiveAudioRoomRow key={r.id} {...r} />
              ))}
            </tbody>
            <tfoot>
              <tr>
                <td>
                  <results.AutoLoad />
                </td>
              </tr>
            </tfoot>
          </table>
        )}
      </SearchResults>
    );
  }
}

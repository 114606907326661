import { useEffect, useState } from "react";

const useInput = (initialValue = "") => {
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const handleSet = (value) => {
    setValue(value);
  };

  return {
    value,
    onChange: handleChange,
    set: handleSet,
  };
};

export default useInput;

import React, { Component } from "react";
import PropTypes from "prop-types";

import ContentLabellingJobForm from "../../components/ContentLabellingJobs/ContentLabellingJobForm";

export default class ContentLabellingJobEdit extends Component {
  static propTypes = {
    api: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = { job: {}, errors: {} };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    this.props.api.ContentLabellingJobs.show(this.props.match.params.id).then(
      (data) => {
        this.setState({ job: data.record });
      }
    );
  }

  handleSubmit(job) {
    this.props.api.ContentLabellingJobs.update(job).then((data) => {
      if (data.errors) {
        this.setState({ errors: data.errors });
      } else {
        this.props.history.push({
          pathname: `/content-labelling-jobs/${job.id}`,
          state: {
            alert: {
              message: "Content Labelling Job was successfully updated",
              className: "alert-success",
            },
          },
        });
      }
    });
  }

  render() {
    const { job } = this.state;

    if (job.id === undefined) {
      return <p>Loading...</p>;
    }

    return (
      <div className="container pt-4 pb-4">
        <h4>Edit {job.title}</h4>
        <ContentLabellingJobForm
          job={job}
          handleSubmit={this.handleSubmit}
          api={this.props.api}
          errors={this.state.errors}
          edit={true}
        />
      </div>
    );
  }
}

import Base from "./Base";

export default class Posts extends Base {
  index(params) {
    const q = this._query(params);

    return this._fetch(`/admin/pages/posts?${q}`)
      .then((response) => response.json())
      .then((data) => [data.posts]);
  }

  show(id) {
    return this._fetch(`/admin/pages/posts/${id}`)
      .then((response) => response.json())
      .then((data) => data.post);
  }

  update({ id, ...params }) {
    return this._fetch(`/admin/pages/posts/${id}`, {
      method: "PATCH",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(params),
    })
      .then((response) => response.json())
      .then((data) => data.post);
  }

  publishPost(id, params) {
    return this._fetch(`/admin/pages/posts/published_posts/${id}`, {
      method: "PATCH",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(params),
    });
  }

  removePhoto(id, note) {
    return this._fetch(`/admin/pages/posts/${id}/image`, {
      method: "DELETE",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ note: note }),
    }).then((response) => response);
  }

  share(id) {
    return this._fetch(`/admin/pages/posts/${id}/share`).then((response) =>
      response.json()
    );
  }
}

import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

import StringEditor from "../../components/StringEditor";
import TextEditor from "../../components/TextEditor";

export default class SEOPageCommunity extends React.Component {
  static propTypes = {
    page: PropTypes.shape({
      id: PropTypes.number.isRequired,
      slug: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      heading: PropTypes.string,
      status: PropTypes.string.isRequired,
      summary: PropTypes.string.isRequired,
      category: PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
      }).isRequired,
      subcategories: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number.isRequired,
          name: PropTypes.string.isRequired,
          slug: PropTypes.string.isRequired,
          title: PropTypes.string,
          heading: PropTypes.string,
          summary: PropTypes.string,
        })
      ).isRequired,
    }).isRequired,

    api: PropTypes.object.isRequired,
    onUpdate: PropTypes.func.isRequired,
  };

  render() {
    const p = this.props.page;

    return (
      <div className="SEOPageCommunity">
        <section>
          <h2>
            Category #{p.category.id} "{p.category.name}"
          </h2>

          <div className="SEOPageCommunity-form-group">
            <label>Title</label>
            <StringEditor
              value={p.title}
              onSave={this._doUpdate.bind(this, "title")}
            />
          </div>

          <div className="SEOPageCommunity-form-group">
            <label>Heading</label>
            <StringEditor
              value={p.heading}
              placeholder={p.title}
              onSave={this._doUpdate.bind(this, "heading")}
            />
          </div>

          <div className="SEOPageCommunity-form-group">
            <label>Slug</label>
            <StringEditor
              value={p.slug}
              format="^[a-z0-9][a-z0-9-]+$"
              onSave={this._doUpdate.bind(this, "slug")}
            />
          </div>

          <div className="SEOPageCommunity-form-group">
            <label>Summary</label>
            <TextEditor
              value={p.summary}
              onSave={this._doUpdate.bind(this, "summary")}
              rows={7}
            />
          </div>
        </section>

        {p.subcategories.map((sc) => (
          <section key={sc.id} className="SEOPage-subcategory">
            <h2>
              Subcategory #{sc.id} "{sc.name}"
            </h2>

            <div className="SEOPageCommunity-form-group">
              <label>Title</label>
              <StringEditor
                value={sc.title}
                onSave={this._doUpdateSubcat.bind(this, sc.id, "title")}
              />
            </div>

            <div className="SEOPageCommunity-form-group">
              <label>Heading</label>
              <StringEditor
                value={sc.heading}
                placeholder={sc.title}
                onSave={this._doUpdateSubcat.bind(this, sc.id, "heading")}
              />
            </div>

            <div className="SEOPageCommunity-form-group">
              <label>Slug</label>
              <StringEditor
                value={sc.slug}
                format="^[a-z0-9][a-z0-9-]+$"
                onSave={this._doUpdateSubcat.bind(this, sc.id, "slug")}
              />
            </div>

            <div className="SEOPageCommunity-form-group">
              <label>Summary</label>
              <TextEditor
                value={sc.summary}
                onSave={this._doUpdateSubcat.bind(this, sc.id, "summary")}
                rows={3}
              />
            </div>

            <div className="SEOPageCommunity-form-group">
              <label>Posts</label>
              <ul className="SEOPageCommunity-posts">
                {!p.posts[sc.id] && <li>(none)</li>}
                {p.posts[sc.id] &&
                  p.posts[sc.id].map((p) => (
                    <li key={p.id}>
                      <Link to={`/seo/posts/?mode=all&id_query=${p.id}`}>
                        #{p.id}
                      </Link>{" "}
                      {p.title} | {p.body}
                    </li>
                  ))}
              </ul>
            </div>
          </section>
        ))}
      </div>
    );
  }

  _doUpdate(field, value) {
    this.props.api.SEO.Pages.update(this.props.page.id, {
      [field]: value,
    }).then(this.props.onUpdate.bind(this));
  }

  _doUpdateSubcat(id, field, value) {
    this.props.api.SEO.Pages.update(id, { [field]: value }).then(
      this.props.onUpdate.bind(this)
    );
  }
}

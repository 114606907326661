import React from "react";

import SearchForm from "../components/SearchForm";
import SearchResults from "../components/SearchResults";
import SearchHistory from "../components/SearchHistory";
import CaseRow from "../components/CaseRow";
import CaseStats from "../components/CaseStats";
import SelectAdminUsers from "../components/SelectAdminUsers";

const validBadges = ["ambassador", "trusted"];

export default class Cases extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userId: this.props.api.Session.user.id.toString(),
      adminUsers: [],
      query: {
        claimed_by_id: "all",
        status: "open",
        user_status: "",
        user_type: "",
      },
      claimedByName: "all",
    };

    this._onClaimedByIdChange = this._onClaimedByIdChange.bind(this);
    this._displayEmptyResults = this._displayEmptyResults.bind(this);
  }

  _onClaimedByIdChange({
    target: {
      value: claimed_by_id,
      options = [{ text: "all" }],
      selectedIndex = 0,
    },
  }) {
    const { query: previousQuery } = this.state;
    const claimedByName = options[selectedIndex].text;

    this.setState({
      query: {
        ...previousQuery,
        claimed_by_id,
      },
      claimedByName,
    });
  }

  _displayEmptyResults(results) {
    if (results.length > 0) {
      return <p></p>;
    }
    const { claimedByName } = this.state;
    let selectedUserText;
    switch (claimedByName.toLowerCase()) {
      case "all":
        selectedUserText = "There are no cases assigned";
        break;
      case "mine only":
        selectedUserText = "You have no cases assigned";
        break;
      default:
        selectedUserText = `${claimedByName} has no cases assigned`;
    }

    return (
      <div style={{ textAlign: "center" }}>
        <p>{selectedUserText}</p>
        <button value="all" onClick={this._onClaimedByIdChange}>
          View all cases
        </button>
      </div>
    );
  }

  render() {
    const {
      query: { claimed_by_id },
    } = this.state;

    return (
      <div className="Cases">
        <div className="Cases-top">
          <SearchForm
            query={this.state.query}
            onSubmit={(q) => this.setState({ query: q })}
          >
            {(form) => (
              <React.Fragment>
                <form.CheckGroup
                  name="status"
                  title="Status"
                  options={["open", "closed"]}
                  labels={["Open", "Closed"]}
                />

                <form.CheckGroup
                  name="user_status"
                  title="User Status"
                  options={["active", "censored", "banned"]}
                  labels={["Active", "Censored", "Banned"]}
                />

                <form.CheckGroup
                  name="badges"
                  title="Badges"
                  options={validBadges}
                  labels={validBadges}
                />
                <form.CheckGroup
                  name="from_badges"
                  title="Created By MVP"
                  options={validBadges}
                  labels={validBadges}
                />
                <form.RadioGroup
                  name="language"
                  title="Language"
                  options={["", "es"]}
                  labels={["Any", "Spanish"]}
                />
                <div>
                  <label>Assigned to:</label>
                  <SelectAdminUsers
                    value={claimed_by_id}
                    onChange={this._onClaimedByIdChange}
                    api={this.props.api}
                  >
                    <option value="all">All</option>
                    <option value={this.state.userId}>Mine only</option>
                  </SelectAdminUsers>
                </div>
              </React.Fragment>
            )}
          </SearchForm>
          <CaseStats api={this.props.api} />
        </div>

        <SearchHistory
          query={Object.assign({}, this.state.query)}
          onSubmit={(q) => this.setState({ query: q })}
        />

        <SearchResults
          api={this.props.api.Cases}
          query={Object.assign({}, this.state.query)}
          displayOnEmpty={this._displayEmptyResults}
        >
          {(results) => (
            <table className="Cases-table">
              <thead>
                <CaseRow.Headers />
              </thead>
              <tbody>
                {results
                  ?.items()
                  .filter(
                    (item) =>
                      item.last_report_at !== null && item.reports.length !== 0
                  )
                  .map((x) => (
                    <CaseRow key={x.user.id} case={x} api={this.props.api} />
                  ))}
              </tbody>
              <tfoot>
                <tr>
                  <td colSpan={CaseRow.Columns}>
                    <results.AutoLoad />
                  </td>
                </tr>
              </tfoot>
            </table>
          )}
        </SearchResults>
      </div>
    );
  }
}

import React, { Component } from "react";
import PropTypes from "prop-types";

export default class ExperimentStatusBadge extends Component {
  static propTypes = {
    data: PropTypes.shape({
      started_at: PropTypes.string,
      ended_at: PropTypes.string,
    }),
  };

  render() {
    const exp = this.props.data;

    let status;

    if (exp.started_at && exp.ended_at) {
      status = "ended";
    } else if (exp.started_at) {
      status = "started";
    } else {
      status = "pending";
    }

    return <span className={`Experiment-${status}`}>{status}</span>;
  }
}

import React, { Component } from "react";
import PropTypes from "prop-types";

import classnames from "classnames";

const kEditableAbuse = ["ad", "hate", "other", "nudity"];

const kActions = [
  {
    title: "Ad / Commercial",
    icon: "💵",
    abuse: "ad",
    selected: (abuse) => abuse.includes("ad"),
  },
  {
    title: "Hate / Abusive",
    icon: "🤬",
    abuse: "hate",
    selected: (abuse) => abuse.includes("hate"),
  },
  {
    title: "Nudity",
    icon: "😈",
    abuse: "nudity",
    selected: (abuse) => abuse.includes("nudity"),
  },
  {
    title: "Other / Inappropriate",
    icon: "🙅",
    abuse: "other",
    selected: (abuse) => abuse.includes("other"),
  },
  {
    title: "Ok",
    icon: "😊",
    abuse: null,
    selected: (abuse) =>
      abuse.filter((a) => kEditableAbuse.includes(a)).length === 0,
  },
];

export default class ContentAbuseEditor extends Component {
  static propTypes = {
    api: PropTypes.object.isRequired,
    system: PropTypes.array.isRequired,
    subjectID: PropTypes.number.isRequired,
    reasons: PropTypes.object.isRequired, // reason => count
    abuse: PropTypes.array.isRequired,

    onUpdate: PropTypes.func,
  };

  render() {
    const p = this.props;

    return (
      <table className="ContentAbuseEditor">
        <tbody>
          <tr>
            {kActions.map((a, idx) => (
              <td ref={idx}>
                <button
                  className={classnames(
                    "ContentAbuseEditor-button",
                    a.selected(p.abuse)
                      ? "ContentAbuseEditor-button--active"
                      : "ContentAbuseEditor-button--inactive",
                    p.reasons[a.abuse] && "ContentAbuseEditor-button--abuse"
                  )}
                  title={a.title}
                  onClick={this._onClick.bind(this, a.abuse)}
                >
                  <span className="ContentAbuseEditor-emoji">{a.icon}</span>
                </button>
              </td>
            ))}
          </tr>
          <tr>
            {kActions.map((a, idx) => (
              <td ref={idx}>
                {a.abuse && p.reasons[a.abuse] > 0 && (
                  <span className="ContentAbuseEditor-count">
                    {p.reasons[a.abuse]}
                  </span>
                )}
              </td>
            ))}
          </tr>
        </tbody>
      </table>
    );
  }

  _onClick(abuse) {
    const newAbuse = this.props.abuse.filter(
      (a) => !kEditableAbuse.includes(a)
    );
    if (abuse) newAbuse.push(abuse);

    console.log(
      `abuse: ${JSON.stringify(this.props.abuse)} => ${JSON.stringify(
        newAbuse
      )}`
    );
    this.props.api
      .update({
        id: this.props.subjectID,
        note: "while editing report",
        post: {
          abuse: newAbuse,
        },
        comment: {
          abuse: newAbuse,
        },
      })
      .then(() => {
        if (this.props.onUpdate.length === 2) {
          this.props.onUpdate(newAbuse, abuse);
        } else {
          this.props.onUpdate(abuse);
        }
      });
  }
}

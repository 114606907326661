import React, { Component } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

import Moment from "../components/Moment";
import StatusButton from "../components/StatusButton";
import GroupTabPosts from "../components/GroupTabPosts";
import GroupTabMembers from "../components/GroupTabMembers";
import GroupTabLog from "../components/GroupTabLog";
import GroupSetStatusForm from "../components/GroupSetStatusForm";
import GroupShareForm from "../components/GroupShareForm";
import Image from "../components/Image";

export default class Group extends Component {
  static propTypes = {
    api: PropTypes.object,
  };

  constructor(props) {
    super(props);

    this.state = {
      group: {},
      statusOverlay: false,
      shareOverlay: false,
      contentMode: "Posts",
    };

    this._onJoinModeChange = this._onJoinModeChange.bind(this);
    this._onTabClick = this._onTabClick.bind(this);
    this._tabLink = this._tabLink.bind(this);
  }

  componentDidMount() {
    const groupId = this.props.match.params.id;

    this.props.api.Groups.show(groupId).then((data) => {
      this.setState({ group: data });
    });
  }

  _onTabClick(e) {
    e.preventDefault();
    this.setState({ contentMode: e.target.getAttribute("data-contentmode") });
  }

  _tabLink(tab) {
    const c = this.state.contentMode === tab ? "active" : "";
    return (
      <a
        href={`#${tab}`}
        className={c}
        data-contentmode={tab}
        onClick={this._onTabClick}
      >
        {tab}
      </a>
    );
  }

  _onJoinModeChange({ target: { value: join_mode } }) {
    const {
      match: {
        params: { id },
      },
    } = this.props;

    this.setState({ group: { ...this.state.group, join_mode } });
    this.props.api.Groups.update({
      id: id,
      join_mode: join_mode,
    });
  }

  _refresh() {
    this.props.api.Groups.show(this.props.match.params.id).then((data) => {
      this.setState({ group: data });
    });
  }

  render() {
    const { contentMode, group } = this.state;

    if (group.id === undefined) {
      return (
        <div className="container">
          <p>Loading...</p>
        </div>
      );
    }

    const groupActivityElement =
      group.last_post_at !== null ? (
        <Moment time={group.last_post_at} />
      ) : (
        <span>-</span>
      );

    /* eslint-disable jsx-a11y/accessible-emoji */
    return (
      <div className="GroupDetails">
        <section className="GroupDetails-group">
          <div className="content">
            <h1>{group.name}</h1>

            <dl className="details">
              <dt>Author</dt>
              <dd>
                <Link to={`/users/${group.author.id}`}>
                  {group.author.first_name} {group.author.last_name}
                </Link>
              </dd>

              <dt>Status</dt>
              <dd>
                <StatusButton
                  status={group.status}
                  onClick={this.setState.bind(
                    this,
                    { statusOverlay: true },
                    null
                  )}
                >
                  {group.status}
                </StatusButton>
              </dd>

              <dt>Web URL:</dt>
              <dd>
                <a
                  href={`https://www.peanut-app.io/groups/${this.state.group.to_param}`}
                >
                  https://www.peanut-app.io/groups/{this.state.group.to_param}
                </a>
              </dd>
              <dt>Share</dt>
              <dd>
                <React.Fragment>
                  <button
                    className="badge badge-pill"
                    onClick={this.setState.bind(
                      this,
                      { shareOverlay: true },
                      null
                    )}
                  >
                    Share 📤
                  </button>
                </React.Fragment>
              </dd>

              <dt>Join mode</dt>
              <dd>
                🚪{" "}
                <select
                  value={this.state.group.join_mode}
                  onChange={this._onJoinModeChange}
                >
                  <option value={"open"}>Open</option>
                  <option value={"on_invite"}>On Invite</option>
                  <option value={"on_request"}>On Request</option>
                </select>
              </dd>

              <dt>Description</dt>
              <dd>{group.description}</dd>
            </dl>

            <dl className="details">
              <dt>👩 Members</dt>
              <dd>{group.user_count}</dd>

              <dt>💬 Posts</dt>
              <dd>{group.post_count}</dd>

              <dt>⏱ Last activity</dt>
              <dd>{groupActivityElement}</dd>
            </dl>
          </div>
          <Image url={group.banner_url} className="Cover" openInNewTab />

          {this.state.statusOverlay && (
            <GroupSetStatusForm
              api={this.props.api}
              groupID={group.id}
              status={this.state.group.status}
              onCancel={this.setState.bind(
                this,
                { statusOverlay: false },
                null
              )}
              onComplete={this._refresh.bind(this)}
            />
          )}
          {this.state.shareOverlay && (
            <GroupShareForm
              api={this.props.api}
              groupID={group.id}
              onCancel={this.setState.bind(this, { shareOverlay: false }, null)}
            />
          )}
        </section>

        <section className="Group-tabs">
          <nav>
            {this._tabLink("Posts")}
            {this._tabLink("Members")}
            {this._tabLink("Log")}
          </nav>
        </section>
        {contentMode === "Posts" && (
          <div>
            <GroupTabPosts
              group_id={this.props.match.params.id}
              api={this.props.api}
            />
          </div>
        )}

        {contentMode === "Members" && (
          <div>
            <GroupTabMembers
              group_id={this.props.match.params.id}
              api={this.props.api}
            />
          </div>
        )}

        {contentMode === "Log" && (
          <div>
            <GroupTabLog history={group.history} api={this.props.api} />
          </div>
        )}
      </div>
    );
  }
}

import React, { Component } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

export default class BlockedBy extends Component {
  static propTypes = {
    api: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      userId: props.user.id,
      blockedBy: props.user.blocked_by,
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.user.id !== prevProps.user.id) {
      this.setState({
        userId: this.props.user.id,
        blockedBy: this.props.user.blocked_by,
      });
    }
  }

  onClick(blocked_by_id) {
    this.props.api.Users.unblock({
      user_id: blocked_by_id,
      suggested_user_id: this.state.userId,
    }).then(() => {
      this.setState({
        blockedBy: this.state.blockedBy.filter(
          (bb) => bb.user_id !== blocked_by_id
        ),
      });
    });
  }

  render() {
    if (this.state.blockedBy.length === 0) {
      return (
        <div>
          <h3>Blocked by</h3>
          <p>Not blocked by anyone</p>
        </div>
      );
    }

    return (
      <div>
        <h3>Blocked by</h3>

        <table>
          <tbody>
            {this.state.blockedBy.map((bb) => (
              <tr key={bb.user_id}>
                <td width="200">
                  <Link
                    className="UserLink UserLink--inline"
                    to={`/users/${bb.user_id}#log`}
                  >
                    {bb.first_name} {bb.last_name}
                  </Link>
                </td>
                <td>
                  <button
                    className="BlockedUsers-button"
                    onClick={() => this.onClick(bb.user_id)}
                  >
                    Unblock
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }
}

import React from "react";
import PropTypes from "prop-types";
import LandingPageRow from "./components/LandingPageRow";

export const typeLandingPage = PropTypes.shape({
  id: PropTypes.number.isRequired,
  slug: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  metaDescription: PropTypes.string,
  heading: PropTypes.string.isRequired,
  summary: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  imageName: PropTypes.string.isRequired,
  branchUrl: PropTypes.string.isRequired,
});

export function LandingPageTable({ data }) {
  return (
    <table>
      <thead>
        <tr>
          <th>Title</th>
          <th>Url</th>
          <th>Status</th>

          <th>&nbsp;</th>
        </tr>
      </thead>
      <tbody>
        {data.map((landingPage) => (
          <LandingPageRow key={landingPage.id} data={landingPage} />
        ))}
      </tbody>
    </table>
  );
}

LandingPageTable.propTypes = {
  data: PropTypes.arrayOf(typeLandingPage).isRequired,
};

export default LandingPageTable;

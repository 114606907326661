import { Chip, makeStyles } from "@material-ui/core";
import React from "react";
import classnames from "classnames";
import styles from "./ChipTable.module.scss";
import useBreaches from "../../hooks/api/useBreaches";
import useWithdraw from "../../hooks/api/useWithdraw";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "10px 5px 0 0",
  },
  suggested: {
    outline: "1.5px solid #6E757C",
    outlineOffset: "2px",
  },
  selected: {
    backgroundColor: "#6E757C",
  },
  severeChip: {
    borderColor: "#FFC3C5",
    backgroundColor: "white",
  },
  defaultChip: {
    backgroundColor: "white",
  },
}));

const ChipTable = ({
  userID,
  type = "",
  guidelineList,
  subjectType,
  subjectID,
  breachID,
  reportIDs,
  suggestedViolatedGuidelines,
  selectedReasons,
  reducedTd,
  disabled,
  onShowModal,
  onError,
  onWithdrawError,
  onSuccess,
  onWithdrawSuccess,
  onLoading,
  onUpdate,
}) => {
  const classes = useStyles();
  const conditions = ["comment", "post", "group"];

  const { mutate: mutateBreach, isLoading } = useBreaches(
    onSuccess,
    onError,
    onUpdate
  );

  const { mutate: mutateWithdraw } = useWithdraw(
    onWithdrawSuccess,
    onWithdrawError
  );

  const submitBreach = (payload) => {
    onLoading(
      payload.violated_guidelines.length === 0
        ? "Infirming Breach..."
        : "Submitting Breach..."
    );
    setTimeout(() => {
      onLoading("");
      mutateBreach(payload);
    }, 2000);
  };

  const submitWithdraw = (breachID) => {
    setTimeout(() => {
      mutateWithdraw(breachID);
    }, 2000);
  };

  const pickSelection = (id, violation_severity) => {
    const payload = {
      user_id: userID,
      subject_type: subjectType,
      subject_id: subjectID,
      violated_guidelines: id ? [id] : [],
    };

    if (violation_severity !== "severe") {
      //if the report types is a group type do not submit reports ids
      if (conditions.some((el) => subjectType.toLowerCase().includes(el))) {
        submitBreach(payload);
      } else {
        submitBreach({ ...payload, report_ids: reportIDs });
      }
      //call to /id/withdraw when in history
      if (type === "history/breach") {
        submitWithdraw(breachID);
      }
    } else {
      //if severe breach show warning modal. pass mutations to make the call
      onShowModal(
        { id, violation_severity },
        {
          submitBreach,
          submitWithdraw,
        }
      );
    }
  };

  const suggestedGuidelineToClass = (id) => {
    var suggestedGuidelineClass = "";

    suggestedViolatedGuidelines?.forEach((guideline) =>
      guideline === id
        ? (suggestedGuidelineClass = classes.suggested)
        : (suggestedGuidelineClass = "")
    );

    return suggestedGuidelineClass;
  };

  return (
    <>
      <tr className={styles.wrapper}>
        {!reducedTd && <td width={1}></td>}
        <td width={1} className={styles.borderLeft}></td>
        <td className="chiptable" style={{ textAlign: "left" }}>
          <div>
            {guidelineList?.map(({ id, title, icon, violation_severity }) => {
              return violation_severity === "severe" ? (
                <Chip
                  key={id}
                  label={`${icon} ${title}`}
                  onClick={() => pickSelection(id, violation_severity)}
                  className={classnames(
                    classes.root,
                    suggestedGuidelineToClass(id),
                    selectedReasons?.id === id
                      ? classes.selected
                      : classes.severeChip
                  )}
                  disabled={disabled || isLoading}
                  variant="outlined"
                />
              ) : (
                <Chip
                  key={id}
                  label={`${icon} ${title}`}
                  onClick={() => pickSelection(id, violation_severity)}
                  className={classnames(
                    classes.root,
                    suggestedGuidelineToClass(id),
                    selectedReasons?.id === id
                      ? classes.selected
                      : classes.defaultChip
                  )}
                  disabled={disabled || isLoading}
                  variant="outlined"
                />
              );
            })}
            <Chip
              label="❌ Infirm"
              className={classes.root}
              variant="outlined"
              disabled={disabled || isLoading}
              onClick={() => pickSelection("")}
            />
          </div>
        </td>
        <td width={1}></td>
      </tr>
    </>
  );
};

export default ChipTable;

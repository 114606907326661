import React, { Component } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

import h from "./helpers";

const phrases = {
  status: [
    [/,deleted/, "deletes comment"],
    [/,disabled/, "deletes comment"],
    [/,hidden/, "hides comment"],
    [/hidden,active/, "unhides comment"],
    [/deleted,active/, "undeletes comment"],
    [/disabled,active/, "undeletes comment"],
    [/,active/, "resets comment"],
  ],

  abuse: h.renderAbuseAction.bind(h, "comment"),
  default: "takes no action on comment",
};

export default class HistoryComment extends Component {
  static propTypes = {
    userID: PropTypes.number,

    id: PropTypes.number.isRequired,
    created_at: PropTypes.string.isRequired,
    changeset: PropTypes.object.isRequired,
    notes: PropTypes.string,
    created_by: PropTypes.shape({
      id: PropTypes.number.isRequired,
      first_name: PropTypes.string.isRequired,
    }).isRequired,
    comment: PropTypes.shape({
      id: PropTypes.number.isRequired,
      post_id: PropTypes.number.isRequired,
    }).isRequired,
  };

  static type = "history/comment";
  static icon = "💬";

  render() {
    const p = this.props;

    return (
      <tr className="EventHistoryComment">
        {h.sharedColumns(this)}
        <td>
          {h.renderActor(p.created_by, p.userID)}{" "}
          {h.renderActions(p.changeset, phrases)} #{p.comment.id}
          {" on post "}
          <Link to={`/posts/${p.comment.post_id}`}>
            #{p.comment.post_id}
          </Link>{" "}
          {h.renderNotes(p.notes)}
        </td>
        <td></td>
      </tr>
    );
  }
}

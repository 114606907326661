import { useMutation } from "react-query";
import Cookies from "universal-cookie";

const cookies = new Cookies();
const xsrf_token = cookies.get("XSRF-TOKEN");
const url = new URL(`${process.env.REACT_APP_API_HOST}/admin/users`);

const submitConnection = (userId, connectUserId) => {
  return fetch(`${url}/${userId}/connections`, {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      "X-XSRF-Token": xsrf_token,
    },
    body: JSON.stringify({
      other_user_id: connectUserId,
    }),
  });
};
const useCreateConnection = (userId, connectUserId) => {
  return useMutation(() => submitConnection(userId, connectUserId), {
    onSuccess: async (response, variables, context) => {
      console.log(response, variables, context);
      if (response.status === 201) {
        alert(`Connected with user ${connectUserId}`);
      } else {
        alert("Connection process failed. Please check logs.");
      }
    },
    onError: (error, variables, context) => {
      console.log(error, variables, context);
    },
  });
};

export default useCreateConnection;

import React, { Component } from "react";
import PropTypes from "prop-types";

export default class Giphy extends Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
  };

  constructor(props) {
    super(props);

    this.host = process.env.REACT_APP_GIPHY_HOST;

    this.state = { url: null };
  }

  async componentDidMount() {
    const url = `${this.host}/v1/gifs/${this.props.id}?api_key=${process.env.REACT_APP_GIPHY_KEY}`;

    fetch(url, { headers: { "Content-Type": "application/json" } })
      .then((response) => {
        if (response.status === 401) {
          return Promise.reject("Authentication error");
        }
        if (response.status === 403) {
          return Promise.reject("Forbidden");
        }
        if (response.status >= 500) {
          return Promise.reject("Server error");
        }
        if (response.status >= 200 && response.status <= 299) {
          return response;
        }

        return Promise.reject(`Client error (${response.status})`);
      })
      .then((response) => response.json())
      .then((response) =>
        this.setState({ url: response.data.images.original.webp })
      );
  }

  render() {
    return (
      <img
        alt="giphy"
        width={this.props.width}
        height={this.props.height}
        src={this.state.url}
      />
    );
  }
}

import React, { Component } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

import SearchResults from "../components/SearchResults";
import MessageRow from "../components/MessageRow";

export default class Conversation extends Component {
  static propTypes = {
    api: PropTypes.object,
  };

  constructor(props) {
    super(props);
    this.state = {
      conversationID: null,
      conversation: null,
    };
  }

  render() {
    if (this.state.conversationID === null) {
      return (
        <div className="Conversation">
          <p>Loading...</p>
        </div>
      );
    }

    return (
      <div className="Conversation">
        {this._renderConversation()}
        {this._renderMessages()}
      </div>
    );
  }

  _renderConversation() {
    if (this.state.conversation === null) {
      return (
        <div className="Conversation-info">
          <p>Loading...</p>
        </div>
      );
    }

    const c = this.state.conversation;

    return (
      <div className="Conversation-info">
        Conversation between
        <ul>
          {c.users.map((u) => (
            <li key={u.id}>
              <Link to={`/users/${u.id}`}>{u.first_name}</Link>
            </li>
          ))}
        </ul>
        ({c.messages} messages).
      </div>
    );
  }

  _renderMessages() {
    const userIDs = this.state.conversation
      ? this.state.conversation.users.map((u) => u.id)
      : [];
    let lastUserID = null;

    return (
      <SearchResults
        key="messages"
        api={this.props.api.Messages}
        query={{ conversation_id: this.state.conversationID }}
        displayOnEmpty={this._renderAllChecked.bind(this)}
      >
        {(results) => (
          <>
            <table>
              <thead>
                <MessageRow.Headers />
              </thead>
              {results.items().map((c) => {
                const senderID = c.bot ? "bot" : c.sender_id;
                const noUser = lastUserID === senderID;
                lastUserID = senderID;

                return (
                  <MessageRow
                    key={c.id}
                    api={this.props.api}
                    message={c}
                    userIndex={userIDs.indexOf(c.sender_id)}
                    noUser={noUser}
                  />
                );
              })}
              <tfoot>
                <tr>
                  <td />
                  <td />
                  <td>
                    <results.AutoLoad />
                  </td>
                  <td />
                </tr>
              </tfoot>
            </table>
          </>
        )}
      </SearchResults>
    );
  }

  componentDidMount() {
    this.props.api.Conversations.show(this.props.match.params.id).then(
      (data) => {
        this.setState({ conversation: data });
      }
    );

    this.setState({
      conversationID: this.props.match.params.id,
    });
  }

  _renderAllChecked(items) {
    const lastMessage = items.slice(-1)[0];
    const lastCursor = lastMessage ? lastMessage.cursor : null;

    return (
      <button
        className="Conversation-button-done"
        onClick={this._onAllChecked.bind(this, lastCursor)}
      >
        All checked
      </button>
    );
  }

  _onAllChecked(cursor) {
    this.props.api.Conversations.update(
      this.props.match.params.id,
      cursor
    ).then(() => this.props.history.goBack());
  }
}

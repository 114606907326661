// eslint-disable-next-line no-unused-vars
import React from "react";
import DefaultRow from "../DefaultRow";

export default class ParticipantLeftRow extends DefaultRow {
  static action = "participant_left";
  static label = "Left";
  static report = false;

  _notes() {
    return <>{this._firstName()} left the room.</>;
  }
}

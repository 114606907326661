import React, { Component } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

import Moment from "./Moment";

export default class LiveAudioRoomRow extends Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    started_at: PropTypes.string.isRequired,
    scheduled_at: PropTypes.string,
    recording_started_at: PropTypes.string,
  };

  static Headers = () => (
    <tr>
      <th>Status</th>
      <th>Recording started / Scheduled</th>
      <th>Title</th>
    </tr>
  );

  render() {
    const room = this.props;

    return (
      <tr className="LiveAudioRoomRow">
        <td className="LiveAudioRoomRow-status">
          <span>#{room.status}</span>
        </td>

        <td className="LiveAudioRoomRow-startedAt">
          <Moment
            time={
              room.recording_started_at || room.scheduled_at || room.started_at
            }
          />
        </td>

        <td className="LiveAudioRoomRow-title">
          <Link to={`/live-audio/${room.id}`}>#{room.title}</Link>
        </td>
      </tr>
    );
  }
}

import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import PropTypes from "prop-types";

import classnames from "classnames";
import h from "./helpers";

import MessageBody from "../MessageBody";

export default class ReportConversation extends Component {
  static propTypes = {
    api: PropTypes.object.isRequired,
    type: PropTypes.string.isRequired,
    subject_id: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,

    created_at: PropTypes.string.isRequired,
    reason: PropTypes.string,
    notes: PropTypes.string,
    created_by: PropTypes.shape({
      id: PropTypes.number.isRequired,
      first_name: PropTypes.string,
    }).isRequired,

    onUpdate: PropTypes.func,
    onToggleOptions: PropTypes.func,
  };

  static type = "report/conversation";
  static icon = "⚠️💬";

  constructor(props) {
    super(props);
    this.state = { redirect: false };
  }

  render() {
    const p = this.props;

    return (
      <tr
        className={classnames(
          "EventsRow",
          "EventsReportConversation",
          "EventsRow--" + (p.status === "open" ? "open" : "closed")
        )}
        onMouseOver={() => p.onToggleOptions(true)}
      >
        {h.sharedColumns(this)}
        <td>
          {h.renderActor(p.created_by)} reports conversation{" "}
          <NavLink to={`/conversations/${p.subject_id}`}>
            #{p.subject_id.replace(/-.*/, "")}
          </NavLink>{" "}
          {h.renderNotes(p.notes, p.reason)}
          {p.message && (
            <p className="EventReportUserConversation-content">
              <MessageBody {...p.message} />
            </p>
          )}
        </td>
      </tr>
    );
  }
}

import React from "react";
import PropTypes from "prop-types";

import DropArea from "./DropArea";
import TextEditor from "./TextEditor";

export default class TextEditorDroppable extends React.Component {
  static propTypes = {
    api: PropTypes.object.isRequired,
    onSave: PropTypes.func.isRequired,
    showTools: PropTypes.bool,
    value: PropTypes.string,
  };

  render() {
    const { onSave, ...p } = this.props;

    return (
      <DropArea api={this.props.api} onComplete={this._onUpload.bind(this)}>
        <TextEditor onSave={this._onFieldSave.bind(this)} {...p} />
      </DropArea>
    );
  }

  _onFieldSave(...args) {
    this.props.onSave(...args);
  }

  _onUpload(data) {
    const url = data.url.replace("{width}", "1200");
    const markdown = `![alt-text](${url})`;
    this.props.onSave(`${this.props.value}\n${markdown}`);
  }
}

// eslint-disable-next-line no-unused-vars
import React from "react";
import DefaultRow from "../DefaultRow";

export default class RoomClosedRow extends DefaultRow {
  static action = "room_closed";
  static label = "Room Closed";
  static report = false;

  _notes() {
    return <>{this._firstName()} closed the room.</>;
  }
}

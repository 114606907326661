import React, { useCallback, useEffect, useState } from "react";
import classNames from "classnames";
import { Link, useParams } from "react-router-dom";
import ButtonWithConfirmation from "../../components/ButtonWithConfirmation";

import StringEditor from "../../components/StringEditor";
import useLandingPage from "../../hooks/api/useLandingPage";
import useLandingPageUpdate from "../../hooks/api/useLandingPageUpdate";

import imageGeneric1 from "./assets/background_generic_1_mobile.webp";
import imageGeneric2 from "./assets/background_generic_2_mobile.webp";
import imageGeneric3 from "./assets/background_generic_3_mobile.webp";
import imageLoss1 from "./assets/background_loss_1_mobile.webp";
import imageLoss2 from "./assets/background_loss_2_mobile.webp";
import imageParenting1 from "./assets/background_parenting_1_mobile.webp";
import imageParenting2 from "./assets/background_parenting_2_mobile.webp";
import imagePregnancy1 from "./assets/background_pregnancy_1_mobile.webp";
import imagePregnancy2 from "./assets/background_pregnancy_2_mobile.webp";
import imagePregnancy3 from "./assets/background_pregnancy_3_mobile.webp";
import ImageOption from "./components/ImageOption";

const Images = {
  "generic-1": imageGeneric1,
  "generic-2": imageGeneric2,
  "generic-3": imageGeneric3,
  "loss-1": imageLoss1,
  "loss-2": imageLoss2,
  "parenting-1": imageParenting1,
  "parenting-2": imageParenting2,
  "pregnancy-1": imagePregnancy1,
  "pregnancy-2": imagePregnancy2,
  "pregnancy-3": imagePregnancy3,
};

const LandingPages = () => {
  const { id } = useParams();
  const { data: sourceData } = useLandingPage(id);
  const { mutate } = useLandingPageUpdate();

  const [data, setData] = useState(sourceData);

  useEffect(() => {
    setData(sourceData);
  }, [sourceData]);

  const handleUpdate = useCallback(
    (newValue) => {
      const updatedData = { ...data, ...newValue };
      setData(updatedData);
      mutate({ id, data: updatedData });
    },
    [data, id, mutate]
  );

  const handleSlugChange = useCallback(
    (value) => {
      handleUpdate({ slug: value });
    },
    [handleUpdate]
  );

  const handleHeadingChange = useCallback(
    (value) => {
      handleUpdate({ heading: value });
    },
    [handleUpdate]
  );

  const handleSummaryChange = useCallback(
    (value) => {
      handleUpdate({ summary: value });
    },
    [handleUpdate]
  );

  const handleCtaButtonNameChange = useCallback(
    (value) => {
      handleUpdate({ cta_button_name: value });
    },
    [handleUpdate]
  );

  const handleBranchUrlChange = useCallback(
    (value) => {
      handleUpdate({ cta_button_url: value });
    },
    [handleUpdate]
  );

  const handleImageChange = useCallback(
    (value) => {
      handleUpdate({ image_name: value });
    },
    [handleUpdate]
  );

  const handleMetaDescriptionChange = useCallback(
    (value) => {
      handleUpdate({ meta_description: value });
    },
    [handleUpdate]
  );

  const handleMetaTitleChange = useCallback(
    (value) => {
      handleUpdate({ meta_title: value });
    },
    [handleUpdate]
  );

  const handleStatusChange = useCallback(() => {
    const newStatus = data?.status === "pending" ? "active" : "pending";
    handleUpdate({ status: newStatus });
  }, [handleUpdate, data?.status]);

  return data ? (
    <div className="LandingPage">
      <main className="wrapper">
        <header>
          <h1>
            <Link to="/landing-pages">Landing Pages</Link> ▸ #{data.id}
          </h1>

          <div>
            <ButtonWithConfirmation
              title="Warning"
              message={
                data?.page?.status === "pending"
                  ? "The page will become visible on the website."
                  : "The page will be removed from the website, and links may break."
              }
              onClick={handleStatusChange}
              className={classNames("SEOPage-action", "status-action")}
            >
              {data?.status}
            </ButtonWithConfirmation>
            <a
              className="SEOPage-action"
              href={`${process.env.REACT_APP_MAIN_URL}/l/${data.slug}?preview=1`}
              target="_preview"
            >
              Preview
            </a>
          </div>
        </header>

        <div>
          <label>URL</label>

          <div className="inlineUrl">
            {process.env.REACT_APP_MAIN_URL}/l/
            <StringEditor value={data.slug} onSave={handleSlugChange} />
          </div>
        </div>

        <div>
          <label>Header</label>
          <StringEditor value={data.heading} onSave={handleHeadingChange} />
        </div>

        <div>
          <label>Introduction</label>
          <StringEditor value={data.summary} onSave={handleSummaryChange} />
        </div>

        <div>
          <label>CTA Button Name</label>
          <StringEditor
            value={data.cta_button_name}
            onSave={handleCtaButtonNameChange}
          />
        </div>
        <div>
          <label>Branch Url:</label>
          <StringEditor
            value={data.cta_button_url}
            onSave={handleBranchUrlChange}
          />
        </div>

        <div>
          <label>Sharing Page Title</label>
          <StringEditor
            value={data.meta_title}
            onSave={handleMetaTitleChange}
          />
        </div>

        <div>
          <label>Sharing Page Description</label>
          <StringEditor
            value={data.meta_description}
            onSave={handleMetaDescriptionChange}
          />
        </div>

        <div>
          <label>Background image:</label>

          <div className="ImageOptions">
            {Object.entries(Images).map(([name, image]) => (
              <ImageOption
                key={name}
                name={name}
                image={image}
                selected={data.image_name === name}
                onClick={handleImageChange}
              />
            ))}
          </div>
        </div>
      </main>
    </div>
  ) : (
    <div>Loading</div>
  );
};

export default LandingPages;

import React, { Component } from "react";
import PropTypes from "prop-types";

import Image from "./Image";
import RemovePhotoForm from "./RemovePhotoForm";
import classNames from "classnames";

export default class PostPhoto extends Component {
  static propTypes = {
    api: PropTypes.object.isRequired,
    post: PropTypes.object.isRequired,
    onComplete: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.state = { removePhotoOverlay: false };
  }

  render() {
    const post = this.props.post;

    return (
      <div className="PostPhoto">
        <Image
          url={post.image ? post.image.url : "none"}
          className={classNames({
            "PostPhoto-Image": true,
            detected_nsfw: post.status !== "active",
          })}
          openInNewTab
        />

        {post.image && post.image.url && (
          <button
            onClick={() => {
              this.setState({ removePhotoOverlay: true });
            }}
            className="PostPhoto-RemoveLink"
          >
            remove image
          </button>
        )}

        {this.state.removePhotoOverlay && (
          <RemovePhotoForm
            api={this.props.api}
            postID={post.id}
            onCancel={this.setState.bind(
              this,
              { removePhotoOverlay: false },
              null
            )}
            onComplete={this.props.onComplete}
          />
        )}
      </div>
    );
  }
}

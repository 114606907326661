import Base from "./Base";

export default class LiveAudioEvents extends Base {
  index(params) {
    const { roomId, ...editedParams } = params;
    const q = this._query(this._compact(editedParams));

    return this._fetch(`/admin/audio_rooms/${roomId}/events?${q}`)
      .then((response) => response.json())
      .then(({ data }) => [data]);
  }
}

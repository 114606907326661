import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import SearchForm from "../../components/SearchForm";
import SearchHistory from "../../components/SearchHistory";
import SearchResults from "../../components/SearchResults";

export default class ContentLabellingJobs extends Component {
  static propTypes = {
    api: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      query: {},
      filters: { subject_types: {}, classifier_types: {} },
    };
  }

  componentDidMount() {
    this.props.api.ContentLabellingJobs.formData().then((data) => {
      this.setState({
        filters: {
          subject_types: data.subject_types,
          classifier_types: data.classifier_types,
        },
      });
    });
  }

  render() {
    return (
      <div className="container pt-4 pb-4">
        <div className="row">
          <div className="col-6">
            <SearchForm
              query={this.state.query}
              onSubmit={(q) => this.setState({ query: q })}
            >
              {(form) => (
                <React.Fragment>
                  <form.CheckGroup
                    name="subject_type"
                    title="Subject Type"
                    options={Object.values(this.state.filters.subject_types)}
                    labels={Object.keys(this.state.filters.subject_types)}
                  />
                  <form.CheckGroup
                    name="classifier_type"
                    title="Classifier Type"
                    options={Object.values(this.state.filters.classifier_types)}
                    labels={Object.keys(this.state.filters.classifier_types)}
                  />
                </React.Fragment>
              )}
            </SearchForm>
          </div>
          <div className="col-6">
            <Link
              to="/content-labelling-jobs/new"
              className="btn btn-outline-secondary float-right"
            >
              Create new Job
            </Link>
          </div>
        </div>

        <SearchHistory
          query={this.state.query}
          onSubmit={(q) => this.setState({ query: q })}
        />

        <SearchResults
          api={this.props.api.ContentLabellingJobs}
          query={this.state.query}
          recordsKey="records"
        >
          {(results) => (
            <table className="ContentLabellingJobs-table">
              <thead>
                <tr>
                  <th>Internal ID</th>
                  <th>Title</th>
                  <th>Subject Type</th>
                  <th>Classifier Type</th>
                  <th>Completion</th>
                  <th>Total Labels Assigned</th>
                  <th>Select Subjects Query Key</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {results.items().map((job) => (
                  <tr key={job.id}>
                    <td>
                      <Link to={`/content-labelling-jobs/${job.id}`}>
                        {job.internal_id}
                      </Link>
                      {job.finished && (
                        <div className="badge badge-secondary">finished</div>
                      )}
                      {job.empty_queue_warning && (
                        <div className="badge badge-warning">
                          subjects queue is empty
                        </div>
                      )}
                    </td>
                    <td>{job.title}</td>
                    <td>{job.subject_type}</td>
                    <td>{job.classifier_type}</td>
                    <td>
                      {job.completely_labelled_subjects_count}/
                      {job.completion_target}
                    </td>
                    <td>{job.total_labels_count}</td>
                    <td>{job.select_subjects_query_key}</td>
                    <td>
                      <Link
                        to={`/content-labelling-jobs/${job.id}/edit`}
                        className="btn btn-sm btn-outline-secondary float-right m-1"
                      >
                        Edit
                      </Link>
                      <Link
                        to={`/content-labelling-jobs/${job.id}`}
                        className="btn btn-sm btn-outline-secondary float-right m-1"
                      >
                        View Stats
                      </Link>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={job.link}
                        className="btn btn-sm btn-outline-secondary float-right m-1"
                      >
                        Open In Labelling App
                      </a>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </SearchResults>
      </div>
    );
  }
}

import React, { Component } from "react";
import PropTypes from "prop-types";

import ModalForm from "./ModalForm";
import RadioGroup from "./RadioGroup";

export default class PostCategoryForm extends Component {
  static propTypes = {
    api: PropTypes.object.isRequired,
    postID: PropTypes.number.isRequired,
    categoryID: PropTypes.number.isRequired,
    onCancel: PropTypes.func.isRequired,
    onComplete: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      categoryID: this.props.categoryID,
      categories: null,
    };
  }

  componentWillMount() {
    if (this.state.categories) return;

    this.props.api.Categories.index().then((data) => {
      this.setState({
        categories: {
          names: data.map((d) => d.name),
          ids: data.map((d) => d.id),
        },
      });
    });
  }

  render() {
    if (!this.state.categories) return <React.Fragment />;

    return (
      <ModalForm
        className="PostCategoryForm"
        title="Change post category"
        onCancel={this.props.onCancel}
        form={
          <RadioGroup
            name="categoryID"
            labels={this.state.categories.names}
            options={this.state.categories.ids}
            selected={this.state.categoryID}
            onChange={this._onChange.bind(this)}
          />
        }
        actions={
          <button
            type="button"
            className="btn btn-primary"
            onClick={this._onSubmit.bind(this)}
          >
            Save
          </button>
        }
      />
    );
  }

  _onChange(event) {
    this.setState({ categoryID: parseInt(event.target.value) });
  }

  _onSubmit(sensitive) {
    this.props.api.Posts.update({
      id: this.props.postID,
      category_id: this.state.categoryID,
    }).then(() => {
      this.props.onCancel();
      this.props.onComplete();
    });
  }
}

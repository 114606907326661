import Base from "./Base";

export default class LiveAudioRooms extends Base {
  index(params) {
    const q = this._query(params);

    return this._fetch(`/admin/audio_rooms?${q}`)
      .then((response) => response.json())
      .then(({ data: { rooms, cursor } }) => [rooms, cursor]);
  }

  show(id, params = {}) {
    const q = this._query(params);

    return this._fetch(`/admin/audio_rooms/${id}?${q}`)
      .then((response) => response.json())
      .then(({ data: { room } }) => room);
  }

  update({ id, ...params }) {
    return this._fetch(`/admin/audio_rooms/${id}`, {
      method: "PATCH",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(params),
    });
  }

  terminate(id) {
    return this._fetch(`/admin/audio_rooms/${id}/terminate`, {
      method: "DELETE",
    });
  }
}

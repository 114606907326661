import React from "react";
import PropTypes from "prop-types";

export default class CampaignFilterStub extends React.Component {
  static propTypes = {
    api: PropTypes.object.isRequired,
    campaign_id: PropTypes.number.isRequired,
    id: PropTypes.number.isRequired,
    disabled: PropTypes.bool.isRequired,
  };

  render() {
    return <span className="CampaignFilterStub">(legacy campaign stub)</span>;
  }
}

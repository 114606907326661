import React from "react";
import { NavLink } from "react-router-dom";

import Moment from "../components/Moment";
import SearchHistory from "../components/SearchHistory";

export default class UserEvents extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      query: {
        user_id: "",
      },
      batches: [],
      paused: false,
    };
    this._timer = null;
  }

  render() {
    const contextValues = [
      "context_source_product_feature",
      "context_text",
      "context_product_id",
      "context_post_id",
      "context_model_id",
      "context_tag_ids",
    ];
    return (
      <div className="UserEvents">
        <SearchHistory
          api={this.props.api}
          query={this.state.query}
          onSubmit={(q) => this._onChangeUser(q.user_id)}
        />
        <div className="UserEvents-form">
          <label htmlFor="id">User ID</label>
          <input
            name="id"
            type="text"
            value={this.state.query.user_id}
            onChange={(e) => this._onChangeUser(e.target.value)}
          />

          <div className="UserEvents-form-actions">
            <button
              className={`UserEvents-action--${this.state.paused}`}
              onClick={this.setState.bind(this, { paused: true }, null)}
            >
              ❙❙
            </button>
            <button
              className={`UserEvents-action--${!this.state.paused}`}
              onClick={this.setState.bind(this, { paused: false }, null)}
            >
              ▶︎
            </button>
          </div>
        </div>
        <table className="UserEvents-data">
          <thead>
            <tr>
              <th>Batch</th>
              <th>Event</th>
              <th>Action</th>
              <th>Subject</th>
              <th>Source</th>
              <th>Context</th>
            </tr>
          </thead>
          <tbody>
            {this._eachEvent((d) => (
              <tr key={d.key}>
                {d.idx === 0 && (
                  <td rowSpan={d.batch.data.length}>
                    <Moment time={d.batch.created_at} format="HH:mm:ss" />
                  </td>
                )}
                <td>
                  <Moment time={d.event.created_at} format="HH:mm:ss" />
                </td>

                <td className="UserEvents-data-action">{d.event.action}</td>

                <td className="UserEvents-data-subject">
                  {this._subjectLink(d.event.subject)}
                </td>

                <td className="UserEvents-data-source">{d.event.source}</td>

                <td className="UserEvents-data-context">
                  {contextValues.map((propName) => (
                    <div key={propName}>
                      {JSON.stringify(d.event[propName])}
                    </div>
                  ))}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }

  _subjectLink({ id, type }) {
    if (type === "post") {
      const intID = parseInt(id.replace(/-.*/, ""), 36);
      return <NavLink to={`/posts/${intID}`}>Post #{intID}</NavLink>;
    }
    if (type === "group") {
      return <NavLink to={`/groups/${id}`}>Group #{id}</NavLink>;
    }
    return (
      <React.Fragment>
        {type} #{id}
      </React.Fragment>
    );
  }

  _eachEvent(cb) {
    const result = [];
    this.state.batches.forEach((batch, i) => {
      batch.data.forEach((event, j) => {
        result.push(
          cb({
            batch: batch,
            key: 1000 * i + j,
            idx: j,
            event: event,
          })
        );
      });
    });
    return result;
  }

  _onChangeUser(id) {
    console.log(`_onChangeUser(${id})`);
    clearTimeout(this._timer);
    this.setState(
      { query: { user_id: id }, batches: [] },
      this._refresh.bind(this)
    );
  }

  _currentCursor() {
    if (this.state.batches.length === 0) return;
    return this.state.batches[0].cursor;
  }

  // 1. deduplicate batches by UUID
  // 2. keep only 100 batches
  _mergeBatches(oldBatches, newBatches) {
    const uuids = new Set();
    oldBatches.forEach((b) => uuids.add(b.uuid));
    // console.log(`${uuids.size} uuids in old batches`);
    // console.log(uuids);
    return newBatches
      .filter((b) => !uuids.has(b.uuid))
      .concat(oldBatches)
      .slice(0, 100);
  }

  _refresh() {
    if (this.state.query.user_id.length === 0) return;

    const params = {};
    if (this.state.batches.length > 0) {
      params.cursor = this.state.batches[0].cursor;
    }
    this.props.api.UserEvents.index(this.state.query.user_id, params).then(
      (data) => {
        clearTimeout(this._timer);
        const newBatches = this.state.paused
          ? this.state.batches
          : this._mergeBatches(this.state.batches, data.batches);
        this.setState({ batches: newBatches }, () => {
          this._timer = setTimeout(this._refresh.bind(this), 2000);
        });
      }
    );
  }
}

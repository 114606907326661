import Base from "./Base";

export default class Campaigns extends Base {
  index(params) {
    const q = this._query(params);
    return this._fetch(`/admin/campaigns?${q}`)
      .then((response) => response.json())
      .then((data) => [data.campaigns]);
  }

  show(id) {
    return this._fetch(`/admin/campaigns/${id}`).then((response) =>
      response.json()
    );
  }

  create(params) {
    return this._fetch(`/admin/campaigns`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(params),
    }).then((response) => response.json());
  }

  update(id, params) {
    return this._fetch(`/admin/campaigns/${id}`, {
      method: "PATCH",
      headers: { "content-type": "application/json" },
      body: JSON.stringify(params),
    }).then((response) => response.json());
  }

  test(id) {
    return this._fetch(`/admin/campaigns/${id}/test`, {
      method: "PUT",
    });
  }

  schedule(id) {
    return this._fetch(`/admin/campaigns/${id}/schedule`, {
      method: "PUT",
    });
  }

  cancel(id) {
    return this._fetch(`/admin/campaigns/${id}/cancel`, {
      method: "PUT",
    });
  }

  destroy(id) {
    return this._fetch(`/admin/campaigns/${id}`, {
      method: "DELETE",
    });
  }
}

import React from "react";

import SearchForm from "../components/SearchForm";
import SearchHistory from "../components/SearchHistory";
import SearchResults from "../components/SearchResults";
import GroupItem from "../components/GroupItem";

export default class Groups extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      query: {
        id: "",
        name: "",
        status: "active",
        sort_by: "user_count",
        seo_status: "all",
      },
    };
  }

  _setQueryParams(q) {
    if (q["id"].length > 0) {
      this.setState({
        query: {
          id: q["id"],
          name: "",
          sort_by: "user_count",
          seo_status: "all",
        },
      });
    } else {
      this.setState({ query: q });
    }
  }

  render() {
    return (
      <div className="Groups">
        <SearchForm
          query={this.state.query}
          onSubmit={(q) => this._setQueryParams(q)}
          className="Groups-form"
        >
          {(form) => (
            <React.Fragment>
              <form.Input label="Group ID" name="id" />
              <form.Input label="Group name" name="name" />
              <form.Input label="Group UID" name="uid" />
              <div>
                <form.CheckGroup
                  name="status"
                  title="Status"
                  options={["active", "censored", "hidden", "disabled"]}
                  labels={["Active", "Censored", "Hidden", "Disabled"]}
                />
              </div>
              <form.RadioGroup
                name="sort_by"
                title="Sort By"
                options={["user_count", "post_count", "last_post_at"]}
                labels={["User count", "Post count", "Activity"]}
              />
              <form.RadioGroup
                name="seo_status"
                title="SEO status"
                options={["indexed", "not_indexed", "all"]}
                labels={["Indexed", "Not indexed", "All"]}
              />
            </React.Fragment>
          )}
        </SearchForm>

        <SearchHistory
          query={this.state.query}
          onSubmit={(q) => this.setState({ query: q })}
        />

        <SearchResults api={this.props.api.Groups} query={this.state.query}>
          {(results) => (
            <table className="Groups-table">
              <thead>
                <GroupItem.Headers />
              </thead>

              <tbody>
                {results.items().map((n) => (
                  <GroupItem key={n.id} api={this.props.api.Groups} {...n} />
                ))}
              </tbody>

              <tfoot>
                <tr>
                  <td></td>
                  <td>
                    <results.AutoLoad />
                  </td>
                  <td></td>
                  <td></td>
                </tr>
              </tfoot>
            </table>
          )}
        </SearchResults>
      </div>
    );
  }
}

import {
  Button,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import React, { useCallback, useState } from "react";
import useCreateWave from "../hooks/api/useCreateWave";
import useCreateConnection from "../hooks/api/useCreateConnection";
import useDeleteConnection from "../hooks/api/useDeleteConnection";
import useConnections from "../hooks/api/useConnections";
import DeleteIcon from "@material-ui/icons/Delete";

function UserTabWaves({ userId }) {
  const { mutate } = useCreateWave(userId);
  const [connectUserId, setConnectUserId] = useState(undefined);
  const [deleteUserId, setDeleteUserId] = useState(undefined);
  const { mutate: createConnection } = useCreateConnection(
    userId,
    connectUserId
  );
  const { mutate: deleteConnection } = useDeleteConnection(
    userId,
    deleteUserId
  );
  const { data: connections } = useConnections(userId, 100);

  const handleConnect = useCallback(() => {
    createConnection();
  }, [createConnection]);

  const handleDisconnect = async (deleteUserId) => {
    await setDeleteUserId(deleteUserId);
    deleteConnection();
  };

  return (
    <div className="UserTabWaves">
      <h2>Waves</h2>
      <Button variant="contained" color="secondary" onClick={() => mutate()}>
        Give me 5 Waves 👋
      </Button>

      <div className="connectionsSection">
        <h2>Connections</h2>
        <div className="connectionsTitle">
          <p>Establish connection with user:</p>
          <input
            type="text"
            id="userId"
            name="userId"
            placeholder="Enter userId to connect with"
            value={connectUserId}
            onChange={(e) => setConnectUserId(e.target.value)}
          />
          <Button variant="contained" color="secondary" onClick={handleConnect}>
            Connect 👉 👈
          </Button>
        </div>

        {connections && connections.length > 0 ? (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Profile Picture</TableCell>
                <TableCell>First name</TableCell>
                <TableCell>Last name</TableCell>
                <TableCell>User ID</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {connections.map((data, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <img
                      src={data.avatar_url}
                      alt="Avatar"
                      width={80}
                      height={80}
                    />
                  </TableCell>
                  <TableCell>{data.first_name}</TableCell>
                  <TableCell>{data.last_name}</TableCell>
                  <TableCell>{data.id}</TableCell>
                  <TableCell>
                    <IconButton
                      color="secondary"
                      aria-label="delete"
                      onClick={() => handleDisconnect(data.id)}
                    >
                      <DeleteIcon fontSize="small" />
                    </IconButton>
                  </TableCell>{" "}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        ) : (
          <p>No data available</p>
        )}
      </div>
    </div>
  );
}

export default UserTabWaves;

import React, { Component } from "react";
import PropTypes from "prop-types";

import classnames from "classnames";

export default class CheckGroup extends Component {
  static propTypes = {
    className: PropTypes.string,
    title: PropTypes.string,
    name: PropTypes.string,
    labels: PropTypes.arrayOf(PropTypes.string),
    options: PropTypes.arrayOf(PropTypes.string),
    selected: PropTypes.string,
    onChange: PropTypes.func,
  };

  static defaultProps = {
    className: "",
  };

  render() {
    const p = this.props;
    const selected = this._split(p.selected);

    return (
      <div className={classnames("CheckGroup", p.className)}>
        {p.title && <label className="title">{p.title}:</label>}
        {p.options.map((option, idx) => (
          <div key={idx}>
            <input
              type="checkbox"
              id={`${p.name}-${option}`}
              value={option}
              checked={selected.indexOf(option) >= 0}
              onChange={this._onChange.bind(this)}
            />
            <label key={idx} htmlFor={`${p.name}-${option}`}>
              {p.labels[idx]}
            </label>
          </div>
        ))}
      </div>
    );
  }

  _split(str = "") {
    return str.split(",").filter((s) => s.length > 0);
  }

  _onChange(e) {
    // e.preventDefault();

    const option = e.target.value;
    const selected = this._split(this.props.selected);
    const k = selected.indexOf(option);

    // console.log(`option: ${option} selected: ${selected.join(', ')} k: ${k}`);
    if (k >= 0) {
      selected.splice(k, 1);
    } else {
      selected.push(option);
    }
    // console.log(` --> selected: ${selected.join(', ')}`);

    // emit fake event
    this.props.onChange({
      target: { name: this.props.name, value: selected.join(",") },
    });
  }
}

import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { Button, Tooltip } from "@material-ui/core";
import { FormatListNumberedOutlined } from "@material-ui/icons";

import TextAreaAutoExpand from "./TextAreaAutoExpand";

export default class TextEditor extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    onSave: PropTypes.func.isRequired,
    value: PropTypes.string,
    disabled: PropTypes.bool,
    format: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    rows: PropTypes.number,
    placeholder: PropTypes.string,
    showTools: PropTypes.bool,
  };

  constructor(props) {
    super(props);
    this.state = { value: this.props.value, changed: false };
    this._textAreaRef = React.createRef();
  }

  render() {
    return (
      <div className={classnames("TextEditor", this.props.className)}>
        {this.props.showTools && (
          <div
            className="TextEditor-tools"
            onClick={this._onListButtonClick.bind(this)}
          >
            <Tooltip title="Format List (Numbered)">
              <Button>
                <FormatListNumberedOutlined />
              </Button>
            </Tooltip>
          </div>
        )}
        <div className="TextEditor-container">
          <TextAreaAutoExpand
            className="TextEditor-input"
            onChange={(e) =>
              this.setState({
                changed: this.props.value !== e.target.value,
                value: e.target.value,
              })
            }
            onBlur={this._maybeSave.bind(this)}
            disabled={this.props.disabled}
            placeholder={this.props.placeholder}
            value={this.state.value}
            rows={this.props.rows}
            ref={this._ref}
            getRef={(ref) => (this._textAreaRef = ref)}
          />
          <div className="TextEditor-after">
            <div
              className={classnames(
                `TextEditor-flag`,
                this.state.changed && `TextEditor-flag--unsaved`,
                this._validFormat() || `TextEditor-flag--invalid`
              )}
              onClick={this._maybeSave.bind(this)}
            >
              ⏎
            </div>
          </div>
        </div>
      </div>
    );
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      this.setState({ value: this.props.value, changed: false });
    }
  }

  _maybeSave() {
    if (!this._validFormat()) return;
    if (!this.state.changed) return;
    this.props.onSave(this.state.value);
  }

  _validFormat() {
    if (!this.props.format) return true;
    return new RegExp(this.props.format).test(this.state.value);
  }

  _onListButtonClick() {
    const textArea = this._textAreaRef;
    const { value, selectionStart, selectionEnd } = textArea;
    if (selectionStart === selectionEnd) {
      return;
    }

    const startOfLine = value.lastIndexOf("\n", selectionStart - 1) + 1;
    const selection = value.substring(startOfLine, selectionEnd);

    // Check if the selected text is already formatted as a list
    const isList = /^\d\.\s/gm.test(selection);

    let newValue;
    if (isList) {
      // Remove the list formatting
      newValue =
        value.substring(0, startOfLine) +
        selection.replace(/^\d\.\s/gm, "") +
        value.substring(selectionEnd);
    } else {
      // Add the list formatting
      const list = selection
        .split("\n")
        .map((line) => `1. ${line}`)
        .join("\n");
      newValue =
        value.substring(0, startOfLine) + list + value.substring(selectionEnd);
    }

    textArea.value = newValue;
    this.setState({ value: newValue, changed: true });
  }
}

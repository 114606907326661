import React from "react";
import PropTypes from "prop-types";

import CheckboxFilter from "../CheckboxFilter";

const methods = {
  invite: "Invite",
  share: "Share",
  post_share: "Post Share",
  group_share: "Group Share",
  room_share: "Room Share",
  direct_mail: "Direct Mail",
};

const ReferralChannelsFilter = (props) => {
  return (
    <CheckboxFilter {...props} title={"Referral Channels"} values={methods} />
  );
};

ReferralChannelsFilter.propTypes = {
  selectedValues: PropTypes.arrayOf(PropTypes.string).isRequired,
  onChange: PropTypes.func.isRequired,
};

ReferralChannelsFilter.defaultProps = {
  selectedValues: [],
  onChange: () => {},
};

export default ReferralChannelsFilter;

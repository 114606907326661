import Base from "./Base";

export default class Categories extends Base {
  index(params) {
    return this._fetch(`/admin/pages/categories/assignable`)
      .then((response, ...args) => {
        return response.json();
      })
      .then((data) => data.categories);
  }
}

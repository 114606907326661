import React from "react";
import PropTypes from "prop-types";

import classnames from "classnames";

import UserLink from "../components/UserLink";
import Moment from "../components/Moment";

export default class PaymentRow extends React.Component {
  static propTypes = {
    api: PropTypes.object.isRequired,
    id: PropTypes.number.isRequired,
    user: PropTypes.object.isRequired,
    created_at: PropTypes.string.isRequired,
    referral_count: PropTypes.number.isRequired,
    amount: PropTypes.number.isRequired,
    psp_reference: PropTypes.string,
    status: PropTypes.string.isRequired,
  };

  static Headers = () => (
    <tr>
      <th>Date</th>
      <th className="PaymentRow-user">User</th>
      <th>Referrals</th>
      <th>Amount</th>
      <th>Notes</th>
      <th>Reference</th>
      <th>Action</th>
    </tr>
  );

  constructor(props) {
    super(props);
    this.state = { ...this.props };
    this.timer = null;
  }

  render() {
    const s = this.state;

    return (
      <tr>
        <td>
          <Moment time={s.created_at} />
        </td>
        <td className="PaymentRow-user">
          <UserLink inline {...this.props.user} />
        </td>
        <td>{s.referral_count}</td>
        <td>
          {/* FIXME: refactor to use StringEditor */}
          <div className="input-group input-group-sm">
            <div className="input-group-prepend">
              <div className="input-group-text">$</div>
            </div>
            <input
              className="form-control"
              size="4"
              onChange={(e) => {
                this.setState({ pendingAmount: true, amount: e.target.value });
              }}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  e.preventDefault();
                  this._saveAmount();
                }
              }}
              value={s.amount}
              disabled={s.status === "cleared"}
            />
            <div className="input-group-append">
              <div
                className={classnames(
                  "input-group-text",
                  s.pendingAmount && "Payments--unsaved"
                )}
              >
                ⏎
              </div>
            </div>
          </div>
        </td>
        <td>
          {/* FIXME: refactor to use StringEditor */}
          <div className="input-group input-group-sm">
            <input
              className="form-control"
              size="30"
              onChange={(e) => {
                this.setState({ pendingNote: true, note: e.target.value });
              }}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  e.preventDefault();
                  this._saveNote();
                }
              }}
              value={s.note || ""}
            />
            <div className="input-group-append">
              <div
                className={classnames(
                  "input-group-text",
                  s.pendingNote && "Payments--unsaved"
                )}
              >
                ⏎
              </div>
            </div>
          </div>
        </td>
        <td>
          <input
            className="form-control form-control-sm"
            onChange={(e) => {
              this.setState({ psp_reference: e.target.value });
            }}
            value={s.psp_reference || ""}
            disabled={s.status === "cleared"}
          />
        </td>

        <td>
          <button
            className={classnames(
              "btn btn-sm",
              s.status === "cleared" ? "btn-outline-secondary" : "btn-primary"
            )}
            onClick={this._onClick.bind(this)}
            disabled={s.status === "cleared" || !s.psp_reference}
          >
            {s.status === "cleared" ? "Confirmed" : "Confirm"}
          </button>
        </td>
      </tr>
    );
  }

  _onClick() {
    this.props.api.Payments.update({
      id: this.props.id,
      psp_reference: this.state.psp_reference,
      status: "cleared",
    }).then((data) => this.setState(data));
  }

  _saveNote() {
    this.props.api.Payments.update({
      id: this.props.id,
      note: this.state.note,
    }).then((data) => this.setState({ pendingNote: false, ...data }));
  }

  _saveAmount() {
    this.props.api.Payments.update({
      id: this.props.id,
      amount: this.state.amount,
    }).then((data) => this.setState({ pendingAmount: false, ...data }));
  }
}

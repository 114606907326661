import React, { Component } from "react";
import PropTypes from "prop-types";
import styles from "./TerminateButton.module.scss";

export default class TerminateButton extends Component {
  static propTypes = {
    api: PropTypes.object.isRequired,
    room: PropTypes.object.isRequired,
    onComplete: PropTypes.func,
  };

  render() {
    const room = this.props.room;

    if (!room.available) {
      return null;
    }

    let alert;
    let text;
    if (room.status === "scheduled") {
      alert = `Are you sure you want to cancel this pod?\n\nNB! THIS CANNOT BE REVERTED!`;
      text = "Cancel pod";
    } else {
      alert = `Are you sure you want to terminate this pod?\n\nNB! THIS CANNOT BE REVERTED!`;
      text = "Terminate pod";
    }

    return (
      <button
        className={styles.terminateButton}
        onClick={() => {
          if (window.confirm(alert)) this._onSubmit();
        }}
      >
        {text}{" "}
        <span role="img" aria-label="danger">
          💥️
        </span>
      </button>
    );
  }

  _onSubmit() {
    this.props.api.LiveAudioRooms.terminate(this.props.room.id).then(() => {
      if (this.props.onComplete) {
        this.props.onComplete();
      }
    });
  }
}

import React, { Component } from "react";
import PropTypes from "prop-types";

import h from "./helpers";

const phrases = {
  active: [
    [/,false/, "deletes photo"],
    [/,true/, "undeletes photo"],
  ],

  default: "takes no action on photo",
};

export default class HistoryUserPhoto extends Component {
  static propTypes = {
    userID: PropTypes.number,

    id: PropTypes.number.isRequired,
    created_at: PropTypes.string.isRequired,
    changeset: PropTypes.object.isRequired,
    notes: PropTypes.string,
    created_by: PropTypes.shape({
      id: PropTypes.number.isRequired,
      first_name: PropTypes.string.isRequired,
    }).isRequired,
    user_photo: PropTypes.shape({
      id: PropTypes.number.isRequired,
    }).isRequired,
  };

  static type = "history/user_photo";
  static icon = "🌄";

  render() {
    const p = this.props;

    return (
      <tr className="EventHistoryUserPhoto">
        {h.sharedColumns(this)}
        <td>
          {h.renderActor(p.created_by, p.userID)}{" "}
          {h.renderActions(p.changeset, phrases)} #{p.user_photo.id}{" "}
          {h.renderNotes(p.notes)}
        </td>
        <td></td>
      </tr>
    );
  }
}

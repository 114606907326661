import React, { Component } from "react";
import PropTypes from "prop-types";

export default class ReferralCashOutForm extends Component {
  static propTypes = {
    api: PropTypes.object.isRequired,
    userId: PropTypes.string.isRequired,
  };

  static defaultState = {
    amount: 0,
    referrals: 0,
    referral_rate: 1.6,
    bonus: 0,
    from: "",
    to: "",
  };

  constructor(props) {
    super(props);
    this.state = ReferralCashOutForm.defaultState;
    this.handleChange = this._handleChange.bind(this);
    this.onClick = this._onClick.bind(this);
  }

  _handleChange(e) {
    const field = e.target.name;
    const value = e.target.value;

    this.setState({ [field]: value }, this._preview);
  }

  _onClick(e) {
    this.props.api.Payments.create(this._params()).then(() => {
      this.setState(ReferralCashOutForm.defaultState, this.props.onUpdate);
    });
  }

  _preview() {
    this.props.api.Payments.preview(this._params()).then((data) => {
      this.setState(data, this.props.onUpdate);
    });
  }

  _params() {
    return {
      id: this.props.userId,
      from: this.state.from,
      to: this.state.to,
      referral_rate: this.state.referral_rate,
      bonus: this.state.bonus,
    };
  }

  render() {
    return (
      <div className="ReferralCashOut">
        <div className="ReferralCashOutTitle">Referral cash out</div>

        <form className="ReferralCashOutForm">
          <div>
            <label>From date</label>
            <input
              type="date"
              name="from"
              value={this.state.from}
              onChange={this.handleChange}
            />
          </div>

          <div>
            <label>To date</label>
            <input
              type="date"
              name="to"
              value={this.state.to}
              onChange={this.handleChange}
            />
          </div>

          <div>
            <label>Referrals</label>
            <input
              type="number"
              disabled
              name="referrals"
              value={this.state.referrals}
            />
          </div>

          <div>
            <label>Referral rate</label>
            <input
              type="number"
              name="referral_rate"
              value={this.state.referral_rate}
              onChange={this.handleChange}
            />
          </div>

          <div>
            <label>Bonus</label>
            <input
              type="number"
              name="bonus"
              value={this.state.bonus}
              onChange={this.handleChange}
            />
          </div>

          <div>
            <label>Payout</label>
            <input
              type="number"
              disabled
              name="amount"
              value={this.state.amount}
            />
          </div>

          <div>
            <label>&nbsp;</label>
            <input type="button" value="Create Payout" onClick={this.onClick} />
          </div>
        </form>
      </div>
    );
  }
}

import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";

import { ReactQueryDevtools } from "react-query/devtools";
import "./index.scss";
import App from "./App";

import * as Sentry from "@sentry/browser";

Sentry.init({
  dsn: "https://fdbf989f77ad4c329b13787eade6860e@sentry.io/1362137",
  environment: process.env.REACT_APP_ENV_NAME,
});

const queryClient = new QueryClient();

ReactDOM.render(
  <QueryClientProvider client={queryClient}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
    <ReactQueryDevtools initialIsOpen={false} />
  </QueryClientProvider>,
  document.getElementById("root")
);

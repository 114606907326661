import Base from "./Base";

export default class Warnings extends Base {
  create(id, params) {
    return this._fetch(`/admin/users/${id}/warnings`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(params),
    });
  }
}

import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import moment from "moment";

import styles from "./UnreportedAbusiveContentListItem.module.scss";

export class UnreportedAbusiveContentListItem extends Component {
  static propTypes = {
    onSelect: PropTypes.func,
    selectInputName: PropTypes.string,
  };

  static defaultProps = {
    onSelect: () => {},
    selectInputName: "abusiveContentList",
  };

  constructor(props) {
    super(props);

    this._handleSelect = this._handleSelect.bind(this);
  }

  _content() {
    const { type, ...item } = this.props;
    let content = null;

    switch (type) {
      case "Comment":
        content = {
          id: item.id,
          title: item.body,
          type: "Comment",
          url: `/posts/${item.post_id}#comments_${item.id}`,
        };
        break;
      case "Post":
        content = {
          id: item.id,
          title: item.title,
          type: "Post",
          url: `/posts/${item.id}/`,
        };
        break;
      case "Layer::UserConversation":
        content = {
          id: item.id,
          type: "Layer::UserConversation",
          url: `/conversations/${item.conversation_id}`,
        };
        break;
      default:
        break;
    }

    return content;
  }

  _handleSelect() {
    const { id, type, onSelect } = this.props;

    onSelect(id, type);
  }

  render() {
    const { id, type, created_at, selected, selectInputName } = this.props;
    const content = this._content();

    if (!content) {
      return <></>;
    }

    return (
      <div
        className={`${styles.container} ${
          selected && styles.containerSelected
        }`}
      >
        <div className={styles.radioContainer}>
          <input
            type="radio"
            id={`${type}_${id}`}
            name={selectInputName}
            value={`${type}_${id}`}
            onClick={this._handleSelect}
          />
        </div>
        <div className={styles.detailsContainer}>
          <header>
            <Link to={content.url}>
              {content.type} #{content.id}
            </Link>
          </header>
          {content.title && <blockquote>{content.title}</blockquote>}
          <footer>
            <div>{moment(created_at).format("D MMM YYYY")}</div>
          </footer>
        </div>
      </div>
    );
  }
}

export default UnreportedAbusiveContentListItem;

class Errors {
  constructor() {
    this.callbacks = [];
  }

  notify(...args) {
    this.callbacks.forEach((cb) => cb(...args));
  }

  on(cb) {
    this.callbacks.push(cb);
  }

  off(cb) {
    this.callbacks = this.callbacks.filter((x) => x === cb);
  }
}

export default new Errors();

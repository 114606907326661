import Base from "./Base";

export default class CommentsSearch extends Base {
  index(params) {
    const q = this._query(params);

    return this._fetch(`/admin/pages/comments/search?${q}`)
      .then((response) => response.json())
      .then((data) => [data.comments]);
  }
}

import React, { Component } from "react";
import { Route as BaseRoute } from "react-router-dom";

class Route extends Component {
  render() {
    const { component, exact, path, ...props } = this.props;
    return (
      <BaseRoute
        exact={exact}
        path={path}
        render={(routeProps) => {
          return this._renderComponent(component, routeProps, props);
        }}
      />
    );
  }

  _renderComponent(component, routeProps, extraProps) {
    const finalProps = Object.assign({}, routeProps, extraProps);
    return React.createElement(component, finalProps);
  }
}

export default Route;

import React, { Component } from "react";
import PropTypes from "prop-types";

import classnames from "classnames";

export default class RadioGroup extends Component {
  static propTypes = {
    className: PropTypes.string,
    title: PropTypes.string,
    name: PropTypes.string.isRequired,
    labels: PropTypes.arrayOf(PropTypes.string).isRequired,
    options: PropTypes.arrayOf(PropTypes.string).isRequired,
    selected: PropTypes.string.isRequired,
    onChange: PropTypes.func,
  };

  static defaultProps = {
    className: "",
  };

  render() {
    const p = this.props;

    return (
      <div className={classnames("RadioGroup", p.className)}>
        {p.title && <label className="title">{p.title}:</label>}
        {p.options.map((option, idx) => (
          <div key={idx}>
            <input
              type="radio"
              id={`${p.name}-${option}`}
              name={p.name}
              value={option}
              checked={p.selected === option}
              onChange={p.onChange}
            />
            <label htmlFor={`${p.name}-${option}`}>{p.labels[idx]}</label>
          </div>
        ))}
      </div>
    );
  }
}

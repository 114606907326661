import React, { Component } from "react";
import PropTypes from "prop-types";

import StatusButton from "./StatusButton";

export default class PublicationStatus extends Component {
  static propTypes = {
    post: PropTypes.object.isRequired,
  };

  render() {
    const post = this.props.post;
    const publication = post.published_post;

    const alertColourMap = {
      accepted: "success",
      denied: "danger",
      requested: "neutral",
      expired: "neutral",
      retracted: "danger",
      pending: "warning",
      published: "success",
    };

    return (
      <div>
        {publication && (
          <StatusButton status={alertColourMap[publication.status]}>
            {publication.status === "pending"
              ? "Not published"
              : publication.status === "published"
              ? "Published"
              : "Unpublished"}
          </StatusButton>
        )}
      </div>
    );
  }
}

import Base from "./Base";

export default class UserFeatures extends Base {
  index(params) {
    return this._fetch(`/admin/users/${params.user_id}/features`)
      .then((response) => response.json())
      .then((data) => [data.user_features]);
  }

  expire(userId) {
    return this._fetch(`/admin/users/${userId}/features/expire`, {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
    });
  }

  create(userId, params) {
    return this._fetch(`/admin/users/${userId}/features`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(params),
    })
      .then((response) => response.json())
      .then((data) => [data.user_features]);
  }
}

import React from "react";
import { Link } from "react-router-dom";

import Moment from "../Moment";

class Helpers {
  sharedColumns(obj) {
    const p = obj.props;
    const i = obj.constructor.icon;

    return (
      <React.Fragment>
        <td width="1">
          <Moment time={p.created_at} />
        </td>
        <td className="UserTabLogs-icon" width="1">
          {i}
        </td>
      </React.Fragment>
    );
  }

  renderActor(u, contextUserID) {
    if (u === undefined || u === null || u.id === 278244) {
      // NB. 278244 is the ID of the system user
      return "System";
    }

    if (u.id === contextUserID) {
      return "User";
    }

    return <Link to={`/users/${u.id}`}>{u.first_name || `User#${u.id}`}</Link>;
  }

  renderNotes(notes, reasonCode) {
    let reason = null;
    if (reasonCode && reasonCode !== "unknown") {
      reason = reasonCode.replace(/_/g, " ");
    }

    if (!notes && !reason) return;

    const message = [reason, notes].filter((x) => !!x).join(" — ");

    if (reasonCode === "reused_blocked_device") {
      let userUidMatch = notes && notes.match(/\d+/);

      if (userUidMatch != null) {
        let userUid = userUidMatch[0];
        return (
          <React.Fragment>
            (
            <em>
              reused blocked device by user{" "}
              <Link to={`/users/${userUid}`}>{userUid}</Link>
            </em>
            )
          </React.Fragment>
        );
      }
    }

    return (
      <React.Fragment>
        (<strong>{message}</strong>)
      </React.Fragment>
    );
  }

  // reasons: reason => count
  // system: array
  renderCountUsers({ reasons, system }) {
    let countUsers = 0;
    if (Object.keys(reasons).length > 0) {
      countUsers = this.sumValues(reasons);
    }

    if (system.length > 0 && countUsers <= 1) {
      return `🤖System reports`;
    } else if (system.length > 0) {
      return `
        🤖System and
        ${countUsers === 2 ? "👤" : "👥"}
        ${countUsers - 1}
        ${countUsers === 2 ? "user reports" : "users report"}
      `;
    } else {
      return `
        ${countUsers === 1 ? "👤" : "👥"}
        ${countUsers}
        ${countUsers === 1 ? "user reports" : "users report"}
      `;
    }
  }

  /**
   * @param {Object} props
   * @param {number[]} props.reporter_ids
   */
  renderUserIds({ reporter_ids }) {
    if (reporter_ids.length === 0) {
      return null;
    }

    const userIdLinks = reporter_ids.map((id, index, originalArray) => (
      <React.Fragment key={id}>
        <Link to={`/users/${id}`}>{id}</Link>
        {index < originalArray.length - 1 ? ", " : ""}
      </React.Fragment>
    ));

    return (
      <span>
        &nbsp;(User {reporter_ids.length === 1 ? "id" : "ids"} {userIdLinks})
      </span>
    );
  }

  sumValues(obj) {
    return Object.values(obj).reduce((acc, x) => acc + x);
  }

  renderActions(changeset, phrases) {
    if (Object.entries(changeset).length === 0) {
      return phrases.default;
    }

    return (
      <ul className="RenderedActions">
        {Object.entries(changeset).map(([field, value], idx) => (
          <li key={idx}>{this._renderAction(phrases, field, value)}</li>
        ))}
      </ul>
    );
  }

  renderAbuseAction(type, from, to) {
    const added = to.filter((x) => !from.includes(x)).join(", ");
    const removed = from.filter((x) => !to.includes(x)).join(", ");

    const changes = [
      added && `adds flag "${added}"`,
      removed && `removes flag "${removed}"`,
    ]
      .filter((x) => !!x)
      .join(" ");

    return removed ? `${changes} from ${type}` : `${changes} to ${type}`;
  }

  _renderAction(phrases, field, value) {
    let from, to;

    if (Array.isArray(value)) {
      from = value[0];
      to = value[1];
    } else to = value;

    if (phrases[field] === undefined) {
      return this._renderFallbackAction(field, from, to);
    }

    const change = `${from},${to}`;

    if (typeof phrases[field] === "function") {
      return phrases[field](from, to);
    }

    for (let [regexp, sentence] of phrases[field]) {
      if (!regexp.test(change)) continue;
      return sentence.replace("{from}", from).replace("{to}", to);
    }

    return this._renderFallbackAction(field, from, to);
  }

  _renderFallbackAction(field, from, to) {
    return `changes ${field}: ${from} → ${to}`;
  }
}

export default new Helpers();

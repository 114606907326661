import React, { Component } from "react";
import PropTypes from "prop-types";

import styles from "./auto_annotations.module.scss";

export default class AutoAnnotationUrlField extends Component {
  static propTypes = {
    api: PropTypes.object.isRequired,
    auto_annotation: PropTypes.object.isRequired,
    countryCode: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      isDirty: false,
      countryCode: props.countryCode,
      url: props.url,
      deleted: false,
      newRecord: !props.countryCode.length,
    };
  }

  render() {
    if (this.state.deleted === true) {
      return null;
    }

    return (
      <div className="mb-2">
        <input
          type="text"
          className={styles.countryCodeInput}
          disabled={!this.state.newRecord}
          onChange={this._onCountryCodeChange.bind(this)}
          value={this.state.countryCode}
        />
        <input
          type="text"
          className={styles.urlInput}
          onChange={this._onUrlChange.bind(this)}
          value={this.state.url}
        />
        {this.state.isDirty && (
          <button className={styles.saveBtn} onClick={this._onSave.bind(this)}>
            Save
          </button>
        )}
        {!this.state.newRecord && (
          <button
            className={styles.deleteBtn}
            onClick={this._onDelete.bind(this)}
          >
            Delete
          </button>
        )}
      </div>
    );
  }

  _onDelete(event) {
    this.props.api.AutoAnnotations.deleteUrl(
      this.props.auto_annotation.id,
      this.state.countryCode
    ).then((data) => {
      this.setState({ deleted: true });
    });
  }

  _onSave(event) {
    this.props.api.AutoAnnotations.updateUrl(
      this.props.auto_annotation.id,
      this.state.countryCode,
      this.state.url
    ).then((data) => {
      this.setState({ isDirty: false, newRecord: false });
    });
  }

  _onUrlChange(event) {
    const value = event.target.value;
    this.setState({ url: value, isDirty: true });
  }

  _onCountryCodeChange(event) {
    const value = event.target.value;
    this.setState({ countryCode: value, isDirty: true });
  }
}

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import {
  Button,
  DialogActions,
  DialogContent,
  TextField,
} from "@material-ui/core";
import DialogTitle from "../../../components/DialogTitle";
import Dialog from "@material-ui/core/Dialog";

const ReferralChannelsFilter = ({ values, onChange, onClose, title, open }) => {
  const [firstName, setFirstName] = useState(values.first_name || "");
  const [lastName, setLastName] = useState(values.last_name || "");
  const [email, setEmail] = useState(values.email || "");
  const [id, setUserId] = useState(values.id || "");
  const [uid, setUserUid] = useState(values.uid || "");

  useEffect(() => {
    setFirstName(values.first_name);
    setLastName(values.last_name);
    setEmail(values.email);
    setUserId(values.id);
    setUserUid(values.uid);
  }, [values]);

  const idUpdateMap = {
    firstName: setFirstName,
    lastName: setLastName,
    email: setEmail,
    id: setUserId,
    uid: setUserUid,
  };

  const handleApply = () => {
    const changes = {
      first_name: firstName,
      last_name: lastName,
      email: email,
      id,
      uid,
    };

    onChange(changes);
    onClose();
  };

  const handleClose = () => {
    onClose();
  };

  const handleChange = ({ target: { id, value } }) => {
    idUpdateMap[id] && idUpdateMap[id](value);
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <DialogTitle id="simple-dialog-title" onClose={handleClose}>
        {title}
      </DialogTitle>
      <DialogContent dividers>
        <TextField
          id="firstName"
          label="First name"
          value={firstName}
          onChange={handleChange}
          fullWidth
          autofocus={true}
        />

        <TextField
          id="lastName"
          label="Last name"
          value={lastName}
          onChange={handleChange}
          fullWidth
        />

        <TextField
          id="email"
          label="Email"
          value={email}
          onChange={handleChange}
          fullWidth
        />

        <TextField
          id="id"
          label="ID (Numeric)"
          value={id}
          onChange={handleChange}
          fullWidth
        />

        <TextField
          id="uid"
          label="UID"
          value={uid}
          onChange={handleChange}
          fullWidth
        />
      </DialogContent>
      <DialogActions>
        <Button type="submit" onClick={handleApply} color="primary">
          Apply
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ReferralChannelsFilter.propTypes = {
  onClose: PropTypes.func.isRequired,
};

ReferralChannelsFilter.defaultProps = {
  onClose: () => {},
};

export default ReferralChannelsFilter;

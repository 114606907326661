import Base from "./Base";

export default class Images extends Base {
  create(params) {
    return this._fetch(`/admin/stored_images`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(params),
    }).then((response) => response.json());
  }

  show(id) {
    return this._fetch(`/admin/stored_images/${id}`).then((response) =>
      response.json()
    );
  }

  update(id, params) {
    return this._fetch(`/admin/stored_images/${id}`, {
      method: "PATCH",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(params),
    }).then((response) => response.json());
  }

  upload(file, { width, height }) {
    const meta = {};

    return this.create({
      content_type: file.type,
      size: file.size,
      width: width,
      height: height,
    })
      .then((data) => {
        meta.id = data.id;
        return fetch(data.upload_url, {
          method: "POST",
          headers: {
            "Content-Type": data.content_type,
            "X-Goog-Resumable": "start",
          },
        });
      })
      .then((response) => {
        const loc = response.headers.get("location");
        return fetch(loc, {
          method: "PUT",
          body: file,
          headers: {
            "Content-Type": file.type,
          },
        });
      })
      .then((response) => {
        return this.update(meta.id, { status: "uploaded" });
      });
  }
}

import React from "react";
import PropTypes from "prop-types";

import ModalForm from "./ModalForm";

export default class ButtonWithConfirmation extends React.Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    yesButtonLabel: PropTypes.string,
    noButtonLabel: PropTypes.string,
  };

  static defaultProps = {
    yesButtonLabel: "Proceed",
    noButtonLabel: "Cancel",
  };

  constructor(props) {
    super(props);
    this.state = { modal: false };
  }

  render(props) {
    const { title, message, onClick, noButtonLabel, yesButtonLabel, ...rest } =
      this.props;

    return (
      <React.Fragment>
        <button
          {...rest}
          onClick={this.setState.bind(this, { modal: true }, null)}
        >
          {this.props.children}
        </button>
        {this.state.modal && (
          <ModalForm
            title={title}
            form={<p>{message}</p>}
            onCancel={this._onCancel.bind(this)}
            actions={
              <span className="ButtonWithConfirmation-actions">
                <button onClick={this._onCancel.bind(this)}>
                  {noButtonLabel}
                </button>
                <button onClick={this._onConfirm.bind(this)}>
                  {yesButtonLabel}
                </button>
              </span>
            }
          />
        )}
      </React.Fragment>
    );
  }

  _onCancel() {
    this.setState({ modal: false });
  }

  _onConfirm() {
    this.setState({ modal: false }, this.props.onClick);
  }
}

import React, { Component } from "react";
import PropTypes from "prop-types";

import moment from "moment";

export default class Moment extends Component {
  static propTypes = {
    date: PropTypes.string,
    time: PropTypes.string,
    format: PropTypes.string,
    relative: PropTypes.bool,
    nosuffix: PropTypes.bool,
  };

  render() {
    const p = this.props;
    const t = new Date(p.time || p.date);
    let m = this.props.utc ? moment(t).utc() : moment(t);

    if (isNaN(t.getTime())) {
      return <span>-</span>;
    }

    const mAbs = p.format
      ? m.format(p.format)
      : p.time
      ? m.format("D MMM YYYY, HH:mm")
      : m.format("D MMM YYYY");
    const mRel = m.fromNow(p.nosuffix);

    const [tMain, tAlt] = p.relative ? [mRel, mAbs] : [mAbs, mRel];

    return (
      <span className="Moment">
        <span className="Moment-main">{tMain}</span>
        <span className="Moment-alt">{tAlt}</span>
      </span>
    );
  }
}

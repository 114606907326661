import Session from "./Session";
import Users from "./Users";
import Posts from "./Posts";
import Comments from "./Comments";
import UserPhotos from "./UserPhotos";
import UserGroupMemberships from "./UserGroupMemberships";
import Categories from "./Categories";
import PostTags from "./PostTags";
import Groups from "./Groups";
import Conversations from "./Conversations";
import Messages from "./Messages";
import UserLogs from "./UserLogs";
import Cases from "./Cases";
import Reports from "./Reports";
import Experiments from "./Experiments";
import Referrals from "./Referrals";
import Payments from "./Payments";
import UserEvents from "./UserEvents";
import Campaigns from "./Campaigns";
import CampaignFilters from "./CampaignFilters";
import Status from "./Status";
import Tags from "./Tags";
import Images from "./Images";
import SEOPages from "./seo/Pages";
import SEOPosts from "./seo/Posts";
import SEOCategories from "./seo/Categories";
import SEOTags from "./seo/Tags";
import SEOAuthors from "./seo/Authors";
import StoredVideos from "./StoredVideos";
import Mvps from "./Mvps";
import Warnings from "./Warnings";
import Guidelines from "./Guidelines";
import LiveAudioRecording from "./LiveAudioRecording";
import LiveAudioReports from "./LiveAudioReports";
import LiveAudioRooms from "./LiveAudioRooms";
import LiveAudioParticipants from "./LiveAudioParticipants";
import LiveAudioEvents from "./LiveAudioEvents";
import UserDevices from "./UserDevices";
import UnreportedAbusiveContent from "./UnreportedAbusiveContent";
import ContentLabellingJobs from "./ContentLabellingJobs";
import AutoAnnotations from "./AutoAnnotations";
import CommentsSearch from "./CommentsSearch";
import UserFeatures from "./UserFeatures";

class API {
  constructor() {
    this._onStart = this._onStart.bind(this);
    this._onStop = this._onStop.bind(this);
    this._onError = this._onError.bind(this);

    this.callbacks = {
      start: [],
      stop: [],
      error: [],
    };

    Object.assign(this, {
      Session: this._setup(Session),
      Users: this._setup(Users),
      Posts: this._setup(Posts),
      Comments: this._setup(Comments),
      UserPhotos: this._setup(UserPhotos),
      UserGroupMemberships: this._setup(UserGroupMemberships),
      Categories: this._setup(Categories),
      PostTags: this._setup(PostTags),
      Groups: this._setup(Groups),
      Conversations: this._setup(Conversations),
      Messages: this._setup(Messages),
      UserLogs: this._setup(UserLogs),
      Cases: this._setup(Cases),
      Reports: this._setup(Reports),
      Experiments: this._setup(Experiments),
      Referrals: this._setup(Referrals),
      Payments: this._setup(Payments),
      UserEvents: this._setup(UserEvents),
      Campaigns: this._setup(Campaigns),
      CampaignFilters: this._setup(CampaignFilters),
      Status: this._setup(Status),
      Tags: this._setup(Tags),
      Images: this._setup(Images),
      SEO: {
        Pages: this._setup(SEOPages),
        Posts: this._setup(SEOPosts),
        Categories: this._setup(SEOCategories),
        Tags: this._setup(SEOTags),
        Authors: this._setup(SEOAuthors),
      },
      StoredVideos: this._setup(StoredVideos),
      Mvps: this._setup(Mvps),
      Warnings: this._setup(Warnings),
      Guidelines: this._setup(Guidelines),
      LiveAudioRecording: this._setup(LiveAudioRecording),
      LiveAudioReports: this._setup(LiveAudioReports),
      LiveAudioRooms: this._setup(LiveAudioRooms),
      LiveAudioParticipants: this._setup(LiveAudioParticipants),
      LiveAudioEvents: this._setup(LiveAudioEvents),
      UserDevices: this._setup(UserDevices),
      UnreportedAbusiveContent: this._setup(UnreportedAbusiveContent),
      ContentLabellingJobs: this._setup(ContentLabellingJobs),
      AutoAnnotations: this._setup(AutoAnnotations),
      CommentsSearch: this._setup(CommentsSearch),
      UserFeatures: this._setup(UserFeatures),
    });
  }

  on(event, cb) {
    this.callbacks[event].push(cb);
  }

  off(event, cb) {
    this.callbacks[event] = this.callbacks[event].filter((x) => x === cb);
  }

  _setup(module) {
    return new module({
      onStart: this._onStart.bind(this),
      onStop: this._onStop.bind(this),
      onError: this._onError.bind(this),
    });
  }

  _onError(...args) {
    this.callbacks.error.forEach((cb) => cb(...args));
  }

  _onStart(key) {
    this.callbacks.start.forEach((cb) => cb(key));
  }

  _onStop(key) {
    this.callbacks.stop.forEach((cb) => cb(key));
  }
}

export default API;

import Base from "./Base";

export default class Experiments extends Base {
  index(params) {
    const q = this._query(params);

    return this._fetch(`/admin/experiments?${q}`)
      .then((response) => response.json())
      .then((data) => [data.experiments]);
  }

  show(id) {
    return this._fetch(`/admin/experiments/${id}`).then((response) =>
      response.json()
    );
  }

  create(params) {
    return this._fetch(`/admin/experiments`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(params),
    }).then((response) => response.json());
  }

  update({ id, ...params }) {
    return this._fetch(`/admin/experiments/${id}`, {
      method: "PATCH",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(params),
    }).then((response) => response.json());
  }

  addUser(id, userID, forcedVariant) {
    const params = {
      experiment_user: {
        user_id: userID,
        forced_variant: forcedVariant,
      },
    };

    return this._fetch(`/admin/experiments/${id}/experiment_users`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(params),
    });
  }

  removeUser(id, expUserID) {
    return this._fetch(
      `/admin/experiments/${id}/experiment_users/${expUserID}`,
      {
        method: "DELETE",
        headers: { "Content-Type": "application/json" },
      }
    );
  }

  start(id) {
    return this._fetch(`/admin/experiments/${id}/start`, { method: "PUT" });
  }

  analyses(id) {
    return this._fetch(`/admin/experiments/${id}/analyses`).then((response) =>
      response.json()
    );
  }

  results(xid, aid) {
    return this._fetch(
      `/admin/experiments/${xid}/analyses/${aid}/results`
    ).then((response) => response.json());
  }
}

import Base from "./Base";

export default class LiveAudioReports extends Base {
  show(params) {
    const { roomId, ...editedParams } = params;
    const q = this._query(editedParams);

    return this._fetch(`/admin/audio_rooms/${roomId}/recording?${q}`)
      .then((response) => response.json())
      .then(({ data }) => data);
  }
}

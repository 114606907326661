import React, { Component } from "react";
import PropTypes from "prop-types";
import Moment from "../../../../../../components/Moment";
import UserLink from "../../../../../../components/UserLink";

import styles from "./DefaultRow.module.scss";

export default class DefaultRow extends Component {
  static propTypes = {
    roomLive: PropTypes.bool,
  };

  static defaultProps = {
    onSeek: (value) => {},
    onJumpTo: (seconds) => {},
    roomLive: false,
  };

  constructor(props) {
    super(props);

    this._actions = this._actions.bind(this);
    this._firstName = this._firstName.bind(this);
    this._notes = this._notes.bind(this);
    this._type = this._type.bind(this);
    this._who = this._who.bind(this);

    this._handleWhenClick = this._handleWhenClick.bind(this);
    this.onUpdate = this.onUpdate.bind(this);
  }

  onUpdate(results) {
    this.props.onChange(results);
  }

  _actions() {
    return <></>;
  }

  _firstName() {
    const { first_name } = { ...this.props.action_user };

    return first_name || "Someone";
  }

  _type() {
    return <>{this.constructor.label}</>;
  }

  _notes() {
    return <></>;
  }

  _who() {
    const { action_user } = this.props;
    return <UserLink {...action_user} />;
  }

  _handleWhenClick() {
    const { relative_at } = this.props;
    var a = relative_at.split(":");

    const seconds = +a[0] * 60 * 60 + +a[1] * 60 + +a[2];

    this.props.onJumpTo(seconds);
  }

  render() {
    const { event_at, relative_at } = this.props;

    return (
      <tr>
        <td>
          <span class={styles.relativeTime} onClick={this._handleWhenClick}>
            {relative_at}
          </span>
          (<Moment date={event_at} format="HH:mm:ss" />)
        </td>
        <td className={styles.whoColumn}>{this._who()}</td>
        <td>{this._type()}</td>
        <td>{this._notes()}</td>
        <td>{this._actions()}</td>
      </tr>
    );
  }
}

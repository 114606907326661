import { useMutation } from "react-query";
import Cookies from "universal-cookie";

const cookies = new Cookies();
const xsrf_token = cookies.get("XSRF-TOKEN");
const url = new URL(`${process.env.REACT_APP_API_HOST}/admin/breaches/`);

const submitWithdraw = (id) => {
  return fetch(`${url}${id}/withdraw`, {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      "X-XSRF-Token": xsrf_token,
    },
  });
};
const useWithdraw = (onWithdrawSuccess, onWithdrawError) => {
  return useMutation(submitWithdraw, {
    onSuccess: (response) => {
      if (!response.ok) {
        onWithdrawError("There was an error.");
        throw new Error("Bad response from server");
      } else {
        onWithdrawError("");
        onWithdrawSuccess(true);
      }
    },
  });
};

export default useWithdraw;

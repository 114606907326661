import React from "react";
import PropTypes from "prop-types";

export default class Cases extends React.Component {
  static propTypes = {
    api: PropTypes.object.isRequired,
  };
  constructor(props) {
    super(props);
    this.state = { error: false };
  }

  render() {
    return (
      <div className="Case">
        {this.state.error && <p>Error: Case already claimed.</p>}
        {this.state.error || <p>Claiming case...</p>}
      </div>
    );
  }

  componentDidMount() {
    this.props.api.Cases.claim(this.props.match.params.id).then(
      ({ data, conflict }) => {
        if (conflict) {
          this.setState({ error: true });
          return;
        }

        this.props.history.replace(`/users/${data.user.id}`);
      }
    );
  }
}

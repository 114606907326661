import React, { Component } from "react";
import PropTypes from "prop-types";

import Moment from "../components/Moment";
import ExperimentInput from "../components/ExperimentInput";
import ExperimentStatusBadge from "../components/ExperimentStatusBadge";
import ExperimentUsers from "../components/ExperimentUsers";
import ExperimentResults from "../components/ExperimentResults";

export default class Experiment extends Component {
  static propTypes = {
    api: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      experiment: {},
      cache_ttl: null,
      pendingNote: false,
    };
  }

  componentDidMount() {
    this._onRefresh();
  }

  render() {
    const exp = this.state.experiment;

    if (exp.id === undefined) {
      return <span>Loading&hellip;</span>;
    }

    return (
      <div className="Experiment">
        <section className="ExperimentDetails">
          <div className="ExperimentDetails-content">
            <div className="ExperimentDetails-warning">
              {/* eslint-ignore jsx-a11y/accessible-emoji */}
              ⚠️ Changes may take up to {this.state.cache_ttl} seconds take
              effect!
            </div>

            <h2 className="ExperimentDetails-header">{exp.name}</h2>

            <dl className="ExperimentDetails-details">
              <dt>Variants</dt>
              <dd>
                {Object.keys(exp.variants).map((variant) => (
                  <span key={variant}>
                    <strong>{variant}:</strong> {exp.variants[variant]}%{" "}
                  </span>
                ))}
              </dd>

              <dt>Status</dt>
              <dd>
                <ExperimentStatusBadge data={exp} />
              </dd>

              <dt>Client</dt>
              <dd>{exp.client ? "Yes" : "No"}</dd>

              <dt>Started at</dt>
              <dd>
                {exp.started_at ? (
                  <React.Fragment>
                    <Moment time={exp.started_at} />
                    {exp.days_running && ` ${exp.days_running} days`}
                  </React.Fragment>
                ) : (
                  <button
                    className="badge badge-pill badge-secondary btn"
                    onClick={this._onStart.bind(this)}
                  >
                    Start
                  </button>
                )}
              </dd>

              <dt>Ended at</dt>
              <dd>
                <Moment time={exp.ended_at} />
              </dd>
            </dl>

            <dl className="ExperimentDetails-details">
              <dt>Forced to</dt>
              <dd>
                <select
                  className="form-control form-control-sm"
                  onChange={this._forceVariant.bind(this)}
                  disabled={!!exp.ended_at}
                  defaultValue={exp.forced_variant}
                >
                  <option value="">none</option>
                  {Object.keys(exp.variants).map((v, idx) => (
                    <option key={idx} value={v}>
                      {v}
                    </option>
                  ))}
                </select>
              </dd>

              <dt>Min. version iOS</dt>
              <dd>
                <ExperimentInput
                  value={exp.ios_version}
                  placeholder="n/a"
                  minLength="5"
                  maxLength="8"
                  onKeyPress={this._updateIosVersion.bind(this)}
                />
              </dd>

              <dt>Min. version Android</dt>
              <dd>
                <ExperimentInput
                  value={exp.android_version}
                  placeholder="n/a"
                  minLength="5"
                  maxLength="8"
                  onKeyPress={this._updateAndroidVersion.bind(this)}
                />
              </dd>

              <dt>Name</dt>
              <dd>
                <ExperimentInput
                  value={exp.name}
                  disabled={exp.started_at !== null}
                  onKeyPress={this._updateName.bind(this)}
                />
              </dd>

              <dt>Note</dt>
              <dd>
                <ExperimentInput
                  value={exp.note}
                  onKeyPress={this._updateNote.bind(this)}
                />
              </dd>
            </dl>
          </div>
        </section>

        <ExperimentUsers
          api={this.props.api}
          experiment={this.state.experiment}
          onChange={this._onRefresh.bind(this)}
        />

        {this.state.experiment && (
          <ExperimentResults
            api={this.props.api}
            experiment={this.state.experiment}
          />
        )}
      </div>
    );
  }

  _onRefresh() {
    this.props.api.Experiments.show(this.props.match.params.id).then((data) => {
      this.setState({ experiment: data.experiment, cache_ttl: data.cache_ttl });
    });
  }

  _onClickStartExperiment() {
    this.props.api.Experiments.start(this.state.experiment.id).then(
      this._onRefresh.bind(this)
    );
  }

  _updateAndroidVersion(value) {
    if (value === "") {
      value = null;
    }

    this.props.api.Experiments.update({
      id: this.state.experiment.id,
      android_version: value,
    }).then(this._onRefresh.bind(this));
  }

  _updateIosVersion(value) {
    if (value === "") {
      value = null;
    }

    this.props.api.Experiments.update({
      id: this.state.experiment.id,
      ios_version: value,
    }).then(this._onRefresh.bind(this));
  }

  _updateNote(value) {
    this.props.api.Experiments.update({
      id: this.state.experiment.id,
      note: value,
    }).then(this._onRefresh.bind(this));
  }

  _updateName(value) {
    this.props.api.Experiments.update({
      id: this.state.experiment.id,
      name: value,
    }).then(this._onRefresh.bind(this));
  }

  _forceVariant(e) {
    this.props.api.Experiments.update({
      id: this.state.experiment.id,
      forced_variant: e.target.value,
    }).then(this._onRefresh.bind(this));
  }

  _onStart() {
    this.props.api.Experiments.start(this.state.experiment.id).then(
      this._onRefresh.bind(this)
    );
  }
}

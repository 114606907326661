import React, { Component } from "react";
import PropTypes from "prop-types";

import classnames from "classnames";

// Tri-state checkbox
export default class CheckBox extends Component {
  static propTypes = {
    className: PropTypes.string,
    name: PropTypes.string,
    label: PropTypes.string,
    selected: PropTypes.string, // true, false, empty
    onChange: PropTypes.func,
  };

  static defaultProps = {
    className: "",
  };

  render() {
    const p = this.props;
    const indeterminate = p.selected === "";

    return (
      <div className={classnames("CheckBox", p.className)}>
        <div>
          <input
            type="checkbox"
            id={p.name}
            checked={p.selected === "true"}
            onChange={this._onChange.bind(this)}
            ref={(e) => {
              if (e !== null) e.indeterminate = indeterminate;
            }}
          />
          <label htmlFor={p.name}>{p.label}</label>
        </div>
      </div>
    );
  }

  _onChange(e) {
    // e.preventDefault();

    const value =
      this.props.selected === "true"
        ? "false"
        : this.props.selected === "false"
        ? ""
        : "true";

    // emit fake event
    this.props.onChange({
      target: { name: this.props.name, value: value },
    });
  }
}

import React, { Component } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import MDEditor from "@uiw/react-md-editor";

export default class ContentLabellingJobForm extends Component {
  static propTypes = {
    api: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      subject_types: {},
      classifier_types: {},
      select_subjects_queries: {},
      job: props.job,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleContentLabelChange = this.handleContentLabelChange.bind(this);
    this.handleLabellingInstructionsChange =
      this.handleLabellingInstructionsChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.addContentLabel = this.addContentLabel.bind(this);
    this.removeContentLabel = this.removeContentLabel.bind(this);
    this.toggleQueryExpanded = this.toggleQueryExpanded.bind(this);
  }

  componentDidMount() {
    this.props.api.ContentLabellingJobs.formData().then((data) => {
      this.setState({
        subject_types: data.subject_types,
        classifier_types: data.classifier_types,
        select_subjects_queries: data.select_subjects_queries,
      });
    });
  }

  toggleQueryExpanded() {
    this.setState({ queryExpanded: !this.state.queryExpanded });
  }

  handleChange(e) {
    const { name, value } = e.target;
    const jobAttributes = { [name]: value };
    if (name === "classifier_type" && value === "binary") {
      jobAttributes.max_labels_applied = 1;
    }
    this.setState({ job: Object.assign(this.state.job, jobAttributes) });
  }

  handleLabellingInstructionsChange(value) {
    this.setState({
      job: Object.assign(this.state.job, { labelling_instructions: value }),
    });
  }

  handleContentLabelChange(e, contentLabel) {
    const { name, value } = e.target;
    contentLabel[name] = value;
    this.setState({ job: this.state.job });
  }

  handleSubmit(e) {
    e.preventDefault();

    this.props.handleSubmit(
      Object.assign(this.state.job, {
        content_labels_attributes: this.state.job.content_labels,
      })
    );
  }

  addContentLabel(e) {
    e.preventDefault();

    this.state.job.content_labels.push({ title: "", description: "" });
    this.setState({ job: this.state.job });
  }

  removeContentLabel(contentLabel) {
    if (this.contentLabelsCount() <= 2) {
      return;
    }
    contentLabel._destroy = true;
    this.setState({ job: this.state.job });
  }

  contentLabelsCount() {
    return this.state.job.content_labels.filter((label) => !label._destroy)
      .length;
  }

  render() {
    const {
      internal_id,
      title,
      description,
      labelling_instructions,
      completion_target,
      target_labellers_per_subject,
      subject_type,
      classifier_type,
      max_labels_applied,
      select_subjects_query_key,
      content_labels,
    } = this.state.job;
    const errors = this.props.errors;
    return (
      <form onSubmit={this.handleSubmit}>
        <div className="form-group">
          <label>Internal ID</label>
          <input
            className={classnames("form-control", {
              "is-invalid": errors.internal_id,
            })}
            type="text"
            name="internal_id"
            value={internal_id || ""}
            onChange={this.handleChange}
            required
          />
          <div className="invalid-feedback">{errors.internal_id}</div>
        </div>
        <div className="form-group">
          <label>Title</label>
          <input
            className={classnames("form-control", {
              "is-invalid": errors.title,
            })}
            type="text"
            name="title"
            value={title || ""}
            onChange={this.handleChange}
            required
          />
          <div className="invalid-feedback">{errors.title}</div>
        </div>
        <div className="form-group">
          <label>Description</label>
          <textarea
            className={classnames("form-control", {
              "is-invalid": errors.description,
            })}
            type="text"
            name="description"
            value={description}
            onChange={this.handleChange}
          />
          <div className="invalid-feedback">{errors.description}</div>
        </div>
        <div className="form-group">
          <label>Labelling Instructions</label>
          <MDEditor
            name="labelling_instructions"
            value={labelling_instructions}
            onChange={this.handleLabellingInstructionsChange}
            className={classnames("form-control", {
              "is-invalid": errors.labelling_instructions,
            })}
          />
          <div className="invalid-feedback">
            {errors.labelling_instructions}
          </div>
        </div>
        <div className="form-group">
          <label>Completion Target</label>
          <input
            className={classnames("form-control", {
              "is-invalid": errors.completion_target,
            })}
            type="number"
            min={0}
            name="completion_target"
            value={completion_target || ""}
            onChange={this.handleChange}
            required
          />
          <div className="invalid-feedback">{errors.completion_target}</div>
        </div>
        <div className="form-group">
          <label>Target Labellers per Subject</label>
          <input
            className={classnames("form-control", {
              "is-invalid": errors.target_labellers_per_subject,
            })}
            type="number"
            min={0}
            name="target_labellers_per_subject"
            value={target_labellers_per_subject || ""}
            onChange={this.handleChange}
            required
          />
          <div className="invalid-feedback">
            {errors.target_labellers_per_subject}
          </div>
        </div>
        <div className="form-group">
          <label>Subject Type</label>
          <select
            className={classnames("form-control", {
              "is-invalid": errors.subject_type,
            })}
            name="subject_type"
            value={subject_type}
            onChange={this.handleChange}
            disabled={this.props.edit}
            required
          >
            <option value="" key="blank"></option>
            {Object.keys(this.state.subject_types).map((k, idx) => (
              <option value={this.state.subject_types[k]} key={idx}>
                {k}
              </option>
            ))}
          </select>
          <div className="invalid-feedback">{errors.subject_type}</div>
        </div>
        <div className="form-group">
          <label>Classifier Type</label>
          <select
            className={classnames("form-control", {
              "is-invalid": errors.classifier_type,
            })}
            name="classifier_type"
            value={classifier_type}
            onChange={this.handleChange}
            required
          >
            <option value="" key="blank"></option>
            {Object.keys(this.state.classifier_types).map((k, idx) => (
              <option value={this.state.classifier_types[k]} key={idx}>
                {k}
              </option>
            ))}
          </select>
          <div className="invalid-feedback">{errors.classifier_type}</div>
        </div>
        <div className="form-group">
          <label>Max Amount of Labels Applied</label>
          <input
            className={classnames("form-control", {
              "is-invalid": errors.max_labels_applied,
            })}
            type="number"
            min={1}
            name="max_labels_applied"
            value={max_labels_applied || ""}
            readOnly={classifier_type === "binary"}
            onChange={this.handleChange}
          />
          <div className="invalid-feedback">{errors.max_labels_applied}</div>
        </div>
        <div className="form-group">
          <label>Select Subjects Query Key</label>
          <select
            className={classnames("form-control", {
              "is-invalid": errors.select_subjects_query_key,
            })}
            name="select_subjects_query_key"
            value={select_subjects_query_key}
            onChange={this.handleChange}
            disabled={this.props.edit}
            required
          >
            <option value="" key="blank"></option>
            {Object.keys(this.state.select_subjects_queries).map((key) => (
              <option value={key} key={key}>
                {key}
              </option>
            ))}
          </select>
          <div className="invalid-feedback">
            {errors.select_subjects_query_key}
          </div>
          <p className="text-muted mt-2">Query body</p>
          <div
            className={classnames("multiline-text expandable", {
              expanded: this.state.queryExpanded,
            })}
          >
            {this.state.select_subjects_queries[select_subjects_query_key]}
            <span
              className="expand text-muted"
              onClick={this.toggleQueryExpanded}
            ></span>
          </div>
        </div>
        <hr />
        <div className="form-group">
          <h5>Content Labels</h5>
          {content_labels.map((contentLabel, key) => {
            if (contentLabel._destroy) {
              return null;
            }

            return (
              <div className="form-group" key={key}>
                <div className="row">
                  <div className="col-4">
                    <label>Title</label>
                    <input
                      className={classnames("form-control", {
                        "is-invalid": errors.title,
                      })}
                      type="text"
                      name="title"
                      value={contentLabel.title}
                      onChange={(e) =>
                        this.handleContentLabelChange(e, contentLabel)
                      }
                      required
                    />
                  </div>
                  <div className="col">
                    <label>Description</label>
                    <div className="row">
                      <div className="col">
                        <input
                          className={classnames("form-control", {
                            "is-invalid": errors.description,
                          })}
                          type="text"
                          name="description"
                          value={contentLabel.description}
                          onChange={(e) =>
                            this.handleContentLabelChange(e, contentLabel)
                          }
                        />
                      </div>
                      <div className="col">
                        <button
                          type="button"
                          className="btn btn-outline-danger mt-4-px"
                          onClick={(e) => this.removeContentLabel(contentLabel)}
                          disabled={
                            (contentLabel.id && this.props.edit) ||
                            this.contentLabelsCount() <= 2
                          }
                        >
                          Remove label
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        <div className="form-group">
          <button
            type="button"
            className="btn btn-outline-success"
            onClick={this.addContentLabel}
          >
            + Add a label
          </button>
        </div>
        <div className="form-group">
          <button type="submit" className="btn btn-primary">
            Submit
          </button>
        </div>
      </form>
    );
  }
}

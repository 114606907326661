import React, { Component } from "react";
import { Link } from "react-router-dom";

import PropTypes from "prop-types";
import classnames from "classnames";

import h from "./helpers";

export default class ReportGroup extends Component {
  static propTypes = {
    api: PropTypes.object.isRequired,
    type: PropTypes.string.isRequired,
    subject_id: PropTypes.number.isRequired,

    created_at: PropTypes.string.isRequired,
    reasons: PropTypes.object.isRequired,
    system: PropTypes.array.isRequired,
    open: PropTypes.bool.isRequired,

    reporter_ids: PropTypes.arrayOf(PropTypes.number),

    name: PropTypes.string.isRequired,
    description: PropTypes.string,

    onUpdate: PropTypes.func,
    onToggleOptions: PropTypes.func,
  };

  static type = "admin/user_logs_controller/reported_group";
  static icon = "👩";

  render() {
    const p = this.props;

    return (
      <tr
        className={classnames(
          "EventsRow",
          "EventsReportGroup",
          "EventsRow--" + (p.open ? "open" : "closed")
        )}
        onMouseOver={() => p.onToggleOptions(true)}
      >
        {h.sharedColumns(this)}
        <td>
          {h.renderCountUsers(p)} group{" "}
          <Link to={`/groups/${p.subject_id}`}>#{p.subject_id}</Link>
          {h.renderUserIds(p)}
          {p.notes &&
            p.notes.map((e, i) => (
              <p className="EventReportPost-content" key={i}>
                {e}
              </p>
            ))}
        </td>
      </tr>
    );
  }
}

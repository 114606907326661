import React, { Component } from "react";
import PropTypes from "prop-types";

export default class ParticipantAutocomplete extends Component {
  static propTypes = {
    onChange: PropTypes.func,
    api: PropTypes.object.isRequired,
    roomId: PropTypes.string.isRequired,
  };

  static defaultProps = {
    onChange: () => {},
  };

  constructor(props) {
    super(props);

    this.state = {
      data: [],
      selectedUserId: -1,
    };

    this._handleChange = this._handleChange.bind(this);
  }

  componentWillMount() {
    this._loadParticipants();
  }

  _loadParticipants() {
    const {
      api: { LiveAudioParticipants: LiveAudioParticipantsApi },
      roomId,
    } = this.props;

    LiveAudioParticipantsApi.index({ roomId }).then((data) => {
      this.setState({ data });
    });
  }

  _handleChange({ target: { value } }) {
    this.setState({ selectedUserId: value }, () => this.props.onChange(value));
  }

  render() {
    const { data, selectedUserId } = this.state;

    if (data === []) {
      return <></>;
    }

    return (
      <select onChange={this._handleChange}>
        <option value="">All</option>
        {data.map(({ user }) => (
          <option
            key={`participant_user_${user.id}`}
            selected={selectedUserId === user.id}
            value={user.id}
          >
            {user.first_name}
          </option>
        ))}
      </select>
    );
  }
}

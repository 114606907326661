import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

export default class DateTimeEditor extends React.Component {
  static propTypes = {
    onSave: PropTypes.func.isRequired,
    value: PropTypes.string,
    disabled: PropTypes.bool,
  };

  constructor(props) {
    super(props);
    this.state = { value: this.props.value, changed: false };
  }

  render() {
    return (
      <div className="DateTimeEditor">
        <input
          type="datetime-local"
          onChange={(e) => {
            this.setState({
              changed: this.props.value !== e.target.value,
              value: e.target.value,
            });
          }}
          onKeyPress={(e) => {
            if (e.key !== "Enter") return;
            e.preventDefault();
            this._maybeSave();
          }}
          onBlur={this._maybeSave.bind(this)}
          value={this.state.value || ""}
          disabled={this.props.disabled}
        />
        <div className="DateTimeEditor-after">
          <div
            className={classnames(
              `DateTimeEditor-flag`,
              this.state.changed && `DateTimeEditor-flag--unsaved`,
              this._validFormat() || `DateTimeEditor-flag--invalid`
            )}
            onClick={() => this.props.onSave(this.state.value)}
          >
            ⏎
          </div>
        </div>
      </div>
    );
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      this.setState({ changed: this.props.value !== this.state.value });
    }
  }

  _maybeSave() {
    if (!this._validFormat()) return;
    if (!this.state.changed) return;
    this.props.onSave(this.state.value);
  }

  _validFormat() {
    return true;
  }
}

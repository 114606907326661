import React, { Component } from "react";
import { Link } from "react-router-dom";

import Moment from "../../../../components/Moment";
import UserLink from "../../../../components/UserLink";
import StatusButton from "../../../../components/StatusButton";

class LiveAudioRoomSearchResultRow extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const {
      id,
      title,
      description,
      host,
      group,
      started_at,
      scheduled_at,
      recording_started_at,
      status,
      open_reports_count,
    } = this.props;

    return (
      <tr className="LiveAudioRoomSearchResultRow">
        <td>
          <StatusButton status={status === "started" ? "live" : status}>
            {status === "started" ? "Live" : status}
          </StatusButton>
        </td>
        <td>
          <Link to={`/live-audio/${id}`}>{title}</Link>
          <p>{description}</p>
        </td>
        <td>{host && <UserLink {...host} />}</td>
        <td>{group && <a href={`/groups/${group.id}`}>{group.name}</a>}</td>
        <td>
          <Moment time={recording_started_at || scheduled_at || started_at} />
        </td>
        <td>{open_reports_count}</td>
      </tr>
    );
  }
}

export default LiveAudioRoomSearchResultRow;

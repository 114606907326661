import React from "react";
import usePaymentHistory from "../../hooks/api/usePaymentHistory";
import UserPaymentRow from "./components/UserPaymentRow";

const UserPaymentsTable = ({ userId }) => {
  const { data } = usePaymentHistory(userId);

  return (
    <div>
      <h3>Payment History</h3>

      <table>
        <thead>
          <tr>
            <td>Title</td>
            <td>Status</td>
            <td>Purchased at</td>
            <td>Expires at</td>
            <td>Platform</td>
            <td>Amount (Local Currency)</td>
            <td>Amount (USD)</td>
            <td>Auto Renews</td>
            <td>Transaction Id</td>
          </tr>
        </thead>
        <tbody>
          {data && data.length > 0 ? (
            data.map((payment) => (
              <UserPaymentRow
                key={payment.store_transaction_id}
                payment={payment}
              />
            ))
          ) : (
            <tr>
              <td colSpan="9">No Payment History</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default UserPaymentsTable;

import React from "react";
import PropTypes from "prop-types";

import h from "./helpers";

const HistoryCase = (props) => {
  const { assigned_by: assignedBy, claimed_by: claimedBy, notes } = props;
  const { id: assignedById } = assignedBy || {};
  const { id: claimedById } = claimedBy || {};

  const message = () => {
    // If the user claimed the case...
    if (assignedById && claimedById && assignedById === claimedById) {
      return <>{h.renderActor(assignedBy)} claimed the case</>;
    } else if (assignedById && claimedById) {
      return (
        <>
          {h.renderActor(assignedBy)} assigned the case to{" "}
          {h.renderActor(claimedBy)}
        </>
      );
      //return `${h.renderActor(assignedBy, null)} assigned the case to ${renderActor(claimedBy, null)}`
    } else if (!claimedById) {
      return <>{h.renderActor(assignedBy)} unassigned the case</>;
    }
  };

  return (
    <tr>
      {h.sharedColumns({ props, constructor: { icon: HistoryCase.icon } })}
      <td>
        {message()}
        {notes && <blockquote>{notes}</blockquote>}
      </td>
      <td></td>
    </tr>
  );
};

HistoryCase.type = "history/case";
HistoryCase.icon = "ℹ️";
HistoryCase.propTypes = {
  assigned_by: PropTypes.object,
  claimed_by: PropTypes.object,
  notes: PropTypes.string,
  created_at: PropTypes.string.isRequired,
};

export default HistoryCase;

/* eslint-disable jsx-a11y/accessible-emoji, jsx-a11y/aria-role */
// eslint-disable-next-line no-unused-vars
import React from "react";
import UserLink from "../../../../../../components/UserLink";
import DefaultRow from "../DefaultRow";

import styles from "./ParticipantReported.module.scss";

export default class ParticipantReported extends DefaultRow {
  static action = "participant_reported";
  static label = "Participant Reported";
  static report = true;

  _who() {
    const { actor, action_user } = this.props;
    return (
      <div className={styles.multipleUserLinks}>
        <div>
          <UserLink {...actor} />
        </div>
        <div>
          <span role="image">📝</span>
        </div>
        <div>
          <UserLink {...action_user} />
        </div>
      </div>
    );
  }

  _notes() {
    const { actor } = this.props;

    return (
      <>
        <b>{actor.first_name}</b> reported <b>{this._firstName()}</b>
      </>
    );
  }
}

import React, { Component } from "react";
import PropTypes from "prop-types";

import Child from "./Child";
import PromptAnswer from "./PromptAnswer";
import UserLocation from "./UserLocation";

export default class UserTabInfo extends Component {
  static propTypes = {
    api: PropTypes.object.isRequired,
    data: PropTypes.shape({
      id: PropTypes.number.isRequired,
    }).isRequired,
  };

  render() {
    const d = this.props.data;
    const roles = this.props.api.Session.user.roles;

    return (
      <section className="UserTabInfo">
        <dl className="UserTabInfo-details">
          <dt>Children</dt>
          <dd>
            {d.children.map((c) => (
              <Child key={c.id} data={c} />
            ))}
            {d.children.length === 0 && "None"}
          </dd>

          <dt>Bio</dt>
          <dd>{d.description ? d.description.about_me : "Unspecified"}</dd>

          <dt>Work status</dt>
          <dd>{d.work_status ? d.work_status.val : "Unspecified"}</dd>

          <dt>Education</dt>
          <dd>
            {d.education.length > 0 ? (
              <ul>
                {d.education.map((e, idx) => this._renderEducation(e, idx))}
              </ul>
            ) : (
              "Unspecified"
            )}
          </dd>

          <dt>Work history</dt>
          <dd>
            {d.work_history.length > 0 ? (
              <ul>
                {d.work_history.map((e, idx) => this._renderWork(e, idx))}
              </ul>
            ) : (
              "Unspecified"
            )}
          </dd>

          <dt>Muted Keywords</dt>
          <dd>
            {d.muted_keywords.length > 0 ? (
              <>
                {d.muted_keywords.map((e, idx) => {
                  return <span className="MutedKeyword">{e.value}</span>;
                })}
              </>
            ) : (
              "None"
            )}
          </dd>

          <dt>Prompt answers</dt>
          <dd>
            {d.answers.map((a) => (
              <PromptAnswer key={a.id} data={a} />
            ))}
            {d.answers.length === 0 && "None"}
          </dd>

          {roles.includes("MODERATOR") && (
            <>
              <dt>Data export</dt>
              <dd>
                <a
                  href={this.props.api.Users.dataExportUrl(d.id)}
                  target="_blank"
                  rel="noreferrer"
                >
                  Download data
                </a>
              </dd>
            </>
          )}
        </dl>

        <UserLocation api={this.props.api} userID={this.props.data.id} />
      </section>
    );
  }

  _renderEducation(e, idx) {
    const parts = [];

    if (e.level) {
      parts.push(e.level.name);
    }
    if (e.school) {
      parts.push(e.school.name);
    }
    if (e.graduation_year) {
      parts.push(e.graduation_year);
    }
    if (!e.visible) {
      parts.push("(hidden)");
    }

    return <li key={idx}>{parts.join(", ")}</li>;
  }

  _renderWork(e, idx) {
    const parts = [];

    if (e.employer) {
      parts.push(e.employer.name);
    }
    if (e.position) {
      parts.push(e.position.name);
    }
    if (!e.visible) {
      parts.push("(hidden)");
    }

    return <li key={idx}>{parts.join(", ")}</li>;
  }
}

/* eslint-disable react-hooks/exhaustive-deps */
import { useHistory, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";

const queryString = require("query-string");

const useFilters = (keys) => {
  const location = useLocation();
  const history = useHistory();

  const [filters, setFilters] = useState({});

  const { pathname } = location;

  useEffect(() => {
    const search = queryString.stringify(filters, { arrayFormat: "bracket" });
    history.push({
      pathname,
      search,
    });
  }, [filters, history, pathname]);

  useEffect(() => {
    if (location.search === "") {
      return;
    }

    const params = queryString.parse(location.search, {
      arrayFormat: "bracket",
    });
    updateFilters(params);
  }, [location.search]);

  const sanitizeFilters = (newFilters) => {
    return keys.reduce((result, key) => {
      if (newFilters[key] !== undefined) {
        result[key] = newFilters[key];
      }
      return result;
    }, {});
  };

  const updateFilters = (newFilters) => {
    const sanitizedFilters = sanitizeFilters(newFilters);
    setFilters(sanitizedFilters);
  };

  const grouped = (groupName) => {
    const results = Object.keys(filters).reduce((result, key) => {
      if (key.startsWith(`${groupName}[`)) {
        result[key] = filters[key];
      }
      return result;
    }, {});

    return results;
  };

  return {
    apply: (newFilters) => {
      updateFilters(newFilters);
    },
    current: filters,
    group: (name) => grouped(name),
  };
};

export default useFilters;
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import classnames from "classnames";

import h from "./helpers";

export default class ReportLiveAudioRoom extends Component {
  static propTypes = {
    api: PropTypes.object.isRequired,
    type: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,

    created_at: PropTypes.string.isRequired,
    reason: PropTypes.string,
    notes: PropTypes.string,
    created_by: PropTypes.shape({
      id: PropTypes.number.isRequired,
      first_name: PropTypes.string.isRequired,
    }).isRequired,

    onUpdate: PropTypes.func,
    onToggleOptions: PropTypes.func,
  };

  static type = "report/live_audio_room";
  static icon = "⚠️🔊";

  render() {
    const p = this.props;

    return (
      <tr
        className={classnames(
          "EventsRow",
          "EventsReportUser",
          "EventsRow--" + (p.status === "open" ? "open" : "closed")
        )}
        onMouseOver={() => p.onToggleOptions(true)}
      >
        {h.sharedColumns(this)}
        <td>
          {h.renderActor(p.created_by)} reported Pod: "
          <Link to={`/live-audio/${p.audio_room.id}`}>
            {p.audio_room.title}
          </Link>
          " at {p.audio_room.when} (<strong>{p.reason}</strong>){" "}
          {p.notes && <p class="EventReportPost-content">"{p.notes}"</p>}
        </td>
      </tr>
    );
  }
}

import React, { Component } from "react";
import PropTypes from "prop-types";
import ModalForm from "./ModalForm";
import GuidelineSelect from "./GuidelineSelect";
import ReportsList from "./ReportsList";
import UnreportedAbusiveContentList from "./UnreportedAbusiveContentList";

export default class UserWarningOverlay extends Component {
  static propTypes = {
    api: PropTypes.object.isRequired,
    userID: PropTypes.number.isRequired,
    warnings: PropTypes.array,
    onCancel: PropTypes.func.isRequired,
    onComplete: PropTypes.func.isRequired,
  };

  static defaultProps = {
    warnings: [],
  };

  constructor(props) {
    super(props);
    this.state = {
      guidelineTitle: null,
      notes: "",
      reportId: null,
      itemId: null,
      itemType: null,
      response: null,
      mode: "warned",
    };

    this._handleGuidelineSelect = this._handleGuidelineSelect.bind(this);
    this._handleNotesChange = this._handleNotesChange.bind(this);
    this._handleReportSelect = this._handleReportSelect.bind(this);
    this._handleUnreportedAbusiveItemSelect =
      this._handleUnreportedAbusiveItemSelect.bind(this);
    this._handleBanClick = this._handleBanClick.bind(this);
    this._handleWarnClick = this._handleWarnClick.bind(this);
    this._isValid = this._isValid.bind(this);
    this._onDisableAccount = this._onDisableAccount.bind(this);
    this._onSubmit = this._onSubmit.bind(this);
  }

  _handleGuidelineSelect(guidelineTitle) {
    this.setState({ guidelineTitle });
  }

  _handleNotesChange({ target: { value } }) {
    this.setState({ notes: value });
  }

  _handleReportSelect(reportId) {
    this.setState({ reportId, itemId: null, itemType: null });
  }

  _handleUnreportedAbusiveItemSelect(itemId, itemType) {
    this.setState({ itemId, itemType, reportId: null });
  }

  _handleBanClick() {
    this.setState({ mode: "banned" });
  }
  _handleWarnClick() {
    this.setState({ mode: "warned" });
  }

  _isValid() {
    const { guidelineTitle } = this.state;
    return guidelineTitle && guidelineTitle.length > 0;
  }

  _onDisableAccount() {
    this.props.api.Users.updateStatus({
      id: this.props.userID,
      message: "Account disabled",
      action: "disable",
    }).then(() => {
      this.props.onCancel();
      this.props.onComplete();
    });
  }

  _onSubmit() {
    const { guidelineTitle, reportId, itemId, itemType, notes, mode } =
      this.state;

    const params = {
      guideline_title: guidelineTitle,
      report_id: reportId,
      item_id: itemId,
      item_type: itemType,
      warning_type: mode,
      notes,
    };

    this.props.api.Warnings.create(this.props.userID, params)
      .then(() => {
        this.props.onCancel();
        this.props.onComplete();
      })
      .catch((e) => {
        console.error(e);
        this.setState({ response: "Save failed!" });
      });
  }

  render() {
    const { warnings, userID } = this.props;
    const { notes, mode, itemId, reportId } = this.state;

    const previousWarnings = [
      ...new Set(
        warnings.map((warning) => warning.guideline_title).filter(Boolean)
      ),
    ];
    const hasPreviousWarnings = previousWarnings.length > 0;

    const actionText = mode === "warned" ? "Warn User" : "Ban User";

    return (
      <ModalForm
        className="UserWarningForm"
        title="Ban / Warning"
        onCancel={this.props.onCancel}
        form={
          <React.Fragment>
            <div>
              <div className="modeButtonsContainer">
                <button
                  className={mode === "warned" ? "selected" : ""}
                  onClick={this._handleWarnClick}
                >
                  Warn
                </button>
                <button
                  className={mode === "banned" ? "selected" : ""}
                  onClick={this._handleBanClick}
                >
                  Ban
                </button>
              </div>
            </div>
            <div className="reasonContainer">
              <label>Reason:</label>
              <div>
                <GuidelineSelect
                  api={this.props.api}
                  onChange={this._handleGuidelineSelect}
                  additionalReasons={
                    mode === "banned"
                      ? ["Suspected Male", "Suspected Underage"]
                      : []
                  }
                />
                {hasPreviousWarnings && (
                  <p style={{ fontSize: "0.8em" }}>
                    Previously warned for:{" "}
                    {previousWarnings
                      .map((pw) => <strong>{pw}</strong>)
                      .reduce((prev, curr) => [prev, ", ", curr])}
                  </p>
                )}
              </div>
            </div>
            <div className="reasonContainer">
              <label>Notes (optional):</label>
              <div>
                <input
                  type="text"
                  onChange={this._handleNotesChange}
                  value={notes}
                  placeholder="Not visible to the user"
                />
              </div>
            </div>
            <label className="fullLine">Related report (optional):</label>
            <ReportsList
              api={this.props.api}
              userID={userID}
              onChange={this._handleReportSelect}
              reportSelected={reportId}
              selectInputName={"reportAbusiveContentList"}
            />
            <label className="fullLine">
              Unreported abusive content (optional):
            </label>
            <UnreportedAbusiveContentList
              api={this.props.api}
              userID={userID}
              onChange={this._handleUnreportedAbusiveItemSelect}
              itemSelected={itemId}
              selectInputName={"reportAbusiveContentList"}
            />
          </React.Fragment>
        }
        actions={
          <>
            <button
              type="button"
              className="btn btn-secondary"
              onClick={this._onDisableAccount}
            >
              Disable Account
            </button>
            <div className="spacer">
              <span>{this.state.response}</span>
            </div>
            <button
              type="button"
              className="btn btn-primary"
              onClick={this._onSubmit}
              disabled={!this._isValid()}
            >
              {actionText}
            </button>
          </>
        }
      />
    );
  }
}

import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import moment from "moment";

import styles from "./ReportsListItem.module.scss";

export class ReportsListItem extends Component {
  static propTypes = {
    onSelect: PropTypes.func,
    selectInputName: PropTypes.string,
  };

  static defaultProps = {
    onSelect: () => {},
    selectInputName: "reportList",
  };

  constructor(props) {
    super(props);

    this._handleSelect = this._handleSelect.bind(this);
  }

  _content() {
    const { reason, report_type, post, comment, user } = this.props;
    let content = null;

    switch (report_type) {
      case "Report::Post":
        content = {
          id: post.id,
          title: post.title,
          type: "Post",
          url: `/posts/${post.id}/`,
        };
        break;
      case "Report::Comment":
        content = {
          id: comment.id,
          title: comment.body,
          type: "Comment",
          url: `/posts/${comment.post_id}#comments_${comment.id}`,
        };
        break;
      case "Report::User":
        if (reason !== "reused_blocked_device") {
          content = {
            id: user.id,
            type: "Profile",
            url: `/users/${user.id}`,
          };
        }
        break;

      case "Report::UserPhoto":
        content = {
          id: user.id,
          type: "User Photo",
          url: `/users/${user.id}`,
        };
        break;

      default:
        break;
    }

    return content;
  }

  _handleSelect() {
    const { id, onSelect } = this.props;

    onSelect(id);
  }

  render() {
    const { created_at, id, notes, reason, selected, selectInputName } =
      this.props;
    const content = this._content();

    if (!content) {
      return <></>;
    }

    return (
      <div
        className={`${styles.container} ${
          selected && styles.containerSelected
        }`}
      >
        <div className={styles.radioContainer}>
          <input
            type="radio"
            id={id}
            name={selectInputName}
            value={id}
            onClick={this._handleSelect}
          />
        </div>
        <div className={styles.detailsContainer}>
          <header>
            <Link to={content.url}>
              {content.type} #{content.id}
            </Link>
          </header>
          {content.title && <blockquote>{content.title}</blockquote>}
          {notes && (
            <div className={styles.notesContainer}>
              <div>Notes:</div>
              <div>{notes}</div>
            </div>
          )}
          <footer>
            <div>{moment(created_at).format("D MMM YYYY")}</div>
            <div>
              Reason: <strong>{reason}</strong>
            </div>
          </footer>
        </div>
      </div>
    );
  }
}

export default ReportsListItem;

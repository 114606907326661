import { useMutation } from "react-query";
import Cookies from "universal-cookie";

const cookies = new Cookies();
const xsrf_token = cookies.get("XSRF-TOKEN");
const url = new URL(`${process.env.REACT_APP_API_HOST}/admin/seo/authors`);

const submitAuthor = () => {
  return fetch(url, {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      "X-XSRF-Token": xsrf_token,
    },
    body: JSON.stringify({
      skip_slug: true,
      name: "temp-name",
      email: null,
      phone_number: null,
      linkedin_url: null,
      personal_url: null,
      workplace_url: null,
      medical_expert: false,
      photo_id: null,
      title: "fill me",
      summary: "fill me",
      body: null,
      language: "en",
      meta_title: null,
      meta_description: null,
      job_title: null,
    }),
  });
};
const useCreateAuthor = () => {
  return useMutation(submitAuthor, {
    onSuccess: async (response, variables, context) => {
      const { id } = await response.json();
      window.open(`/seo/authors/${id}`, "_blank");
    },
    onError: (error, variables, context) => {
      console.log(error);
    },
  });
};

export default useCreateAuthor;

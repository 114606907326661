import React, { Component } from "react";
import PropTypes from "prop-types";

import classnames from "classnames";
import AlertDialog from "./AlertDialog/AlertDialog";

const icons = {
  confirmed: "✔︎",
  infirmed: "✘",
  ignored: "∅",
};

export default class ReportActions extends Component {
  static propTypes = {
    api: PropTypes.object.isRequired,
    reportType: PropTypes.string.isRequired,
    subjectID: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
      .isRequired,
    reason: PropTypes.string.isRequired,
    statuses: PropTypes.object.isRequired, // status => count

    onUpdate: PropTypes.func,
    stubbed: PropTypes.bool,
    selectedReasons: PropTypes.number,
  };

  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
    };
    this._renderButtons = this._renderButtons.bind(this);
    this._renderButtons = this._renderButtons.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
  }

  render() {
    const { reportType } = this.props;

    return (
      <span className="ReportActions">
        {reportType === "report/conversation"
          ? this._renderConversationLink()
          : this._renderButtons()}
      </span>
    );
  }

  handleCloseModal() {
    this.setState({ showModal: false });
  }

  _renderConversationLink() {
    return this._renderButton("view", "View");
  }

  _renderButtons() {
    return Object.entries(icons).map(([action, icon]) =>
      this._renderButton(action)
    );
  }
  _renderButton(action, text = "") {
    const p = this.props;

    return (
      <>
        <button
          className={classnames(
            "ReportActions-button",
            `ReportActions-button--${action}`,
            p.statuses[action]
              ? "ReportActions-button--active"
              : "ReportActions-button--inactive"
          )}
          key={action}
          title={action}
          onClick={this._onSubmit.bind(this, action)}
        >
          {icons[action] || text}
        </button>
        <AlertDialog
          open={this.state.showModal}
          onCloseModal={this.handleCloseModal}
        />
      </>
    );
  }

  _onSubmit(action) {
    if (this.props.stubbed) {
      this.props.onUpdate(false);
      return;
    }

    if (this.props.selectedReasons <= 4) {
      this.setState({ showModal: true });
    } else {
      this.props.api.Reports.update({
        subjectID: this.props.subjectID,
        reason: this.props.reason,
        reportType: this.props.reportType,
        status: action,
      }).then(() => this.props.onUpdate(action === "confirmed"));
    }
  }
}

import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import classnames from "classnames";
import ellipsize from "ellipsize";

import Image from "../../components/Image";
import ButtonWithConfirmation from "../../components/ButtonWithConfirmation";
import SearchForm from "../../components/SearchForm";
import SearchResults from "../../components/SearchResults";
import SearchHistory from "../../components/SearchHistory";

import PostCopyEditor from "./PostCopyEditor";
import SeoIndexingButton from "../../components/SeoIndexingButton";
import MetaModal from "../../components/MetaModal";

class CategorySet {
  constructor(cats) {
    this._top = cats.filter((c) => c.children.length !== 0);
    this._all = {};
    cats.forEach((c) => (this._all[c.id] = c));

    this._top.forEach((c) => {
      c.children = c.children.map((id) => this._all[id]);
    });
  }

  get(id) {
    return this._all[id];
  }
  top() {
    return this._top;
  }
}

class SEOPostRow extends React.Component {
  static propTypes = {
    data: PropTypes.shape({
      id: PropTypes.number.isRequired,
      uid: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      body: PropTypes.string,
      seo_indexed: PropTypes.bool.isRequired,

      image: PropTypes.shape({
        url: PropTypes.string.isRequired,
      }),

      video: PropTypes.shape({
        url: PropTypes.string.isRequired,
        thumbnail_url: PropTypes.string.isRequired,
      }),

      published_post: PropTypes.shape({
        status: PropTypes.string.isRequired,
        seo_category_id: PropTypes.number,
        seo_subcategory_id: PropTypes.number,
      }).isRequired,
    }).isRequired,

    categories: PropTypes.object.isRequired,

    api: PropTypes.object.isRequired,
  };

  static Headers = () => (
    <tr>
      <th>ID</th>
      <th>Image</th>
      <th>Video</th>
      <th>Content</th>
      <th>Categories</th>
      <th>Actions</th>
    </tr>
  );

  static Columns = 5;

  constructor(props) {
    super(props);
    this.state = { data: this.props.data, metaModalOpen: false };

    this.handleMetaModelOpen = this.handleMetaModelOpen.bind(this);
    this.handleMetaModelClose = this.handleMetaModelClose.bind(this);
    this.handleMetaChanges = this.handleMetaChanges.bind(this);

    this._updatePublishedPostBound = this._updatePublishedPost.bind(this);
  }

  handleMetaModelOpen() {
    this.setState({ metaModalOpen: true });
  }
  handleMetaModelClose() {
    this.setState({ metaModalOpen: false });
  }

  handleMetaChanges(changes) {
    this._updatePublishedPostBound(changes);
  }

  render() {
    const p = this.state.data;

    const cat = this.props.categories.get(p.published_post.seo_category_id);
    const subcats = cat ? cat.children : [];

    const title =
      p.published_post.title === null ? p.title : p.published_post.title;
    const body =
      p.published_post.body === null ? p.body : p.published_post.body;
    const image =
      p.published_post.image === undefined ? p.image : p.published_post.image;

    return (
      <tr className="SEOPostRow">
        <td>
          <Link to={`/posts/${p.id}`}>#{p.id}</Link>
        </td>

        <td>
          {image && (
            <React.Fragment>
              <Image
                url={image.url}
                className={classnames(
                  "SEOPostRow-image",
                  `SEOPostRow-image--${p.published_post.use_image}`
                )}
                openInNewTab
              />
              <input
                type="checkbox"
                checked={p.published_post.use_image}
                onChange={this._updatePublishedPost.bind(this, {
                  use_image: !p.published_post.use_image,
                })}
              />
            </React.Fragment>
          )}
        </td>

        <td>
          {p.video && p.video.url && (
            <React.Fragment>
              <Image
                url={p.video.thumbnail_url}
                className={classnames(
                  "SEOPostRow-image",
                  `SEOPostRow-image--${p.published_post.use_video}`
                )}
                openInNewTab
              />
              <input
                type="checkbox"
                checked={p.published_post.use_video}
                onChange={this._updatePublishedPost.bind(this, {
                  use_video: !p.published_post.use_video,
                })}
              />
            </React.Fragment>
          )}
        </td>

        <td>
          <div className="SEOPostRow-text">
            <span>{title}</span>
            <span>{body}</span>
          </div>
        </td>

        <td>
          <select
            className="SEOPostRow-select"
            onChange={this._updateCategory.bind(this, "seo_category_id")}
            value={p.published_post.seo_category_id || "none"}
          >
            <option key="none" value="">
              -
            </option>
            {this.props.categories.top().map((c) => (
              <option key={c.id} value={c.id}>
                {c.name}
              </option>
            ))}
          </select>

          <select
            className="SEOPostRow-select"
            onChange={this._updateCategory.bind(this, "seo_subcategory_id")}
            value={p.published_post.seo_subcategory_id || "none"}
            disabled={!cat}
          >
            <option key="none" value="">
              -
            </option>
            {subcats.map((c) => (
              <option key={c.id} value={c.id}>
                {c.name}
              </option>
            ))}
          </select>
        </td>

        <td>
          <div className="SEOPostRow-actions">
            <button onClick={() => window.open(p.url, "_blank")}>View</button>
            <PostCopyEditor
              original={{ title: p.title, body: p.body, image: p.image }}
              edited={{
                title: p.published_post.title,
                body: p.published_post.body,
                image: p.published_post.image,
              }}
              onSave={this._updatePublishedPost.bind(this)}
              api={this.props.api}
            />
            {(p.published_post.status === "ignored" ||
              p.published_post.status === "retracted" ||
              p.published_post.status === "pending") && (
              <React.Fragment>
                <button
                  onClick={this._updatePublishedPost.bind(this, {
                    status: "published",
                  })}
                >
                  Publish
                </button>
              </React.Fragment>
            )}
            {p.published_post.status === "published" && (
              <React.Fragment>
                <ButtonWithConfirmation
                  onClick={this._updatePublishedPost.bind(this, {
                    status: "retracted",
                  })}
                  title="Warning"
                  message="Unpublishing a post may break existing links to it."
                >
                  Unpublish
                </ButtonWithConfirmation>
                <a href={p.url} target="_preview">
                  Preview
                </a>
              </React.Fragment>
            )}
            <React.Fragment>
              <SeoIndexingButton
                api={this.props.api.SEO.Posts}
                seoIndexedEntityId={p.id}
                seoIndexed={p.seo_indexed}
              />
            </React.Fragment>
            <button onClick={this.handleMetaModelOpen}>Meta</button>
            <MetaModal
              onClose={this.handleMetaModelClose}
              onChange={this.handleMetaChanges}
              title={p.meta_title || p.title}
              description={p.meta_description || ellipsize(p.body || "", 320)}
              open={this.state.metaModalOpen}
            />
          </div>
        </td>
      </tr>
    );
  }

  _updatePublishedPost(params) {
    this.props.api.SEO.Posts.update({ id: this.props.data.id, ...params }).then(
      (data) => this.setState({ data: data })
    );
  }

  _updateCategory(field, e) {
    this._updatePublishedPost({ [field]: e.target.value });
  }
}

export default class Users extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      query: {
        text_query: "",
        id_query: "",
        mode: "pending",
      },
      categories: {},
    };
  }

  render() {
    if (!this.state.categories) return <React.Fragment />;

    return (
      <div className="SEOPosts">
        <SearchForm
          query={this.state.query}
          onSubmit={(q) => this.setState({ query: q })}
        >
          {(form) => (
            <React.Fragment>
              <form.RadioGroup
                title="Mode"
                name="mode"
                labels={["Pending", "Published", "Indexed", "All"]}
                options={["pending", "published", "indexed", "all"]}
              />
              <form.Input label="Text" name="text_query" />
              <form.Input label="ID / UID" name="id_query" />
            </React.Fragment>
          )}
        </SearchForm>

        <SearchHistory
          query={this.state.query}
          onSubmit={(q) => this.setState({ query: q })}
        />

        <SearchResults api={this.props.api.SEO.Posts} query={this.state.query}>
          {(results) => (
            <table className="SEOPosts-table">
              <thead>
                <SEOPostRow.Headers />
              </thead>
              <tbody>
                {results.items().map((x) => (
                  <SEOPostRow
                    key={x.uid}
                    categories={this.state.categories}
                    data={x}
                    api={this.props.api}
                  />
                ))}
              </tbody>
              <tfoot>
                <tr>
                  <td colSpan={SEOPostRow.Columns}>
                    <results.AutoLoad />
                  </td>
                </tr>
              </tfoot>
            </table>
          )}
        </SearchResults>
      </div>
    );
  }

  componentDidMount() {
    this.props.api.SEO.Categories.index().then((data) =>
      this.setState({ categories: new CategorySet(data) })
    );
  }
}

import React from "react";
import PropTypes from "prop-types";
import { sprintf } from "sprintf-js";
import NumberFormat from "react-number-format";
import classnames from "classnames";

const scale = (x) => {
  const log = Math.log10(Math.abs(x));
  return Math.floor(log / 3) * 3;
};

const units = {
  [-6]: "µ",
  [-3]: "m",
  0: "",
  3: "k",
  6: "M",
};

const FloatFormat = ({
  value,
  decimals,
  noScale,
  sign,
  prefix,
  suffix,
  className,
}) => {
  if (value === undefined || isNaN(value)) return <React.Fragment />;

  decimals = decimals || 2;
  const exp = noScale ? 0 : scale(value);
  const s = sign ? "+" : "";
  const u = units[exp];
  const x = value * 10 ** -exp;
  return (
    <span className={classnames("FloatFormat", className)} data-value={value}>
      {prefix}
      {sprintf(`%${s}0.${decimals}f`, x)}
      {u}
      {suffix}
    </span>
  );
};

export default class ExperimentResults extends React.Component {
  static propTypes = {
    api: PropTypes.object.isRequired,
    experiment: PropTypes.shape({
      id: PropTypes.number.isRequired,
      variants: PropTypes.object.isRequired,
    }).isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      analyses: [],
      resultId: null,
      results: {},
    };
  }

  render() {
    if (this.state.analyses.length === 0) return <div>No analysis yet.</div>;

    const results =
      this.state.results && this.state.results[this.state.resultId];

    const headers = this._allVariants().map((variant, i) => (
      <React.Fragment key={i}>
        <th key={i}>{variant}</th>
        {this._otherVariants(variant).map((v, j) => (
          <th key={j}>vs. {v}</th>
        ))}
      </React.Fragment>
    ));

    return (
      <section className="ExperimentResults">
        <h3>Analysis</h3>
        <select
          className="ExperimentResults-select"
          onChange={(e) => this.setState({ resultId: e.target.value })}
        >
          {this.state.analyses.map((a, idx) => (
            <option key={idx} value={a.id}>
              {this._renderAnalysis(a)}
            </option>
          ))}
        </select>
        <div>
          <table className="ExperimentResults-table">
            {results &&
              Object.entries(results).map(([bucket, metrics], idx) => (
                <tbody key={idx}>
                  <tr>
                    <th>
                      <strong>{bucket}</strong>
                    </th>
                    {headers}
                  </tr>
                  {Object.entries(metrics).map(([metric, data]) =>
                    this._renderMetricRow(metric, data)
                  )}
                </tbody>
              ))}
          </table>
        </div>
      </section>
    );
  }

  _allVariants() {
    return Object.keys(this.props.experiment.variants);
  }

  _otherVariants(v) {
    return this._allVariants().filter((o) => o !== v);
  }

  _renderMetricRow(metric, data) {
    return (
      <tr key={metric} className="ExperimentResults-row">
        <td>{metric}</td>
        {this._allVariants().map((variant, idx) =>
          this._renderMetric(idx, variant, data[variant])
        )}
      </tr>
    );
  }

  _renderMetric(idx, variant, data) {
    if (data === undefined) {
      return (
        <React.Fragment key={idx}>
          <td />
          <td />
        </React.Fragment>
      );
    }

    const type = data.conversion ? "chi2" : data.mean ? "ttest" : null;

    const value = type ? (
      <>
        <FloatFormat value={100 * data.conversion} noScale suffix=" %" />
        <FloatFormat value={data.mean} />
        <FloatFormat
          value={data.stdev}
          prefix="±"
          className="small text-muted"
        />
        <div className="ExperimentResults-details">
          <NumberFormat
            className="small text-muted"
            value={data.samples}
            thousandSeparator={true}
            suffix=" samples"
            displayType="text"
          />
        </div>
      </>
    ) : (
      <>
        <NumberFormat
          value={data.samples}
          thousandSeparator={true}
          displayType="text"
        />
      </>
    );

    const vs = data.vs ? (
      this._otherVariants(variant).map((v, idx) => (
        <React.Fragment key={idx}>{this._renderVs(data.vs[v])}</React.Fragment>
      ))
    ) : (
      <td colSpan={this._allVariants().length - 1} />
    );

    return (
      <React.Fragment key={idx}>
        <td>{value}</td>
        {vs}
      </React.Fragment>
    );
  }

  _renderVs(data) {
    console.log("_renderVs", data);
    if (!data) return <td />;

    // We consider p-values >0.9 significant.
    const isSignificant = data.value < 0.1 || data.pvalue > 0.9;
    let colour = null;
    // We also consider statistical power as an indicator; so we "tint"
    // significant results more when statistical power is higher.
    if (isSignificant) {
      const alpha = 0.1 + 0.9 * data.spower ** 3;
      colour =
        data.change < 0
          ? `rgba(255,170,170,${alpha})`
          : `rgba(170,255,170,${alpha})`;
    }

    return (
      <td
        style={{
          backgroundColor: colour,
        }}
      >
        <FloatFormat value={100 * data.change} noScale sign suffix=" %" />
        <div className="ExperimentResults-details">
          <FloatFormat
            value={data.pvalue}
            noScale
            prefix="p="
            className="small text-muted"
          />
          <br />
          <FloatFormat
            value={data.spower}
            noScale
            prefix="sp="
            className="small text-muted"
          />
        </div>
      </td>
    );
  }

  componentDidMount() {
    this.props.api.Experiments.analyses(this.props.experiment.id).then(
      (data) => {
        const analysisId =
          data.analyses.length > 0 ? data.analyses[0].id : null;
        this.setState({ analyses: data.analyses, resultId: analysisId });
      }
    );
  }

  componentDidUpdate(prevProps, prevState) {
    console.log(`ExperimentResults#componentDidUpdate`, prevState, this.state);

    if (prevState.resultId !== this.state.resultId && this.state.resultId) {
      this.props.api.Experiments.results(
        this.props.experiment.id,
        this.state.resultId
      ).then((data) => {
        this.setState({
          results: Object.assign({}, this.state.results, {
            [this.state.resultId]: data,
          }),
        });
      });
    }
  }

  _renderAnalysis(a) {
    return [
      `${a.user_age || "all"} users`,
      a.user_type && a.user_type !== "all" && `${a.user_type} only`,
      a.platform && `${a.platform} platform`,
      a.density && `density within ${a.density}`,
    ]
      .filter((x) => !!x)
      .join(", ");
  }
}

import React from "react";
import PropTypes from "prop-types";

import BooleanSwitch from "./BooleanSwitch";
import StringEditor from "../StringEditor";

export default class CampaignFilterGroup extends React.Component {
  static propTypes = {
    api: PropTypes.object.isRequired,
    campaign_id: PropTypes.number.isRequired,
    id: PropTypes.number.isRequired,
    negated: PropTypes.bool.isRequired,
    value_int: PropTypes.number.isRequired,
    onSave: PropTypes.func.isRequired,
    disabled: PropTypes.bool.isRequired,
  };

  render() {
    const p = this.props;

    return (
      <span className="CampaignFilterGroup">
        user{" "}
        <BooleanSwitch
          api={p.api}
          campaign_id={p.campaign_id}
          id={p.id}
          value={p.negated}
          onSave={p.onSave}
          disabled={p.disabled}
        >
          {this.props.negated ? "is not" : "is"}
        </BooleanSwitch>{" "}
        in group{" "}
        <StringEditor
          value={p.value_int}
          disabled={p.disabled}
          format="^[0-9]+$"
          onSave={this._onChange.bind(this)}
          placeholder={"group id"}
          inline
        />
      </span>
    );
  }

  _onChange(value) {
    const p = this.props;
    this.props.api.CampaignFilters.update(p.campaign_id, p.id, {
      filter: { value_int: value },
    }).then(p.onSave);
  }
}

import React, { Component } from "react";
import PropTypes from "prop-types";
import ModalForm from "../ModalForm";
import SelectAdminUsers from "../SelectAdminUsers";

class CaseAssignModal extends Component {
  constructor(props) {
    super(props);

    const { selectedUserId } = props;

    this.state = {
      selectedUserId,
      message: "",
    };
    this._isValid = this._isValid.bind(this);
    this._onSave = this._onSave.bind(this);
    this._onMessageChange = this._onMessageChange.bind(this);
    this._onSelectedUserChange = this._onSelectedUserChange.bind(this);
  }

  _isValid() {
    const { selectedUserId, message } = this.state;
    return selectedUserId > 0 && message.length > 0;
  }

  _onMessageChange({ target: { value: message } }) {
    this.setState({ message });
  }

  async _onSave() {
    const {
      id,
      api: { Cases: casesApi },
      onClose,
      onUpdate,
    } = this.props;
    const { message: notes, selectedUserId: claimed_by_id } = this.state;

    const data = await casesApi.update(id, { notes, claimed_by_id });

    this.setState({
      message: "",
    });
    onUpdate(data);
    onClose();
  }

  _onSelectedUserChange({ target: { value: selectedUserId } }) {
    this.setState({
      selectedUserId,
    });
  }

  render() {
    const { onClose } = this.props;
    const { message, selectedUserId } = this.state;

    return (
      <ModalForm
        className={"CaseAssignModal"}
        title="Assign Case"
        onCancel={onClose}
        actions={
          <button
            class="CaseAssignAction"
            onClick={this._onSave}
            disabled={!this._isValid()}
          >
            Save
          </button>
        }
        form={
          <>
            <div>
              <label>Assign To:</label>
              <SelectAdminUsers
                onChange={this._onSelectedUserChange}
                value={selectedUserId}
                api={this.props.api}
              >
                <option value="-1">--- Please Select ---</option>
              </SelectAdminUsers>
            </div>
            <div>
              <label>Message</label>
              <textarea
                onChange={this._onMessageChange}
                defaultValue={message}
                className="CaseAssignMessage"
              />
            </div>
          </>
        }
      />
    );
  }
}

CaseAssignModal.propTypes = {
  api: PropTypes.object.isRequired,
  id: PropTypes.number.isRequired,
  selectedUserId: PropTypes.number,
  onClose: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
};

export default CaseAssignModal;
import Cookies from "universal-cookie";

const cookies = new Cookies();
const xsrf_token = cookies.get("XSRF-TOKEN");
const url = new URL(`${process.env.REACT_APP_API_HOST}/admin/users`);

const submitFreeTrial = (id) => {
  return fetch(`${url}/${id}/toggle_free_trial_eligibility`, {
    method: "PATCH",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      "X-XSRF-Token": xsrf_token,
    },
  });
};

export default submitFreeTrial;

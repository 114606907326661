import React, { Component } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

export default class UserWipeButton extends Component {
  static propTypes = {
    api: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    onComplete: PropTypes.func,
  };

  render() {
    const user = this.props.user;
    const alert = `Are you sure you want to wipe ${user.first_name} ${user.last_name}?\nNB! THIS CANNOT BE REVERTED!`;

    if (!["disabled", "banned"].includes(user.account_status)) {
      return null;
    }

    return (
      <button
        type="button"
        className={classnames("UserWipeButton", this.props.className)}
        disabled={user.first_name === "wiped"}
        onClick={() => {
          if (window.confirm(alert)) this._onSubmit();
        }}
      >
        Wipe{" "}
        <span role="img" aria-label="danger">
          💥️
        </span>
      </button>
    );
  }

  _onSubmit() {
    this.props.api.Users.wipe(this.props.user.id).then(() => {
      if (this.props.onComplete) {
        this.props.onComplete();
      }
    });
  }
}

import React, { Component } from "react";
import PropTypes from "prop-types";

import SearchResults from "./SearchResults";
import UserFeatureRow from "./UserFeatureRow";

export default class UserFeatures extends Component {
  static propTypes = {
    api: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = { duration: 1 };
  }

  render() {
    return (
      <div>
        <SearchResults
          key="features"
          api={this.props.api.UserFeatures}
          query={{ user_id: this.props.user.id }}
        >
          {(results) => (
            <table>
              <thead>
                <UserFeatureRow.Headers />
              </thead>
              <tbody>
                {results.items().map((f) => (
                  <UserFeatureRow key={f.code} user_feature={f} />
                ))}
              </tbody>
            </table>
          )}
        </SearchResults>

        <button
          className="btn btn-danger mb-4"
          onClick={this.expireFeatures.bind(this)}
        >
          Expire all features
        </button>

        <h5>Grant WYWA access</h5>
        <form className="UserFeatureDurationForm">
          <input
            type="radio"
            value="1"
            id="durationOne"
            onChange={() => this.setState({ duration: 1 })}
            checked={this.state.duration === 1}
          />
          <label htmlFor="durationOne">1 day</label>
          <input
            type="radio"
            value="7"
            id="durationSeven"
            onChange={() => this.setState({ duration: 7 })}
            checked={this.state.duration === 7}
          />
          <label htmlFor="durationSeven">1 week</label>

          <button onClick={this.submit.bind(this)}>Grant access</button>
        </form>
      </div>
    );
  }

  submit(e) {
    e.preventDefault();

    this.props.api.UserFeatures.create(this.props.user.id, {
      duration: this.state.duration,
    }).then(() => {
      window.location.reload();
    });
  }

  expireFeatures(e) {
    e.preventDefault();

    this.props.api.UserFeatures.expire(this.props.user.id).then(() => {
      window.location.reload();
    });
  }
}

// eslint-disable-next-line no-unused-vars
import React from "react";
import DefaultRow from "../DefaultRow";

export default class SpeakRequestRow extends DefaultRow {
  static action = "speak_request";
  static label = "Request To Speak";
  static report = false;

  _notes() {
    return <>{this._firstName()} requested to speak.</>;
  }
}

import RoomCreatedRow from "./components/RoomCreatedRow";
import ParticipantJoinedRow from "./components/ParticipantJoinedRow";
import ParticipantLeftRow from "./components/ParticipantLeftRow";
import SpeakRequestRow from "./components/SpeakRequestRow";
import SpeakGrantedRow from "./components/SpeakGrantedRow";
import ReactionSentRow from "./components/ReactionSentRow";
import RoomReportedRow from "./components/RoomReportedRow";
import RoomClosedRow from "./components/RoomClosedRow";
import RoomEditedRow from "./components/RoomEditedRow";
import ParticipantKickedRow from "./components/ParticipantKickedRow";
import ParticipantReported from "./components/ParticipantReported";
import ListeningOnlySetRow from "./components/ListeningOnlySetRow";

// the order here is the order displayed on the page
const EventTypes = [
  RoomReportedRow,
  ParticipantReported,
  RoomCreatedRow,
  ParticipantJoinedRow,
  ParticipantKickedRow,
  ParticipantLeftRow,
  ReactionSentRow,
  RoomEditedRow,
  SpeakGrantedRow,
  SpeakRequestRow,
  RoomClosedRow,
  ListeningOnlySetRow,
].reduce((result, event_type) => {
  result[event_type.action] = event_type;
  return result;
}, {});

export default EventTypes;

import React from "react";
import PropTypes from "prop-types";

import PageCommon from "./PageCommon";
import DateTimeEditor from "../DateTimeEditor";
import StringEditor from "../StringEditor";
import DropArea from "../DropArea";
import Image from "../Image";
import TopicSelector from "../TopicSelector/TopicSelector";
import TextEditor from "../TextEditor";
import MedicalReviewerSelector from "../MedicalReviewerSelector/MedicalReviewerSelector";

export default class SEOPageBlog extends React.Component {
  static propTypes = {
    page: PropTypes.shape({
      id: PropTypes.number.isRequired,
      canonical_link: PropTypes.string,
      published_at: PropTypes.string,
      author: PropTypes.string,
      language: PropTypes.string,
      original_slug: PropTypes.string,
      avatar: PropTypes.shape({
        id: PropTypes.number,
        url: PropTypes.string,
      }),
      page_tag_id: PropTypes.number,
    }),

    api: PropTypes.object.isRequired,
    onUpdate: PropTypes.func.isRequired,
  };

  render() {
    const p = this.props;
    return (
      <PageCommon
        api={p.api}
        page={p.page}
        onUpdate={p.onUpdate}
        className="SEOPageBlog"
      >
        <label>Canonical Link</label>
        <StringEditor
          value={p.page.canonical_link}
          onSave={this._doUpdate.bind(this, "canonical_link")}
        />

        <label>Publish on</label>
        <DateTimeEditor
          value={p.page.published_at}
          onSave={(data) => this._doUpdate("published_at", data)}
        />
        <label>Cover image</label>
        <DropArea
          api={p.api}
          onComplete={(data) => this._doUpdate("cover_image_id", data.id)}
        >
          <Image
            className="SEOPageBlog-cover"
            url={p.page.cover_image && p.page.cover_image.url}
            width={1200}
          />
        </DropArea>
        <label>Topic</label>
        <TopicSelector
          isOriginal={p.page.original}
          onSave={(tagId) => this._doUpdate("pages_tag_id", tagId)}
          value={p.page.pages_tag_id}
        />
        <label>Language</label>
        <div className="SEOBlogLanguageSelect">
          <input
            type="radio"
            value="en"
            id="languageEn"
            onChange={(e) => this._doUpdate("language", "en")}
            checked={p.page.language === "en"}
          />
          <label htmlFor="languageEn">English</label>

          <input
            type="radio"
            value="es"
            id="languageEs"
            onChange={(e) => this._doUpdate("language", "es")}
            checked={p.page.language === "es"}
          />
          <label htmlFor="languageEs">Spanish</label>
        </div>

        <label>Flags</label>
        <div className="SEOBlogLanguageSelect">
          <label>
            <input
              type="checkbox"
              id="scales"
              name="scales"
              checked={p.page.flags.includes("medical")}
              onClick={(e) => this._doUpdateFlag("medical")}
            />
            Medical
          </label>

          <label>
            <input
              type="checkbox"
              id="scales"
              name="scales"
              checked={p.page.flags.includes("nsfw")}
              onClick={(e) => this._doUpdateFlag("nsfw")}
            />
            NFSW
          </label>
        </div>

        <label>Fact Checked</label>
        <div className="SEOBlogLanguageSelect">
          <input
            type="radio"
            value="true"
            id="fact-checked-true"
            onChange={(e) => this._doUpdate("fact_checked", "true")}
            checked={p.page.fact_checked === true}
          />
          <label htmlFor="factCheckedTrue">Yes</label>

          <input
            type="radio"
            value="false"
            id="fact-checked-false"
            onChange={(e) => this._doUpdate("fact_checked", "false")}
            checked={p.page.fact_checked === false}
          />
          <label htmlFor="factCheckedFalse">No</label>
        </div>

        <label>Reviewed by</label>
        <MedicalReviewerSelector
          value={p.page.reviewed_by}
          isMedicalReviewer={true}
          onSave={(id) => this._doUpdate("reviewed_by_id", id)}
        />

        <label>Written by</label>
        <MedicalReviewerSelector
          value={p.page.written_by}
          isMedicalReviewer={false}
          onSave={(id) => this._doUpdate("written_by_id", id)}
        />

        <label>Original Slug (only include the last part of the URL)</label>
        <StringEditor
          value={p.page.original_slug}
          onSave={this._doUpdate.bind(this, "original_slug")}
        />

        <label>Author</label>
        <div className="SEOPageBlog-author">
          <StringEditor
            value={p.page.author}
            onSave={this._doUpdate.bind(this, "author")}
          >
            <DropArea
              api={p.api}
              onComplete={(data) => this._doUpdate("avatar_id", data.id)}
              className="SEOPageBlog-avatar-container"
            >
              <div
                className="SEOPageBlog-avatar"
                style={{ backgroundImage: this._avatarUrl() }}
                data-test={this._avatarUrl()}
              />
            </DropArea>
          </StringEditor>
        </div>

        <label>Tag</label>
        <StringEditor
          value={p.page.tag && p.page.tag.name}
          onSave={this._doUpdate.bind(this, "tag")}
        />

        <label>References</label>
        <TextEditor
          value={`[${p.page.references.map((ref) => `"${ref}"`).join(",")}]`}
          onSave={(value) => this._doUpdateReferences(value)}
        />

        <label>Thumbnail image</label>
        <DropArea
          api={p.api}
          onComplete={(data) => this._doUpdate("hero_image_id", data.id)}
        >
          <Image
            className="SEOPageBlog-hero"
            url={p.page.hero_image && p.page.hero_image.url}
            width={1200}
          />
        </DropArea>
      </PageCommon>
    );
  }

  _avatarUrl() {
    if (!this.props.page.avatar.url) return;
    const url = this.props.page.avatar.url.replace("{width}", "256");
    return `url(${url})`;
  }

  _doUpdate(field, value) {
    this.props.api.SEO.Pages.update(this.props.page.id, {
      [field]: value,
    }).then(this.props.onUpdate.bind(this));
  }

  _doUpdateFlag(field) {
    const flags = this.props.page.flags;
    if (flags.includes(field)) {
      flags.splice(flags.indexOf(field), 1);
    } else {
      flags.push(field);
    }
    this._doUpdate("flags", flags);
  }

  _doUpdateReferences(value) {
    const refs = JSON.parse(value);
    this._doUpdate("references", refs);
  }
}

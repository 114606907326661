import React, { Component } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

import h from "./helpers";

const phrases = {
  peanut_pick: [
    [/,false/, "un-features pod"],
    [/,true/, "features pod"],
  ],

  default: "takes no action on pod",
};

export default class HistoryLiveAudioRoom extends Component {
  static propTypes = {
    userID: PropTypes.number,

    id: PropTypes.number.isRequired,
    created_at: PropTypes.string.isRequired,
    changeset: PropTypes.object.isRequired,
    notes: PropTypes.string,
    created_by: PropTypes.shape({
      id: PropTypes.number.isRequired,
      first_name: PropTypes.string.isRequired,
    }).isRequired,
    live_audio_room: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
  };

  static type = "history/live_audio_room";
  static icon = "🎵";

  render() {
    const p = this.props;

    return (
      <tr className="EventHistoryLiveAudioRoom">
        {h.sharedColumns(this)}
        <td>
          {h.renderActor(p.created_by, p.userID)}{" "}
          {h.renderActions(p.changeset, phrases)}{" "}
          <Link to={`/live-audio/${p.live_audio_room.id}`}>
            #{p.live_audio_room.id}
          </Link>{" "}
          {h.renderNotes(p.notes)}
        </td>
        <td></td>
      </tr>
    );
  }
}

import React, { Component } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import ModalForm from "./ModalForm";

export default class PickForm extends Component {
  static propTypes = {
    api: PropTypes.object.isRequired,
    apiObject: PropTypes.string.isRequired,
    subject: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    isPicked: PropTypes.bool.isRequired,
    onCancel: PropTypes.func.isRequired,
    onComplete: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = { messageBody: "" };
  }

  render() {
    return (
      <ModalForm
        className="ChangeFlagForm"
        title={this._title()}
        onCancel={this.props.onCancel}
        form={
          <React.Fragment>
            <label>Reason</label>

            <input
              className="form-control"
              value={this.state.messageBody}
              onChange={this._onFieldChange.bind(this)}
              ref={(e) => {
                e && e.focus();
              }}
            />
          </React.Fragment>
        }
        actions={
          <button
            type="button"
            className={classnames("btn", "btn-primary")}
            disabled={this.state.messageBody.length < 1}
            onClick={this._onSubmit.bind(this)}
          >
            Save
          </button>
        }
      />
    );
  }

  _title() {
    return this.props.isPicked
      ? `Un-feature ${this.props.subject}`
      : `Feature ${this.props.subject}`;
  }

  _onFieldChange(event) {
    const value = event.target.value;

    this.setState({ messageBody: value });
  }

  _onSubmit() {
    this.props.api
      .update({
        id: this.props.id,
        note: this.state.messageBody,
        [this.props.apiObject]: { peanut_pick: !this.props.isPicked },
      })
      .then(() => {
        this.setState({ messageBody: "" });
        this.props.onCancel();
        this.props.onComplete();
      });
  }
}

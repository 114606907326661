import React from "react";
import PropTypes from "prop-types";

export default class ProgressBar extends React.Component {
  static propTypes = {
    value: PropTypes.number.isRequired,
    className: PropTypes.string,
  };

  render() {
    const w = Math.round(this.props.value * 100) + "%";
    return (
      <div className={`ProgressBar ${this.props.className}`}>
        <div className="ProgressBar-inner" style={{ width: w }} />
      </div>
    );
  }
}

import React, { Component } from "react";
import PropTypes from "prop-types";
import Moment from "./Moment";
import moment from "moment";

const LAST_PREGNANCY_WEEK = 40;

const genders = {
  P: "Peanut",
  M: "Boy",
  F: "Girl",
  TTC: "TTC",
  MP: "Menopause",
  OLS: "Other life-stage",
};

export default class Child extends Component {
  static propTypes = {
    data: PropTypes.object.isRequired,
  };

  calculateAgeOrPregnancy(data) {
    const today = moment();
    if (data.dob) {
      const dob = moment(data.dob);
      if (dob.isAfter(today)) {
        // Child is unborn, dob is in the future
        let weeksPregnant = LAST_PREGNANCY_WEEK - dob.diff(today, "weeks");
        weeksPregnant = Math.max(
          0,
          Math.min(weeksPregnant, LAST_PREGNANCY_WEEK)
        ); // Ensure within valid range
        return `${weeksPregnant} weeks`;
      } else {
        // Child is born, dob is in the past
        const years = today.diff(dob, "years");
        dob.add(years, "years"); // Adjust dob to calculate months
        const months = today.diff(dob, "months");
        return `${years} years ${months} months`;
      }
    } else if (data.ttc_since) {
      return (
        <>
          since <Moment date={data.ttc_since} />
        </>
      );
    }
    return "N/A"; // In case neither dob nor ttc_since is provided
  }

  render() {
    const { data } = this.props;
    const ageOrPregnancy = this.calculateAgeOrPregnancy(data);

    return (
      <div className="Child">
        {data.name && <span>{data.name}</span>}
        <span>{genders[data.gender]}</span>
        <span>{ageOrPregnancy}</span>
      </div>
    );
  }
}

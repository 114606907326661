import React, { Component } from "react";

import { Link } from "react-router-dom";

import PropTypes from "prop-types";
import ModalForm from "./ModalForm";
import Avatar from "./Avatar";

export default class UserPhoneSwapButton extends Component {
  static propTypes = {
    api: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      overlay: false,
      confirmSwap: false,
      showCheckBtn: true,
      confirmSwapBtn: true,
      finalConfirmation: false,
      errors: [],
      data: {},
      sourceID: "",
    };
  }

  render() {
    const user = this.props.user;

    var data = this.state.data;
    var errorList = this.state.errors.map((error, index) => (
      <li key={index}>{error}</li>
    ));

    return (
      <span>
        <button
          className="UserPhoneSwapButton"
          disabled={user.phone_number === null}
          onClick={this.onClick.bind(this)}
        >
          Phone Swap{" "}
          <span role="img" aria-label="mobile phone">
            📱
          </span>
        </button>

        {this.state.overlay && (
          <ModalForm
            title="Phone number swap"
            form={
              <div>
                <div>
                  <label>
                    Enter the user UID for the phone you want to swap to:
                  </label>
                  <input
                    className="form-control"
                    value={this.state.sourceID}
                    onChange={this.onInputChange.bind(this)}
                    ref={(e) => {
                      e && e.focus();
                    }}
                  />
                </div>

                <br />

                {this.state.errors.length > 0 && (
                  <div className="alert alert-danger">
                    <span>
                      The phone number could not be switched because:{" "}
                    </span>
                    <hr />
                    <ul>{errorList}</ul>
                  </div>
                )}

                {this.state.confirmSwap && (
                  <div>
                    <p>
                      You are about to swap {data.target.first_name}'s phone
                      number.
                      <strong> This will disable their newest account.</strong>
                    </p>

                    <div className="UserPhoneSwapButton-comparison">
                      <div className="UserPhoneSwapButton-comparison-from">
                        {data.source.avatar_url && (
                          <div className="UserPhoneSwapButton-comparison-avatar">
                            <Avatar
                              alt={data.source.first_name}
                              url={data.source.avatar_url}
                              size={64}
                            />
                          </div>
                        )}

                        <Link to={`/users/${data.source.id}`}>
                          {data.source.first_name}
                        </Link>

                        <p className="text-muted">{data.source.uid}</p>

                        <br />

                        <p className="text-danger">
                          {data.source.phone_number}
                        </p>
                      </div>

                      <div className="UserPhoneSwapButton-comparison-to">
                        {data.target.avatar_url && (
                          <div className="UserPhoneSwapButton-comparison-avatar">
                            <Avatar
                              alt={data.target.first_name}
                              url={data.target.avatar_url}
                              size={64}
                            />
                          </div>
                        )}

                        <Link to={`/users/${data.target.id}`}>
                          {data.target.first_name}
                        </Link>

                        <p className="text-muted">{data.target.uid}</p>

                        <br />

                        <p className="text-danger">
                          {data.target.phone_number}
                        </p>

                        <p className="text-success">
                          {data.source.phone_number}
                        </p>
                      </div>
                    </div>

                    {this.state.confirmSwapBtn && (
                      <div>
                        <button
                          type="button"
                          className="btn btn-success btn-lg btn-block"
                          onClick={this.onSwapConfirmation.bind(this)}
                        >
                          Confirm
                        </button>
                      </div>
                    )}

                    {this.state.finalConfirmation && (
                      <div className="UserPhoneSwapButton-confirmation">
                        <p>Are you sure about this change?</p>

                        <button
                          type="button"
                          className="btn btn-primary btn-lg"
                          value="true"
                          onClick={this.onFinalConfirmation.bind(this)}
                        >
                          Yes
                        </button>

                        <button
                          type="button"
                          className="btn btn-secondary btn-lg"
                          value="false"
                          onClick={this.onFinalConfirmation.bind(this)}
                        >
                          No
                        </button>
                      </div>
                    )}
                  </div>
                )}
              </div>
            }
            onCancel={this.onCancel.bind(this)}
            actions={
              this.state.showCheckBtn && (
                <button
                  className="btn btn-primary"
                  onClick={this.onSubmit.bind(this)}
                >
                  Check
                </button>
              )
            }
          />
        )}
      </span>
    );
  }

  onClick(e) {
    this.setState({ overlay: true });
  }

  onCancel(e) {
    this.setState({
      overlay: false,
      confirmSwap: false,
      showCheckBtn: true,
      confirmSwapBtn: true,
      finalConfirmation: false,
      errors: [],
      data: {},
      sourceID: "",
    });
  }

  onInputChange(e) {
    const value = e.target.value;

    this.setState({ sourceID: value });
  }

  onSubmit(e) {
    this.setState({
      errors: [],
      data: {},
      showCheckBtn: null,
      confirmSwap: false,
    });

    this.props.api.Users.accountSwapCheck({
      target_uid: this.props.user.uid,
      source_uid: this.state.sourceID,
    }).then(({ data, hasFailed }) => {
      if (hasFailed) {
        this.setState({ errors: data.errors });
      } else {
        this.setState({ confirmSwap: true, data: data });
        console.log(data);
      }
    });
  }

  onSwapConfirmation(e) {
    this.setState({
      confirmSwapBtn: false,
      finalConfirmation: true,
    });
  }

  onFinalConfirmation(e) {
    const value = e.target.value;

    if (value === "true") {
      this.props.api.Users.accountSwap({
        target_uid: this.props.user.uid,
        source_uid: this.state.sourceID,
      }).then(() => {
        window.location.reload();
      });
    } else {
      this.onCancel(e);
    }
  }
}

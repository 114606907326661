import Base from "./Base";

export default class Users extends Base {
  index(params) {
    const q = this._query(params);

    return this._fetch(`/admin/users?${q}`)
      .then((response) => response.json())
      .then((data) => [data.users]);
  }

  show(id) {
    return this._fetch(`/admin/users/${id}`)
      .then((response) => response.json())
      .then((data) => data.user);
  }

  showStats(id) {
    return this._fetch(`/admin/users/${id}/stats`).then((response) =>
      response.json()
    );
  }

  showLocation(id) {
    return this._fetch(`/admin/users/${id}/location`).then((response) =>
      response.json()
    );
  }

  updateStatus({ id, action, message }) {
    const params = {
      user_status_update: {
        action: action,
        note: message,
      },
    };

    return this._fetch(`/admin/users/${id}/status`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(params),
    });
  }

  updateBadges({ id, badges }) {
    const params = {
      badges: badges,
    };

    return this._fetch(`/admin/users/${id}/badges`, {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(params),
    });
  }

  updatePayPal({ id, paypal }) {
    const params = {
      paypal: paypal,
    };

    return this._fetch(`/admin/users/${id}/paypal`, {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(params),
    });
  }

  expunge(id) {
    return this._fetch(`/admin/users/${id}/expunge`, {
      method: "DELETE",
    });
  }

  wipe(id) {
    return this._fetch(`/admin/users/${id}/wipe`, {
      method: "DELETE",
    });
  }

  unblock(params) {
    return this._fetch("/admin/user_events", {
      method: "DELETE",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(params),
    });
  }

  addNote(id, note) {
    return this._fetch(`/admin/users/${id}/notes`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ note: note }),
    });
  }

  resetSelfieCheck(id) {
    return this._fetch(`/admin/users/${id}/selfie_check`, {
      method: "DELETE",
    });
  }

  getAuthToken(id) {
    return this._fetch(`/admin/users/${id}/token`)
      .then((response) => response.json())
      .then((data) => data.auth_token);
  }

  accountSwapCheck(params) {
    return this._fetch("/admin/account_swap/phone_number_check", {
      method: "PUT",
      passErrors: [409],
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(params),
    }).then((response) => {
      const hasFailed = response.status === 409;

      return response.json().then((data) => {
        return { data: data, hasFailed: hasFailed };
      });
    });
  }

  accountSwap(params) {
    return this._fetch("/admin/account_swap/phone_number", {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(params),
    });
  }

  getReports(userId) {
    return this._fetch(`/admin/users/${userId}/reports`).then((response) =>
      response.json()
    );
  }

  clearPhone(id) {
    return this._fetch(`/admin/users/${id}/phone`, {
      method: "DELETE",
    });
  }

  dataExportUrl(id) {
    return `${this.host}/admin/user_data_exports/${id}`;
  }
}

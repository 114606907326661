import React, { Component } from "react";
import PropTypes from "prop-types";
import { MapContainer, Marker, CircleMarker, TileLayer } from "react-leaflet";
import L from "leaflet";

import "leaflet/dist/leaflet.css";

// Workaround for
// https://github.com/Leaflet/Leaflet/issues/4968#issuecomment-483402699
// Vendor Leaflet marker icon.
import lfIconUrl from "../assets/marker-icon.png";
import lfIconRetinaUrl from "../assets/marker-icon-2x.png";
import lfIconShadow from "../assets/marker-shadow.png";

const markerIcon = new L.Icon({
  iconUrl: lfIconUrl,
  iconRetinaUrl: lfIconRetinaUrl,
  shadowUrl: lfIconShadow,

  iconAnchor: new L.Point(12, 41),
  popupAnchor: new L.Point(1, -34),
  shadowSize: new L.Point(41, 41),
  iconSize: new L.Point(25, 41),
  tooltipAnchor: new L.Point(16, -28),
});

export default class UserLocation extends Component {
  static propTypes = {
    api: PropTypes.object.isRequired,
    userID: PropTypes.number.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = { location: null };
  }

  componentDidMount() {
    this.props.api.Users.showLocation(this.props.userID).then((data) =>
      this.setState(data)
    );
  }

  render() {
    if (!this.state.location) return <React.Fragment />;

    const position = this.state.location.coords;

    const mapbox_token =
      "pk.eyJ1IjoianVsaWVuLXBlYW51dCIsImEiOiJjanhvdWF0OXMwOXdhM2RudDRsM3h5ZmRmIn0.r7ALfsFs_dyrufjpe3vXdQ";
    const tile_url = `https://api.mapbox.com/styles/v1/mapbox/streets-v11/tiles/{z}/{x}/{y}?access_token=${mapbox_token}`;
    const attribution = "&copy; MapBox";

    return (
      <div className="UserLocation">
        <div className="UserLocation-details">
          <dl>
            <dt>Location</dt>
            <dd>{this.state.location.primary}</dd>
            <dt>Neighborhood</dt>
            <dd>{this.state.location.residence || <em>not specified</em>}</dd>
            <dt>Density</dt>
            <dd>
              Active users within
              <ul>
                <li>2km: {this.state.location.stats["2km"]}</li>
                <li>4km: {this.state.location.stats["4km"]}</li>
                <li>8km: {this.state.location.stats["8km"]}</li>
                <li>16km: {this.state.location.stats["16km"]}</li>
                <li>32km: {this.state.location.stats["32km"]}</li>
              </ul>
            </dd>
          </dl>
        </div>

        <MapContainer center={position} zoom={8}>
          <TileLayer url={tile_url} attribution={attribution} />
          {this.state.trail.map((t, idx) => (
            <CircleMarker
              key={idx}
              center={t.coords}
              radius={10}
              stroke={false}
              color="red"
            />
          ))}
          <Marker position={position} icon={markerIcon} />
        </MapContainer>
      </div>
    );
  }
}

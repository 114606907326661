import React, { Component } from "react";
import PropTypes from "prop-types";

import CheckGroup from "./CheckGroup";
import ModalForm from "./ModalForm";

export default class PostTagsForm extends Component {
  static propTypes = {
    api: PropTypes.object.isRequired,
    selectedTagIds: PropTypes.string.isRequired,
    onCancel: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      selectedTagIds: this.props.selectedTagIds,
      tags: null,
    };
  }

  componentWillMount() {
    if (this.state.tags) return;

    this.props.api.PostTags.index().then((data) => {
      this.setState({ tags: data });
    });
  }

  render() {
    if (!this.state.tags) return <React.Fragment />;

    return (
      <ModalForm
        className="PostTagsForm"
        title="Change tags"
        onCancel={this.props.onCancel}
        form={
          <div className="TagGroups">
            {this.state.tags.map((item, idx) => (
              <div className="TagBox" key={idx}>
                <label>{item.group}</label>
                <CheckGroup
                  name="tags"
                  labels={item.tags.map((d) => d.name)}
                  options={item.tags.map((d) => d.id.toString())}
                  selected={this.state.selectedTagIds}
                  onChange={this._onChange.bind(this)}
                />
              </div>
            ))}
          </div>
        }
        actions={
          <button
            type="button"
            className="btn btn-primary"
            onClick={this._onSubmit.bind(this)}
          >
            Save
          </button>
        }
      />
    );
  }

  _onChange(e) {
    this.setState({ selectedTagIds: e.target.value });
  }

  _onSubmit() {
    this.props.onSubmit(this.state.selectedTagIds);
  }
}

import React from "react";
import DefaultRow from "../DefaultRow";

export default class ParticipantJoinedRow extends DefaultRow {
  static action = "participant_joined";
  static label = "Participant Joined";
  static report = false;

  _notes() {
    const {
      meta: { status },
    } = this.props;
    return (
      <>
        {this._firstName()} joined as {status === "host" ? "the" : "a"}{" "}
        <b>{status}</b>
      </>
    );
  }
}

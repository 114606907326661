import React from "react";
import PropTypes from "prop-types";

import moment from "moment";
import SearchResults from "../components/SearchResults";
import CampaignRow from "../components/CampaignRow";

export default class Campaigns extends React.Component {
  static propTypes = {
    api: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = { query: {} };
  }

  render() {
    return (
      <div className="Campaigns">
        <button
          className="btn btn-sm btn-outline-secondary float-right"
          onClick={this._onCreate.bind(this)}
        >
          New
        </button>
        <h2>Push campaigns</h2>

        <SearchResults api={this.props.api.Campaigns} query={this.state.query}>
          {(results) => (
            <table className="Campaigns-table">
              <thead>
                <CampaignRow.Headers />
              </thead>
              <tbody>
                {results.items().map((u) => (
                  <CampaignRow key={u.id} api={this.props.api} campaign={u} />
                ))}
              </tbody>
              <tfoot>
                <tr>
                  <td colSpan={3}>
                    <results.AutoLoad />
                  </td>
                </tr>
              </tfoot>
            </table>
          )}
        </SearchResults>
      </div>
    );
  }

  _onCreate() {
    this.props.api.Campaigns.create({
      campaign: {
        name: "FILL ME",
        send_on: moment().add(7, "days").format("YYYY-MM-DD"),
        send_at: "20:00",
        message: {
          title: "FILL ME",
          body: "FILL ME",
        },
      },
    }).then((data) => this.props.history.push(`/campaigns/${data.id}`));
  }
}

import React from "react";
import { Chip, makeStyles } from "@material-ui/core";
import classnames from "classnames";
import styles from "./ChipTableMessage.module.scss";
import useMessageAbuse from "../../hooks/api/useMessageAbuse";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "10px 5px 0 0",
  },
  suggested: {
    outline: "1.5px solid #6E757C",
    outlineOffset: "2px",
  },
  selected: {
    backgroundColor: "#6E757C !important",
  },
  severeChip: {
    borderColor: "#FFC3C5",
    backgroundColor: "white",
  },
  defaultChip: {
    backgroundColor: "white",
  },
}));

const ChipTableMessage = ({
  guidelineList,
  message,
  suggestedViolatedGuidelines,
  reducedTd,
  disabled,
  selectedReason,
  onShowModal,
  onError,
  onSuccess,
  onLoading,
  onSetSelectedReason,
}) => {
  const classes = useStyles();

  const { mutate: mutateBreach, isLoading } = useMessageAbuse(
    onSuccess,
    onError
  );

  const submitBreach = (payload) => {
    onLoading(
      payload.violated_guidelines.length === 0
        ? "Infirming Breach..."
        : "Submitting Breach..."
    );
    setTimeout(() => {
      onLoading("");
      mutateBreach(payload);
    }, 2000);
  };

  const pickSelection = (id, violation_severity) => {
    onSetSelectedReason(id);
    const payload = {
      messageId: message.id,
      violated_guidelines: id ? [id] : [],
    };

    if (violation_severity !== "severe") {
      submitBreach(payload);
    } else {
      //if severe breach show warning modal. pass mutations to make the call
      onShowModal(
        { id, violation_severity },
        {
          submitBreach,
        }
      );
    }
  };

  const suggestedGuidelineToClass = (id) => {
    var suggestedGuidelineClass = "";

    suggestedViolatedGuidelines?.forEach((guideline) =>
      guideline === id
        ? (suggestedGuidelineClass = classes.suggested)
        : (suggestedGuidelineClass = "")
    );

    return suggestedGuidelineClass;
  };

  return (
    <>
      <tr className={styles.wrapper}>
        {!reducedTd && <td width={1}></td>}
        <td width={1} className={styles.borderLeft}></td>
        <td className="chiptable" style={{ textAlign: "left" }}>
          <div>
            {guidelineList?.map(({ id, title, icon, violation_severity }) => {
              return violation_severity === "severe" ? (
                <Chip
                  key={id}
                  label={`${icon} ${title}`}
                  onClick={() => pickSelection(id, violation_severity)}
                  className={classnames(
                    classes.root,
                    suggestedGuidelineToClass(id),
                    selectedReason === id
                      ? classes.selected
                      : classes.severeChip
                  )}
                  disabled={disabled || isLoading}
                  variant="outlined"
                />
              ) : (
                <Chip
                  key={id}
                  label={`${icon} ${title}`}
                  onClick={() => pickSelection(id, violation_severity)}
                  className={classnames(
                    classes.root,
                    suggestedGuidelineToClass(id),
                    selectedReason === id
                      ? classes.selected
                      : classes.defaultChip
                  )}
                  disabled={disabled || isLoading}
                  variant="outlined"
                />
              );
            })}
            <Chip
              label="❌ Infirm"
              className={classes.root}
              variant="outlined"
              disabled={disabled || isLoading}
              onClick={() => pickSelection("")}
            />
          </div>
        </td>
        <td width={1}></td>
      </tr>
    </>
  );
};

export default ChipTableMessage;

// eslint-disable-next-line no-unused-vars
import React from "react";
import DefaultRow from "../DefaultRow";

export default class ListeningOnlySetRow extends DefaultRow {
  static action = "listening_only_set";
  static label = "Quiet listener change";
  static report = false;

  _notes() {
    const {
      meta: { to },
    } = this.props;
    return (
      <>
        {this._firstName()} changed quiet listener status to {to.toString()}.
      </>
    );
  }
}

import React, { useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import useAuthors from "../../hooks/api/useAuthors";

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 120,
    marginTop: 10,
    width: "100%",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const MedicalReviewerSelector = ({ value, isMedicalReviewer, onSave }) => {
  const classes = useStyles();
  const [selectedReviewer, setSelectedReviewer] = React.useState("");
  const { data } = useAuthors();

  const handleChange = (event) => {
    onSave(event.target.value);
    setSelectedReviewer(event.target.value);
  };

  const addLocale = useCallback((url, name) => {
    if (url.includes("es/author")) {
      return `${name} (ES)`;
    } else {
      return name;
    }
  }, []);

  return (
    <div>
      <FormControl
        variant="outlined"
        data-testid="select-form-control"
        className={classes.formControl}
      >
        <InputLabel id="select-label" data-testid="select-label">
          Select {isMedicalReviewer ? "reviewer" : "author"}
        </InputLabel>
        <Select
          value={value ? value.id : selectedReviewer}
          onChange={handleChange}
          data-testid="select-select-component"
          label="Select"
        >
          <MenuItem value={null}>
            <em>None</em>
          </MenuItem>
          {data?.map((item) => (
            <MenuItem key={item?.id} value={item?.id}>
              {addLocale(item?.url, item?.name)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

export default MedicalReviewerSelector;

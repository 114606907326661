import Base from "./Base";

export default class AutoAnnotations extends Base {
  index() {
    return this._fetch(`/admin/auto_annotations`)
      .then((response) => response.json())
      .then((data) => [data.auto_annotations]);
  }

  updateUrl(id, countryCode, url) {
    return this._fetch(`/admin/auto_annotations/${id}/url`, {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ country_code: countryCode, url: url }),
    });
  }

  deleteUrl(id, countryCode) {
    return this._fetch(`/admin/auto_annotations/${id}/url`, {
      method: "DELETE",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ country_code: countryCode }),
    });
  }
}

import React, { Component } from "react";
import PropTypes from "prop-types";

export default class PromptyAnswer extends Component {
  static propTypes = {
    data: PropTypes.object.isRequired,
  };

  render() {
    const d = this.props.data;

    return (
      <div className="PromptAnswer">
        Q: {d.prompt.body}
        <br />
        A: {d.body}
      </div>
    );
  }
}

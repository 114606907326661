import React, { Component } from "react";
import PropTypes from "prop-types";

// Browsers are stupid and do not respect the EXIF image orientation, so
// a lot of our avatars are sideways.
// (https://developer.mozilla.org/en-US/docs/Web/CSS/image-orientation#Browser_compatibility)
//
// This add-on queries the image via XHR and read the tags to rotate it.
// It does have a couple bus which result in React warnings, so an alternative
// could be considered.
//
// Sources:
// - https://medium.com/@oscarfranco_14246/how-to-automatically-rotate-an-image-based-on-exif-data-on-react-a41a27feb57c
// - https://stackoverflow.com/questions/49361523/exif-orientation-image-in-react-js
import ExifImg from "react-exif-orientation-img";

import { parse as uriTemplate } from "uritemplate";

import classnames from "classnames";

const blackPixelURL =
  "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNk+A8AAQUBAScY42YAAAAASUVORK5CYII=";

export default class Avatar extends Component {
  static propTypes = {
    url: PropTypes.string,
    alt: PropTypes.string.isRequired,
    size: PropTypes.number,
    width: PropTypes.number,
    height: PropTypes.number,
    openInNewTab: PropTypes.bool,
    nudity: PropTypes.bool,
  };

  static defaultProps = {
    openInNewTab: false,
    nudity: false,
  };

  constructor(props) {
    super(props);
    this._onImageClick = this._onImageClick.bind(this);
  }

  _onImageClick() {
    const { url } = this.props;
    if (url) {
      window.open(url.replace("{width}x{height}", "full"), "_blank");
    }
  }

  render() {
    const s = this.props.size || 64;
    const w = this.props.width || s * 2;
    const h = this.props.height || s * 2;
    const url = this.props.url
      ? uriTemplate(this.props.url).expand({
          width: w,
          height: h,
          scale_factor: 2,
        })
      : blackPixelURL;

    let { openInNewTab } = this.props;
    return (
      <ExifImg
        className={classnames("Avatar", { nudity: this.props.nudity })}
        alt="avatar"
        src={url}
        style={{
          display: "inline-block",
          width: `${s}px`,
          height: `${s}px`,
          cursor: openInNewTab ? "pointer" : "auto",
        }}
        onClick={openInNewTab ? this._onImageClick : null}
      />
    );
  }
}

import Base from "./Base";

export default class UserPhotos extends Base {
  index(params) {
    const q = this._query(params);

    return this._fetch(`/admin/user_photos?${q}`)
      .then((response) => response.json())
      .then((data) => [data.user_photos, data.cursor]);
  }

  show(id) {
    return this._fetch(`/admin/user_photos/${id}`).then((response) =>
      response.json()
    );
  }

  update(id, params) {
    return this._fetch(`/admin/user_photos/${id}`, {
      method: "PATCH",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(params),
    }).then((response) => response.json());
  }

  updateStatus({ id, action, message }) {
    const params = {
      photo_status_update: {
        action: action,
        note: message,
      },
    };
    return this._fetch(`/admin/user_photos/${id}/status`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(params),
    })
      .then(() => this._fetch(`/admin/user_photos/${id}`))
      .then((response) => response.json());
  }
}

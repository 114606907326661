import PropTypes from "prop-types";

import PickForm from "./PickForm";

export default class DemoteForm extends PickForm {
  static propTypes = {
    api: PropTypes.object.isRequired,
    apiObject: PropTypes.string.isRequired,
    subject: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    isDemoted: PropTypes.bool.isRequired,
    onCancel: PropTypes.func.isRequired,
    onComplete: PropTypes.func.isRequired,
  };

  _title() {
    return this.props.isDemoted
      ? `Un-demote ${this.props.subject}`
      : `Demote ${this.props.subject}`;
  }

  _onSubmit() {
    this.props.api
      .update({
        id: this.props.id,
        note: this.state.messageBody,
        [this.props.apiObject]: { peanut_demote: !this.props.isDemoted },
      })
      .then(() => {
        this.setState({ messageBody: "" });
        this.props.onCancel();
        this.props.onComplete();
      });
  }
}

import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import classnames from "classnames";
import MDEditor from "@uiw/react-md-editor";

import Moment from "../../components/Moment";

export default class ContentLabellingJob extends Component {
  static propTypes = {
    api: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = { job: {} };
    this.toggleQueryExpanded = this.toggleQueryExpanded.bind(this);
  }

  componentDidMount() {
    this.props.api.ContentLabellingJobs.show(this.props.match.params.id).then(
      (data) => {
        this.setState({ job: data.record });
      }
    );
  }

  toggleQueryExpanded() {
    this.setState({ queryExpanded: !this.state.queryExpanded });
  }

  render() {
    const { job } = this.state;
    const alert = this.props.location.state && this.props.location.state.alert;

    if (job.id === undefined) {
      return <p>Loading...</p>;
    }

    return (
      <div className="container pt-4 pb-4">
        {alert && (
          <div className={classnames("alert", alert.className)} role="alert">
            {alert.message}
          </div>
        )}
        <div className="container">
          <div className="d-inline-block">
            <h1>
              {job.title}
              {job.finished && (
                <span className="badge badge-secondary ml-1">finished</span>
              )}
            </h1>
          </div>
          <div className="d-inline-block float-right pt-2">
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={job.link}
              className="btn btn-outline-secondary mr-3"
            >
              Open In Labelling App
            </a>
            <Link
              to={`/content-labelling-jobs/${job.id}/edit`}
              className="btn btn-outline-secondary"
            >
              Edit
            </Link>
          </div>
        </div>
        {job.empty_queue_warning && (
          <div className="container">
            <div className="badge badge-warning">subjects queue is empty</div>
            <div className="text-muted mb-3">
              Job's completion target is not met yet, but subjects queue is
              empty. Probably there are no more subjects that suite query
              parameters
            </div>
          </div>
        )}
        <div className="row container m-0">
          <div className="col">
            <div className="row">
              <div className="text-secondary pr-3">Internal ID</div>
              {job.internal_id}
            </div>
            <div className="row">
              <div className="text-secondary pr-3">Created</div>
              <Moment time={job.created_at} />
            </div>
            <div className="row">
              <div className="text-secondary pr-3">Updated</div>
              <Moment time={job.updated_at} />
            </div>
            <div className="row">
              <div className="text-secondary pr-3">Completion Target</div>
              {job.completion_target}
            </div>
            <div className="row">
              <div className="text-secondary pr-3">
                Target Labellers per Subject
              </div>
              {job.target_labellers_per_subject}
            </div>
            <div className="row">
              <div className="text-secondary pr-3">Subject Type</div>
              {job.subject_type_title}
            </div>
            <div className="row">
              <div className="text-secondary pr-3">Classifier Type</div>
              {job.classifier_type}
            </div>
            <div className="row">
              <div className="text-secondary pr-3">Max Labels Applied</div>
              {job.max_labels_applied}
            </div>
          </div>
          <div className="col">
            <p className="mb-2">
              <span className="text-secondary pr-2">
                Completely Labelled Subjects Count (
                {job.target_labellers_per_subject} labellers per subject)
              </span>
              {job.completely_labelled_subjects_count}/{job.completion_target}
            </p>
            <p className="mb-2">
              <span className="text-secondary pr-2">
                Labelled Subjects Count
              </span>
              {job.labelled_subjects_count}
            </p>
            <p className="mb-2">
              <span className="text-secondary pr-2">Total Labels Assigned</span>
              {job.total_labels_count}
            </p>
            <p className="mb-2">
              <span className="text-secondary pr-2">Subjects Queue Length</span>
              {job.subjects_queue_length}
            </p>
          </div>
        </div>
        <hr />
        <div className="container mt-3">
          <p className="text-secondary">Description</p>
          <p className="multiline-text">{job.description}</p>
          <hr />
          <p className="text-secondary">Labelling Instructions</p>
          <div className="multiline-text">
            <MDEditor.Markdown source={job.labelling_instructions} />
          </div>
          <hr />
          <p>
            <span className="text-secondary">Select Subject Query Key </span>
            {job.select_subjects_query_key}
          </p>
          <div className="text-secondary">Query body</div>
          <div
            className={classnames("multiline-text expandable", {
              expanded: this.state.queryExpanded,
            })}
          >
            {job.query}
            <span
              className="expand text-muted"
              onClick={this.toggleQueryExpanded}
            ></span>
          </div>
          <hr />
          <h3 className="text-secondary">Content Labels</h3>
          {job.content_labels.map((contentLabel, key) => (
            <div key={key}>
              <span className="text-secondary pr-3">Title </span>
              {contentLabel.title}
              <div className="text-secondary pr-3">Description</div>
              {contentLabel.description}
            </div>
          ))}
        </div>
      </div>
    );
  }
}

import React, { Component } from "react";
import PropTypes from "prop-types";

import ModalForm from "./ModalForm";

export default class ExperimentUserForm extends Component {
  static propTypes = {
    api: PropTypes.object.isRequired,
    experiment: PropTypes.object.isRequired,
    onCancel: PropTypes.func.isRequired,
    onComplete: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      response: null,
      userID: null,
      forcedVariant: Object.keys(props.experiment.variants)[0],
    };
  }

  render() {
    return (
      <ModalForm
        className="ExperimentUserForm"
        title="Force variant for a user"
        onCancel={this.props.onCancel}
        form={
          <React.Fragment>
            <div>
              <label>User ID</label>
              <br />
              <input
                className="ExperimentUser-select"
                onChange={(e) => {
                  this.setState({ userID: e.target.value });
                }}
                type="text"
                inputMode="numeric"
              />
            </div>
            <div className="mt-3">
              <label>Forced variant</label>
              <br />
              <select
                className="ExperimentUser-select"
                onChange={(e) => {
                  this.setState({ forcedVariant: e.target.value });
                }}
                value={this.state.forcedVariant}
              >
                {Object.keys(this.props.experiment.variants).map(
                  (variant, _) => (
                    <option key={variant} value={variant}>
                      {variant}
                    </option>
                  )
                )}
              </select>
            </div>
          </React.Fragment>
        }
        actions={
          <React.Fragment>
            <span>{this.state.response}</span>
            <button
              type="button"
              className="btn btn-primary"
              onClick={this._onSubmit.bind(this)}
            >
              Save
            </button>
          </React.Fragment>
        }
      />
    );
  }

  _onSubmit() {
    this.props.api.Experiments.addUser(
      this.props.experiment.id,
      this.state.userID,
      this.state.forcedVariant
    )
      .then(() => {
        this.props.onCancel();
        this.props.onComplete();
      })
      .catch((e) => {
        console.error(e);
        this.setState({ response: "Save failed!" });
      });
  }
}

import React, { Component } from "react";
import PropTypes from "prop-types";
import Errors from "../Errors";

export default class ErrorLog extends Component {
  static propTypes = {
    api: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    this._onErrorReport = this._onErrorReport.bind(this);
    this.state = {
      ok: true,
      errors: [],
    };
  }

  componentDidMount() {
    this.props.api.on("error", this._onErrorReport);
    Errors.on(this._onErrorReport);
  }

  componentWillUnmount() {
    this.props.api.off("error", this._onErrorReport);
    Errors.off(this._onErrorReport);
  }

  render() {
    if (this.state.errors.length === 0) return <React.Fragment />;

    return (
      <ul className="ErrorLog">
        {this.state.errors.map((e, idx) => (
          <li onClick={this._onClick.bind(this, idx)} key={idx}>
            {e}
          </li>
        ))}
      </ul>
    );
  }

  _onErrorReport(key, message) {
    const errors = [...this.state.errors];

    if (errors.length > 2) errors.pop();
    this.setState({ errors: [message, ...errors] });
  }

  _onClick(idx, e) {
    e.preventDefault();

    const errors = [...this.state.errors];
    errors.splice(idx, 1);
    console.log("clicked on", idx);
    console.log(this.state.errors, "=>", errors);
    this.setState({ errors: errors });
  }
}

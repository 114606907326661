import Base from "./Base";

export default class LiveAudioReports extends Base {
  index(params) {
    const { roomId, ...editedParams } = params;
    const q = this._query(editedParams);

    return this._fetch(`/admin/audio_rooms/${roomId}/reports?${q}`)
      .then((response) => response.json())
      .then(({ data: { reports } }) => reports);
  }
}

/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import GroupMembershipRow from "./components/GroupMembershipRow";

const UserTabGroups = (props) => {
  const [groupMemberships, setGroupMemberships] = React.useState([]);
  const [cursor, setCursor] = React.useState();
  const [hasMoreData, setHasMoreData] = React.useState(true);
  const {
    api: { UserGroupMemberships: userGroupMembershipsApi },
    userId,
  } = props;

  const handleLoadMoreClick = () => {
    requestData();
  };

  const requestData = async () => {
    const limit = 50;
    const [data, currentCursor] =
      (await userGroupMembershipsApi.index({
        user_id: userId,
        limit,
        cursor: cursor || "",
      })) || [];

    const resultCount = data?.length;
    if (resultCount > 0) {
      setGroupMemberships([...groupMemberships, ...data]);
    }

    setCursor(currentCursor);

    setHasMoreData(resultCount === limit);
    console.log("requestData :", data);
  };

  React.useEffect(() => {
    requestData();
  }, []);

  return (
    <table>
      <thead>
        <tr>
          <th>Group</th>
          <th>Role</th>
          <th>Status</th>
          <th>Interval</th>
        </tr>
      </thead>
      <tbody>
        {groupMemberships && (
          <>
            {groupMemberships.map((groupMembership) => {
              return (
                <GroupMembershipRow
                  key={`groupMembership_${groupMembership.id}`}
                  {...groupMembership}
                  api={props.api}
                ></GroupMembershipRow>
              );
            })}
            {hasMoreData && (
              <tr>
                <td colSpan="3" align="center">
                  <button onClick={handleLoadMoreClick}>Load More</button>
                </td>
              </tr>
            )}
          </>
        )}
        {!groupMemberships && (
          <tr>
            <td colSpan="3">Not a member of any groups</td>
          </tr>
        )}
      </tbody>
    </table>
  );
};

export default UserTabGroups;

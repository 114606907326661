import React, { Component } from "react";
import PropTypes from "prop-types";

export default class UserPhotosResetSelfieButton extends Component {
  static propTypes = {
    api: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    onComplete: PropTypes.func,
  };

  render() {
    return (
      <button
        type="button"
        className="UserPhotosResetSelfieButton"
        onClick={this._onSubmit.bind(this)}
      >
        Reset selfie check
      </button>
    );
  }

  _onSubmit() {
    this.props.api.resetSelfieCheck(this.props.user.id).then(() => {
      if (this.props.onComplete) {
        this.props.onComplete();
      }
    });
  }
}

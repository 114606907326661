import React, { Component } from "react";
import PropTypes from "prop-types";

import Duration from "./Duration";
import BlockedUsers from "./BlockedUsers";
import BlockedBy from "./BlockedBy";
import UserFeatures from "./UserFeatures";
import EventReportUser from "./events/ReportUser";
import UserPaymentsTable from "./UserPaymentsTable/UserPaymentsTable";

export default class UserTabStats extends Component {
  static propTypes = {
    api: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    onUpdate: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {};
    this._refreshStats = this._refreshStats.bind(this);
    this._refreshReports = this._refreshReports.bind(this);
  }

  componentDidMount() {
    this._refreshStats();
    this._refreshReports();
  }

  componentDidUpdate(prevProps) {
    if (this.props.user.id !== prevProps.user.id) {
      this._refreshStats();
      this._refreshReports();
    }
  }

  _refreshStats() {
    this.props.api.Users.showStats(this.props.user.id).then((data) =>
      this.setState(data)
    );
  }

  _refreshReports() {
    this.props.api.Users.getReports(this.props.user.id).then((data) =>
      this.setState(data)
    );
  }

  render() {
    return (
      <div>
        <h3>Features</h3>

        <UserFeatures user={this.props.user} api={this.props.api} />

        <hr className="Divider" />

        <UserPaymentsTable userId={this.props.user.id} />
        <hr className="Divider" />

        <h3>Received reports</h3>
        {this._renderReportStats(this.state.subject_reports)}
        {this._renderReports(this.state.subject_reports)}

        <h3>Created reports</h3>
        {this._renderReportStats(this.state.actor_reports)}
        {this._renderReports(this.state.actor_reports)}

        <hr className="Divider" />

        {this._renderBlocks()}

        <hr className="Divider" />

        {this._renderStats()}
      </div>
    );
  }

  _renderReports(reports) {
    if (reports && reports.length > 0) {
      return (
        <table className="UserTabLogs-table">
          <thead>
            <tr>
              <th width="1">Time</th>
              <th width="1"></th>
              <th>Activity</th>
              <th style={{ width: 140 }}>Reports / Actions</th>
            </tr>
          </thead>
          <tbody>
            {reports.map((report) => (
              <EventReportUser
                key={report.id}
                api={this.props.api}
                type="report/user"
                subject_id={report.user.id}
                created_by={report.actor}
                onUpdate={(value) => {
                  this._refreshReports();
                  this.props.onUpdate(value);
                }}
                {...report}
              />
            ))}
          </tbody>
        </table>
      );
    } else {
      return <p>No reports...</p>;
    }
  }

  _renderReportStats(reports) {
    if (reports === undefined || reports.length === 0) return;

    const countConfirmed = reports.filter(
      (r) => r.status === "confirmed"
    ).length;
    const countInfirmed = reports.filter((r) => r.status === "infirmed").length;
    const countIgnored = reports.filter((r) => r.status === "ignored").length;

    return (
      <div>
        Confirmed: {Math.round((countConfirmed / reports.length) * 100)}%.
        Infirmed: {Math.round((countInfirmed / reports.length) * 100)}%.
        Ignored: {Math.round((countIgnored / reports.length) * 100)}%
      </div>
    );
  }

  _renderBlocks() {
    return (
      <div className="row">
        <div className="col-md-6">
          <BlockedUsers api={this.props.api} user={this.props.user} />
        </div>

        <div className="col-md-6">
          <BlockedBy api={this.props.api} user={this.props.user} />
        </div>
      </div>
    );
  }

  _renderStats() {
    const d = this.state;

    if (d.stats === undefined) {
      return <p className="UserTabStats">Loading...</p>;
    }

    return (
      <div>
        <h3>Stats</h3>
        <table className="UserTabStats">
          <thead>
            <tr>
              <td width="1" />
              <td width="1" />
              <td>Last 7 days</td>
              <td>Last 28 days</td>
              <td>All time</td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td colSpan="2">Time in-app</td>
              <td>
                <Duration d={d.stats.d7.session_seconds} />
              </td>
              <td>
                <Duration d={d.stats.d28.session_seconds} />
              </td>
              <td>
                <Duration d={d.stats.all.session_seconds} />
              </td>
            </tr>

            <tr>
              <td rowSpan="3">Sent</td>
              <td>Views</td>
              <td>{d.discovery.sender.d7.view_count}</td>
              <td>{d.discovery.sender.d28.view_count}</td>
              <td>{d.discovery.sender.all.view_count}</td>
            </tr>

            <tr>
              <td>Skips</td>
              <td>{d.discovery.sender.d7.skip_count}</td>
              <td>{d.discovery.sender.d28.skip_count}</td>
              <td>{d.discovery.sender.all.skip_count}</td>
            </tr>

            <tr>
              <td>Waves</td>
              <td>{d.discovery.sender.d7.like_count}</td>
              <td>{d.discovery.sender.d28.like_count}</td>
              <td>{d.discovery.sender.all.like_count}</td>
            </tr>

            <tr>
              <td rowSpan="3">Received</td>
              <td>Views</td>
              <td>{d.discovery.receiver.d7.view_count}</td>
              <td>{d.discovery.receiver.d28.view_count}</td>
              <td>{d.discovery.receiver.all.view_count}</td>
            </tr>

            <tr>
              <td>Skips</td>
              <td>{d.discovery.receiver.d7.skip_count}</td>
              <td>{d.discovery.receiver.d28.skip_count}</td>
              <td>{d.discovery.receiver.all.skip_count}</td>
            </tr>

            <tr>
              <td>Waves</td>
              <td>{d.discovery.receiver.d7.like_count}</td>
              <td>{d.discovery.receiver.d28.like_count}</td>
              <td>{d.discovery.receiver.all.like_count}</td>
            </tr>

            <tr>
              <td colSpan="2">Connections</td>
              <td>{d.stats.d7.connection_count}</td>
              <td>{d.stats.d28.connection_count}</td>
              <td>{d.stats.all.connection_count}</td>
            </tr>

            <tr>
              <td colSpan="2">Messages</td>
              <td>{d.stats.d7.message_count}</td>
              <td>{d.stats.d28.message_count}</td>
              <td>{d.stats.all.message_count}</td>
            </tr>

            <tr>
              <td colSpan="2">Comments</td>
              <td>{d.stats.d7.comment_count}</td>
              <td>{d.stats.d28.comment_count}</td>
              <td>{d.stats.all.comment_count}</td>
            </tr>

            <tr>
              <td colSpan="2">Likes</td>
              <td>{d.stats.d7.like_count}</td>
              <td>{d.stats.d28.like_count}</td>
              <td>{d.stats.all.like_count}</td>
            </tr>

            <tr>
              <td colSpan="2">Posts</td>
              <td>{d.stats.d7.post_count}</td>
              <td>{d.stats.d28.post_count}</td>
              <td>{d.stats.all.post_count}</td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
}

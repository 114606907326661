import React, { Component } from "react";

import PropTypes from "prop-types";

export default class UserClearPhoneButton extends Component {
  static propTypes = {
    api: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
  };

  render() {
    const user = this.props.user;

    return (
      <button
        className="UserClearPhoneButton"
        disabled={user.phone_number === null}
        onClick={() => {
          if (window.confirm("Are you sure?")) this.onClear();
        }}
      >
        remove
      </button>
    );
  }

  onClear(e) {
    this.props.api.Users.clearPhone(this.props.user.id).then(() => {
      window.location.reload();
    });
  }
}

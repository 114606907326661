import React from "react";
import ReportActions from "../../../../../../components/ReportActions";
import DefaultRow from "../DefaultRow";

export default class RoomReportedRow extends DefaultRow {
  static action = "room_reported";
  static label = "Room Reported";
  static report = true;

  _notes() {
    const {
      report: { notes },
    } = this.props;

    return (
      <>
        {this._firstName()} reported the room.
        {notes && <blockquote>{notes}</blockquote>}
      </>
    );
  }

  _actions() {
    const {
      report: { report_type, subject_id, reason, status },
      api,
    } = this.props;

    return (
      <ReportActions
        api={api}
        reportType={report_type}
        subjectID={subject_id}
        reason={reason}
        statuses={status}
        onUpdate={this.onUpdate}
      />
    );
  }
}

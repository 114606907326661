import { useQuery } from "react-query";
import Cookies from "universal-cookie";

const usePodShare = (podId) => {
  const cookies = new Cookies();
  const xsrf_token = cookies.get("XSRF-TOKEN");

  const fetchShareUrl = async () => {
    const url = new URL(
      `${process.env.REACT_APP_API_HOST}/admin/audio_rooms/${podId}/share`
    );

    const response = await fetch(url, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-XSRF-Token": xsrf_token,
      },
    });

    const data = await response.json();

    return data.url;
  };

  return useQuery(["podShare", podId], fetchShareUrl);
};

export default usePodShare;

import Base from "../Base";

export default class Authors extends Base {
  index(params) {
    const q = this._query(params);

    return this._fetch(`/admin/seo/authors?${q}`)
      .then((response) => response.json())
      .then((data) => [data.users]);
  }

  update(id, params) {
    return this._fetch(`/admin/seo/authors/${id}`, {
      method: "PATCH",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(params),
    });
  }

  show(id) {
    return this._fetch(`/admin/seo/authors/${id}`).then((response) =>
      response.json()
    );
  }
}

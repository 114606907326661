import { GoogleOAuthProvider, GoogleLogin } from "@react-oauth/google";
import React from "react";

const Authentication = ({ api: { Session: sessionApi }, onLogin }) => {
  const handleSuccess = (response) => {
    const { credential: token } = response;

    sessionApi.create(token).then((response) => {
      onLogin();
    });
  };

  return (
    <section className={`Authentication ${process.env.REACT_APP_ENV_NAME}`}>
      <div>
        <img
          src="/auth_logo.png"
          alt="Login to Peanut Admin"
          className="AuthLogo"
        />

        <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
          <GoogleLogin
            onSuccess={handleSuccess}
            onError={() => {
              console.log("Login Failed");
            }}
            size="large"
            logo_alignment="center"
            useOneTap={false}
            shape="pill"
          />
        </GoogleOAuthProvider>
      </div>
    </section>
  );
};

export default Authentication;

import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

const AlertDialog = ({ open, onCloseModal, onSubmit }) => {
  const handleOnSubmit = () => {
    onSubmit();
    onCloseModal();
  };
  return (
    <div>
      <Dialog open={open} onClose={onCloseModal}>
        <DialogTitle id="alert-dialog-title">Are you sure?</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            If the user has broken this guideline they will be automatically
            banned.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => handleOnSubmit()}
            variant="contained"
            color="secondary"
            autoFocus
          >
            Confirm
          </Button>
          <Button onClick={() => onCloseModal()} variant="contained">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default AlertDialog;

import React, { Component } from "react";
import PropTypes from "prop-types";

import ModalForm from "./ModalForm";
import moment from "moment";

export default class ExperimentForm extends Component {
  static propTypes = {
    api: PropTypes.object.isRequired,
    onCancel: PropTypes.func.isRequired,
    onComplete: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      name: moment().format("YYYYMM-"),
      client: true,
    };
  }

  render() {
    return (
      <ModalForm
        className=""
        title="Create a new experiment"
        onCancel={this.props.onCancel}
        form={
          <React.Fragment>
            <label>Name</label>
            <input
              type="text"
              className="form-control"
              value={this.state.name}
              onChange={this._onNameFieldChange.bind(this)}
              disabled={this.state.sent}
              ref={(e) => {
                e && e.focus();
              }}
            />
            <div className="ExperimentFormHint">
              Use format: YYYYMM-name-of-experiment
            </div>

            <label className="mt-3 mb-0" for="experimentFormClient">
              Client
            </label>
            <input
              id="experimentFormClient"
              type="checkbox"
              className="form-checkbox ml-2"
              checked={this.state.client}
              onChange={this._onClientFieldChange.bind(this)}
              disabled={this.state.sent}
            />
            <div className="ExperimentFormHint">
              Client experiments will be available on the clients.
            </div>
          </React.Fragment>
        }
        actions={
          <React.Fragment>
            <span>{this.state.response}</span>
            <button
              type="button"
              className="btn btn-primary"
              onClick={this._onSubmit.bind(this)}
            >
              Save
            </button>
          </React.Fragment>
        }
      />
    );
  }

  _onNameFieldChange(event) {
    const value = event.target.value;
    this.setState({ name: value });
  }

  _onClientFieldChange(event) {
    const value = event.target.checked;
    this.setState({ client: value });
  }

  _onSubmit() {
    const params = {
      name: this.state.name,
      client: this.state.client,
    };

    this.props.api.Experiments.create({ experiment: params })
      .then((data) => {
        const experimentId = data.experiment.id;
        this.props.onCancel();
        this.props.onComplete(experimentId);
      })
      .catch((e) => {
        console.error(e);
        this.setState({ response: "Save failed!" });
      });
  }
}

import React, { Component } from "react";
import PropTypes from "prop-types";

import SearchResults from "./SearchResults";
import UserPhotoRow from "./UserPhotoRow";
import UserPhotosResetSelfieButton from "./UserPhotosResetSelfieButton";

export default class UserTabPhotos extends Component {
  static propTypes = {
    api: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    onUpdate: PropTypes.func.isRequired,
  };

  render() {
    return (
      <div>
        <UserPhotosResetSelfieButton
          onComplete={this.props.onUpdate}
          api={this.props.api.Users}
          user={this.props.user}
        />

        <SearchResults
          api={this.props.api.UserPhotos}
          query={{ user_id: this.props.user.id }}
        >
          {(results) => {
            const sortedItems = results.items().sort((a, b) => {
              return b.selfie - a.selfie;
            });

            return (
              <table className="UserTabPhotos">
                <thead>
                  <UserPhotoRow.Headers />
                </thead>
                <tbody>
                  {sortedItems.map((d) => (
                    <UserPhotoRow
                      key={d.id}
                      api={this.props.api}
                      onUpdate={this.props.onUpdate}
                      data={d}
                    />
                  ))}
                </tbody>
                <tfoot>
                  <tr>
                    <td>
                      <results.AutoLoad />
                    </td>
                  </tr>
                </tfoot>
              </table>
            );
          }}
        </SearchResults>
      </div>
    );
  }
}

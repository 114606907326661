import React from "react";
import PropTypes from "prop-types";

import Moment from "./Moment";
import UserLink from "./UserLink";

export default class ReferralRow extends React.Component {
  static propTypes = {
    user: PropTypes.object.isRequired,
    created_at: PropTypes.string.isRequired,
    paid_at: PropTypes.string,
  };

  static Headers = () => (
    <tr>
      <th>Referred at</th>
      <th>User</th>
      <th>Payment</th>
    </tr>
  );

  render() {
    const r = this.props;

    return (
      <tr>
        <td>
          <Moment time={r.created_at} utc format="D MMM YYYY, HH:mm z" />
        </td>
        <td>
          <UserLink inline {...r.user} />
        </td>
        <td>{r.paid_at && <Moment time={r.paid_at} />}</td>
      </tr>
    );
  }
}

import React, { Component } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

import Moment from "./Moment";

export default class ConversationRow extends Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    handler: PropTypes.func.isRequired,
    reported: PropTypes.bool.isRequired,
    abuse: PropTypes.array,
  };

  static Headers = () => (
    <tr>
      <td>ID</td>
      <td>Started</td>
      <td>Updated</td>
      <td>Unread Messages</td>
      <td>Participants</td>
      <td>Status</td>
    </tr>
  );

  render() {
    const p = this.props;

    return (
      <tr className="ConversationRow" key={p.id}>
        <td>
          <Link to={`/conversations/${p.id}`}>#{p.id.substring(0, 8)}</Link>
        </td>
        <td>
          <Moment time={p.created_at} />
        </td>
        <td>
          <Moment time={p.updated_at} />
        </td>
        <td>
          {p.unread_count} / {p.messages}
        </td>
        <td>
          <ul>
            {p.users &&
              p.users.map((u) => (
                <li key={u.id}>
                  <Link to={`/users/${u.id}`}>{u.first_name}</Link>
                </li>
              ))}
          </ul>
        </td>

        <td>
          {p.hidden && <span className="ConversationRow-label">hidden</span>}
          {p.muted && <span className="ConversationRow-label">muted</span>}
          {p.deleted && <span className="ConversationRow-label">deleted</span>}
          {p.reported && (
            <span className="ConversationRow-label">reported</span>
          )}
          {p.abuse &&
            p.abuse.map((a) => (
              <span className="ConversationRow-label ConversationRow-label--alert">
                {a}
              </span>
            ))}
        </td>
      </tr>
    );
  }
}

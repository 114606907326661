import React, { Component } from "react";
import PropTypes from "prop-types";
import ModalForm from "./ModalForm";

export default class PostShareForm extends Component {
  static propTypes = {
    api: PropTypes.object.isRequired,
    postID: PropTypes.number.isRequired,
    onCancel: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = { deeplink: null };
  }

  componentDidMount() {
    if (this.state.deeplink) return;

    this.props.api.Posts.share(this.props.postID).then((data) => {
      this.setState({ deeplink: data.url });
    });
  }

  onInputChange(e) {
    e.preventDefault();
  }

  render() {
    if (!this.state.deeplink) return <React.Fragment />;

    return (
      <ModalForm
        title="Public post share link"
        onCancel={this.props.onCancel}
        form={
          <input
            className="form-control"
            value={this.state.deeplink}
            onChange={this.onInputChange.bind(this)}
          />
        }
      />
    );
  }
}

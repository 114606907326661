import React, { Component } from "react";
import PropTypes from "prop-types";

import classnames from "classnames";

import h from "./helpers";

export default class ReportUserPhoto extends Component {
  static propTypes = {
    api: PropTypes.object.isRequired,
    type: PropTypes.string.isRequired,
    subject_id: PropTypes.number.isRequired,
    status: PropTypes.string.isRequired,

    url: PropTypes.string.isRequired,
    created_at: PropTypes.string.isRequired,
    reason: PropTypes.string,
    notes: PropTypes.string,
    created_by: PropTypes.shape({
      id: PropTypes.number.isRequired,
      first_name: PropTypes.string.isRequired,
    }).isRequired,

    onUpdate: PropTypes.func,
    onToggleOptions: PropTypes.func,
  };

  static type = "report/user_photo";
  static icon = "⚠️🌄";

  constructor(props) {
    super(props);
    this._photoLink = this._photoLink.bind(this);
  }

  _photoLink() {
    const { url, subject_id } = this.props;
    if (url) {
      return (
        <a
          href={url.replace("{width}x{height}", "full")}
          target="_blank"
          rel="noopener noreferrer"
        >
          #{subject_id}
        </a>
      );
    } else {
      return <span>#{subject_id}</span>;
    }
  }

  render() {
    const p = this.props;

    return (
      <tr
        className={classnames(
          "EventsRow",
          "EventsReportUserPhoto",
          "EventsRow--" + (p.status === "open" ? "open" : "closed")
        )}
        onMouseOver={() => p.onToggleOptions(true)}
      >
        {h.sharedColumns(this)}
        <td>
          {h.renderActor(p.created_by)} reports photo {this._photoLink()}{" "}
          {h.renderNotes(p.notes, p.reason)}
        </td>
      </tr>
    );
  }
}

import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import classnames from "classnames";

export default class PublishedPostsRow extends React.Component {
  static propTypes = {
    post: PropTypes.object.isRequired,
  };

  static Headers = () => (
    <tr>
      <th>Post</th>
      <th>Public URL</th>
      <th className="PublishedPostsRow--published">Consent</th>
      <th className="PublishedPostsRow--actions">Actions</th>
    </tr>
  );

  copyToClipboard(url, e) {
    navigator.clipboard.writeText(url);
  }

  render() {
    const domainPath = {
      Development: "http://localhost:3002",
      Staging: "https://www.staging.peanut-app.io",
      Production: "https://www.peanut-app.io",
    };

    const postURL = `${domainPath[process.env.REACT_APP_ENV_NAME]}/posts/${
      this.props.post.uid
    }`;
    const consentStatus =
      this.props.post.publication_consent &&
      this.props.post.publication_consent.status;

    let badgeStatus;

    if (!consentStatus) {
      badgeStatus = "badge-secondary";
    } else if (consentStatus === "expired") {
      badgeStatus = "badge-warning";
    } else {
      badgeStatus = "badge-success";
    }

    return (
      <tr>
        <td>
          <Link to={`/posts/${this.props.post.id}`}>
            {this.props.post.title}
          </Link>
        </td>

        <td>
          <a href={postURL}>{postURL}</a>
        </td>

        <td>
          <span className={classnames("badge", badgeStatus)}>
            {consentStatus ? consentStatus : `Automatically Published`}
          </span>
        </td>

        <td>
          <button
            className="btn btn-primary btn-sm nobreak"
            onClick={this.copyToClipboard.bind(this, postURL)}
          >
            Copy link
          </button>
        </td>
      </tr>
    );
  }
}

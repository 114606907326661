import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";

import { Grid } from "@material-ui/core";

import CheckboxFilterItem from "./CheckboxFilterItem";

const CheckboxGroupFilter = ({ includeAll, items, onChange }) => {
  const [allChecked, setAllChecked] = useState(false);

  const shouldAllBeChecked = useCallback(() => {
    const checkedItemsCount = Object.values(items).filter(
      (value) => value.checked
    ).length;
    setAllChecked(checkedItemsCount === 0);
  }, [items]);

  useEffect(() => {
    shouldAllBeChecked();
  }, [items, shouldAllBeChecked]);

  const handleAllChange = (_name, checked) => {
    const updatedItems = Object.keys(items).reduce((result, itemKey) => {
      result[itemKey] = { ...items[itemKey], checked: !checked };
      return result;
    }, {});
    setAllChecked(checked);
    onChange(updatedItems);
  };

  const handleChange = (name, checked) => {
    const updatedItems = { ...items };
    updatedItems[name] = { ...items[name], checked };

    shouldAllBeChecked();
    onChange(updatedItems);
  };

  const itemKeys = Object.keys(items);

  return (
    <Grid item container>
      {includeAll && (
        <CheckboxFilterItem
          key={`checkbox_filter_item_all`}
          name={"all"}
          label="All"
          checked={allChecked}
          onChange={handleAllChange}
        />
      )}
      {itemKeys &&
        itemKeys.map((itemKey) => (
          <CheckboxFilterItem
            key={`checkbox_filter_item_${itemKey}`}
            name={itemKey}
            {...items[itemKey]}
            onChange={handleChange}
          />
        ))}
    </Grid>
  );
};

CheckboxGroupFilter.propTypes = {
  values: PropTypes.objectOf(
    PropTypes.shape({
      label: PropTypes.string,
      checked: PropTypes.bool,
    })
  ),
  onChange: PropTypes.func.isRequired,
};

CheckboxGroupFilter.defaultProps = {
  values: [],
  onChange: () => {},
};

export default CheckboxGroupFilter;
import React from "react";
import PropTypes from "prop-types";

import SearchResults from "../../components/SearchResults";
import ExperimentInput from "../../components/ExperimentInput";

class SEOTagRow extends React.Component {
  static propTypes = {
    data: PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      title: PropTypes.string,
      meta_description: PropTypes.string,
    }).isRequired,

    api: PropTypes.object.isRequired,
  };

  static Headers = (tags) => (
    <tr>
      <th>Name</th>
      <th>Title</th>
      <th>Meta description</th>
    </tr>
  );

  constructor(props) {
    super(props);
    this.state = { data: this.props.data };
  }

  render() {
    const t = this.state.data;

    return (
      <tr>
        <td>{t.name}</td>
        <td>
          <ExperimentInput
            value={t.title}
            onKeyPress={this._updateTitle.bind(this)}
          />
        </td>
        <td>
          <ExperimentInput
            value={t.meta_description}
            onKeyPress={this._updateMetaDescription.bind(this)}
          />
        </td>
      </tr>
    );
  }

  _updateTitle(value) {
    this.props.api.SEO.Tags.update(this.state.data.id, { title: value }).then(
      (data) => this.setState({ data: data.tag })
    );
  }

  _updateMetaDescription(value) {
    this.props.api.SEO.Tags.update(this.state.data.id, {
      meta_description: value,
    }).then((data) => this.setState({ data: data.tag }));
  }
}

export default class SEOTags extends React.Component {
  static propTypes = {
    api: PropTypes.object,
  };

  render() {
    return (
      <div className={`SEOTags`}>
        <div className="SEOTags-heading">
          <h1>SEO ▸ Tags</h1>
        </div>

        <SearchResults api={this.props.api.SEO.Tags} query={{}}>
          {(results) => (
            <table className="SEOTags-table">
              <thead>
                <SEOTagRow.Headers />
              </thead>
              <tbody>
                {results.items().map((x) => (
                  <SEOTagRow key={x.id} data={x} api={this.props.api} />
                ))}
              </tbody>
            </table>
          )}
        </SearchResults>
      </div>
    );
  }
}

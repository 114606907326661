import React, { Component } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import { parse as uriTemplate } from "uritemplate";

export default class Image extends Component {
  static propTypes = {
    url: PropTypes.string,
    width: PropTypes.number,
    className: PropTypes.string,
    openInNewTab: PropTypes.bool,
  };

  static defaultProps = {
    openInNewTab: false,
  };

  constructor(props) {
    super(props);
    this._onImageClick = this._onImageClick.bind(this);
  }

  _onImageClick() {
    const { url } = this.props;
    console.log("url :", url);
    window.open(url.replace("{width}", "full"), "_blank");
  }

  render() {
    const w = this.props.width || 240;
    const url = this.props.url
      ? uriTemplate(this.props.url).expand({ width: w })
      : null;

    const hasImage = url && url !== "none";
    const shouldOpenInNewTab = this.props.openInNewTab && hasImage;

    return (
      <div
        className={classnames("Image", this.props.className)}
        style={{
          backgroundImage: url && `url('${url}')`,
          cursor: shouldOpenInNewTab ? "pointer" : "auto",
        }}
        onClick={shouldOpenInNewTab ? this._onImageClick : null}
      >
        {!hasImage && "no image"}
      </div>
    );
  }
}

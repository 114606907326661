import React, { Component } from "react";
import PropTypes from "prop-types";

import ModalForm from "./ModalForm";

export default class PostSensitiveFlagForm extends Component {
  static propTypes = {
    api: PropTypes.object.isRequired,
    sensitive: PropTypes.bool.isRequired,
    onCancel: PropTypes.func.isRequired,
    onComplete: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = { messageBody: "" };
  }

  render() {
    const sensitive = !!this.props.sensitive;

    return (
      <ModalForm
        className="PostSensitiveFlagForm"
        title="Change post sensitive setting"
        onCancel={this.props.onCancel}
        form={
          <React.Fragment>
            <label>Reason</label>
            <input
              className="form-control"
              value={this.state.messageBody}
              onChange={this._onFieldChange.bind(this)}
              ref={(e) => {
                e && e.focus();
              }}
            />
          </React.Fragment>
        }
        actions={
          <button
            type="button"
            className="btn btn-primary"
            disabled={this.state.messageBody.length < 1}
            onClick={this._onSubmit.bind(this, !sensitive)}
          >
            {sensitive ? "Remove sensitive mark" : "Mark as sensitive"}
          </button>
        }
      />
    );
  }

  _onFieldChange(event) {
    const value = event.target.value;
    this.setState({ messageBody: value });
  }

  _onSubmit(sensitive) {
    this.props.api.Posts.update({
      id: this.props.postID,
      note: this.state.messageBody,
      post: { sensitive: sensitive },
    }).then(() => {
      this.setState({ messageBody: "" });
      this.props.onCancel();
      this.props.onComplete();
    });
  }
}

import React, { Component } from "react";
import PropTypes from "prop-types";
import isEqual from "react-fast-compare";
import Select from "react-select";

import classnames from "classnames";

import RadioGroup from "./RadioGroup";
import CheckGroup from "./CheckGroup";
import CheckBox from "./CheckBox";

// A form that auto-submits on input.
export default class SearchForm extends Component {
  static propTypes = {
    className: PropTypes.string,
    query: PropTypes.object,
    delay: PropTypes.number,
    noauto: PropTypes.bool,
    onSubmit: PropTypes.func.isRequired,
  };

  static defaultProps = {
    className: "",
    query: {},
    delay: 300,
    noauto: false,
  };

  constructor(props) {
    super(props);

    this.state = {
      query: this.props.query,
    };

    this._onFieldChange = this._onFieldChange.bind(this);
    this._onSubmit = this._onSubmit.bind(this);

    // Anonymous components usable in children
    this._handle = {
      Input: (props) => {
        const type_ = props.type || "text";

        return (
          <div className={classnames("Input", props.className)}>
            <label htmlFor={props.name}>{props.label}</label>
            <input
              id={props.name}
              type={type_}
              name={props.name}
              value={this.state.query[props.name]}
              onChange={this._onFieldChange}
            />
          </div>
        );
      },
      Hidden: (props) => {
        return (
          <input
            id={props.name}
            type="hidden"
            name={props.name}
            value={this.state.query[props.name]}
          />
        );
      },
      RadioGroup: (props) => {
        return (
          <RadioGroup
            selected={this.state.query[props.name]}
            onChange={this._onFieldChange}
            {...props}
          />
        );
      },
      CheckGroup: (props) => {
        return (
          <CheckGroup
            selected={this.state.query[props.name]}
            onChange={this._onFieldChange}
            {...props}
          />
        );
      },
      CheckBox: (props) => {
        return (
          <CheckBox
            selected={this.state.query[props.name]}
            onChange={this._onFieldChange}
            {...props}
          />
        );
      },
      MultiSelect: (props) => {
        return (
          <div className={classnames("MultiSelect", props.className)}>
            <label htmlFor={props.name}>{props.title}</label>
            <Select
              isMulti
              classNamePrefix="select"
              onChange={this._onMultiSelectChange.bind(this, props.name)}
              styles={{
                control: (base, state) => {
                  const extra = state.isFocused
                    ? {
                        // reproduce Bootstrap styling
                        borderColor: "#80bdff !important",
                        outline: 0,
                        boxShadow: "0 0 0 0.2rem rgba(0, 123, 255, 0.25)",
                      }
                    : {};
                  return {
                    ...base,
                    ...extra,
                    width: "auto",
                    minWidth: "100px",
                    minHeight: "0px",
                  };
                },
                dropdownIndicator: (base) => ({
                  ...base,
                  padding: "4px",
                }),
                valueContainer: (base) => ({
                  ...base,
                  paddingTop: "1px",
                  paddingBottom: "0px",
                }),
              }}
              {...props}
            />
          </div>
        );
      },
    };
  }

  render() {
    return (
      <form
        className={classnames("SearchForm", this.props.className)}
        onSubmit={this._onSubmit}
      >
        {this.props.children(this._handle)}
        <input type="submit" value="Submit" hidden />
      </form>
    );
  }

  componentDidUpdate(prevProps) {
    if (isEqual(prevProps.query, this.props.query)) {
      return;
    }
    this.setState({ query: this.props.query });
  }

  _onFieldChange(event) {
    const field = event.target.name;
    const value = event.target.value;

    const q = Object.assign({}, this.state.query, { [field]: value });
    this.setState({ query: q }, this._schedSubmit);
  }

  _onMultiSelectChange(field, list) {
    const value = list.map((p) => p.value).join(",");
    const q = Object.assign({}, this.state.query, { [field]: value });
    this.setState({ query: q }, this._schedSubmit);
  }

  _schedSubmit() {
    if (this.props.noauto) {
      return;
    }
    clearTimeout(this._timer);
    this._timer = setTimeout(this._onSubmit, this.props.delay);
  }

  _onSubmit(e) {
    clearTimeout(this._timer);
    if (e !== undefined) {
      e.preventDefault();
    }

    this.props.onSubmit(this.state.query);
  }
}

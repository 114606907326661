import Base from "./Base";

export default class Reports extends Base {
  index(params = {}) {
    const q = this._query(params);
    return this._fetch(`/admin/reports/?${q}`, {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    }).then((response) => response.json());
  }

  update({ reportType, subjectID, reason, status }) {
    const params = {
      subject_id: subjectID,
      report_type: reportType,
      reason: reason,
      status: status,
    };

    return this._fetch(`/admin/reports`, {
      method: "PATCH",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(params),
    });
  }

  ignore({ userID }) {
    const params = { user_id: userID };

    return this._fetch("/admin/reports/ignore", {
      method: "PATCH",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(params),
    });
  }
}

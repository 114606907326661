import Cookies from "universal-cookie";

let counter = 0;

export default class Base {
  constructor(options = {}) {
    this.host = process.env.REACT_APP_API_HOST;
    this.onError = options.onError || this._noop;
    this.onStart = options.onStart || this._noop;
    this.onStop = options.onStop || this._noop;
  }

  _noop() {}

  _cookie(name) {
    const cookies = new Cookies();
    return cookies.get(name);
  }

  _compact(object) {
    return Object.keys(object).reduce((result, key) => {
      const value = object[key];

      if (value !== undefined && value !== null) {
        result[key] = value;
      }
      return result;
    }, {});
  }

  _query(params) {
    const esc = encodeURIComponent;
    return Object.keys(params)
      .filter((k) => esc(params[k]).length > 0)
      .map((k) => `${esc(k)}=${esc(params[k])}`)
      .join("&");
  }

  _fetch(url, params = {}) {
    const token = this._cookie("XSRF-TOKEN");

    params = Object.assign(
      {},
      {
        method: "GET",
        credentials: "include",
      },
      params
    );

    if (token && token.length > 0) {
      params.headers = Object.assign({}, params.headers, {
        "X-XSRF-Token": token,
      });
    }

    const key = ++counter;
    this.onStart(key);

    const promise = fetch(this.host + url, params)
      // fake latency
      // .then((response) => {
      //   return new Promise((resolve, reject) => {
      //     setTimeout(() => resolve(response), 200);
      //   })
      // })

      .then((response) => {
        this.onStop(key);

        if (response.status === 401) {
          return this._handleError(
            "unauthorized",
            "Authentication error. Please log back in"
          );
        }
        if (response.status === 403) {
          return this._handleError(key, "Forbidden. Insufficient priviliges.");
        }
        if (response.status >= 500) {
          return this._handleError(key, "Server error");
        }
        if (response.status >= 200 && response.status <= 299) {
          return response;
        }

        if (
          params.passErrors &&
          params.passErrors.indexOf(response.status) >= 0
        ) {
          return response;
        }

        const errorMessage = `Client error (${response.status})`
        return response.json().then((data) => {
          return this._handleError(key, data.errors ? `${errorMessage}: ${data.errors}` : errorMessage);
        });
      });

    return promise;
  }

  _handleError(key, message) {
    console.error(`request ${key} errored with "${message}"`);
    this.onError(key, message);
    return Promise.reject(message);
  }

  // _fetchWithTimeout(resource, init) {
  //   const controller = new AbortController();
  //   init.signal = controller.signal;
  //
  //   setTimeout(() => controller.abort(), 5000);
  //   fetch(resource, init);
  // }
}

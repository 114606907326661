import { useQuery } from "react-query";
import Cookies from "universal-cookie";

const useConnections = (userId, limit) => {
  const cookies = new Cookies();
  const xsrf_token = cookies.get("XSRF-TOKEN");

  const fetchConnections = async () => {
    const url = new URL(
      `${process.env.REACT_APP_API_HOST}/admin/users/${userId}/connections`
    );

    if (limit) {
      url.searchParams.append("limit", limit);
    }

    const response = await fetch(url, {
      method: "GET",
      credentials: "include",
      cors: "no-cors",
      headers: {
        "Content-Type": "application/json",
        "X-XSRF-Token": xsrf_token,
      },
    });

    const { connections } = await response.json();

    return connections;
  };

  return useQuery(["data", userId, limit], fetchConnections);
};

export default useConnections;

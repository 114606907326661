import { useMutation } from "react-query";
import Cookies from "universal-cookie";

const cookies = new Cookies();
const xsrf_token = cookies.get("XSRF-TOKEN");
const url = new URL(`${process.env.REACT_APP_API_HOST}/admin/test/waves`);

const submitWaves = (userId) => {
  return fetch(url, {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      "X-XSRF-Token": xsrf_token,
    },
    body: JSON.stringify({
      user_id: userId,
    }),
  });
};
const useCreateWave = (userId) => {
  return useMutation(() => submitWaves(userId), {
    onSuccess: async (response, variables, context) => {
      console.log(response, variables, context);
      if (response.status === 201) {
        alert("Wave creation initiated");
      } else {
        alert("Wave creation failed. Please check logs.");
      }
    },
    onError: (error, variables, context) => {
      console.log(error, variables, context);
    },
  });
};

export default useCreateWave;

import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

import Moment from "./Moment";
import ProgressBar from "./ProgressBar";

export default class CampaignRow extends React.Component {
  static propTypes = {
    api: PropTypes.object.isRequired,
    campaign: PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      send_on: PropTypes.string.isRequired,
      send_at: PropTypes.string.isRequired,
      status: PropTypes.string.isRequired,
      progress: PropTypes.number.isRequired,
      message: PropTypes.shape({
        title: PropTypes.string,
        body: PropTypes.string,
      }).isRequired,
      filters: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number.isRequired,
          type: PropTypes.string.isRequired,
          negated: PropTypes.bool.isRequired,
          value_string: PropTypes.string,
          value_int: PropTypes.number,
          status: PropTypes.string.isRequired,
          progress: PropTypes.number.isRequired,
          user_count: PropTypes.number,
        })
      ).isRequired,
    }).isRequired,
  };

  static Headers = () => (
    <tr>
      <th>ID</th>
      <th>Name</th>
      <th>Date</th>
      <th>Time</th>
      <th>Status</th>
      <th>Progress</th>
      <th>Actions</th>
    </tr>
  );

  render() {
    const p = this.props.campaign;

    if (this.state && this.state.deleted) {
      return null;
    }

    return (
      <tr className="CampaignRow">
        <td>{p.id}</td>
        <td>
          <Link to={`/campaigns/${p.id}`}>{p.name}</Link>
        </td>
        <td>
          <Moment date={p.send_on} format="ddd D MMM YYYY" />
        </td>
        <td>{p.send_at}</td>
        <td>
          <span className={`Campaign-status Campaign-status--${p.status}`}>
            {p.status}
          </span>
        </td>
        <td>
          <ProgressBar value={p.progress / 10000} />
        </td>
        <td>
          <div className="CampaignRow-actions">
            <button
              className="CampaignRow-action"
              onClick={this._onDelete.bind(this)}
              disabled={p.status !== "pending"}
            >
              delete
            </button>
          </div>
        </td>
      </tr>
    );
  }

  _onDelete() {
    this.props.api.Campaigns.destroy(this.props.campaign.id).then(
      this.setState({ deleted: true })
    );
  }
}

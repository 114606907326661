import React from "react";
import { Link } from "react-router-dom";

import HistoryPost from "./HistoryPost";
import h from "./helpers";

export default class HistoryPublishedPost extends HistoryPost {
  static type = "history/published_post";
  static icon = "🌎";

  render() {
    const p = this.props;

    return (
      <tr className="EventHistoryPost">
        {h.sharedColumns(this)}
        <td>
          {h.renderActor(p.created_by, p.userID)} published post{" "}
          <Link to={`/posts/${p.post.id}`}>#{p.post.id}</Link>{" "}
          {h.renderNotes(p.notes)}
        </td>
        <td></td>
      </tr>
    );
  }
}

import React, { Component } from "react";
import PropTypes from "prop-types";

import inflection from "inflection";

import Moment from "./Moment";

export default class CaseStats extends Component {
  static propTypes = {
    api: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = { stats: null };
  }

  componentDidMount() {
    this._refresh();
  }

  render() {
    const s = this.state.stats;

    if (!s) return <div className="CaseStats" />;

    return (
      <div className="CaseStats">
        <div className="CaseStats-stat">
          <div>{s.open_cases.count}</div>
          <div>{inflection.inflect("open case", s.open_cases.count)}</div>
        </div>

        <div className="CaseStats-stat">
          <div>
            <Moment relative time={s.open_cases.oldest_at} nosuffix />
          </div>
          <div>oldest case</div>
        </div>

        <div className="CaseStats-stat">
          <div>{s.recent_cases.day}</div>
          <div>
            {inflection.inflect("case", s.recent_cases.day)}
            {" (past 24h)"}
          </div>
        </div>

        <div className="CaseStats-stat">
          <div>{s.recent_cases.week}</div>
          <div>
            {inflection.inflect("case", s.recent_cases.week)}
            {" (past 7 days)"}
          </div>
        </div>
      </div>
    );
  }

  _refresh() {
    this.props.api.Cases.showStats().then((d) => this.setState({ stats: d }));
  }
}

import React from "react";

import { typeLandingPage } from "../../LandingPageTable";
import { Link } from "react-router-dom";

function LandingPageRow({ data: { id, slug, title, status } }) {
  const url = `${process.env.REACT_APP_MAIN_URL}/l/${slug}`;
  return (
    <tr>
      <td>{title}</td>
      <td>
        {status === "active" ? (
          <a href={url} target="_blank" rel="noreferrer">
            {url}
          </a>
        ) : (
          url
        )}
      </td>
      <td>{status}</td>
      <td>
        <Link to={`/landing-pages/${id}`}>Edit</Link>
      </td>
    </tr>
  );
}

LandingPageRow.propTypes = {
  data: typeLandingPage,
};

export default LandingPageRow;

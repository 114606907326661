import React, { Component } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

export default class Spinner extends Component {
  static propTypes = {
    api: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this._onStart = this._onStart.bind(this);
    this._onStop = this._onStop.bind(this);

    this.state = {
      queries: {},
    };
  }

  componentDidMount() {
    this.props.api.on("start", this._onStart);
    this.props.api.on("stop", this._onStop);
  }

  componentWillUnmount() {
    this.props.api.off("start", this._onStart);
    this.props.api.off("stop", this._onStop);
  }

  render() {
    const isLoading = Object.keys(this.state.queries).length > 0;
    return (
      <div className={classnames("Spinner", isLoading && "Spinner--active")} />
    );
  }

  _onStart(key) {
    const q = { ...this.state.queries };
    q[key] = true;
    this.setState({ queries: q });
  }

  _onStop(key) {
    const q = { ...this.state.queries };
    delete q[key];
    this.setState({ queries: q });
  }
}

import React, { Component } from "react";
import PropTypes from "prop-types";

import ReportListItem from "./components/ReportListItem";

import styles from "./ReportsList.module.scss";

export class ReportsList extends Component {
  static propTypes = {
    api: PropTypes.object.isRequired,
    value: PropTypes.string,
    onChange: PropTypes.func,
    userID: PropTypes.number.isRequired,
    selectInputName: PropTypes.string,
  };

  static defaultProps = {
    onChange: () => {},
    selectInputName: "reportList",
  };

  constructor(props) {
    super(props);
    this.state = {
      reports: null,
      selectedReportId: null,
    };

    this._loadReports = this._loadReports.bind(this);

    this._handleReportSelect = this._handleReportSelect.bind(this);
  }

  componentDidMount() {
    this._loadReports();
  }

  async _loadReports() {
    const { userID } = this.props;
    const { reports } =
      (await this.props.api.Reports.index({ user_id: userID })) || {};

    this.setState({
      reports,
    });
  }

  _handleReportSelect(selectedReportId) {
    const { onChange } = this.props;

    this.setState({
      selectedReportId,
    });

    onChange(selectedReportId);
  }

  render() {
    const { reportSelected, selectInputName } = this.props;
    const { reports, selectedReportId } = this.state;

    return (
      <div className={styles.container}>
        {reports &&
          reports.map((report) => (
            <ReportListItem
              key={`report_${report.id}`}
              {...report}
              onSelect={this._handleReportSelect}
              selected={reportSelected && selectedReportId === report.id}
              selectInputName={selectInputName}
            />
          ))}
        {reports && reports.length === 0 && (
          <div className={styles.noDataContainer}>
            <p>User has no reports</p>
          </div>
        )}
      </div>
    );
  }
}

export default ReportsList;

import Base from "../Base";

export default class Pages extends Base {
  index(params) {
    const q = this._query(params);

    return this._fetch(`/admin/seo/pages?${q}`)
      .then((response) => response.json())
      .then((data) => [data.pages]);
  }

  create(params) {
    return this._fetch(`/admin/seo/pages`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(params),
    }).then((response) => response.json());
  }

  show(id) {
    return this._fetch(`/admin/seo/pages/${id}`).then((response) =>
      response.json()
    );
  }

  update(id, params) {
    return this._fetch(`/admin/seo/pages/${id}`, {
      method: "PATCH",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(params),
    });
  }
}

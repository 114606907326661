import React from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";

import { Avatar, Chip, Tooltip } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Skeleton from "@material-ui/lab/Skeleton";

import StatusDot from "../StatusDot";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  large: {
    width: theme.spacing(20),
    height: theme.spacing(20),
  },
}));

const UserChip = ({
  id,
  avatar_url,
  first_name,
  last_name,
  account_status,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const loaded = !!id;

  const handleClick = () => {
    history.push(`/users/${id}`);
  };

  const displayName = () => {
    return account_status === "disabled"
      ? "Unknown"
      : `${first_name || ""} ${last_name || ""}`;
  };

  const avatarUrl = (width, height) => {
    if (!avatar_url) return;

    return avatar_url
      .replace("{width}x{height}", `${width}x${height}`)
      .replace("{scale_factor}", "2");
  };

  const avatar = loaded ? (
    <Tooltip
      title={
        <React.Fragment>
          <img
            alt={displayName()}
            src={avatarUrl(50, 50)}
            className={classes.large}
          />
        </React.Fragment>
      }
    >
      <Avatar alt={displayName()} src={avatarUrl(400, 400)} />
    </Tooltip>
  ) : (
    <Skeleton variant="circle" />
  );

  const label = loaded ? (
    <>
      {displayName()} <StatusDot account_status={account_status} />
    </>
  ) : (
    <div style={{ width: 100 }}>
      <Skeleton variant="text" />
    </div>
  );

  return (
    <Chip
      avatar={avatar}
      label={label}
      onClick={loaded ? handleClick : undefined}
      className={classes[account_status]}
    />
  );
};

UserChip.propTypes = {
  first_name: PropTypes.string,
  avatar_url: PropTypes.string,
  last_name: PropTypes.string,
  id: PropTypes.number,
  account_status: PropTypes.string,
};

UserChip.defaultProps = {};

export default UserChip;

import React, { Component } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import ModalForm from "./ModalForm";

export default class UserGetAuthTokenButton extends Component {
  static propTypes = {
    api: PropTypes.object.isRequired,
    id: PropTypes.number.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = { token: null };
    this.tokenRef = React.createRef();
  }

  render() {
    return (
      <span
        className={classnames("UserGetAuthTokenButton", this.props.className)}
      >
        <button
          onClick={this._onClick.bind(this)}
          className="UserGetAuthTokenButton-btn"
        >
          Auth token
        </button>

        {this.state.token && (
          <ModalForm
            title="Auth token"
            form={
              <tt className="UserGetAuthTokenButton-token" ref={this.tokenRef}>
                {this.state.token}
              </tt>
            }
            onCancel={this.setState.bind(this, { token: null }, null)}
          />
        )}
      </span>
    );
  }

  _onClick(e) {
    if (this.state.token) {
      return;
    }

    this.props.api.Users.getAuthToken(this.props.id).then((token) => {
      this.setState({ token: token.jwt }, () => {
        const el = this.tokenRef.current;
        window.getSelection().removeAllRanges();
        window.getSelection().selectAllChildren(el);
        document.execCommand("copy");
      });
    });
  }
}

import React, { Component } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

export default class StateButton extends Component {
  static propTypes = {
    current: PropTypes.any,
    value: PropTypes.any,
    className: PropTypes.string,
    onClick: PropTypes.func,
    icon: PropTypes.func.isRequired,
  };

  render() {
    const c = classnames(this.props.className, "StateButton", {
      active: this.props.current === this.props.value,
    });

    // FIXME: disable on click, maybe with a superclass / component:
    // https://stackoverflow.com/a/35316216
    return (
      <button className={c} onClick={this._onClick.bind(this)}>
        {this.props.icon(this.props.value)}
      </button>
    );
  }

  _onClick(e) {
    e.preventDefault();
    this.props.onClick();
  }
}

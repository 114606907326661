import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import useAuthors from "../../hooks/api/useAuthors";
import useCreateAuthor from "../../hooks/api/useCreateAuthor";
import { FormControlLabel, Radio, RadioGroup } from "@material-ui/core";

import styles from "./Authors.module.scss";
import classNames from "classnames";

class SEOPageRow extends React.Component {
  static propTypes = {
    data: PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired,

    api: PropTypes.object.isRequired,
  };

  static Headers = () => (
    <tr>
      <th>Name</th>
      <th>Job Title</th>
      <th>Category</th>
      <th>Medical Expert</th>
      <th className="SEOPages-actions">Actions</th>
    </tr>
  );

  constructor(props) {
    super(props);
    this.state = { data: this.props.data };
  }

  render() {
    const p = this.state.data;
    return (
      <tr>
        <td>{p.name}</td>
        <td>{p.job_title || "N/A"}</td>
        <td>
          {p.medical_specialities.length > 0
            ? p.medical_specialities.map((item) => (
                <span>
                  {item}
                  <br />
                </span>
              ))
            : "N/A"}
        </td>
        <td>{p.medical_expert ? "Yes" : "No"}</td>
        <td className="SEOPages-actions">
          <Link
            className="SEOPages-action"
            to={`/seo/authors/${p.id}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            Edit
          </Link>
          <a className="SEOPages-action" href={p.url} target="_preview">
            Preview
          </a>
        </td>
      </tr>
    );
  }
}

function SEOAuthors({ api }) {
  const [medicalExperts, setMedicalExperts] = React.useState("false");
  const type = "blog";
  const { data } = useAuthors(medicalExperts);
  const { mutate } = useCreateAuthor();

  return (
    <div className={classNames("SEOPages", styles.wrapper)}>
      <div className="SEOPages-heading">
        <h1>SEO ▸ Author pages</h1>
        <button onClick={() => mutate()} className="SEOPages-action">
          Create
        </button>
      </div>
      <div>
        <span>Filter :</span>
        <RadioGroup
          value={medicalExperts}
          onChange={(e) => setMedicalExperts(e.target.value)}
        >
          <FormControlLabel value={"false"} control={<Radio />} label="All" />
          <FormControlLabel
            value={"true"}
            control={<Radio />}
            label="Medical Experts only"
          />
        </RadioGroup>
      </div>
      <table className="SEOPages-table">
        <thead>
          <SEOPageRow.Headers type={type} />
        </thead>
        <tbody>
          {data &&
            data?.map((p) => <SEOPageRow key={p.id} data={p} api={api} />)}
        </tbody>
      </table>
    </div>
  );
}

export default SEOAuthors;

import React from "react";

import SearchForm from "../components/SearchForm";
import SearchResults from "../components/SearchResults";
import SearchHistory from "../components/SearchHistory";
import UserRow from "../components/UserRow";

const validBadges = ["ambassador", "trusted"];

const loginMethods = ["facebook", "google", "apple"];

export default class Users extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      query: {
        first_name: "",
        last_name: "",
        uid: "",
        email: "",
        phone_number: "",
        latlng: "",
        created_since: "",
        created_before: "",
        order: "created_at desc",
      },
    };
  }

  render() {
    return (
      <div className="Users">
        <SearchForm
          query={this.state.query}
          onSubmit={(q) => this.setState({ query: q })}
        >
          {(form) => (
            <React.Fragment>
              <form.Input label="First name" name="first_name" />
              <form.Input label="Last name" name="last_name" />
              <form.Input label="Email" name="email" />
              <form.Input label="Phone" name="phone_number" />
              <form.Input label="Location" name="latlng" />
              <form.Input label="UID" name="uid" />
              <form.Input
                label="Created since"
                name="created_since"
                type="date"
                className="date"
              />
              <form.Input
                label="Created before"
                name="created_before"
                type="date"
                className="date"
              />
              <form.CheckGroup
                name="badges"
                title="Badges"
                options={validBadges}
                labels={validBadges}
              />
              <form.CheckGroup
                name="login_methods"
                title="Login"
                options={loginMethods}
                labels={loginMethods}
              />
              <form.RadioGroup
                name="order"
                title="Order"
                options={["created_at desc", "created_at"]}
                labels={["Newest First", "Oldest First"]}
              />
            </React.Fragment>
          )}
        </SearchForm>

        <SearchHistory
          query={this.state.query}
          onSubmit={(q) => this.setState({ query: q })}
        />

        <SearchResults api={this.props.api.Users} query={this.state.query}>
          {(results) => (
            <table className="Users-table">
              <thead>
                <UserRow.Headers />
              </thead>
              <tbody>
                {results.items().map((x) => (
                  <UserRow key={x.uid} {...x} />
                ))}
              </tbody>
              <tfoot>
                <tr>
                  <td colSpan={UserRow.Columns}>
                    <results.AutoLoad />
                  </td>
                </tr>
              </tfoot>
            </table>
          )}
        </SearchResults>
      </div>
    );
  }
}

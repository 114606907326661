import React from "react";
import PropTypes from "prop-types";

import PaymentRow from "../components/PaymentRow";

export default class Payments extends React.Component {
  static propTypes = {
    api: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = { payments: null };
  }

  componentDidMount() {
    this.props.api.Payments.index().then((data) =>
      this.setState({ payments: data })
    );
  }

  render() {
    if (!this.state.payments) return <React.Fragment />;

    return (
      <div className="Payments">
        <table className="Payments-table">
          <thead>
            <PaymentRow.Headers />
          </thead>
          <tbody>
            {this.state.payments.map((r) => (
              <PaymentRow key={r.user.id} api={this.props.api} {...r} />
            ))}
          </tbody>
        </table>
      </div>
    );
  }
}

import React, { Component } from "react";
import PropTypes from "prop-types";
export class GuidelineSelect extends Component {
  static propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func,
    additionalReasons: PropTypes.arrayOf(PropTypes.string),
  };

  static defaultProps = {
    onChange: () => {},
    additionalReasons: [],
    reasons: [
      "Hateful behavior",
      "Harassment and bullying",
      "Impersonation",
      "Misinformation",
      "Plagiarism",
      "False reporting",
      "Harmful and sexual activities",
      "Illegal activities",
      "Advertising",
    ],
  };

  constructor(props) {
    super(props);
    this.state = { selectedReason: null };

    this._handleOnChange = this._handleOnChange.bind(this);
  }

  _handleOnChange({ target: { value } }) {
    const { onChange } = this.props;

    this.setState({
      selectedReason: value,
    });

    onChange(value);
  }

  render() {
    const { reasons, additionalReasons } = this.props;
    const { selectedReason } = this.state;
    return (
      <>
        <select onChange={this._handleOnChange} className="guidelineSelect">
          <option disabled selected>
            Select a reason
          </option>
          {[...reasons, ...additionalReasons].map((reason) => (
            <option key={reason} selected={selectedReason === reason}>
              {reason}
            </option>
          ))}
        </select>
      </>
    );
  }
}

export default GuidelineSelect;

import React, { useState } from "react";
import PropTypes from "prop-types";

import { Chip, FormControl, FormLabel } from "@material-ui/core";

import CheckboxFilterDialog from "./CheckboxFilterDialog";

const CheckboxFilter = ({ onChange, selectedValues, title, values }) => {
  const [dialogOpen, setDialogOpen] = useState(false);

  const handleDialogOpen = () => {
    setDialogOpen(true);
  };
  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleChange = (updatedValues) => {
    onChange(updatedValues);
  };

  const handleDelete = (key) => {
    const updatedSelectedValues = [...selectedValues];
    const currentIndex = updatedSelectedValues.indexOf(key);

    if (currentIndex !== -1) {
      updatedSelectedValues.splice(currentIndex, 1);
      onChange(updatedSelectedValues);
    }
  };

  return (
    <FormControl component="fieldset" fullWidth>
      <FormLabel component="legend">{title}</FormLabel>

      <div>
        {selectedValues.length === 0 && (
          <Chip color="primary" label="All" onClick={handleDialogOpen} />
        )}
        {selectedValues.map((key) => {
          return (
            <Chip
              color="primary"
              label={values[key]}
              onDelete={() => handleDelete(key)}
            />
          );
        })}
        <Chip label="Select" onClick={handleDialogOpen} variant="outlined" />
      </div>

      <CheckboxFilterDialog
        values={values}
        open={dialogOpen}
        onClose={handleDialogClose}
        onChange={handleChange}
        selectedValues={selectedValues}
        title={title}
      />
    </FormControl>
  );
};

CheckboxFilter.propTypes = {
  onChange: PropTypes.func.isRequired,
  selectedValues: PropTypes.arrayOf(PropTypes.string).isRequired,
  title: PropTypes.string.isRequired,
  values: PropTypes.object.isRequired,
};

CheckboxFilter.defaultProps = {
  onChange: () => {},
  selectedValues: [],
  values: {},
};

export default CheckboxFilter;

import Base from "./Base";

export default class Payments extends Base {
  index(params = {}) {
    const q = this._query(params);

    return this._fetch(`/admin/payments?${q}`)
      .then((response) => response.json())
      .then((data) => data.payments);
  }

  update({ id, ...params }) {
    return this._fetch(`/admin/payments/${id}`, {
      method: "PATCH",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(params),
    }).then((response) => response.json());
  }

  create({ id, ...params }) {
    return this._fetch(`/admin/users/${id}/payments`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(params),
    }).then((response) => response.json());
  }

  preview({ id, ...params }) {
    return this._fetch(`/admin/users/${id}/payments/preview`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(params),
    }).then((response) => response.json());
  }
}

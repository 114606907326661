// eslint-disable-next-line no-unused-vars
import React from "react";

import DefaultRow from "../DefaultRow";

export default class RoomEditedRow extends DefaultRow {
  static action = "room_edited";
  static label = "Room Edited";
  static report = false;

  _notes() {
    const { title, description } = this.props.meta || {};

    return (
      <>
        {this._firstName()} edited the room.
        {title && (
          <p>
            The title changed from: "<strong>{title[0]}</strong>" to "
            <strong>{title[0]}</strong>."
          </p>
        )}
        {description && (
          <p>
            The description changed from: "<strong>{description[0]}</strong>" to
            "<strong>{description[0]}</strong>."
          </p>
        )}
      </>
    );
  }
}

import React, { Component } from "react";
import { Link } from "react-router-dom";
import ellipsize from "ellipsize";

import Moment from "../components/Moment";
import PropTypes from "prop-types";
import UserLink from "./UserLink";
import SeoIndexingButton from "./SeoIndexingButton";
import MetaModal from "../components/MetaModal";
import Image from "../components/Image";

export default class GroupItem extends Component {
  static propTypes = {
    api: PropTypes.object.isRequired,
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    join_mode: PropTypes.string.isRequired,
    post_count: PropTypes.number.isRequired,
    user_count: PropTypes.number.isRequired,
    seo_indexed: PropTypes.bool.isRequired,
    description: PropTypes.string,
    banner_url: PropTypes.string,
    last_post_at: PropTypes.string,
    author: PropTypes.shape({
      id: PropTypes.number,
      first_name: PropTypes.string,
      last_name: PropTypes.string,
      avatar_url: PropTypes.string,
      account_status: PropTypes.string,
    }),
  };

  static Headers = () => (
    <tr>
      <th>ID</th>
      <th className="Cover">Cover</th>
      <th>Name</th>
      <th>Author</th>
      <th>Join mode</th>
      <th>Stats</th>
      <th>SEO</th>
    </tr>
  );

  constructor(props) {
    super(props);

    this.state = {
      metaModalOpen: false,
    };

    this.handleMetaModelOpen = this.handleMetaModelOpen.bind(this);
    this.handleMetaModelClose = this.handleMetaModelClose.bind(this);
    this.handleMetaChanges = this.handleMetaChanges.bind(this);
  }

  handleMetaModelOpen() {
    this.setState({ metaModalOpen: true });
  }
  handleMetaModelClose() {
    this.setState({ metaModalOpen: false });
  }

  async handleMetaChanges(changes) {
    await this.props.api.update({ id: this.props.id, ...changes });
  }

  render() {
    const group = this.props;
    const author = group.author;

    let groupActivityElement;

    if (group.last_post_at !== null) {
      groupActivityElement = <Moment time={group.last_post_at} />;
    } else {
      groupActivityElement = <span>-</span>;
    }

    /* eslint-disable jsx-a11y/accessible-emoji */
    return (
      <tr className="GroupItem">
        <td>{group.id}</td>

        <td>
          {group.banner_url && <Image url={group.banner_url} openInNewTab />}
        </td>
        <td>
          <Link to={`/groups/${group.id}`}>{group.name}</Link>
        </td>

        <td>
          <UserLink {...author} />
        </td>

        <td>
          <span className={`tag-join-mode--${group.join_mode}`}>
            {group.join_mode}
          </span>
        </td>

        <td>
          <ul>
            <li>👩 {group.user_count}</li>
            <li>💬 {group.post_count}</li>
            <li>⏱ {groupActivityElement}</li>
          </ul>
        </td>
        <td>
          <SeoIndexingButton
            api={this.props.api}
            seoIndexedEntityId={group.id}
            seoIndexed={group.seo_indexed}
          />
          <button onClick={this.handleMetaModelOpen}>Meta</button>{" "}
          <MetaModal
            onClose={this.handleMetaModelClose}
            onChange={this.handleMetaChanges}
            title={group.meta_title || group.name}
            description={
              group.meta_description || ellipsize(group.description || "", 320)
            }
            open={this.state.metaModalOpen}
          />
        </td>
      </tr>
    );
  }
}

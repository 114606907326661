import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

import inflection from "inflection";

import ButtonWithConfirmation from "../../components/ButtonWithConfirmation";
import SEOPageCommunity from "../../components/seo/PageCommunity";
import SEOPageEditorial from "../../components/seo/PageEditorial";
import SEOPageBlog from "../../components/seo/PageBlog";

export default class SEOPage extends React.Component {
  static propTypes = {
    api: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = { page: null };
  }

  componentDidMount() {
    this._refresh();
  }

  render() {
    const p = this.state.page;
    const PageComponent = this._PageComponent();
    if (!p || !PageComponent) return <React.Fragment />;

    const url =
      p.type === "editorial"
        ? `${process.env.REACT_APP_MAIN_URL}/editorial/${p.slug}?clearCache=1`
        : `${p.url}?clearCache=1`;

    return (
      <div className="SEOPage">
        <div className="SEOPage-heading">
          <h1 className="SEOPage-title">
            SEO ▸{" "}
            <Link to={`/seo/pages/${p.type}`}>
              {inflection.capitalize(p.type)} pages
            </Link>{" "}
            ▸ #{p.id}
          </h1>

          <ButtonWithConfirmation
            title="Warning"
            message={
              p.status === "pending"
                ? "The page will become visible on the website."
                : "The page will be removed from the website, and links may break."
            }
            onClick={this._setStatus.bind(
              this,
              p.status === "pending" ? "active" : "pending"
            )}
            className="SEOPage-action"
          >
            {inflection.capitalize(p.status)}
          </ButtonWithConfirmation>

          <a className="SEOPage-action" href={url} target="_preview">
            Preview
          </a>
        </div>

        <PageComponent
          page={this.state.page}
          onUpdate={this._refresh.bind(this)}
          api={this.props.api}
        />
      </div>
    );
  }

  _PageComponent() {
    if (!this.state.page) return;

    switch (this.state.page.type) {
      case "community":
        return SEOPageCommunity;
      case "editorial":
        return SEOPageEditorial;
      case "blog":
        return SEOPageBlog;
      default:
        return;
    }
  }

  _refresh() {
    this.props.api.SEO.Pages.show(this.props.match.params.id).then((data) =>
      this.setState({ page: data })
    );
  }

  _setStatus(newStatus) {
    this.props.api.SEO.Pages.update(this.props.match.params.id, {
      status: newStatus,
    }).then(this._refresh.bind(this));
  }
}

import React, { Component } from "react";

import Moment from "../../components/Moment";
import StatusButton from "../../components/StatusButton";
import TerminateButton from "./components/TerminateButton";
import ParticipantsTab from "./components/ParticipantsTab";
import EventsTab from "./components/EventsTab";

import PodPlayback from "./components/PodPlayback";
import PodListener from "./components/PodListener";
import SharePodLink from "../../components/SharePodLink";

import styles from "./LiveAudioRoom.module.scss";
import PickForm from "../../components/PickForm";
import DemoteForm from "../../components/DemoteForm";
import EventRow from "../../components/events/Row";

class LiveAudioRoom extends Component {
  constructor(props) {
    super(props);

    this.state = {
      room: null,
      tab: "events",
      pickOverlay: false,
    };

    this._currentTab = this._currentTab.bind(this);
    this._tabLink = this._tabLink.bind(this);

    this.playerRef = React.createRef();
  }

  componentDidMount() {
    this._loadRoom();
  }

  _loadRoom() {
    const {
      api: { LiveAudioRooms: LiveAudioRoomApi },
      match: {
        params: { id },
      },
    } = this.props;

    LiveAudioRoomApi.show(id).then((data) => {
      this.setState({ room: data });
    });
  }

  _tabLink(tab) {
    const f = (e) => {
      this.setState({ tab: tab });
    };
    const c = this.state.tab === tab ? "active" : "";
    return (
      <a href={`#${tab}`} className={c} onClick={f.bind(this)}>
        {tab}
      </a>
    );
  }

  _currentTab() {
    const { tab } = this.state;

    if (tab === "events")
      return (
        <EventsTab
          api={this.props.api}
          roomId={this.state.room.id}
          playerRef={this.playerRef}
        />
      );

    if (tab === "participants")
      return (
        <ParticipantsTab api={this.props.api} roomId={this.state.room.id} />
      );
  }

  render() {
    if (!this.state.room) {
      return <div>Loading...</div>;
    }

    const {
      room: {
        id,
        title,
        status,
        started_at,
        scheduled_at,
        recording_started_at,
        ended_at,
        peanut_pick,
        peanut_demote,
        history,
        host,
        available,
        group,
        access_token: accessToken,
        access_uid: accessUid,
      },
    } = this.state;

    const { access_token: agoraAccessToken, agora_uid: agoraUid } =
      this.state.room.agora || {};

    return (
      <div className="Post LiveAudioRoom">
        {available && (
          <div className="header">
            <TerminateButton
              api={this.props.api}
              room={this.state.room}
              onComplete={this._loadRoom.bind(this)}
            />
          </div>
        )}

        <h1>{title}</h1>

        <section className={styles.metaContainer}>
          <section className={styles.details}>
            <ul>
              <li>
                <span>Status:</span>{" "}
                <StatusButton status={status === "started" ? "live" : status}>
                  {status === "started" ? "live" : status}
                </StatusButton>
              </li>
              <li>
                <span>Recording started / Scheduled:</span>{" "}
                <Moment
                  time={recording_started_at || scheduled_at || started_at}
                />
              </li>
              <li>
                <span>Group:</span>{" "}
                <a href={`/groups/${group.id}`}>Group: {group.name}</a>
              </li>
              <li>
                <span>Ended:</span>{" "}
                {ended_at ? <Moment time={ended_at} /> : <span>&dash;</span>}
              </li>
              <li>
                <span>Pick</span>
                <StatusButton
                  status={peanut_pick}
                  onClick={this.setState.bind(
                    this,
                    { pickOverlay: true },
                    null
                  )}
                >
                  {peanut_pick === true ? "yes" : "no"}
                </StatusButton>
              </li>
              <li>
                <span>Demote</span>
                <StatusButton
                  status={peanut_demote}
                  onClick={this.setState.bind(
                    this,
                    { demoteOverlay: true },
                    null
                  )}
                >
                  {peanut_demote === true ? "yes" : "no"}
                </StatusButton>
              </li>
              <li>
                <span>Share:</span>
                <SharePodLink id={this.state.room.id} />
              </li>
            </ul>
          </section>

          <section className={styles.player}>
            {status === "ended" && (
              <PodPlayback
                ref={this.playerRef}
                roomId={id}
                accessToken={accessToken}
                uid={accessUid}
                api={this.props.api}
              />
            )}
            {status === "started" && (
              <PodListener
                accessToken={agoraAccessToken}
                agoraUid={agoraUid}
                roomId={id}
              />
            )}
          </section>
        </section>

        <table className="Pod-history">
          <caption>History</caption>
          <thead>
            <EventRow.Headers />
          </thead>
          <tbody>
            {history.map((ev, idx) => (
              <EventRow
                key={idx}
                type="history/live_audio_room"
                api={this.props.api}
                userID={host?.id}
                data={ev}
              />
            ))}
          </tbody>
        </table>

        <section className="User-tabs">
          <nav>
            {this._tabLink("events")}
            {this._tabLink("participants")}
          </nav>
        </section>

        <section>{this._currentTab()}</section>

        {this.state.pickOverlay && (
          <PickForm
            api={this.props.api.LiveAudioRooms}
            apiObject={"live_audio_room"}
            subject={"Pod"}
            id={id}
            isPicked={peanut_pick}
            onCancel={this.setState.bind(this, { pickOverlay: false }, null)}
            onComplete={this._loadRoom.bind(this)}
          />
        )}

        {this.state.demoteOverlay && (
          <DemoteForm
            api={this.props.api.LiveAudioRooms}
            apiObject={"live_audio_room"}
            subject={"Pod"}
            id={id}
            isDemoted={peanut_demote}
            onCancel={this.setState.bind(this, { demoteOverlay: false }, null)}
            onComplete={this._loadRoom.bind(this)}
          />
        )}
      </div>
    );
  }
}

export default LiveAudioRoom;

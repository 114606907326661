import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Player, BigPlayButton } from "video-react";

import Moment from "../../../../components/Moment";
import UserLink from "../../../../components/UserLink";

import ContentAbuseEditor from "../../../../components/events/ContentAbuseEditor";

class VideoSearchResultRow extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showVideo: false,
      reports_metadata: props.reports_metadata,
    };

    this._content = this._content.bind(this);
    this._sourceType = this._sourceType.bind(this);

    this._videoModal = this._videoModal.bind(this);
    this._videoOrientation = this._videoOrientation.bind(this);
    this._onAbuseUpdate = this._onAbuseUpdate.bind(this);
    this._subjectUrl = this._subjectUrl.bind(this);
  }

  _content() {
    const { post, comment } = this.props;

    if (post) {
      return post;
    } else if (comment) {
      return { ...comment, title: `Comment on: ${comment.post_title}` };
    } else {
      return {};
    }
  }

  _sourceType() {
    const { post, comment } = this.props;

    let sourceType = "unknown";

    if (post) {
      sourceType = "Post";
    } else if (comment) {
      sourceType = "Comment";
    }

    return sourceType;
  }

  _videoModal() {
    const { thumbnail_url, url } = this.props;

    return (
      <div className={"videoModel"}>
        <div className="videoModelContent">
          <div className={`videoContainer${this._videoOrientation()}`}>
            <Player playsInline poster={thumbnail_url} src={url}>
              <BigPlayButton position="center" />
            </Player>
          </div>
          <button onClick={() => this.setState({ showVideo: false })}>
            Close
          </button>
        </div>
      </div>
    );
  }

  _videoOrientation() {
    const { width, height } = this.props;

    const VIDEO_LANDSCAPE = "Landscape";
    const VIDEO_PORTRAIT = "Portrait";
    return height > width ? VIDEO_PORTRAIT : VIDEO_LANDSCAPE;
  }

  _onAbuseUpdate(abuse) {
    this.setState({
      reports_metadata: {
        abuse,
        reasons: [],
        system: [],
      },
    });
  }

  _contentAbuseEditor() {
    const { subject_type, api, post, comment } = this.props;
    const { reports_metadata } = this.state;

    let props = { ...reports_metadata };

    if (subject_type === "post") {
      props.api = api.Posts;
      props.subjectID = post.id;
    } else if (subject_type === "comment") {
      props.api = api.Comments;
      props.subjectID = comment.id;
    }

    props.onUpdate = this._onAbuseUpdate;

    return <ContentAbuseEditor {...props} />;
  }

  _subjectUrl() {
    const { id, post, comment } = this.props;

    if (comment) {
      return `/posts/${post.id}/#comment_${comment.id}`;
    } else if (post) {
      return `/posts/${post.id}`;
    } else {
      return `/videos/${id}`;
    }
  }

  render() {
    const {
      id,
      created_at,
      details: { transcript },
      subject_status: subjectStatus,
      user,
    } = this.props;

    const {
      reports_metadata: { abuse, reasons },
    } = this.state;

    const { showVideo } = this.state;
    const { title } = this._content();

    return (
      <tr className={"VideoSearchResultRow"}>
        <td>
          <Link to={`/videos/${id}`}>#{id}</Link>

          <br />
          <Moment time={created_at} />
          <br />

          <span className="badge badge-pill badge-secondary">
            {subjectStatus}
          </span>
        </td>
        <td>
          <div className="videoThumbnail">
            <img
              src={`${this.props.thumbnail_url}`}
              className={`videoThumbnail${this._videoOrientation()} ${abuse.join(
                " "
              )} ${Object.keys(reasons).join(" ")}`}
              onClick={() => {
                this.setState({ showVideo: true });
              }}
              alt="Video Thumbnail"
            />
          </div>
          {showVideo && this._videoModal()}
        </td>
        <td>
          <div className="transcriptContainer">
            <p class="title">
              <Link to={this._subjectUrl()}>#{title}</Link>
            </p>

            {transcript && (
              <blockquote>
                <p>{transcript || "Transcript unavailable"}</p>
              </blockquote>
            )}
          </div>
        </td>
        <td>
          <UserLink {...user} />
        </td>

        <td>{this._contentAbuseEditor()}</td>
      </tr>
    );
  }
}

export default VideoSearchResultRow;

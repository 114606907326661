import React, { Component } from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";

import classnames from "classnames";
import moment from "moment";
import Moment from "../components/Moment";

export default class Home extends Component {
  static propTypes = {
    api: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = { status: null };
  }

  render() {
    return (
      <div className="Home">
        <p>Welcome to Peanut Admin.</p>
        <p className="text-muted">
          Logged in as: {this.props.api.Session.user.username}
        </p>

        <p>Other features:</p>
        <ul>
          <li>
            <NavLink to="/labeling/chat">Chat spam/ham labelling</NavLink>
          </li>
          <li>
            <NavLink to="/labeling">Post spam/ham labelling</NavLink>
          </li>

          <li>
            <NavLink to="/log">Live events log</NavLink>
          </li>
          <li>
            <NavLink to="/published_posts">Recently published posts</NavLink>
          </li>
          <li>
            <NavLink to="/campaigns">Push notification campaigns</NavLink>
          </li>
          <li>
            <NavLink to="/auto_annotations">Misinformation warnings</NavLink>
          </li>
          <li>
            <NavLink to="/comments">Comment search</NavLink>
          </li>
        </ul>

        <p>SEO:</p>
        <ul>
          <li>
            <NavLink to="/seo/posts">Post publication</NavLink>
          </li>
          <li>
            <NavLink to="/seo/pages/community">Community pages</NavLink>
          </li>
          <li>
            <NavLink to="/seo/pages/editorial">Editorial pages</NavLink>
          </li>
          <li>
            <NavLink to="/seo/pages/blog">Blog posts</NavLink>
          </li>
          <li>
            <NavLink to="/seo/authors">Medical Reviewers/Authors</NavLink>
          </li>
          <li>
            <NavLink to="/seo/tags">Tags</NavLink>
          </li>
          <li>
            <NavLink to="/landing-pages">Landing Pages</NavLink>
          </li>
        </ul>

        <p>MVP:</p>
        <ul>
          <li>
            <NavLink to="/payments">MVP Payments</NavLink>
          </li>
          <li>
            <NavLink to="/referrals">Referral stats</NavLink>
          </li>
          <li>
            <NavLink to="/user-referrals">Referrals Log</NavLink>
          </li>
        </ul>
        {this._renderStatus()}
      </div>
    );
  }

  componentDidMount() {
    this.props.api.Status.show().then((data) =>
      this.setState({ status: data })
    );
  }

  _renderStatus() {
    if (!this.state.status) return <React.Fragment />;

    const data = this.state.status.app_updates.latest;
    const appUpdateTime = data && data.created_at;
    const appUpdateOk =
      appUpdateTime &&
      moment(appUpdateTime).isAfter(moment().subtract(2, "weeks"));

    return (
      <React.Fragment>
        <div
          className={classnames(
            `Home-status`,
            `Home-status--${appUpdateOk ? "ok" : "warning"}`
          )}
        >
          {data ? (
            <>
              Latest app update{" "}
              <NavLink to={`/posts/${data.id}`}>{data.title}</NavLink> (
              <Moment time={data.created_at} relative />)
            </>
          ) : (
            <>No app update yet!</>
          )}
        </div>
      </React.Fragment>
    );
  }
}

import React, { Component } from "react";
import PropTypes from "prop-types";

import CheckGroup from "./CheckGroup";
import ModalForm from "./ModalForm";

const validBadges = [
  "ambassador",
  "peanut_pack",
  "staff",
  "trusted",
  "anti-hate",
  "creator",
  "ad_tester",
  "sponsored",
];

export default class UserSetBadgesForm extends Component {
  static propTypes = {
    api: PropTypes.object.isRequired,
    userID: PropTypes.number.isRequired,
    badges: PropTypes.array.isRequired,
    onCancel: PropTypes.func.isRequired,
    onComplete: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      badges: validBadges.filter((b) => this.props.badges.includes(b)),
    };
  }

  render() {
    return (
      <ModalForm
        className="UserSetBadgesForm"
        title="Change user badges"
        onCancel={this.props.onCancel}
        form={
          <CheckGroup
            name="badges"
            labels={validBadges}
            options={validBadges}
            selected={this.state.badges.join(",")}
            onChange={this._onChange.bind(this)}
          />
        }
        actions={
          <button
            type="button"
            className="btn btn-primary"
            onClick={this._onSubmit.bind(this)}
          >
            Save
          </button>
        }
      />
    );
  }

  _onChange(e) {
    const value = e.target.value;
    const badges = value.length > 0 ? value.split(",") : [];
    this.setState({ badges: badges });
  }

  _onSubmit(action) {
    this.props.api.Users.updateBadges({
      id: this.props.userID,
      badges: this.state.badges,
    }).then(() => {
      this.props.onCancel();
      this.props.onComplete();
    });
  }
}

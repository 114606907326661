import Base from "./Base";

export default class UserGroupMemberships extends Base {
  index(params) {
    const q = this._query(params);

    return this._fetch(`/admin/user_group_memberships?${q}`)
      .then((response) => response.json())
      .then((data) => [data.user_group_memberships, data.cursor]);
  }

  update(id, params) {
    return this._fetch(`/admin/user_group_memberships/${id}/`, {
      method: "PATCH",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(params),
    });
  }
}

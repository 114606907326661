import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";

import DialogTitle from "../../../components/DialogTitle";

const CheckboxFilterDialog = ({
  values,
  onChange,
  onClose,
  open,
  title,
  ...props
}) => {
  const [selectedValues, setSelectedValues] = useState(props.selectedValues);

  useEffect(() => {
    setSelectedValues(props.selectedValues);
  }, [props.selectedValues]);

  const handleApply = () => {
    onChange(selectedValues);
    onClose();
  };

  const handleClose = () => {
    onClose();
  };

  const handleListItemClick = (key) => {
    const updatedSelectedValues = [...selectedValues];
    const currentIndex = updatedSelectedValues.indexOf(key);

    if (currentIndex === -1) {
      updatedSelectedValues.push(key);
    } else {
      updatedSelectedValues.splice(currentIndex, 1);
    }

    setSelectedValues(updatedSelectedValues);
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <DialogTitle id="simple-dialog-title" onClose={handleClose}>
        {title}
      </DialogTitle>
      <DialogContent dividers>
        <List>
          {Object.keys(values).map((key) => (
            <ListItem button onClick={() => handleListItemClick(key)} key={key}>
              <ListItemIcon>
                <Checkbox
                  edge="start"
                  checked={selectedValues.indexOf(key) !== -1}
                  tabIndex={-1}
                  disableRipple
                />
              </ListItemIcon>
              <ListItemText primary={values[key]} />
            </ListItem>
          ))}
        </List>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleApply} color="primary">
          Apply
        </Button>
      </DialogActions>
    </Dialog>
  );
};

CheckboxFilterDialog.propTypes = {
  onChange: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValues: PropTypes.arrayOf(PropTypes.string).isRequired,
  title: PropTypes.string.isRequired,
  values: PropTypes.object.isRequired,
};

CheckboxFilterDialog.defaultProps = {
  onChange: () => {},
  onClose: () => {},
  open: false,
  selected: [],
  values: {},
};

export default CheckboxFilterDialog;

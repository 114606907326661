import React, { Component } from "react";
import PropTypes from "prop-types";

import SearchResults from "./SearchResults";
import CommentRow from "./CommentRow";

export default class UserTabComments extends Component {
  static propTypes = {
    api: PropTypes.object.isRequired,
    authorId: PropTypes.string.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      comments: [],
    };
  }

  render() {
    return (
      <SearchResults
        api={this.props.api.Comments}
        query={{ author_id: this.props.authorId, order: "desc" }}
      >
        {(results) => (
          <table className="UserTabComments">
            <thead>
              <CommentRow.Headers />
            </thead>
            <tbody>
              {results.items().map((c) => (
                <CommentRow key={c.uid} api={this.props.api} data={c} />
              ))}
            </tbody>
            <tfoot>
              <tr>
                <td>
                  <results.AutoLoad />
                </td>
              </tr>
            </tfoot>
          </table>
        )}
      </SearchResults>
    );
  }
}

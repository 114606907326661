import React, { Component } from "react";
import PropTypes from "prop-types";

import classnames from "classnames";

export default class StatusDot extends Component {
  static propTypes = {
    account_status: PropTypes.string.isRequired,
  };

  render() {
    const s = this.props.account_status;
    return (
      <span className={classnames("StatusDot", `StatusDot--${s}`)}>●</span>
    );
  }
}

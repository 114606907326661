import { useMutation } from "react-query";
import Cookies from "universal-cookie";

const cookies = new Cookies();
const xsrf_token = cookies.get("XSRF-TOKEN");

const deleteImage = async (uid) => {
  const url = `${process.env.REACT_APP_API_HOST}/admin/stored_images/${uid}`;
  const response = await fetch(url, {
    method: "DELETE",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      "X-XSRF-Token": xsrf_token,
    },
  });
  return response;
};

const useDeleteImage = (onSuccess, onError) => {
  return useMutation(deleteImage, {
    onSuccess: () => {
      onSuccess("Message deleted successfully.");
    },
    onError: () => {
      onError("Error deleting message.");
    },
  });
};

export { useDeleteImage };

import React from "react";
import PropTypes from "prop-types";

export default class TextArea extends React.Component {
  static propTypes = {
    value: PropTypes.string,
    getRef: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this._ref = React.createRef();
  }

  render() {
    const { value, getRef, ...rest } = this.props;

    return <textarea value={value || ""} ref={this._ref} {...rest} />;
  }

  shouldComponentUpdate(nextProps) {
    return (
      nextProps.value !== this.props.value ||
      nextProps.disabled !== this.props.disabled
    );
  }

  componentDidMount() {
    this._updateHeight();
    this.props.getRef(this._ref.current);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.value !== this.props.value) this._updateHeight();
    this.props.getRef(this._ref.current);
  }

  _updateHeight() {
    const el = this._ref.current;
    const x = window.scrollX;
    const y = window.scrollY;
    el.style["min-height"] = "0px";
    el.style["min-height"] = el.scrollHeight + 5 + "px";
    // resizing to 0 then to the right size may have moved the window around
    window.scroll(x, y);
  }
}

import React from "react";
import PropTypes from "prop-types";

import StringEditor from "./StringEditor";

export default class CampaignSubjectEditor extends React.Component {
  static propTypes = {
    api: PropTypes.object.isRequired,
    disabled: PropTypes.bool.isRequired,
    onSave: PropTypes.func.isRequired,
    value: PropTypes.shape({
      type: PropTypes.string,
      id: PropTypes.number,
    }),
  };

  constructor(props) {
    super(props);
    this.state = { type: props.value.type || "none", id: props.value.id };
  }

  render() {
    const p = this.props;
    const s = this.state;

    return (
      <div className="CampaignSubjectEditor">
        <select
          className="CampaignSubjectEditor-dropdown custom-select"
          onChange={this._onChangeType.bind(this)}
          value={s.type || "none"}
          disabled={this.props.disabled}
        >
          <option value="none">None</option>
          <option value="post">Post</option>
          <option value="pages_group">Group</option>
          <option value="live_audio/room">Pod</option>
          <option value="live_audio/rooms">Pods</option>
        </select>

        <StringEditor
          className="CampaignSubjectEditor-id"
          disabled={p.disabled || ["none", "live_audio/rooms"].includes(s.type)}
          value={s.id ? String(s.id) : null}
          onSave={(x) => p.onSave({ subject: { type: s.type, id: x } })}
          format={this._campaignSubjectIdFormat(s.type)}
          placeholder={
            s.type === "post"
              ? "Post ID"
              : s.type === "pages_group"
              ? "Group ID"
              : s.type === "live_audio/room"
              ? "Pod ID"
              : null
          }
        />
      </div>
    );
  }

  componentDidUpdate(prevProps) {
    if (prevProps.value !== this.props.value) {
      this.setState({
        type: this.props.value.type || "none",
        id: this.props.value.id,
      });
    }
  }

  _onChangeType(e) {
    const type = e.target.value;

    if (["none", "live_audio/rooms"].includes(type)) {
      this.props.onSave({ subject: { type: type, id: null } });
      return;
    }

    this.setState({ type: type, id: null });
  }

  _campaignSubjectIdFormat(type) {
    switch (type) {
      case "live_audio/rooms":
      case "none":
        return null;
      case "live_audio/room":
        return "^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$";
      default:
        return "^[0-9]+$";
    }
  }
}

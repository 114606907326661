import React, { Component } from "react";
import PropTypes from "prop-types";

import UnreportedAbusiveContentListItem from "./components/UnreportedAbusiveContentListItem";

import styles from "./UnreportedAbusiveContentList.module.scss";

export class UnreportedAbusiveContentList extends Component {
  static propTypes = {
    api: PropTypes.object.isRequired,
    value: PropTypes.string,
    onChange: PropTypes.func,
    userID: PropTypes.number.isRequired,
    selectInputName: PropTypes.string,
  };

  static defaultProps = {
    onChange: () => {},
    selectInputName: "abusiveContentList",
  };

  constructor(props) {
    super(props);
    this.state = {
      items: null,
      selectedUnreportedAbusiveContentId: null,
      selectedUnreportedAbusiveContentType: null,
    };

    this._loadUnreportedAbusiveContent =
      this._loadUnreportedAbusiveContent.bind(this);

    this._handleUnreportedAbusiveContentSelect =
      this._handleUnreportedAbusiveContentSelect.bind(this);
  }

  componentDidMount() {
    this._loadUnreportedAbusiveContent();
  }

  async _loadUnreportedAbusiveContent() {
    const { userID } = this.props;
    const { items } =
      (await this.props.api.UnreportedAbusiveContent.index({
        user_id: userID,
      })) || {};

    this.setState({
      items,
    });
  }

  _handleUnreportedAbusiveContentSelect(
    selectedUnreportedAbusiveContentId,
    selectedUnreportedAbusiveContentType
  ) {
    const { onChange } = this.props;

    this.setState({
      selectedUnreportedAbusiveContentId,
      selectedUnreportedAbusiveContentType,
    });

    onChange(
      selectedUnreportedAbusiveContentId,
      selectedUnreportedAbusiveContentType
    );
  }

  render() {
    const { itemSelected, selectInputName } = this.props;
    const {
      items,
      selectedUnreportedAbusiveContentType,
      selectedUnreportedAbusiveContentId,
    } = this.state;

    const selected = (item) => {
      return (
        itemSelected &&
        selectedUnreportedAbusiveContentId === item.id &&
        selectedUnreportedAbusiveContentType === item.type
      );
    };

    return (
      <div className={styles.container}>
        {items &&
          items?.map((item) => (
            <UnreportedAbusiveContentListItem
              key={`${item.type}_${item.id}`}
              {...item}
              onSelect={this._handleUnreportedAbusiveContentSelect}
              selected={selected(item)}
              selectInputName={selectInputName}
            />
          ))}
        {items && items?.length === 0 && (
          <div className={styles.noDataContainer}>
            <p>User has no unreported abusive content</p>
          </div>
        )}
      </div>
    );
  }
}

export default UnreportedAbusiveContentList;

import React from "react";

import SearchForm from "../components/SearchForm";
import SearchHistory from "../components/SearchHistory";
import SearchResults from "../components/SearchResults";
import PostItem from "../components/PostItem";
import PostTagsForm from "../components/PostTagsForm";

export default class Posts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      categories: [],
      tag_names: [],
      tagsOverlay: false,
      query: {
        author_id: "",
        category_ids: "",
        text: "",
        statuses: "active",
        spam: "",
        peanut_pick: "",
        order: "created_at",
        created_since: this._defaultCreatedSince(),
        created_before: "",
        tag_ids: "",
      },
    };
  }

  _defaultCreatedSince() {
    const d = new Date();
    d.setDate(d.getDate() - 56);
    return d.toISOString().replace(/T.*/, "");
  }

  componentDidMount() {
    this.props.api.Categories.index().then((categories) => {
      const c = categories.map((c) => {
        return { label: c.name, value: c.id };
      });
      this.setState({ categories: categories });
      this.setState({ categories: c });
    });

    this.props.api.PostTags.index().then((data) => {
      let tags = data.map((tag_group) => tag_group.tags).flat();
      let tagNames = tags.reduce(function (result, tag) {
        result[tag.id] = tag.name;
        return result;
      }, {});
      this.setState({ tag_names: tagNames });
    });
  }

  render() {
    return (
      <div className="Posts">
        <SearchForm
          query={this.state.query}
          onSubmit={(q) => this.setState({ query: q })}
          className="Posts-form"
        >
          {(form) => (
            <React.Fragment>
              <form.Input
                label="Author ID"
                name="author_id"
                className="author"
              />
              <form.Input
                label="Created since"
                name="created_since"
                type="date"
                className="date"
              />
              <form.Input
                label="Created before"
                name="created_before"
                type="date"
                className="date"
              />
              <form.MultiSelect
                name="category_ids"
                className="categories"
                title="Categories"
                placeholder="All categories"
                options={this.state.categories}
              />

              <div className="Input tags">
                <label for="tag_ids">Tags</label>
                <form.Hidden name="tag_ids" />
                <div>
                  {this.state.query.tag_ids === "" && (
                    <button
                      className="badge badge-light"
                      onClick={this.setState.bind(
                        this,
                        { tagsOverlay: true },
                        null
                      )}
                    >
                      none
                    </button>
                  )}
                  {this.state.query.tag_ids.split(",").map((tag_id) => (
                    <React.Fragment key={tag_id}>
                      <button
                        className="badge badge-pill badge-secondary"
                        onClick={this.setState.bind(
                          this,
                          { tagsOverlay: true },
                          null
                        )}
                      >
                        {this.state.tag_names[tag_id]}
                      </button>{" "}
                    </React.Fragment>
                  ))}
                </div>
              </div>

              <form.Input label="Text" name="text" className="text" />
              <form.Input label="UID" name="uid" className="text" />

              <div>
                <form.CheckGroup
                  name="statuses"
                  title="Status"
                  options={["active", "censored", "hidden", "disabled"]}
                  labels={["Active", "Censored", "Hidden", "Disabled"]}
                />
              </div>

              <div style={{ marginTop: "24px" }}>
                <form.CheckBox name="spam" label="Spam" />
                <form.CheckBox name="peanut_pick" label="Pick" />
              </div>

              <form.RadioGroup
                name="order"
                title="Order"
                options={[
                  "created_at",
                  "engagement_absolute",
                  "engagement",
                  "trending",
                ]}
                labels={[
                  "Chronological",
                  "Absolute engagement",
                  "Relative engagement",
                  "Trending score",
                ]}
              />
            </React.Fragment>
          )}
        </SearchForm>

        <SearchHistory
          query={this.state.query}
          onSubmit={(q) => this.setState({ query: q })}
        />

        <SearchResults api={this.props.api.Posts} query={this.state.query}>
          {(results) => (
            <table className="Posts-table">
              <thead>
                <PostItem.Headers />
              </thead>

              <tbody>
                {results.items().map((n) => (
                  <PostItem key={n.uid} {...n} />
                ))}
              </tbody>

              <tfoot>
                <tr>
                  <td>
                    <results.AutoLoad />
                  </td>
                </tr>
              </tfoot>
            </table>
          )}
        </SearchResults>

        {this.state.tagsOverlay && (
          <PostTagsForm
            api={this.props.api}
            selectedTagIds={this.state.query.tag_ids}
            onCancel={this.setState.bind(this, { tagsOverlay: false }, null)}
            onSubmit={this._onSubmitTags.bind(this)}
          />
        )}
      </div>
    );
  }

  _onSubmitTags(selectedTagIds) {
    const q = Object.assign({}, this.state.query, { tag_ids: selectedTagIds });
    this.setState({ tagsOverlay: false, query: q });
  }
}

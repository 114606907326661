import { useInfiniteQuery } from "react-query";
import Cookies from "universal-cookie";

const queryString = require("query-string");
const useUserReferrals = (filters = {}) => {
  const cookies = new Cookies();
  const xsrf_token = cookies.get("XSRF-TOKEN");

  const limit = 50;

  const fetchProjects = async ({ pageParam = { offset: 0 } }) => {
    const { offset } = pageParam;

    const modifiedFilters = { ...filters, limit, offset };

    const url = new URL(
      `${process.env.REACT_APP_API_HOST}/admin/user_referrals`
    );
    url.search = queryString.stringify(modifiedFilters, {
      arrayFormat: "bracket",
    });

    const response = await fetch(url, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-XSRF-Token": xsrf_token,
      },
    });

    const json = await response.json();
    return json.user_referrals;
  };

  return useInfiniteQuery(["userReferrals", filters], fetchProjects, {
    getNextPageParam: (lastPage, pages) => {
      if (lastPage && lastPage.length < limit) {
        return undefined;
      }
      const params = { offset: pages.length * limit };
      return params;
    },
  });
};

export default useUserReferrals;

import React, { useEffect, useState } from "react";
import useGuidelines from "../../hooks/api/useGuidelines";

import EventHistoryUser from "./HistoryUser";
import EventHistoryPost from "./HistoryPost";
import EventHistoryPublishedPost from "./HistoryPublishedPost";
import EventHistoryLiveAudioRoom from "./HistoryLiveAudioRoom";
import EventHistoryComment from "./HistoryComment";
import EventHistoryGroup from "./HistoryGroup";
import EventHistoryGroupMembership from "./HistoryGroupMembership";
import EventHistoryCase from "./HistoryCase";
import EventHistoryUserPhoto from "./HistoryUserPhoto";
import EventHistoryBreach from "./HistoryBreach";

import EventReportUser from "./ReportUser";
import EventReportUserPhoto from "./ReportUserPhoto";
import EventReportComment from "./ReportComment";
import EventReportPost from "./ReportPost";
import EventReportGroup from "./ReportGroup";
import EventReportConversation from "./ReportConversation";
import EventReportLiveAudioParticipant from "./ReportLiveAudioParticipant";
import EventReportLiveAudioRoom from "./ReportLiveAudioRoom";
import ChipTable from "../ChipTable/ChipTable";
import AlertDialog from "../AlertDialog/AlertDialog";
import CustomizedSnackbar from "../CustomizedSnackbar/CustomizedSnackbar";

// map type (e.g. 'history/user') to components (e.g. EventHistoryUser)
const dispatchComponents = [
  EventHistoryCase,
  EventHistoryUser,
  EventHistoryPost,
  EventHistoryPublishedPost,
  EventHistoryLiveAudioRoom,
  EventHistoryComment,
  EventHistoryGroupMembership,
  EventHistoryUserPhoto,
  EventHistoryBreach,
  EventHistoryGroup,
  EventReportUser,
  EventReportUserPhoto,
  EventReportComment,
  EventReportPost,
  EventReportGroup,
  EventReportConversation,
  EventReportLiveAudioParticipant,
  EventReportLiveAudioRoom,
].reduce((acc, comp) => {
  acc[comp.type] = comp;
  return acc;
}, {});

const EventRow = (props) => {
  const [showOptions, setShowOptions] = useState(false);
  const [selectedReasons] = useState({
    id: null,
    violation_severity: "",
  });
  const [showModal, setShowModal] = useState(false);
  const [mutation, setMutation] = useState({ id: null, mutation: null });
  const [fakeLoading, setFakeLoading] = useState("");
  const [error, setError] = useState("");
  const [withdrawError, setWithdrawError] = useState("");
  const [success, setSuccess] = useState("");
  const [withdrawSuccess, setWithdrawSuccess] = useState(false);

  const { data } = useGuidelines();

  const conditions = ["reported_comment", "reported_post", "reported_group"];

  const isReportClosed =
    props.data.breach?.withdrawn ||
    props.data.status === "confirmed" ||
    props.data.status === "infirmed" ||
    props.data.status === "ignored";

  const Comp = dispatchComponents[props.type];

  useEffect(() => {
    let clearSuccess = setTimeout(() => {
      setSuccess("");
      setError("");
      setWithdrawSuccess(false);
      setWithdrawError("");
    }, 2000);
    return () => {
      clearTimeout(clearSuccess);
    };
  }, [success, withdrawSuccess, error, withdrawError]);

  const handleOnShowModal = ({ id, violation_severity }, mutation) => {
    setShowModal(true);
    setMutation({ id, submitFns: mutation });
  };

  const handleOnSubmitSevereBreach = () => {
    const payload = {
      user_id: props.userID,
      subject_type:
        props.type === "history/breach"
          ? props.data.breach.subject_type
          : props.type,
      subject_id:
        props.type === "history/breach"
          ? props.data.breach.subject_id
          : props.data.subject_id,
      violated_guidelines: [mutation?.id],
    };

    if (conditions.some((el) => props.type.includes(el))) {
      mutation.submitFns.submitBreach(payload);
    } else {
      mutation.submitFns.submitBreach({
        ...payload,
        report_ids:
          props.type === "history/breach"
            ? props.data.breach.report_id
            : [props.data.id],
      });
    }

    if (props.type === "history/breach") {
      mutation.submitFns.submitWithdraw(props.data.breach.id);
    }
  };

  return (
    <tbody onMouseLeave={() => setShowOptions(false)}>
      {Comp && (
        <Comp
          api={props.api}
          type={props.type}
          userID={props.userID}
          guidelineList={data}
          onUpdate={props.onUpdate}
          onToggleOptions={(payload) => setShowOptions(payload)}
          {...props.data}
        />
      )}
      {props.type === "history/breach" && !isReportClosed && showOptions ? (
        <ChipTable
          userID={props.userID}
          type={props.type}
          guidelineList={data}
          subjectType={props.data.breach.subject_type}
          subjectID={props.data.breach.subject_id}
          breachID={props.data.breach.id}
          reportIDs={props.data.breach.report_ids}
          disabled={props.data.breach.withdrawn}
          selectedReasons={{ id: props.data.breach.guideline_id }}
          onError={(payload) => setError(payload)}
          onWithdrawError={(payload) => setWithdrawError(payload)}
          onSuccess={(payload) => setSuccess(payload)}
          onWithdrawSuccess={(payload) => setWithdrawSuccess(payload)}
          onLoading={(payload) => setFakeLoading(payload)}
          onShowModal={handleOnShowModal}
          onUpdate={props.onUpdate}
        />
      ) : (
        showOptions &&
        !isReportClosed && (
          <ChipTable
            userID={props.userID}
            subjectType={props.type}
            guidelineList={data}
            subjectID={props.data.subject_id}
            reportIDs={[props.data.id]}
            suggestedViolatedGuidelines={
              props.data.suggested_violated_guideline_ids
            }
            selectedReasons={selectedReasons}
            onError={(payload) => setError(payload)}
            onWithdrawError={(payload) => setWithdrawError(payload)}
            onSuccess={(payload) => setSuccess(payload)}
            onWithdrawSuccess={(payload) => setWithdrawSuccess(payload)}
            onLoading={(payload) => setFakeLoading(payload)}
            onShowModal={handleOnShowModal}
            onUpdate={props.onUpdate}
          />
        )
      )}
      <AlertDialog
        open={showModal}
        onCloseModal={() => setShowModal(false)}
        onSubmit={handleOnSubmitSevereBreach}
      />
      {(error || withdrawError) && (
        <CustomizedSnackbar message={error} severity="error" />
      )}
      {fakeLoading && (
        <CustomizedSnackbar message={fakeLoading} severity="info" />
      )}
      {success && <CustomizedSnackbar message={success} severity="success" />}
      {props.type === "history/breach" && success && withdrawSuccess ? (
        <CustomizedSnackbar message={success} severity="success" />
      ) : (
        success && <CustomizedSnackbar message={success} severity="success" />
      )}
    </tbody>
  );
};

EventRow.Headers = () => (
  <tr>
    <th width="1">Time</th>
    <th width="1"></th>
    <th>Activity</th>
    <th style={{ width: 140 }}>Reports / Actions</th>
  </tr>
);

export default EventRow;

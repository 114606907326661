import React, { Component } from "react";
import PropTypes from "prop-types";

import SearchForm from "../components/SearchForm";
import CommentRow from "../components/CommentRow";
import SearchResults from "../components/SearchResults";

export default class Comments extends Component {
  static propTypes = {
    api: PropTypes.object,
  };

  constructor(props) {
    super(props);
    this.state = { query: { q: null } };
  }

  render() {
    return (
      <div className="container">
        <h2>Comment search</h2>
        <p>Results are limited to comments from the last 7 days.</p>
        <SearchForm
          query={this.state.query}
          onSubmit={(q) => this.setState({ query: q })}
          className="Comments-form"
        >
          {(form) => (
            <React.Fragment>
              <form.Input label="Text" name="q" className="text" />
            </React.Fragment>
          )}
        </SearchForm>

        <SearchResults
          api={this.props.api.CommentsSearch}
          query={this.state.query}
        >
          {(results) => (
            <table className="Post-comments">
              <caption>Comments</caption>
              <thead>
                <CommentRow.Headers />
              </thead>
              <tbody>
                {results.items().map((n) => (
                  <CommentRow key={n.uid} api={this.props.api} data={n} />
                ))}
              </tbody>
              <tfoot>
                <tr>
                  <td>
                    <results.AutoLoad />
                  </td>
                </tr>
              </tfoot>
            </table>
          )}
        </SearchResults>
      </div>
    );
  }
}

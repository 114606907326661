import React, { Component } from "react";
import SearchForm from "../../components/SearchForm";
import SearchResults from "../../components/SearchResults";
import SearchHistory from "../../components/SearchHistory";

import VideoSearchResultRow from "./components/VideoSearchResultRow";

class Videos extends Component {
  constructor(props) {
    super(props);

    this.state = {
      query: {
        type: "",
        author_uid: "",
        category_ids: "",
        statuses: "",
        order: "created_at desc",
      },
    };
  }

  render() {
    return (
      <div className="Videos">
        <SearchForm
          query={this.state.query}
          onSubmit={(q) => this.setState({ query: q })}
          className="Posts-form"
        >
          {(form) => (
            <React.Fragment>
              <form.RadioGroup
                name="type"
                title="Type"
                options={["", "Post", "Comment"]}
                labels={["All", "Post", "Comment"]}
              />

              <form.RadioGroup
                name="status"
                title="Status"
                options={["", "reported"]}
                labels={["All", "Reported"]}
              />

              <form.Input
                label="Author UID"
                name="author_uid"
                className="author"
              />

              <form.RadioGroup
                name="order"
                title="Order"
                options={["created_at desc", "created_at"]}
                labels={["Newest First", "Oldest First"]}
              />
            </React.Fragment>
          )}
        </SearchForm>

        <SearchHistory
          query={this.state.query}
          onSubmit={(q) => this.setState({ query: q })}
        />
        <SearchResults
          api={this.props.api.StoredVideos}
          query={this.state.query}
        >
          {(results) => {
            return (
              <table className="UserPhotos-table">
                <thead>
                  <tr>
                    <th>Video ID</th>
                    <th>Video URL</th>
                    <th>Content</th>
                    <th>User</th>
                    <th>Reports</th>
                  </tr>
                </thead>
                <tbody>
                  {results.items().map((d) => (
                    <VideoSearchResultRow
                      key={`storedvideo_result_${d.id}`}
                      api={this.props.api}
                      {...d}
                    />
                  ))}
                </tbody>
                <tfoot>
                  <tr>
                    <td>
                      <results.AutoLoad />
                    </td>
                  </tr>
                </tfoot>
              </table>
            );
          }}
        </SearchResults>
      </div>
    );
  }
}

export default Videos;

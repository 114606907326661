import React, { Component } from "react";
import { NavLink, Switch } from "react-router-dom";
import classnames from "classnames";

import Route from "./components/Route";
import ErrorBoundary from "./components/ErrorBoundary.js";

import Home from "./pages/Home";
import Users from "./pages/Users";
import User from "./pages/User";
import UserPhotos from "./pages/UserPhotos";
import Posts from "./pages/Posts";
import Post from "./pages/Post";
import Groups from "./pages/Groups";
import Group from "./pages/Group";
import Conversation from "./pages/Conversation";
import Cases from "./pages/Cases";
import Case from "./pages/Case";
import Experiments from "./pages/Experiments";
import Experiment from "./pages/Experiment";
import ReferralStats from "./pages/ReferralStats";
import Payments from "./pages/Payments";
import UserEvents from "./pages/UserEvents";
import Campaigns from "./pages/Campaigns";
import Campaign from "./pages/Campaign";
import PublishedPosts from "./pages/PublishedPosts";
import SEOPages from "./pages/seo/Pages";
import SEOPage from "./pages/seo/Page";
import SEOPosts from "./pages/seo/Posts";
import SEOTags from "./pages/seo/Tags";
import Video from "./pages/Video";
import Videos from "./pages/Videos";
import LiveAudioRoom from "./pages/LiveAudioRoom";
import LiveAudioRooms from "./pages/LiveAudioRooms";
import LandingPages from "./pages/LandingPages";

import AutoAnnotations from "./pages/AutoAnnotations";
import UserReferrals from "./pages/UserReferrals";
import ContentLabellingJobs from "./pages/ContentLabellingJobs/Index";
import ContentLabellingJob from "./pages/ContentLabellingJobs/Show";
import ContentLabellingJobNew from "./pages/ContentLabellingJobs/New";
import ContentLabellingJobEdit from "./pages/ContentLabellingJobs/Edit";
import Comments from "./pages/Comments";

import Authentication from "./pages/Authentication";
import PageNotFound from "./pages/PageNotFound";

import API from "./api/API";
import Spinner from "./components/Spinner";
import ErrorLog from "./components/ErrorLog";
import SEOAuthors from "./pages/seo/Authors";
import SEOAuthor from "./pages/seo/Author";
import LandingPage from "./pages/LandingPage";

class App extends Component {
  constructor(props) {
    super(props);

    this._api = new API();

    this.state = {
      isLoggedIn: "unknown",
      navbarOpen: null,
    };
  }

  _doLogout(e) {
    if (e !== undefined) {
      e.preventDefault();
    }
    this._api.Session.destroy().then(() => this.setState({ isLoggedIn: "no" }));
  }

  componentDidMount() {
    this._api.Session.show()
      .then(() => this.setState({ isLoggedIn: "yes" }))
      .catch(() => this.setState({ isLoggedIn: "no" }));

    // If a request is unauthorized, logout the user
    this._api.on("error", (error) => {
      if (error === "unauthorized" && this.state.isLoggedIn === "yes") {
        this._api.Session.destroy().finally(() =>
          this.setState({ isLoggedIn: "no" })
        );
      }
    });
  }

  render() {
    if (this.state.isLoggedIn === "unknown") {
      return <p>Loading...</p>;
    }

    return (
      <div className="App">
        <header className="App-header">
          <nav>
            <Spinner api={this._api} />
            <a className="brand" href="/">
              Peanut Admin {process.env.REACT_APP_ENV_NAME}
            </a>

            <button
              onClick={() => {
                this.setState({ navbarOpen: !this.state.navbarOpen });
              }}
              className="navbar-toggler"
              type="button"
            >
              <span className="navbar-toggler-icon"></span>
            </button>

            {this.state.isLoggedIn === "yes" && (
              <React.Fragment>
                <div
                  className={classnames("navbar-inner", {
                    collapse: !this.state.navbarOpen,
                  })}
                >
                  <ul>
                    <li>
                      <NavLink to="/" exact>
                        Home
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/cases">Cases</NavLink>
                    </li>
                    <li>
                      <NavLink to="/users">Users</NavLink>
                    </li>
                    <li>
                      <NavLink to="/posts">Posts</NavLink>
                    </li>
                    <li>
                      <NavLink to="/groups">Groups</NavLink>
                    </li>
                    <li>
                      <NavLink to="/photos">Photos</NavLink>
                    </li>
                    <li>
                      <NavLink to="/videos">Videos</NavLink>
                    </li>
                    <li>
                      <NavLink to="/live-audio">Pods</NavLink>
                    </li>
                    <li>
                      <NavLink to="/experiments">Experiments</NavLink>
                    </li>
                    <li>
                      <NavLink to="/content-labelling-jobs">
                        Content Labelling Jobs
                      </NavLink>
                    </li>
                  </ul>
                  <a
                    className="logout"
                    href="/"
                    onClick={this._doLogout.bind(this)}
                  >
                    Log out
                  </a>
                </div>
              </React.Fragment>
            )}
          </nav>
        </header>
        <header className="App-errors">
          <ErrorLog api={this._api} />
        </header>
        <ErrorBoundary>
          <main>
            {this.state.isLoggedIn === "yes" && (
              <Switch>
                <Route
                  exact
                  path="/"
                  component={Home}
                  api={this._api}
                  onUnauthorized={this._onLogout}
                />
                <Route
                  exact
                  path="/cases/:id"
                  component={Case}
                  api={this._api}
                />
                <Route exact path="/cases" component={Cases} api={this._api} />
                <Route exact path="/users" component={Users} api={this._api} />
                <Route path="/users/:id" component={User} api={this._api} />
                <Route exact path="/posts" component={Posts} api={this._api} />
                <Route path="/posts/:id" component={Post} api={this._api} />
                <Route
                  exact
                  path="/groups"
                  component={Groups}
                  api={this._api}
                />
                <Route
                  exact
                  path="/groups/:id"
                  component={Group}
                  api={this._api}
                />
                <Route
                  exact
                  path="/photos"
                  component={UserPhotos}
                  api={this._api}
                />
                <Route
                  exact
                  path="/conversations/:id"
                  component={Conversation}
                  api={this._api}
                />
                <Route
                  exact
                  path="/referrals"
                  component={ReferralStats}
                  api={this._api}
                />
                <Route
                  exact
                  path="/payments"
                  component={Payments}
                  api={this._api}
                />
                <Route
                  exact
                  path="/experiments"
                  component={Experiments}
                  api={this._api}
                />
                <Route
                  exact
                  path="/experiments/:id"
                  component={Experiment}
                  api={this._api}
                />
                <Route
                  exact
                  path="/log"
                  component={UserEvents}
                  api={this._api}
                />
                <Route
                  exact
                  path="/campaigns"
                  component={Campaigns}
                  api={this._api}
                />
                <Route
                  exact
                  path="/campaigns/:id"
                  component={Campaign}
                  api={this._api}
                />
                <Route
                  exact
                  path="/published_posts"
                  component={PublishedPosts}
                  api={this._api}
                />
                <Route
                  exact
                  path="/seo/pages/:type([a-z]+)"
                  component={SEOPages}
                  api={this._api}
                />
                <Route
                  exact
                  path="/seo/pages/:id"
                  component={SEOPage}
                  api={this._api}
                />
                <Route
                  exact
                  path="/seo/authors/"
                  component={SEOAuthors}
                  api={this._api}
                />
                <Route
                  exact
                  path="/seo/authors/:id"
                  component={SEOAuthor}
                  api={this._api}
                />
                <Route
                  exact
                  path="/landing-pages/:id"
                  component={LandingPage}
                />
                <Route exact path="/landing-pages/" component={LandingPages} />
                <Route
                  exact
                  path="/seo/posts"
                  component={SEOPosts}
                  api={this._api}
                />
                <Route
                  exact
                  path="/seo/tags"
                  component={SEOTags}
                  api={this._api}
                />
                <Route
                  exact
                  path="/videos/:id"
                  component={Video}
                  api={this._api}
                />
                <Route
                  exact
                  path="/videos"
                  component={Videos}
                  api={this._api}
                />
                <Route
                  exact
                  path="/live-audio/:id"
                  component={LiveAudioRoom}
                  api={this._api}
                />
                <Route
                  exact
                  path="/live-audio"
                  component={LiveAudioRooms}
                  api={this._api}
                />
                <Route exact path="/user-referrals" component={UserReferrals} />
                <Route
                  exact
                  path="/content-labelling-jobs"
                  component={ContentLabellingJobs}
                  api={this._api}
                />
                <Route
                  exact
                  path="/content-labelling-jobs/new"
                  component={ContentLabellingJobNew}
                  api={this._api}
                />
                <Route
                  exact
                  path="/content-labelling-jobs/:id"
                  component={ContentLabellingJob}
                  api={this._api}
                />
                <Route
                  exact
                  path="/content-labelling-jobs/:id/edit"
                  component={ContentLabellingJobEdit}
                  api={this._api}
                />
                <Route
                  exact
                  path="/auto_annotations"
                  component={AutoAnnotations}
                  api={this._api}
                />
                <Route
                  exact
                  path="/comments"
                  component={Comments}
                  api={this._api}
                />
                <Route component={PageNotFound} />
              </Switch>
            )}
            {this.state.isLoggedIn === "no" && (
              <Switch>
                <Route
                  component={Authentication}
                  api={this._api}
                  onLogin={this.setState.bind(this, { isLoggedIn: "yes" })}
                />
              </Switch>
            )}
          </main>
        </ErrorBoundary>
      </div>
    );
  }
}

export default App;

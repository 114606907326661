import React, { Component } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import classNames from "classnames";
import Moment from "./Moment";
import Image from "./Image";
import UserLink from "./UserLink";

export default class PostItem extends Component {
  static propTypes = {
    id: PropTypes.number.isRequired,
    uid: PropTypes.string.isRequired,
    author: PropTypes.shape({
      id: PropTypes.number,
      first_name: PropTypes.string,
      last_name: PropTypes.string,
      avatar_url: PropTypes.string,
    }),
    impression_count: PropTypes.number,
    like_count: PropTypes.number,
    comment_count: PropTypes.number,
    view_count: PropTypes.number,
    post_type: PropTypes.string,
    title: PropTypes.string,
    body: PropTypes.string,
    posted_as: PropTypes.string,
    created_at: PropTypes.string,
    status: PropTypes.string,
    peanut_pick: PropTypes.bool,
    category: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    }),

    sentiment: PropTypes.number,
    emotion: PropTypes.string,

    published_post: PropTypes.shape({
      status: PropTypes.string.isRequired,
    }),
    publication_consent: PropTypes.shape({
      status: PropTypes.string.isRequired,
    }),
  };

  static Headers = () => (
    <tr>
      <th width="1">
        ID <br />
        Posted <br />
        Status
      </th>
      <th width="1">Image</th>
      <th>
        Title <br />
        Body
      </th>
      <th width="1" className="PostItem-author">
        Author
      </th>
      <th width="1">Labels</th>
      <th>Stats</th>
    </tr>
  );

  render() {
    const post = this.props;
    const u = post.author;

    if (post.category === undefined) {
      return "";
    }

    /* eslint-disable jsx-a11y/accessible-emoji */
    return (
      <tr className="PostItem">
        <td>
          # {post.id} <br />
          <Moment date={post.created_at} relative={true} /> <br />
          {post.status}
        </td>

        <td>
          {post.image && (
            <Image
              url={post.image.url}
              className={classNames({
                "PostItem-image": true,
                detected_nsfw: post.status !== "active",
              })}
              openInNewTab
            />
          )}
        </td>

        <td>
          <p className="title">
            <Link to={`/posts/${post.id}`}>{post.title}</Link>
          </p>
          <p className="body">{post.body}</p>
        </td>

        <td className="PostItem-author">
          <UserLink {...u} />
        </td>

        <td className="PostItem-tags">
          {post.group && (
            <Link to={`/groups/${post.group.id}`}>
              <span className="PostItem-tag tag-group">{post.group.name}</span>
            </Link>
          )}
          {post.category && post.category.id > 0 && (
            <Link to={`/posts?category_ids=${post.category.id}`}>
              <span className="PostItem-tag tag-category">
                {post.category.name}
              </span>
            </Link>
          )}
          {post.peanut_pick && (
            <span className="PostItem-tag tag-pick">Pick</span>
          )}
          {post.abuse.map((a, idx) => (
            <span key={idx} className={`PostItem-tag tag-${a}`}>
              {a}
            </span>
          ))}
          {this._postPublicationTag(post)}
        </td>

        <td>
          <ul className="stats">
            <li>👁 {post.impression_count}</li>
            <li>👆 {post.view_count}</li>
            <li>👍 {post.like_count}</li>
            <li>💬 {post.comment_count}</li>
          </ul>
        </td>
      </tr>
    );
  }

  _postConsentTag(p) {
    if (!p.publication_consent) return <React.Fragment />;

    let tag;
    switch (p.publication_consent.status) {
      case "requested":
        tag = "consent requested";
        break;
      case "accepted":
        tag = "consent accepted";
        break;
      case "denied":
        tag = "consent denied";
        break;
      case "expired":
        tag = "consent expired";
        break;
      default:
        break;
    }
    if (!tag) return <React.Fragment />;
    return (
      <span className={`PostItem-tag tag-${tag.replace(" ", "-")}`}>{tag}</span>
    );
  }

  _postPublicationTag(p) {
    if (!p.published_post) return <React.Fragment />;

    let tag;
    switch (p.published_post.status) {
      case "published":
        tag = "published";
        break;
      case "pending":
        tag = "publishable";
        break;
      case "retracted":
        tag = "unpublishable";
        break;
      default:
        break;
    }
    if (!tag) return <React.Fragment />;
    return <span className={`PostItem-tag tag-${tag}`}>{tag}</span>;
  }
}

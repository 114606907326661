import React, { Component } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

import classnames from "classnames";

import StatusDot from "./StatusDot";
import Avatar from "./Avatar";

export default class UserLink extends Component {
  static propTypes = {
    className: PropTypes.string,
    inline: PropTypes.bool,
    id: PropTypes.number.isRequired,
    first_name: PropTypes.string.isRequired,
    account_status: PropTypes.string.isRequired,
    avatar_url: PropTypes.string,
    disabled: PropTypes.bool,
  };

  render() {
    const p = this.props;
    return (
      <Link
        className={classnames(
          "UserLink",
          p.inline && "UserLink--inline",
          p.disabled && "UserLink--disabled",
          p.className
        )}
        to={`/users/${p.id}`}
      >
        <Avatar alt={p.first_name} url={p.avatar_url} size={48} />
        <div className="UserLink-name">
          {p.first_name} <StatusDot {...p} />
        </div>
      </Link>
    );
  }
}

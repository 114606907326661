import React, { Component } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

import ExperimentUserForm from "../components/ExperimentUserForm";

export default class ExperimentUsers extends Component {
  static propTypes = {
    api: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    experiment: PropTypes.shape({
      id: PropTypes.number.isRequired,
    }).isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      experiment: {},
      formOverlay: false,
    };
  }

  render() {
    const exp = this.props.experiment;

    if (!exp) return <React.Fragment />;

    return (
      <section className="ExperimentUsers">
        <h3>User overrides</h3>

        <table className="Experiments-table">
          <thead className="Experiments-table-head">
            <tr>
              <th>User</th>
              <th>Forced variant</th>
              <th>
                <button
                  onClick={this.setState.bind(
                    this,
                    { formOverlay: true },
                    null
                  )}
                  className="ExperimentUser-formButton"
                >
                  Add user
                </button>
              </th>
            </tr>
          </thead>

          <tbody>
            {exp.users.map((user) => (
              <tr key={user.user_id}>
                <td>
                  <Link to={`/users/${user.user_id}`}>
                    #{user.user_id} - {user.first_name} {user.last_name}
                  </Link>
                </td>
                <td>{user.forced_variant}</td>
                <td>
                  <button
                    className="ExperimentUser-remove"
                    onClick={() => {
                      this._onDelete(user.id);
                    }}
                  >
                    &times;
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        {exp.users.length === 0 && <em>No user overrides&hellip;</em>}

        {this.state.formOverlay && (
          <ExperimentUserForm
            api={this.props.api}
            experiment={exp}
            onCancel={this.setState.bind(this, { formOverlay: false }, null)}
            onComplete={this.props.onChange}
          />
        )}
      </section>
    );
  }

  _onDelete(expUserID) {
    this.props.api.Experiments.removeUser(
      this.state.experiment.id,
      expUserID
    ).then(this.props.onChange);
  }
}

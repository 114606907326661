// eslint-disable-next-line no-unused-vars
import React from "react";
import DefaultRow from "../DefaultRow";

export default class ReactionSentRow extends DefaultRow {
  static action = "reaction_sent";
  static label = "Reaction";
  static report = false;

  _notes() {
    const { reaction } = this.props;
    return <>{reaction}</>;
  }
}

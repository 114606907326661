import React, { Component } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

import Moment from "./Moment";
import Avatar from "./Avatar";

export default class User extends Component {
  static propTypes = {
    id: PropTypes.number.isRequired,
    uid: PropTypes.string.isRequired,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    created_at: PropTypes.string.isRequired,
    operating_systems: PropTypes.arrayOf(PropTypes.string),
    primary_location: PropTypes.shape({
      name: PropTypes.string,
    }),
    account_status: PropTypes.string.isRequired,
    completed_registration: PropTypes.bool.isRequired,
    avatar_url: PropTypes.string,
  };

  static Headers = () => (
    <tr>
      <th>Avatar</th>
      <th>IDs</th>
      <th>Name/Email</th>
      <th>Signed up</th>
      <th>Platform</th>
      <th>Location</th>
      <th>Status</th>
    </tr>
  );

  static Columns = 7;

  render() {
    const u = this.props;

    return (
      <tr key={u.id}>
        <td>
          {u.avatar_url && (
            <Avatar
              alt={u.first_name}
              url={u.avatar_url}
              size={64}
              openInNewTab
            />
          )}
        </td>
        <td className="nobreak">
          {u.id}
          <br />
          {u.uid}
        </td>
        <td>
          <Link to={`/users/${u.id}`}>
            {u.first_name} {u.last_name}
          </Link>
          <br />
          {u.email}
        </td>
        <td className="nobreak">
          <Moment date={u.created_at} />
        </td>
        <td>
          <ul>
            {u.operating_systems.map((x) => (
              <li key={x}>{x}</li>
            ))}
            {u.login_methods.map((x) => (
              <li key={x}>{x}</li>
            ))}
          </ul>
        </td>
        <td>{u.primary_location && u.primary_location.name}</td>
        <td className="nobreak">
          {u.account_status}
          <br />
          {u.completed_registration || "not registered"}
        </td>
      </tr>
    );
  }
}

import React, { Component } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

import { Player, BigPlayButton } from "video-react";

import classnames from "classnames";

import h from "./helpers";

export default class ReportPost extends Component {
  static propTypes = {
    api: PropTypes.object.isRequired,
    type: PropTypes.string.isRequired,
    subject_id: PropTypes.number.isRequired,

    created_at: PropTypes.string.isRequired,
    reasons: PropTypes.object.isRequired,
    system: PropTypes.array.isRequired,
    open: PropTypes.bool.isRequired,

    reporter_ids: PropTypes.arrayOf(PropTypes.number),

    title: PropTypes.string.isRequired,
    body: PropTypes.string,

    onUpdate: PropTypes.func,
    onToggleOptions: PropTypes.func,
  };

  static type = "admin/user_logs_controller/reported_post";
  static icon = "⚠️📖";

  constructor(props) {
    super(props);

    this.state = {
      showVideo: false,
    };

    this._confirmVideoReport = this._confirmVideoReport.bind(this);
  }
  _reportVideo() {
    const p = this.props;
    const {
      subject_id,
      video: { id, thumbnail_url, url },
      system,
    } = this.props;
    const { showVideo } = this.state;

    const displayReasons = system ? system[0] : ["unknown"];

    return (
      <>
        {h.renderCountUsers(p)} video <Link to={`/videos/${id}/`}>#{id}</Link>{" "}
        on post <Link to={`/posts/${subject_id}/`}>#{subject_id}</Link>
        {"  "}
        {h.renderUserIds(p)}
        <button onClick={() => this.setState({ showVideo: !showVideo })}>
          {showVideo ? "Hide" : "Show"} Video
        </button>
        &nbsp;
        <strong>({displayReasons})</strong>
        {showVideo && (
          <Player
            ref={(player) => {
              this.player = player;
            }}
            playsInline
            poster={thumbnail_url}
            src={url}
          >
            <BigPlayButton position="center" />
          </Player>
        )}
      </>
    );
  }

  _reportContent() {
    const p = this.props;
    return (
      <>
        {h.renderCountUsers(p)} post{" "}
        <Link to={`/posts/${p.subject_id}`}>#{p.subject_id}</Link>
        {h.renderUserIds(p)}
        <p className="EventReportPost-content">
          "{p.title} {p.body}"
        </p>
      </>
    );
  }

  _confirmVideoReport(action) {
    const { subject_id, system } = this.props;
    this.props.api.Reports.update({
      subjectID: subject_id,
      reason: system[0],
      reportType: "ReportedPost",
      status: action,
    }).then(() => this.props.onUpdate(action === "confirmed"));
  }

  render() {
    const p = this.props;
    const {
      video,
      reasons: { video_explicit_nudity },
    } = this.props;

    let content = this._reportContent();

    if (video && video_explicit_nudity) {
      content = this._reportVideo();
    }

    return (
      <tr
        className={classnames(
          "EventsRow",
          "EventsReportPost",
          "EventsRow--" + (p.open ? "open" : "closed")
        )}
        onMouseOver={() => p.onToggleOptions(true)}
      >
        {h.sharedColumns(this)}
        <td>{content}</td>
      </tr>
    );
  }
}

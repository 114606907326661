import React from "react";
import PropTypes from "prop-types";

import classnames from "classnames";

export default class ExperimentInput extends React.Component {
  static propTypes = {
    value: PropTypes.string,
    placeholder: PropTypes.string,
    minLength: PropTypes.string,
    maxLength: PropTypes.string,
    onKeyPress: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
  };

  constructor(props) {
    super(props);

    this.state = {
      pending: false,
      value: this.props.value,
    };
  }

  render() {
    return (
      <div className="input-group input-group-sm">
        {/* FIXME: refactor to use StringEditor */}
        <input
          type="text"
          placeholder={this.props.placeholder}
          minLength={this.props.minLength}
          maxLength={this.props.maxLength}
          className="form-control"
          disabled={this.props.disabled}
          onChange={(e) => {
            this.setState({ pending: true, value: e.target.value });
          }}
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              e.preventDefault();
              this.props.onKeyPress(this.state.value);
              this.setState({ pending: false });
            }
          }}
          value={this.state.value || ""}
        />
        <div className="input-group-append">
          <div
            className={classnames(
              "input-group-text",
              this.state.pending && "Experiment-unsaved"
            )}
          >
            ⏎
          </div>
        </div>
      </div>
    );
  }
}

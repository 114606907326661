import React, { Component } from "react";

import styles from "./PodPlayback.module.scss";
export default class PodPlayback extends Component {
  setTime(seconds) {
    this.playerRef.current.currentTime = seconds;
  }

  play() {
    this.playerRef.current.play();
  }
  constructor(props) {
    super(props);

    this.state = {
      playbackSpeed: "1.0",
    };

    this.playerRef = React.createRef();
    this.setTime = this.setTime.bind(this);
    this.onSetPlaybackSpeed = this.onSetPlaybackSpeed.bind(this);
  }

  componentDidMount() {
    this._loadRecording();
  }

  _loadRecording() {
    const {
      api: { LiveAudioRecording: LiveAudioRecordingApi },
      roomId,
    } = this.props;

    LiveAudioRecordingApi.show({ roomId }).then((data) => {
      this.setState({ data });
    });
  }

  onSetPlaybackSpeed(speed) {
    this.setState({ playbackSpeed: speed }, () => {
      this.playerRef.current.playbackRate = parseFloat(speed);
    });
  }

  render() {
    const { playbackSpeed } = this.state;

    if (!this.state.data || !this.state.data.recordings) {
      return <></>;
    }

    const {
      data: { recordings },
    } = this.state;
    const recording = recordings[0];

    return (
      <div>
        <audio
          controls
          src={recording}
          ref={this.playerRef}
          style={{ width: "100%" }}
        />
        <div>
          <ul className={styles.playbackSpeedOptions}>
            <li>Speed:</li>
            {["1.0", "1.25", "1.5", "2.0"].map((speed) => {
              return (
                <li>
                  <button
                    className={playbackSpeed === speed ? styles.selected : ""}
                    onClick={() => this.onSetPlaybackSpeed(speed)}
                  >
                    {speed}
                  </button>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    );
  }
}

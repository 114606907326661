import React, { useEffect, useState } from "react";

import classnames from "classnames";

import UserLink from "./UserLink";
import Moment from "./Moment";
import MessageBody from "./MessageBody";
import useGuidelines from "../hooks/api/useGuidelines";
import ChipTableMessage from "./ChipTableMessage/ChipTableMessage";
import AlertDialog from "./AlertDialog/AlertDialog";
import CustomizedSnackbar from "./CustomizedSnackbar/CustomizedSnackbar";

const MessageRow = (props) => {
  const [showOptions, setShowOptions] = useState(false);
  const [selectedReason, setSelectedReason] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [mutation, setMutation] = useState({ id: null, mutation: null });
  const [fakeLoading, setFakeLoading] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const { data } = useGuidelines();

  const p = props.message;

  const handleOnShowModal = ({ id, violation_severity }, mutation) => {
    setShowModal(true);
    setMutation({ id, submitFns: mutation });
  };

  const handleOnSubmitSevereBreach = () => {
    const payload = {
      messageId: p.id,
      violated_guidelines: [mutation?.id],
    };

    mutation.submitFns.submitBreach(payload);
  };

  useEffect(() => {
    let clearSuccess = setTimeout(() => {
      setSuccess("");
      setError("");
    }, 2000);
    return () => {
      clearTimeout(clearSuccess);
    };
  }, [success, error]);

  useEffect(() => {
    if (p?.violated_guideline_ids?.length > 0) {
      setSelectedReason(p.violated_guideline_ids[0]);
    }
  }, [p]);

  return (
    <tbody onMouseLeave={() => setShowOptions(false)}>
      <tr className="MessageRow" onMouseOver={() => setShowOptions(true)}>
        <td>
          {props.noUser ? null : p.bot ? (
            "Bot"
          ) : (
            <UserLink inline {...p.user} />
          )}
        </td>
        <td
          className={classnames(
            "MessageRow-body",
            p.bot
              ? `MessageRow-body--bot`
              : `MessageRow-body--${props.userIndex}`
          )}
        >
          <div>
            <MessageBody {...p} />
          </div>
        </td>
        <td width="1"></td>
        {/* <td width="1">
          {!p.bot && <MessageAbuseEditor api={props.api} message={p} />}
        </td> */}
        <td>
          <Moment time={p.created_at} />
        </td>

        <AlertDialog
          open={showModal}
          onCloseModal={() => setShowModal(false)}
          onSubmit={handleOnSubmitSevereBreach}
        />
        {error && <CustomizedSnackbar message={error} severity="error" />}
        {fakeLoading && (
          <CustomizedSnackbar message={fakeLoading} severity="info" />
        )}
        {success && <CustomizedSnackbar message={success} severity="success" />}
      </tr>

      {showOptions && (
        <ChipTableMessage
          api={props.api}
          message={p}
          guidelineList={data}
          reducedTd={true}
          onShowModal={handleOnShowModal}
          onError={(payload) => setError(payload)}
          onSuccess={(payload) => setSuccess(payload)}
          onLoading={(payload) => setFakeLoading(payload)}
          selectedReason={selectedReason}
          onSetSelectedReason={(payload) => setSelectedReason(payload)}
        />
      )}
    </tbody>
  );
};

MessageRow.Headers = () => (
  <tr>
    <th>From</th>
    <th>Body</th>
    <th></th>
    <th>Timestamp</th>
  </tr>
);

export default MessageRow;

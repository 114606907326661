import React from "react";
import PropTypes from "prop-types";

import BooleanSwitch from "./BooleanSwitch";

const languages = ["English", "Spanish"];

export default class CampaignFilterLanguage extends React.Component {
  static propTypes = {
    api: PropTypes.object.isRequired,
    campaign_id: PropTypes.number.isRequired,
    id: PropTypes.number.isRequired,
    negated: PropTypes.bool.isRequired,
    value_string: PropTypes.string.isRequired,
    onSave: PropTypes.func.isRequired,
    disabled: PropTypes.bool.isRequired,
  };

  render() {
    const p = this.props;

    return (
      <span className="CampaignFilterLanguage">
        user's language{" "}
        <BooleanSwitch
          api={p.api}
          campaign_id={p.campaign_id}
          id={p.id}
          value={p.negated}
          onSave={p.onSave}
          disabled={p.disabled}
        >
          {this.props.negated ? "is not" : "is"}
        </BooleanSwitch>{" "}
        <select
          onChange={this._onChange.bind(this)}
          style={{ padding: "2px 1ex", borderRadius: "3px", marginTop: 0 }}
        >
          {languages.map((language) => {
            return (
              <option value={language} selected={p.value_string === language}>
                {language}
              </option>
            );
          })}
        </select>
      </span>
    );
  }

  _onChange({ target: { value } }) {
    const p = this.props;
    this.props.api.CampaignFilters.update(p.campaign_id, p.id, {
      filter: { value_string: value },
    }).then(p.onSave);
  }
}

import React, { Component } from "react";
import PropTypes from "prop-types";

import inflection from "inflection";
import classnames from "classnames";

import UserLink from "./UserLink";
import Moment from "./Moment";
import CaseAssignButton from "./CaseAssignButton";
import CaseClaimButton from "./CaseClaimButton";

const formatType = {
  "Report::User": "User",
  "Report::UserPhoto": "Photo",
  "Report::Comment": "Comment",
  "Report::Post": "Post",
  "Report::PagesGroup": "Group",
  "Report::Conversation": "Chat",
};

export default class CaseRow extends Component {
  static propTypes = {
    api: PropTypes.object.isRequired,
    case: PropTypes.object.isRequired,
  };

  static Headers = () => (
    <tr>
      <th width="1">Reported user</th>
      <th width="1">Last report</th>
      <th>Reports summary</th>
      <th width="1">Status</th>
      <th width="1"></th>
      <th width="1"></th>
      <th>Assigned To</th>
      <th width="1"></th>
      <th nowrap="true"></th>
    </tr>
  );

  static Columns = 8;

  constructor(props) {
    super(props);
    this.state = {
      case: props.case,
      error: null,
    };

    this._onCaseUpdate = this._onCaseUpdate.bind(this);
  }

  _onCaseUpdate(params) {
    const { case: oldCase } = this.state;
    const newCase = { ...oldCase, ...params };

    this.setState({
      case: newCase,
    });
  }

  render() {
    const {
      case: { user: u },
      error: e,
    } = this.state;
    const nClosed = this._countClosed(this.state.case.reports);

    const {
      case: { id: caseId, claimed_by: claimedBy },
    } = this.state;
    const { id: claimedByUserId } = claimedBy || {};

    const currentUserId = this.props.api.Session.user.id;
    const linkDisabled = claimedByUserId !== currentUserId;

    return (
      <tr
        className={classnames(
          "CaseRow",
          e === true && "CaseRow--error",
          e === false && "CaseRow--ok"
        )}
      >
        <td width="1">
          <UserLink inline disabled={linkDisabled} {...u} />
        </td>
        <td>
          <Moment relative time={this.state.case.last_report_at} />
        </td>
        <td>
          <ul className="CaseRow-list">
            {this.state.case.reports.map((r) => this._renderReportGroup(r))}
            {nClosed > 0 && <li>{nClosed} old reports</li>}
          </ul>
        </td>
        <td className="CaseRow-status-col">
          <span
            className={classnames(
              `CaseRow-status`,
              `CaseRow-status--${this.state.case.status}`
            )}
          >
            {this.state.case.status}
          </span>
        </td>

        <td>
          {this.state.case.status_changed_at && (
            <Moment
              relative
              nosuffix
              time={this.state.case.status_changed_at}
            />
          )}
        </td>
        <td>
          <span className="nobreak">
            {inflection.ordinalize(`${this.state.case.strikes} strike`)}
          </span>
        </td>
        <td>
          {claimedBy ? `${claimedBy.first_name} ${claimedBy.last_name}` : "N/A"}
        </td>
        <td>
          {this.state.case.assigned_at && (
            <Moment relative time={this.state.case.assigned_at} />
          )}
        </td>
        <td style={{ textAlign: "right", whiteSpace: "nowrap" }}>
          <CaseAssignButton
            id={caseId}
            selectedUserId={claimedByUserId}
            api={this.props.api}
            onUpdate={this._onCaseUpdate}
          />
          &nbsp;
          <CaseClaimButton
            id={caseId}
            subjectUserId={u.id}
            selectedUserId={claimedByUserId}
            api={this.props.api}
            onUpdate={this._onCaseUpdate}
          />
        </td>
      </tr>
    );
  }

  _renderReportGroup(r) {
    if (r.open_count <= 0) {
      return;
    }
    return (
      <li key={r.report_type}>
        <span className="CaseRow-report-count">{r.open_count}</span>{" "}
        {formatType[r.report_type]}
      </li>
    );
  }

  _countClosed() {
    return this.state.case.reports
      .map((r) => r.closed_count)
      .reduce((a, x) => a + x, 0);
  }
}

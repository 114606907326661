import React, { Component } from "react";
import PropTypes from "prop-types";

import classnames from "classnames";

import h from "./helpers";

export default class ReportUser extends Component {
  static propTypes = {
    api: PropTypes.object.isRequired,
    type: PropTypes.string.isRequired,
    subject_id: PropTypes.number.isRequired,
    status: PropTypes.string.isRequired,

    created_at: PropTypes.string.isRequired,
    reason: PropTypes.string,
    notes: PropTypes.string,
    created_by: PropTypes.shape({
      id: PropTypes.number.isRequired,
      first_name: PropTypes.string,
    }).isRequired,
    user: PropTypes.shape({ first_name: PropTypes.string }),

    onUpdate: PropTypes.func,
    onToggleOptions: PropTypes.func,
  };

  static type = "report/user";
  static icon = "⚠️👤";

  render() {
    const p = this.props;

    return (
      <tr
        className={classnames(
          "EventsRow",
          "EventsReportUser",
          "EventsRow--" + (p.status === "open" ? "open" : "closed")
        )}
        onMouseOver={() => p.onToggleOptions(true)}
      >
        {h.sharedColumns(this)}
        <td>
          {h.renderActor(p.created_by)} reports{" "}
          {h.renderActor({
            id: p.subject_id,
            first_name: p.user ? p.user.first_name : `User#${p.subject_id}`,
          })}{" "}
          {h.renderNotes(p.notes, p.reason)}
        </td>
      </tr>
    );
  }
}

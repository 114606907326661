import React, { Component } from "react";
import PropTypes from "prop-types";

import SearchResults from "./SearchResults";
import ConversationRow from "./ConversationRow";

export default class UserTabChat extends Component {
  static propTypes = {
    api: PropTypes.object.isRequired,
    userId: PropTypes.string.isRequired,
  };

  render() {
    return (
      <SearchResults
        key="conversations"
        api={this.props.api.Conversations}
        query={{ user_id: this.props.userId }}
      >
        {(results) => (
          <table className="UserTabChat">
            <thead>
              <ConversationRow.Headers />
            </thead>
            <tbody>
              {results.items().map((c) => (
                <ConversationRow key={c.id} {...c} />
              ))}
            </tbody>
            <tfoot>
              <tr>
                <td>
                  <results.AutoLoad />
                </td>
              </tr>
            </tfoot>
          </table>
        )}
      </SearchResults>
    );
  }
}

import React, { Component } from "react";
import PropTypes from "prop-types";
import inflection from "inflection";

export default class Duration extends Component {
  static propTypes = {
    d: PropTypes.number,
  };

  render() {
    let unit = "second";
    let value = this.props.d || 0;

    if (value > 2 * 86400) {
      value = Math.round(value / 86400);
      unit = "day";
    } else if (value > 2 * 3600) {
      value = Math.round(value / 3600);
      unit = "hour";
    } else if (value > 2 * 60) {
      value = Math.round(value / 60);
      unit = "minute";
    }

    return (
      <React.Fragment>
        {value} {inflection.inflect(unit, value)}
      </React.Fragment>
    );
  }
}

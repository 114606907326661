import Base from "./Base";

export default class Groups extends Base {
  index(params) {
    const q = this._query(params);

    return this._fetch(`/admin/pages/groups?${q}`)
      .then((response) => response.json())
      .then((data) => [data.groups]);
  }

  show(id) {
    return this._fetch(`/admin/pages/groups/${id}`)
      .then((response) => response.json())
      .then((data) => data.group);
  }

  update({ id, ...params }) {
    return this._fetch(`/admin/pages/groups/${id}`, {
      method: "PATCH",
      passErrors: [409],
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(params),
    }).then((response) => {
      const isError = response.status === 409;

      return response.json().then((data) => {
        return { data: data, conflict: isError };
      });
    });
  }

  share(id) {
    return this._fetch(`/admin/pages/groups/${id}/share`).then((response) =>
      response.json()
    );
  }
}

/* eslint-disable react/jsx-pascal-case */
import React from "react";
import PropTypes from "prop-types";
import NumberFormat from "react-number-format";

import FilterTTC from "./CampaignFilter/TTC";
import FilterPregnant from "./CampaignFilter/Pregnant";
import FilterMenopause from "./CampaignFilter/Menopause";
import FilterLastActive from "./CampaignFilter/LastActive";
import FilterCountry from "./CampaignFilter/Country";
import FilterLanguage from "./CampaignFilter/Language";
import FilterGroup from "./CampaignFilter/Group";
import FilterTag from "./CampaignFilter/Tag";
import FilterAge from "./CampaignFilter/Age";
import FilterChildAge from "./CampaignFilter/ChildAge";
import FilterStub from "./CampaignFilter/Stub";
import ProgressBar from "./ProgressBar";

export default class CampaignFilterRow extends React.Component {
  static propTypes = {
    api: PropTypes.object.isRequired,
    id: PropTypes.number.isRequired,
    filter: PropTypes.shape({
      id: PropTypes.number.isRequired,
      type: PropTypes.string.isRequired,
      negated: PropTypes.bool.isRequired,
      value_string: PropTypes.string,
      value_int: PropTypes.number,
      status: PropTypes.string.isRequired,
      progress: PropTypes.number.isRequired,
      user_count: PropTypes.number,
    }).isRequired,
    onSave: PropTypes.func.isRequired,
    disabled: PropTypes.bool.isRequired,
  };

  static Types = {
    country: { class: FilterCountry, create: true },
    group: { class: FilterGroup, create: true },
    // location:     { class: FilterLocation,    create: true  },
    last_active: { class: FilterLastActive, create: true },
    pregnant: { class: FilterPregnant, create: true },
    ttc: { class: FilterTTC, create: true },
    menopause: { class: FilterMenopause, create: true },
    age: { class: FilterAge, create: true },
    child_age: { class: FilterChildAge, create: true },
    tag: { class: FilterTag, create: true },
    stub: { class: FilterStub, create: false },
    language: { class: FilterLanguage, create: true },
  };

  static Headers = () => (
    <React.Fragment>
      <th>Filter</th>
      <th>Status</th>
      <th>Progress</th>
      <th>#Users</th>
      <th />
    </React.Fragment>
  );

  render() {
    const p = this.props.filter;

    const Helper = CampaignFilterRow.Types[p.type];

    if (!Helper) return <React.Fragment />;

    return (
      <tr className="CampaignFilterRow">
        <td>
          <Helper.class
            api={this.props.api}
            campaign_id={this.props.id}
            onSave={this.props.onSave}
            id={p.id}
            negated={p.negated}
            value_int={p.value_int}
            value_string={p.value_string}
            disabled={this.props.disabled}
          />
        </td>
        <td>
          <span className="badge badge-pill badge-secondary">{p.status}</span>
        </td>
        <td>
          <ProgressBar value={p.progress / 10000} />
        </td>
        <td>
          <NumberFormat
            value={p.user_count}
            displayType="text"
            thousandSeparator
          />
        </td>
        <td>
          <button
            className="CampaignFilterRow-action"
            onClick={this._onDelete.bind(this)}
            disabled={this.props.disabled}
          >
            ⌧
          </button>
        </td>
      </tr>
    );
  }

  _onDelete() {
    this.props.api.CampaignFilters.destroy(
      this.props.id,
      this.props.filter.id
    ).then(this.props.onSave);
  }
}

import React, { Component } from "react";
import CaseAssignModal from "../CaseAssignModal";
import PropTypes from "prop-types";

class CaseAssignButton extends Component {
  constructor(props) {
    super(props);

    this.state = {
      displayModal: false,
    };

    this._onButtonClick = this._onButtonClick.bind(this);
    this._onModalClose = this._onModalClose.bind(this);
  }

  _onButtonClick() {
    this.setState({
      displayModal: true,
    });
  }
  _onModalClose(shouldReloadCase = false) {
    this.setState({
      displayModal: false,
    });
  }

  render() {
    const { api, id, selectedUserId, onUpdate } = this.props;
    const { displayModal } = this.state;

    return (
      <>
        <button className="CaseRow-assign" onClick={this._onButtonClick}>
          Assign
        </button>

        {displayModal && (
          <CaseAssignModal
            id={id}
            selectedUserId={selectedUserId}
            onClose={this._onModalClose}
            api={api}
            onUpdate={onUpdate}
          />
        )}
      </>
    );
  }
}

CaseAssignButton.propTypes = {
  id: PropTypes.number.isRequired,
  selectedUserId: PropTypes.number,
  api: PropTypes.object.isRequired,
  onUpdate: PropTypes.func.isRequired,
};

export default CaseAssignButton;

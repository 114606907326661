import React from "react";
import PropTypes from "prop-types";
import NumberFormat from "react-number-format";

import StringEditor from "../components/StringEditor";
import DateEditor from "../components/DateEditor";
import CampaignFilterRow from "../components/CampaignFilterRow";
import ProgressBar from "../components/ProgressBar";
import CampaignSubjectEditor from "../components/CampaignSubjectEditor";
import ModalForm from "../components/ModalForm";
import ButtonWithConfirmation from "../components/ButtonWithConfirmation";

export default class Campaign extends React.Component {
  static propTypes = {
    api: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      campaign: null,
      showScheduleModal: false,
    };

    this._timer = null;
  }

  render() {
    const p = this.state.campaign;
    if (!p) return <React.Fragment />;

    return (
      <div className="Campaign">
        <h2>
          <span className="text-muted">Campaign</span> #{p.id}
        </h2>

        <div className="Campaign-form">
          <label>Name</label>
          <StringEditor
            disabled={p.status !== "pending"}
            value={p.name}
            onSave={(x) => this._onUpdate({ name: x })}
          />

          <label>Push title</label>
          <StringEditor
            disabled={p.status !== "pending"}
            value={p.message.title}
            onSave={(x) => this._onUpdate({ message: { title: x } })}
          />

          <label>Push body</label>
          <StringEditor
            disabled={p.status !== "pending"}
            value={p.message.body}
            onSave={(x) => this._onUpdate({ message: { body: x } })}
          />

          <label>Deeplink to</label>
          <CampaignSubjectEditor
            disabled={p.status !== "pending"}
            value={p.subject}
            api={this.props.api}
            onSave={(x) => this._onUpdate(x)}
          />

          <label>Send at</label>
          <div className="Campaign-time">
            <DateEditor
              disabled={p.status !== "pending"}
              value={p.send_on}
              onSave={(x) => this._onUpdate({ send_on: x })}
            />

            <StringEditor
              disabled={p.status !== "pending"}
              value={p.send_at}
              onSave={(x) => this._onUpdate({ send_at: x })}
              format="^([0-1][0-9]|2[0-3]):[0-5][0-9]$"
            />

            <select
              className="Campaign-time-dropdown"
              onChange={this._onChangeTimeType.bind(this)}
              value={p.local_time ? "local" : "utc"}
              disabled={p.status !== "pending"}
            >
              <option value="local">Local time</option>
              <option value="utc">UTC</option>
            </select>
          </div>

          <label>Status</label>
          <div>
            {p.spread > 0 && (
              <div>
                {/* eslint-ignore jsx-a11y/accessible-emoji */}
                ⚠️ Pushes will be sent up to {Math.round(p.spread / 60)} minutes
                after the specified time.
              </div>
            )}

            <div>
              <span className={`Campaign-status Campaign-status--${p.status}`}>
                {p.status}
              </span>

              <ProgressBar value={p.progress / 10000} />
            </div>
          </div>
        </div>

        <div className="Campaign-actions">
          <select
            className="Campaign-action Campaign-select"
            onChange={this._onAddFilter.bind(this)}
            value=""
            disabled={this._isFilterRunning(p) || p.status !== "pending"}
          >
            <option value="">Add filter...</option>
            {Object.entries(CampaignFilterRow.Types)
              .filter(([k, v]) => v.create)
              .map(([k, v], idx) => (
                <option key={idx} value={k}>
                  {k}
                </option>
              ))}
          </select>

          <button
            className="Campaign-action"
            disabled={this._isFilterRunning(p) || p.status !== "pending"}
            onClick={this._onSyncFilters.bind(this)}
          >
            Sync filters
          </button>

          <button
            className="Campaign-action"
            disabled={!this._isTestable(p)}
            onClick={this._onTest.bind(this)}
          >
            Send test push
          </button>

          <button
            className="Campaign-action Campaign-schedule"
            disabled={!this._isSendable(p)}
            onClick={this.setState.bind(
              this,
              { showScheduleModal: true },
              null
            )}
          >
            Schedule
          </button>

          <ButtonWithConfirmation
            className="Campaign-action"
            disabled={!this._isCancellable(p)}
            title="Warning"
            message={"Are you sure you want to cancel this campaign?"}
            yesButtonLabel={"Cancel campaign"}
            noButtonLabel={"Back"}
            onClick={this._onCancel.bind(this)}
          >
            Cancel
          </ButtonWithConfirmation>
          {this.state.showScheduleModal && (
            <ModalForm
              title="Schedule campaign"
              form={
                <div>
                  <p>
                    This campaign will be scheduled for delivery to{" "}
                    <NumberFormat
                      value={this._targetUsers()}
                      displayType="text"
                      thousandSeparator
                    />{" "}
                    users if you confirm.
                  </p>
                  <p>
                    This action <em>cannot</em> be easily reverted
                  </p>
                </div>
              }
              onCancel={this.setState.bind(
                this,
                { showScheduleModal: false },
                null
              )}
              actions={
                <>
                  <button
                    className="Campaign-action"
                    onClick={this.setState.bind(
                      this,
                      { showScheduleModal: false },
                      null
                    )}
                  >
                    Cancel
                  </button>
                  <button
                    className="Campaign-action Campaign-schedule"
                    onClick={this.setState.bind(
                      this,
                      { showScheduleModal: false },
                      this._onSchedule.bind(this)
                    )}
                  >
                    Confirm
                  </button>
                </>
              }
            />
          )}
        </div>

        <table className="Campaign-filters">
          <thead>
            <tr>
              <CampaignFilterRow.Headers />
            </tr>
          </thead>
          <tbody>
            {p.filters.map((f, idx) => (
              <CampaignFilterRow
                key={idx}
                id={Number(this.props.match.params.id)}
                api={this.props.api}
                filter={f}
                disabled={this._isFilterRunning(p)}
                onSave={this._refresh.bind(this)}
              />
            ))}
          </tbody>
        </table>
      </div>
    );
  }

  componentDidMount() {
    this._refresh();
  }

  _refresh() {
    this.props.api.Campaigns.show(this.props.match.params.id).then((data) => {
      clearTimeout(this._timer);
      this.setState({ campaign: data }, () => {
        if (
          this._isFilterRunning(this.state.campaign) ||
          this.state.campaign.status === "scheduling"
        ) {
          setTimeout(this._refresh.bind(this), 1000);
        }
      });
    });
  }

  _onUpdate(params) {
    this.props.api.Campaigns.update(this.props.match.params.id, params).then(
      (data) => this.setState({ campaign: data })
    );
  }

  _onChangeTimeType(e) {
    e.preventDefault();
    this._onUpdate({ local_time: e.target.value === "local" });
  }

  _onAddFilter(e) {
    e.preventDefault();
    if (!e.target.value) return;

    this.props.api.CampaignFilters.create(this.props.match.params.id, {
      filter: { type: e.target.value },
    }).then(this._refresh.bind(this));
  }

  _isFilterRunning(campaign) {
    if (!campaign) return false;
    if (campaign.filters.find((f) => f.status === "running")) return true;
    return false;
  }

  _isTestable(campaign) {
    if (!campaign) return false;
    return true;
  }

  _isSendable(campaign) {
    if (!this.props.api.Session.user.roles.includes("CAMPAIGN_SEND"))
      return false;
    if (!campaign) return false;
    if (!["pending", "failed"].includes(campaign.status)) return false;
    if (campaign.filters.length === 0) return false;
    if (campaign.filters.find((f) => f.status !== "completed" && f.status !== "estimated")) return false;
    return true;
  }

  _isCancellable(campaign) {
    if (!this.props.api.Session.user.roles.includes("CAMPAIGN_SEND"))
      return false;
    if (!campaign) return false;
    if (!["pending", "scheduling", "scheduled"].includes(campaign.status))
      return false;
    return true;
  }

  _targetUsers() {
    if (!this.state.campaign) return;
    if (this.state.campaign.filters.length === 0) return;
    const filters = this.state.campaign.filters;
    return filters[filters.length - 1].user_count;
  }

  _onSyncFilters() {
    this.props.api.CampaignFilters.run(this.props.match.params.id).then(
      this._refresh.bind(this)
    );
  }

  _onTest() {
    this.props.api.Campaigns.test(this.props.match.params.id).then(
      this._refresh.bind(this)
    );
  }

  _onSchedule() {
    this.props.api.Campaigns.schedule(this.props.match.params.id).then(
      this._refresh.bind(this)
    );
  }

  _onCancel() {
    this.props.api.Campaigns.cancel(this.props.match.params.id).then(
      this._refresh.bind(this)
    );
  }
}

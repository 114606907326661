import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import inflection from "inflection";

import SearchResults from "../../components/SearchResults";
import SearchForm from "../../components/SearchForm";

class SEOPageRow extends React.Component {
  static propTypes = {
    data: PropTypes.shape({
      id: PropTypes.number.isRequired,
      slug: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      status: PropTypes.string.isRequired,
      category: PropTypes.shape({
        name: PropTypes.string.isRequired,
      }),
      tag: PropTypes.shape({
        name: PropTypes.string.isRequired,
      }),
    }).isRequired,

    api: PropTypes.object.isRequired,
  };

  static Headers = (pages) => (
    <tr>
      <th>Slug</th>
      <th>Title</th>
      {pages.type !== "blog" && <th className="SEOPages-category">Category</th>}
      {pages.type === "blog" && <th className="SEOPages-tag">Tag</th>}
      <th>Status</th>
      {pages.type === "blog" && <th>Featured</th>}
      <th className="SEOPages-actions">Actions</th>
    </tr>
  );

  constructor(props) {
    super(props);
    this.state = { data: this.props.data };
  }

  render() {
    const p = this.state.data;

    const url =
      p.type === "editorial"
        ? `${process.env.REACT_APP_MAIN_URL}/editorial/${p.slug}`
        : `${p.url}`;

    return (
      <tr>
        <td>{p.slug}</td>
        <td>{p.title}</td>
        {p.type !== "blog" && (
          <td className="SEOPages-category">{p.category && p.category.name}</td>
        )}
        {p.type === "blog" && (
          <td className="SEOPages-tag">{p.tag && p.tag.name}</td>
        )}
        <td>{p.status}</td>
        {p.type === "blog" && (
          <td className="SEOPages-featured">
            <input
              type="checkbox"
              checked={p.featured}
              onChange={this._setFeatured.bind(this, !p.featured)}
            />
          </td>
        )}
        <td className="SEOPages-actions">
          <Link className="SEOPages-action" to={`/seo/pages/${p.id}`}>
            Edit
          </Link>
          <a className="SEOPages-action" href={url} target="_preview">
            Preview
          </a>
        </td>
      </tr>
    );
  }

  _refresh() {
    this.props.api.SEO.Pages.show(this.props.data.id).then((data) =>
      this.setState({ data: data })
    );
  }

  _setFeatured(newFeatured) {
    this.props.api.SEO.Pages.update(this.props.data.id, {
      featured: newFeatured,
    }).then(this._refresh.bind(this));
  }
}

export default class SEOPages extends React.Component {
  static propTypes = {
    api: PropTypes.object,
  };

  constructor(props) {
    super(props);
    this.state = {
      query: {
        text: "",
        type: this.props.match.params.type,
      },
    };
  }

  render() {
    const type = this.props.match.params.type;

    return (
      <div className={`SEOPages SEOPages--${type}`}>
        <div className="SEOPages-heading">
          <h1>SEO ▸ {inflection.capitalize(type)} pages</h1>

          <button onClick={this._create.bind(this)} className="SEOPages-action">
            Create
          </button>
        </div>

        {(type === "blog" || type === "community") && (
          <SearchForm
            query={this.state.query}
            onSubmit={(q) => this.setState({ query: q })}
            className="SEOPages-form"
          >
            {(form) => (
              <React.Fragment>
                {type === "blog" && (
                  <form.Input label="Text" name="text" className="text" />
                )}
                <form.RadioGroup
                  title="Language"
                  name="language"
                  labels={["English", "Spanish"]}
                  options={["en", "es"]}
                />

                <form.Hidden name="type" />
              </React.Fragment>
            )}
          </SearchForm>
        )}

        <SearchResults api={this.props.api.SEO.Pages} query={this.state.query}>
          {(results) => (
            <table className="SEOPages-table">
              <thead>
                <SEOPageRow.Headers type={type} />
              </thead>
              <tbody>
                {results.items().map((p) => (
                  <SEOPageRow key={p.id} data={p} api={this.props.api} />
                ))}
              </tbody>
            </table>
          )}
        </SearchResults>
      </div>
    );
  }

  _create() {
    this.props.api.SEO.Pages.create({
      type: this.props.match.params.type,
    }).then((data) => {
      window.location = `/seo/pages/${data.id}`;
    });
  }
}

import React, { Component } from "react";
import PropTypes from "prop-types";
import ButtonWithConfirmation from "./ButtonWithConfirmation";

export default class SeoIndexingButton extends Component {
  static propTypes = {
    api: PropTypes.object.isRequired,
    seoIndexedEntityId: PropTypes.number.isRequired,
    seoIndexed: PropTypes.bool.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = this.props;
  }

  render() {
    return (
      <ButtonWithConfirmation
        onClick={this._toggleSeoIndexed.bind(this)}
        title="Warning"
        message="Modifying SEO indexing will affect the sitemap. Are you sure?"
      >
        {this.state.seoIndexed === true ? "Remove" : "Add"} indexing
      </ButtonWithConfirmation>
    );
  }

  _toggleSeoIndexed() {
    this.props.api
      .update({
        id: this.props.seoIndexedEntityId,
        seo_indexed: !this.state.seoIndexed,
      })
      .then(() => this.setState({ seoIndexed: !this.state.seoIndexed }));
  }
}

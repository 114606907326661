import React from "react";
import useStatusWarnings from "../hooks/api/useStatusWarnings";

const UserStatusWarning = (userID) => {
  const { data } = useStatusWarnings(userID);

  return (
    <>
      {data ? (
        <span
          className={`badge badge-pill badge-warning`}
          style={{ margin: "0 4px" }}
        >
          {data?.warning_type}
        </span>
      ) : (
        ""
      )}
    </>
  );
};

export default UserStatusWarning;

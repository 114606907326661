import React, { useCallback } from "react";
import classNames from "classnames";
function ImageOption({ name, image, onClick, selected }) {
  const handleOnClick = useCallback(() => {
    onClick && onClick(name);
  }, [name, onClick]);
  return (
    <div
      className={classNames("ImageOption", { selected: selected })}
      onClick={handleOnClick}
    >
      <img src={image} alt={name} />
    </div>
  );
}

export default ImageOption;

import React, { Component } from "react";
import PropTypes from "prop-types";

import classnames from "classnames";

export default class StatusButton extends Component {
  static propTypes = {
    className: PropTypes.string,
    status: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired,
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
  };

  static defaultProps = {
    className: "",
  };

  render() {
    const p = this.props;

    return (
      <button
        type="button"
        className={classnames(
          `StatusButton`,
          `StatusButton--${p.status}`,
          p.className
        )}
        onClick={p.onClick}
        disabled={p.disabled}
      >
        {this.props.children}
      </button>
    );
  }
}

import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Player, BigPlayButton } from "video-react";

import StatusButton from "../../components/StatusButton";
import UserLink from "../../components/UserLink";
import EventRow from "../../components/events/Row";

class Videos extends Component {
  constructor(props) {
    super(props);

    this.state = {
      video: null,
    };
    this._setThumbnail = this._setThumbnail.bind(this);
    this._nudityLabel = this._nudityLabel.bind(this);
    this._loadVideo = this._loadVideo.bind(this);
  }

  componentDidMount() {
    this._loadVideo();
  }

  _commentLink() {
    const {
      video: {
        comment: { id, post_id, post_title },
      },
    } = this.state;
    return (
      <Link to={`/posts/${post_id}#comment_${id}`}>
        <span className="PostItem-tag tag-category">Comment</span>&nbsp;on{" "}
        {post_title}
      </Link>
    );
  }

  _loadVideo() {
    const {
      api: { StoredVideos: videoApi },
      match: {
        params: { id },
      },
    } = this.props;

    videoApi.show(id).then((data) => {
      this.setState({ video: data });
    });
  }

  _postLink() {
    const {
      video: {
        post: { id, title },
      },
    } = this.state;
    return (
      <Link to={`/posts/${id}`}>
        <span className="PostItem-tag tag-category">Post</span>&nbsp;
        {title}
      </Link>
    );
  }

  _nudityLabel() {
    const {
      video: {
        details: { detected_nsfw },
      },
    } = this.state;
    switch (detected_nsfw) {
      case true:
        return "😈 nudity";
      case false:
        return "😇 safe";
      default:
        return "👤 unknown";
    }
  }

  _renderLoading() {
    return <div>Loading...</div>;
  }

  _setThumbnail() {
    const {
      api: { StoredVideos: videoApi },
      match: {
        params: { id },
      },
    } = this.props;

    const {
      player: { currentTime },
    } = this.player.getState();
    this.player.pause();

    videoApi.update(id, { thumbnail_position: Math.round(currentTime) });
  }

  _sourceType() {
    const {
      video: { post, comment },
    } = this.state;

    if (post) {
      return "post";
    } else if (comment) {
      return "comment";
    } else {
      return "unknown";
    }
  }

  _sourceLink() {
    switch (this._sourceType()) {
      case "post":
        return this._postLink();
      case "comment":
        return this._commentLink();
      default:
        return <></>;
    }
  }

  render() {
    if (!this.state.video) {
      return this._renderLoading();
    }

    const {
      video: {
        id,
        status,
        details: { transcript },
        reports,
        thumbnail_url,
        url,
        user,
      },
    } = this.state;

    return (
      <div className="container">
        <div className={"outerContainer"}>
          <div className={"metaContainer"}>
            <h1 className="name">Video #{id}</h1>

            <div>
              <div className={"metaLabel"}>Status:</div>
              <div className={"metaDetails"}>
                <StatusButton onClick={() => {}} status={status}>
                  {status}
                </StatusButton>
              </div>
            </div>

            <div>
              <div className={"metaLabel"}>Uploaded by:</div>
              <div className={"metaDetails"}>
                <UserLink {...user} />
              </div>
            </div>

            <div>
              <div className={"metaLabel"}>Status:</div>
              <div className={"metaDetails"}>{this._sourceLink()}</div>
            </div>

            <div>
              <div className={"metaLabel"}>Status:</div>
              <div className={"metaDetails"}>{this._nudityLabel()}</div>
            </div>

            <div>
              <div className={"metaLabel"}>Transcript:</div>
              <div className={"metaDetails"}>
                <blockquote>
                  {transcript || "Transcript unavailable"}
                </blockquote>
              </div>
            </div>
          </div>

          <div className={"videoContainer"}>
            <Player
              ref={(player) => {
                this.player = player;
              }}
              playsInline
              poster={thumbnail_url}
              src={url}
            >
              <BigPlayButton position="center" />
            </Player>

            <button onClick={this._setThumbnail}>Set Thumbnail</button>
          </div>
        </div>

        <h4>Reports</h4>
        <table className="UserTabLogs-table">
          <thead>
            <tr>
              <th width="1"></th>
              <th width="1"></th>
              <th>Activity</th>
              <th style={{ width: 140 }}>Reports / Actions</th>
            </tr>
          </thead>
          <tbody>
            {reports &&
              reports.map((report) => {
                return (
                  <EventRow
                    api={this.props.api}
                    type={report.type}
                    data={report}
                  />
                );
              })}
            on
          </tbody>
        </table>
      </div>
    );
  }
}

export default Videos;

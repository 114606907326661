import Base from "./Base";

export default class Messages extends Base {
  index(params) {
    const q = this._query(params);

    return this._fetch(`/admin/chat/messages?${q}`)
      .then((response) => response.json())
      .then((data) => [data.messages]);
  }

  update(id, abuse) {
    return this._fetch(`/admin/chat/messages/${id}`, {
      method: "PATCH",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        abuse: abuse,
      }),
    }).then((response) => response.json());
  }
}

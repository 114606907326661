import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

export default class StringEditor extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    onSave: PropTypes.func.isRequired,
    value: PropTypes.string,
    size: PropTypes.number,
    disabled: PropTypes.bool,
    format: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    inline: PropTypes.bool,
    placeholder: PropTypes.string,
  };

  constructor(props) {
    super(props);
    this.state = { value: this.props.value, changed: false };
  }

  render() {
    return (
      <div
        className={classnames(
          "StringEditor",
          this.props.inline && "StringEditor--inline",
          this.props.className
        )}
      >
        <div className="StringEditor-container">
          <input
            className="StringEditor-input"
            size={this.props.size || 30}
            onChange={(e) => {
              this.setState({
                changed: this.props.value !== e.target.value,
                value: e.target.value,
              });
            }}
            onKeyPress={(e) => {
              if (e.key !== "Enter") return;
              e.preventDefault();
              this._maybeSave();
            }}
            onBlur={this._maybeSave.bind(this)}
            value={this.state.value || ""}
            disabled={this.props.disabled}
            placeholder={this.props.placeholder}
          />
          <div className="StringEditor-after">
            <div
              className={classnames(
                `StringEditor-flag`,
                this.state.changed && `StringEditor-flag--unsaved`,
                this._validFormat() || `StringEditor-flag--invalid`
              )}
              onClick={this._maybeSave.bind(this)}
            >
              ⏎
            </div>
          </div>
          {this.props.children}
        </div>
      </div>
    );
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      this.setState({ value: this.props.value, changed: false });
    }
  }

  _maybeSave() {
    if (!this._validFormat()) return;
    if (!this.state.changed) return;
    this.props.onSave(this.state.value);
  }

  _validFormat() {
    if (!this.props.format) return true;
    return new RegExp(this.props.format).test(this.state.value);
  }
}

import React, { Component } from "react";
import * as Sentry from "@sentry/browser";

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, info) {
    this.setState({ hasError: true, error: error });
    Sentry.captureException(error);
  }

  reload() {
    window.location.reload();
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="ErrorBoundary">
          <h1>
            <span role="img" aria-label="flushed">
              😳
            </span>
          </h1>
          <h1 className="ErrorBoundary-section">Oops! Something went wrong.</h1>

          <p className="ErrorBoundary-section">
            We have registered the error and will look into it as soon as
            possible.
            <br />
            You can try{" "}
            <span className="text-link" onClick={this.reload}>
              reloading the page
            </span>
            , and see if it happens again.
          </p>

          <pre className="ErrorBoundary-section">Error: {this.state.error}</pre>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;

import React from "react";
import PropTypes from "prop-types";

import CheckboxFilter from "../CheckboxFilter";

const channels = {
  sms: "SMS",
  facebook: "Facebook",
  whatsapp: "WhatsApp",
  email: "Email",
  fb_messenger: "FB Messenger",
  offline: "Offline",
  other: "Other",
  direct_mail: "Direct Mail",
};

const ReferralMethodFilter = (props) => {
  return (
    <CheckboxFilter {...props} title={"Referral Methods"} values={channels} />
  );
};

ReferralMethodFilter.propTypes = {
  selectedValues: PropTypes.arrayOf(PropTypes.string).isRequired,
  onChange: PropTypes.func.isRequired,
};

ReferralMethodFilter.defaultProps = {
  selectedValues: [],
  onChange: () => {},
};

export default ReferralMethodFilter;

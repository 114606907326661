import React, { Component } from "react";
import PropTypes from "prop-types";

import AutoAnnotationUrlField from "./auto_annotation_url_field";
import styles from "./auto_annotations.module.scss";

export default class AutoAnnotationUrlsForm extends Component {
  static propTypes = {
    api: PropTypes.object.isRequired,
    auto_annotation: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = { urls: props.auto_annotation.urls };
  }

  componentWillReceiveProps(nextProps) {
    if (this.props !== nextProps) {
      this.setState({
        urls: nextProps.auto_annotation.urls,
      });
    }
  }

  render() {
    return (
      <div>
        <h3>Edit urls for {this.props.auto_annotation.theme}</h3>
        {Object.entries(this.state.urls).map(([countryCode, url]) => (
          <AutoAnnotationUrlField
            auto_annotation={this.props.auto_annotation}
            api={this.props.api}
            key={countryCode}
            countryCode={countryCode}
            url={url}
          />
        ))}

        <button className={styles.addBtn} onClick={this.onAddUrl.bind(this)}>
          Add url
        </button>
      </div>
    );
  }

  onAddUrl() {
    let newUrls = this.state.urls;
    newUrls[""] = "";
    this.setState({ urls: newUrls });
  }
}

import React from "react";
import useLandingPages from "../../hooks/api/useLandingPages";

import LandingPageTable from "../../components/LandingPageTable";

import Cookies from "universal-cookie";
import { useHistory } from "react-router-dom";

const LandingPages = () => {
  const { data } = useLandingPages();
  const history = useHistory();

  const handleCreateNew = async () => {
    const cookies = new Cookies();
    const xsrf_token = cookies.get("XSRF-TOKEN");

    const response = await fetch(
      `${process.env.REACT_APP_API_HOST}/admin/seo/pages?type=landingpage`,
      {
        method: "POST",
        credentials: "include",
        cors: "no-cors",
        headers: {
          "Content-Type": "application/json",
          "X-XSRF-Token": xsrf_token,
        },
      }
    );
    const { id } = await response.json();
    history.push(`/landing-pages/${id}`);
  };

  return (
    <div className="LandingPages">
      <main className="wrapper">
        <header>
          <h1>Landing Pages</h1>
          <div>
            <button className="SEOPage-action" onClick={handleCreateNew}>
              Create new
            </button>
          </div>
        </header>
        {data && <LandingPageTable data={data.pages} />}
      </main>
    </div>
  );
};

export default LandingPages;

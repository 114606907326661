import { useQuery } from "react-query";

import Cookies from "universal-cookie";

const usePurchaseHistory = (userId) => {
  const cookies = new Cookies();
  const xsrf_token = cookies.get("XSRF-TOKEN");

  const fetchPurchaseHistory = async () => {
    const url = new URL(
      `${process.env.REACT_APP_API_HOST}/admin/users/${userId}/purchased_products`
    );

    const response = await fetch(url, {
      method: "GET",
      credentials: "include",
      cors: "no-cors",
      headers: {
        "Content-Type": "application/json",
        "X-XSRF-Token": xsrf_token,
      },
    });

    const data = await response.json();

    return data.purchased_products;
  };

  return useQuery(["purchaseHistory", userId], fetchPurchaseHistory);
};

export default usePurchaseHistory;

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Grid,
  FormLabel,
  FormControl,
  CircularProgress,
} from "@material-ui/core";
import { Waypoint } from "react-waypoint";

import useUserReferrals from "../../hooks/api/useUserReferrals";
import useFilters from "../../hooks/useFilters";

import ReferralChannelsFilter from "../../components/Filters/ReferralChannelsFilter";
import ReferralMethodsFilter from "../../components/Filters/ReferralMethodsFilter";
import CheckboxGroupFilter from "../../components/Filters/CheckboxGroupFilter";
import UserFilter from "../../components/Filters/UserFilter";

import UserReferralsRow from "./UserReferralsRow";

const initialReferral = {
  initial_referral: { label: "Initial Referrals", checked: false },
};

const UserReferrals = (props) => {
  const [initialReferralOnly, setInitialReferral] = useState(initialReferral);

  const [userFilterValues, setUserFilterValues] = useState({});
  const [referrerFilterValues, setReferrerFilterValues] = useState({});

  const filters = useFilters([
    "referral_channels",
    "referral_methods",
    "user[first_name]",
    "user[last_name]",
    "user[email]",
    "user[id]",
    "user[uid]",
    "referrer[first_name]",
    "referrer[last_name]",
    "referrer[email]",
    "referrer[id]",
    "referrer[uid]",
    "initial_referral",
  ]);

  const { data, isFetching, hasNextPage, fetchNextPage } = useUserReferrals(
    filters.current
  );

  const rows =
    data && data.pages
      ? data.pages.reduce((result, page) => result.concat(page), [])
      : Array.apply(null, Array(50));

  useEffect(() => {
    setUserFilterValues({
      email: filters.current["user[email]"],
      first_name: filters.current["user[first_name]"],
      id: filters.current["user[id]"],
      last_name: filters.current["user[last_name]"],
      uid: filters.current["user[uid]"],
    });
  }, [JSON.stringify(filters.group("user"))]);

  useEffect(() => {
    setReferrerFilterValues({
      email: filters.current["referrer[email]"],
      first_name: filters.current["referrer[first_name]"],
      id: filters.current["referrer[id]"],
      last_name: filters.current["referrer[last_name]"],
      uid: filters.current["referrer[uid]"],
    });
  }, [JSON.stringify(filters.group("referrer"))]);

  useEffect(() => {
    setInitialReferral({
      initial_referral: {
        ...initialReferral.initial_referral,
        checked: filters.current.initial_referral === "true",
      },
    });
  }, [filters.current.initial_referral]);

  const handleReferralChannelChange = (selectedChannels) => {
    const updatedFilters = Object.assign(filters.current, {
      referral_channels: selectedChannels,
    });
    filters.apply(updatedFilters);
  };

  const handleReferralMethodChange = (selectedChannels) => {
    const updatedFilters = Object.assign(filters.current, {
      referral_methods: selectedChannels,
    });
    filters.apply(updatedFilters);
  };

  const handleUserFilterChange = (userFilters) => {
    const updatedFilters = Object.keys(userFilters).reduce((result, key) => {
      if (userFilters[key] && userFilters[key].length > 0) {
        result[`user[${key}]`] = userFilters[key];
      }
      return result;
    }, {});

    filters.apply(updatedFilters);
  };

  const handleReferrerFilterChange = (referrerFilters) => {
    const updatedFilters = Object.keys(referrerFilters).reduce(
      (result, key) => {
        if (referrerFilters[key] && referrerFilters[key].length > 0) {
          result[`referrer[${key}]`] = referrerFilters[key];
        }
        return result;
      },
      {}
    );

    filters.apply(updatedFilters);
  };
  const handleInitialReferralChange = ({ initial_referral: { checked } }) => {
    const updatedFilters = Object.assign(filters.current, {
      initial_referral: checked,
    });
    if (!checked) {
      delete updatedFilters.initial_referral;
    }

    filters.apply(updatedFilters);
  };

  return (
    <div className="Experiments">
      <h2>User Referrals</h2>

      <Grid container spacing={2}>
        <Grid item xs={2}>
          <ReferralChannelsFilter
            selectedValues={filters.current.referral_channels}
            title="Referral Channels"
            onChange={handleReferralChannelChange}
          />
        </Grid>

        <Grid item xs={2}>
          <ReferralMethodsFilter
            selectedValues={filters.current.referral_methods}
            title="Referral Methods"
            onChange={handleReferralMethodChange}
          />
        </Grid>

        <Grid item xs={3}>
          <FormControl component="fieldset" fullWidth>
            <FormLabel component="legend">User</FormLabel>
            <UserFilter
              onChange={handleUserFilterChange}
              values={userFilterValues}
            />
          </FormControl>
        </Grid>
        <Grid item xs={3}>
          <FormControl component="fieldset" fullWidth>
            <FormLabel component="legend">Referrer</FormLabel>
            <UserFilter
              onChange={handleReferrerFilterChange}
              title="Filter Referrers"
              values={referrerFilterValues}
            />
          </FormControl>
        </Grid>
        <Grid item xs={2}>
          <FormControl component="fieldset" fullWidth>
            <FormLabel component="legend">Referral Channel</FormLabel>
            <CheckboxGroupFilter
              onChange={handleInitialReferralChange}
              items={initialReferralOnly}
            />
          </FormControl>
        </Grid>
      </Grid>

      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell align="right">User</TableCell>
              <TableCell align="right">Referrer</TableCell>
              <TableCell align="right">Initial</TableCell>
              <TableCell align="right">Referral Channel</TableCell>
              <TableCell align="right">Referral Method</TableCell>
              <TableCell align="right">Created At</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, index) => {
              return (
                <UserReferralsRow
                  key={`user_referrer_row_${row ? row.id : index}`}
                  {...row}
                />
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>

      {isFetching && (
        <div style={{ textAlign: "center", marginTop: 30, marginBottom: 30 }}>
          <CircularProgress />
        </div>
      )}
      {!isFetching && hasNextPage && <Waypoint onEnter={fetchNextPage} />}
    </div>
  );
};

export default UserReferrals;

import React from "react";
import PropTypes from "prop-types";

import PageCommon from "./PageCommon";

class ParentSelector extends React.Component {
  static propTypes = {
    id: PropTypes.number,
    excludeId: PropTypes.number.isRequired,
    api: PropTypes.object.isRequired,
    onUpdate: PropTypes.func.isRequired,
    className: PropTypes.string,
  };

  constructor(props) {
    super(props);
    this.state = { pages: [] };
  }

  componentDidMount() {
    this.props.api.SEO.Pages.index({ type: "editorial" }).then(([pages]) =>
      this.setState({ pages: pages })
    );
  }

  render() {
    if (!this.state.pages) return <React.Fragment />;
    const p = this.props;

    return (
      <select
        className={p.className}
        onChange={(e) => p.onUpdate(e.target.value)}
        value={p.id || "none"}
      >
        <option key="none" value="">
          None
        </option>
        {this.state.pages
          .filter((p) => p.id !== this.props.excludeId)
          .map((p) => (
            <option key={p.id} value={p.id}>
              {p.title} (#{p.id})
            </option>
          ))}
      </select>
    );
  }
}

export default class SEOPageEditorial extends React.Component {
  static propTypes = {
    page: PropTypes.shape({
      id: PropTypes.number.isRequired,
      parent: PropTypes.shape({
        id: PropTypes.number,
      }),
    }),

    api: PropTypes.object.isRequired,
    onUpdate: PropTypes.func.isRequired,
  };

  render() {
    const p = this.props;

    return (
      <PageCommon
        api={p.api}
        page={p.page}
        onUpdate={p.onUpdate}
        className="SEOPageEditorial"
        allowBlankTitle={true}
        allowBlankSummary={true}
      >
        <label>Parent</label>
        <ParentSelector
          api={p.api}
          id={p.page.parent ? p.page.parent.id : null}
          excludeId={p.page.id}
          onUpdate={this._doUpdate.bind(this, "parent_id")}
          className="SEOPageEditorial-selector"
        />
      </PageCommon>
    );
  }

  _doUpdate(field, value) {
    this.props.api.SEO.Pages.update(this.props.page.id, {
      [field]: value,
    }).then(this.props.onUpdate.bind(this));
  }
}

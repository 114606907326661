// eslint-disable-next-line no-unused-vars
import React from "react";
import DefaultRow from "../DefaultRow";

export default class RoomCreatedRow extends DefaultRow {
  static action = "room_created";
  static label = "Room Created";
  static report = false;

  _notes() {
    return <>{this._firstName()} created the room.</>;
  }
}

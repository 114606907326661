import Base from "./Base";

export default class UserLogs extends Base {
  index(params) {
    const { id, ...rest } = params;
    const q = this._query(rest);
    return this._fetch(`/admin/users/${params.id}/log?${q}`)
      .then((res) => res.json())
      .then((data) => [data.user_logs]);
  }
}

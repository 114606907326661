import Base from "./Base";

export default class UserEvents extends Base {
  index(id, params = {}) {
    const q = this._query(params);

    return this._fetch(`/admin/user_events/${id}?${q}`).then((response) =>
      response.json()
    );
  }
}

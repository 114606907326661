import React, { Component } from "react";
import PropTypes from "prop-types";

import h from "./helpers";
export default class HistoryBreach extends Component {
  static propTypes = {
    userID: PropTypes.number,
    id: PropTypes.number.isRequired,
    created_at: PropTypes.string.isRequired,
    changeset: PropTypes.object.isRequired,
    breach: PropTypes.object.isRequired,
    notes: PropTypes.string,
    created_by: PropTypes.shape({
      id: PropTypes.number.isRequired,
      first_name: PropTypes.string.isRequired,
    }).isRequired,
    onToggleOptions: PropTypes.func,
  };

  static type = "history/breach";
  static icon = "🤖";

  render() {
    const p = this.props;

    const breachType = p?.guidelineList?.map((item) =>
      item.id === p.breach.guideline_id ? `${item.icon} ${item.title}` : ""
    );

    return (
      <tr className="HistoryBreach" onMouseOver={() => p.onToggleOptions(true)}>
        {h.sharedColumns(this)}
        <>
          <td>
            {h.renderActor(p.created_by)} has{" "}
            <strong>
              {Object.keys(p.changeset).length === 0
                ? "submitted"
                : "withdrawn"}
            </strong>{" "}
            a breach ({breachType}){h.renderNotes(p.notes, p.reason)}
            <br />
            {h.renderActor({
              id: p?.breach?.reports[0]?.actor_id,
            })}{" "}
            reports{" "}
            {h.renderActor({
              id: p.breach.subject_id,
              first_name: p.user
                ? p.user.first_name
                : `User#${p.breach.subject_id}`,
            })}
            {p.breach.reports.map((i) => h.renderNotes(i.notes, i.reason))}
          </td>
        </>
        <td></td>
      </tr>
    );
  }
}

import Base from "./Base";

export default class UserDevices extends Base {
  index(params) {
    const q = this._query(params);

    return this._fetch(`/admin/user_devices?${q}`)
      .then((response) => response.json())
      .then((data) => [data.user_devices]);
  }

  silent_push(id) {
    return this._fetch(`/admin/user_devices/${id}/silent_push`, {
      method: "PUT",
    }).then((response) => response.json());
  }
}

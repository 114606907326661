import Base from "./Base";

export default class Session extends Base {
  create(googleAuthToken) {
    const params = {
      token: googleAuthToken,
    };

    return this._fetch("/admin/session", {
      method: "POST",
      body: JSON.stringify(params),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        this.user = data;
        return data;
      });
  }

  destroy() {
    return this._fetch("/admin/session", {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
    }).then(() => {
      this.user = null;
    });
  }

  show() {
    return this._fetch("/admin/session")
      .then((response) => response.json())
      .then((data) => {
        this.user = data;
        return data;
      });
  }
}

import React, { Component } from "react";
import PropTypes from "prop-types";

import UserProfile from "../components/UserProfile";
import UserTabLogs from "../components/UserTabLogs";
import UserTabInfo from "../components/UserTabInfo";
import UserTabGroups from "../components/UserTabGroups";
import UserTabLiveAudioRooms from "../components/UserTabLiveAudioRooms";
import UserTabPhotos from "../components/UserTabPhotos";
import UserTabPosts from "../components/UserTabPosts";
import UserTabComments from "../components/UserTabComments";
import UserTabChat from "../components/UserTabChat";
import UserTabDevices from "../components/UserTabDevices";
import UserTabStats from "../components/UserTabStats";
import UserTabMVP from "../components/UserTabMVP";
import UserTabWaves from "../components/UserTabWaves";

export default class User extends Component {
  static propTypes = {
    api: PropTypes.object,
  };

  constructor(props) {
    super(props);
    this.state = {
      user: {},
      tab: "log",
      triggerStatusButtonClick: false,
    };
    this._updateTab = this._updateTab.bind(this);
  }

  render() {
    const d = this.state.user;
    const roles = this.props.api.Session.user.roles;

    if (d.id === undefined) {
      return (
        <div className="User">
          <p>Loading...</p>
        </div>
      );
    }

    return (
      <div className="User">
        <UserProfile
          api={this.props.api}
          user={this.state.user}
          onRefresh={this._refresh.bind(this)}
          triggerStatusButtonClick={this.state.triggerStatusButtonClick}
          resetTriggerStatusButtonClick={() =>
            this.setState({ triggerStatusButtonClick: false })
          }
        />

        <section className="User-tabs">
          <nav>
            {this._tabLink("log")}
            {this._tabLink("info")}
            {this._tabLink("stats")}
            {this._tabLink("photos")}
            {this._tabLink("pods")}
            {this._tabLink("posts")}
            {this._tabLink("comments")}
            {this._tabLink("chat")}
            {this._tabLink("mvp")}
            {this._tabLink("groups")}
            {roles.includes("STAFF") && this._tabLink("discovery")}
            {roles.includes("DEVELOPER") && this._tabLink("devices")}
          </nav>
          {this._currentTab()}
        </section>
      </div>
    );
  }

  componentDidMount() {
    this._updateTab();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.match.params.id !== this.props.match.params.id) {
      this._refresh();
    }

    const tab = window.location.hash.replace(/^#/, "");
    if (tab.length && tab !== this.state.tab) {
      this._updateTab();
    }
  }

  _updateTab() {
    const newState = {};
    const tab = window.location.hash.replace(/^#/, "");
    if (tab.length > 0) {
      newState.tab = tab;
    }

    this.setState(newState, this._refresh.bind(this));
  }

  _userID() {
    return Number(this.props.match.params.id);
  }

  _refresh(triggerStatusButtonClick = false) {
    this.props.api.Users.show(this.props.match.params.id).then((data) => {
      this.setState({ user: data });

      if (triggerStatusButtonClick && data.account_status === "active") {
        this.setState({ triggerStatusButtonClick });
      }
    });
  }

  _tabLink(tab) {
    const f = (e) => {
      this.setState({ tab: tab });
    };
    const c = this.state.tab === tab ? "active" : "";
    return (
      <a href={`#${tab}`} className={c} onClick={f.bind(this)}>
        {tab}
      </a>
    );
  }

  _currentTab() {
    const t = this.state.tab;

    if (t === "info")
      return <UserTabInfo api={this.props.api} data={this.state.user} />;
    if (t === "stats")
      return (
        <UserTabStats
          api={this.props.api}
          user={this.state.user}
          onUpdate={(value) => this._refresh(value)}
        />
      );
    if (t === "log")
      return (
        <UserTabLogs
          api={this.props.api}
          userID={this._userID()}
          userStatus={this.state.user.account_status}
          onUpdate={(value) => this._refresh(value)}
        />
      );
    if (t === "photos")
      return (
        <UserTabPhotos
          api={this.props.api}
          user={this.state.user}
          onUpdate={this._refresh.bind(this)}
        />
      );
    if (t === "pods")
      return (
        <UserTabLiveAudioRooms
          api={this.props.api}
          participantUserId={this.props.match.params.id}
        />
      );
    if (t === "posts")
      return (
        <UserTabPosts
          api={this.props.api}
          authorId={this.props.match.params.id}
        />
      );
    if (t === "comments")
      return (
        <UserTabComments
          api={this.props.api}
          authorId={this.props.match.params.id}
        />
      );
    if (t === "chat")
      return (
        <UserTabChat api={this.props.api} userId={this.props.match.params.id} />
      );
    if (t === "mvp")
      return (
        <UserTabMVP api={this.props.api} userId={this.props.match.params.id} />
      );
    if (t === "groups")
      return (
        <UserTabGroups
          api={this.props.api}
          userId={this.props.match.params.id}
        />
      );
    if (t === "discovery")
      return <UserTabWaves userId={this.props.match.params.id} />;
    if (t === "devices")
      return (
        <UserTabDevices
          api={this.props.api}
          userId={this.props.match.params.id}
        />
      );
    return <p>Not implemented</p>;
  }
}

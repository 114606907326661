import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link, withRouter } from "react-router-dom";

import SearchForm from "../components/SearchForm";
import SearchHistory from "../components/SearchHistory";
import SearchResults from "../components/SearchResults";
import Moment from "../components/Moment";
import ExperimentStatusBadge from "../components/ExperimentStatusBadge";
import ExperimentForm from "../components/ExperimentForm";

class Experiments extends Component {
  static propTypes = {
    api: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      query: { status: "pending,started" },
      experiments: [],
      formOverlay: false,
    };
  }

  render() {
    return (
      <div className="Experiments">
        {this.state.formOverlay && (
          <ExperimentForm
            api={this.props.api}
            onCancel={this.setState.bind(this, { formOverlay: false }, null)}
            onComplete={(experimentId) => {
              this.props.history.push(`/experiments/${experimentId}`);
            }}
          />
        )}

        <button
          onClick={() => {
            this.setState({ formOverlay: true });
          }}
          type="button"
          className="CreateExperimentBtn"
        >
          New Experiment
        </button>

        <SearchForm
          query={this.state.query}
          onSubmit={(q) => this.setState({ query: q })}
        >
          {(form) => (
            <React.Fragment>
              <form.CheckGroup
                name="status"
                title="Status"
                options={["pending", "started", "ended"]}
                labels={["Pending", "Started", "Ended"]}
              />
            </React.Fragment>
          )}
        </SearchForm>

        <SearchHistory
          query={this.state.query}
          onSubmit={(q) => this.setState({ query: q })}
        />

        <SearchResults
          api={this.props.api.Experiments}
          query={this.state.query}
        >
          {(results) => (
            <table className="Experiments-table">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Status</th>
                  <th>Forced variant</th>
                  <th>Days running</th>
                  <th>Started</th>
                  <th>Ended</th>
                </tr>
              </thead>
              <tbody>
                {results.items().map((experiment) => (
                  <tr key={experiment.id}>
                    <td>
                      <Link to={`/experiments/${experiment.id}`}>
                        {experiment.name}
                      </Link>
                      {experiment.note && (
                        <p className="Experiments-note">{experiment.note}</p>
                      )}
                    </td>
                    <td>
                      <ExperimentStatusBadge data={experiment} />
                    </td>
                    <td>
                      <code>{experiment.forced_variant}</code>
                    </td>
                    <td>{experiment.days_running}</td>
                    <td>
                      <Moment time={experiment.started_at} />
                    </td>
                    <td>
                      <Moment time={experiment.ended_at} />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </SearchResults>
      </div>
    );
  }
}

export default withRouter(Experiments);

import React, { Component } from "react";
import PropTypes from "prop-types";
import Moment from "./Moment";

export default class DeviceRow extends Component {
  static propTypes = {
    api: PropTypes.object.isRequired,
    device: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      device: props.device,
    };
  }

  static Headers = () => (
    <tr>
      <td>Device ID</td>
      <td>Model</td>
      <td>Platform</td>
      <td>Reachable</td>
      <td>Notification</td>
      <td>Location</td>
      <td>Silent push at</td>
    </tr>
  );

  render() {
    const d = this.state.device;

    return (
      <tr className="DeviceRow" key={d.id}>
        <td>{d.device_id}</td>
        <td>{d.model_label}</td>
        <td>{d.platform}</td>
        <td>{d.reachable ? "true" : "false"}</td>
        <td>{d.notifications_status}</td>
        <td>{d.location_status}</td>
        <td>
          <Moment time={d.silent_push_at} />{" "}
          <span
            role="img"
            aria-label="refresh"
            onClick={this._onSilentPush.bind(this)}
          >
            🔄
          </span>
        </td>
      </tr>
    );
  }

  _onSilentPush() {
    this.props.api.UserDevices.silent_push(this.props.device.id).then(
      (data) => {
        this.setState({ device: data });
        alert("Silent push sent!");
      }
    );
  }
}

import React, { Component } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

import h from "./helpers";

const phrases = {
  status: [
    [/disabled,active/, "deletes group"],
    [/hidden,active/, "unhides group"],
    [/censored,active/, "uncensors group"],
    [/,disabled/, "deletes group"],
    [/,hidden/, "hides group"],
    [/,censored/, "censors group"],
  ],

  default: "takes no action on group",
};

export default class HistoryGroup extends Component {
  static propTypes = {
    userID: PropTypes.number,
    id: PropTypes.number.isRequired,
    created_at: PropTypes.string.isRequired,
    changeset: PropTypes.object.isRequired,
    notes: PropTypes.string,
    created_by: PropTypes.shape({
      id: PropTypes.number.isRequired,
      first_name: PropTypes.string.isRequired,
    }).isRequired,
    group: PropTypes.shape({
      id: PropTypes.number.isRequired,
    }).isRequired,
  };

  static type = "history/group";
  static icon = "👩";

  render() {
    const p = this.props;

    return (
      <tr className="EventHistoryGroup">
        {h.sharedColumns(this)}
        <td>
          {h.renderActor(p.created_by, p.userID)}{" "}
          {h.renderActions(p.changeset, phrases)}{" "}
          <Link to={`/groups/${p.group.id}`}>#{p.group.id}</Link>{" "}
          {h.renderNotes(p.notes)}
        </td>
        <td></td>
      </tr>
    );
  }
}

import React, { Component } from "react";
import PropTypes from "prop-types";

import ModalForm from "./ModalForm";

export default class SendMessageForm extends Component {
  static propTypes = {
    api: PropTypes.object.isRequired,
    userID: PropTypes.number.isRequired,
    onCancel: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      sent: false,
      response: null,
      messageBody: "",
    };
  }

  render() {
    return (
      <ModalForm
        className="SendMessageForm"
        title="Send chat message"
        onCancel={this.props.onCancel}
        form={
          <React.Fragment>
            <label>Message contents</label>
            <input
              className="form-control"
              value={this.state.body}
              onChange={this._onFieldChange.bind(this)}
              disabled={this.state.sent}
              ref={(e) => {
                e && e.focus();
              }}
            />
          </React.Fragment>
        }
        actions={
          this.state.sent
            ? this._renderFooterSent()
            : this._renderFooterUnsent()
        }
      />
    );
  }

  _renderFooterUnsent() {
    return (
      <React.Fragment>
        <span>{this.state.response}</span>
        <button
          type="button"
          className="btn btn-primary"
          onClick={this._onSubmit.bind(this)}
        >
          Send
        </button>
      </React.Fragment>
    );
  }

  _renderFooterSent() {
    return <span>{this.state.response}</span>;
  }

  _startChat() {
    this.setState({ chatOverlay: true });
  }

  _stopChat() {
    this.setState({ chatOverlay: false });
  }

  _onFieldChange(event) {
    const value = event.target.value;
    this.setState({ messageBody: value });
  }

  _onSubmit() {
    this.props.api.Conversations.create({
      user_id: this.props.userID,
      message: this.state.messageBody,
    })
      .then(() => {
        this.setState({ response: "Message sent", sent: true });
      })
      .catch((e) => {
        console.error(e);
        this.setState({ response: "Sending failed!" });
      });
  }
}

import React, { Component } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

import h from "./helpers";

const phrases = {
  active: [
    [/,false/, "deletes post"],
    [/,true/, "reactivates post"],
  ],

  status: [
    [/disabled,active/, "deletes post"],
    [/hidden,active/, "unhides post"],
    [/censored,active/, "uncensors post"],
    [/,disabled/, "deletes post"],
    [/,hidden/, "hides post"],
    [/,censored/, "censors post"],
  ],

  peanut_pick: [
    [/,false/, "un-features post"],
    [/,true/, "features post"],
  ],

  reset: [[/.*/, "resets post"]],

  abuse: h.renderAbuseAction.bind(h, "post"),

  default: "takes no action on post",
};

export default class HistoryPost extends Component {
  static propTypes = {
    userID: PropTypes.number,

    id: PropTypes.number.isRequired,
    created_at: PropTypes.string.isRequired,
    changeset: PropTypes.object.isRequired,
    notes: PropTypes.string,
    created_by: PropTypes.shape({
      id: PropTypes.number.isRequired,
      first_name: PropTypes.string.isRequired,
    }).isRequired,
    post: PropTypes.shape({
      id: PropTypes.number.isRequired,
    }).isRequired,
  };

  static type = "history/post";
  static icon = "📖";

  render() {
    const p = this.props;

    return (
      <tr className="EventHistoryPost">
        {h.sharedColumns(this)}
        <td>
          {h.renderActor(p.created_by, p.userID)}{" "}
          {h.renderActions(p.changeset, phrases)}{" "}
          <Link to={`/posts/${p.post.id}`}>#{p.post.id}</Link>{" "}
          {h.renderNotes(p.notes)}
        </td>
        <td></td>
      </tr>
    );
  }
}

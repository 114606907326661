import React, { useState } from "react";
import { Link } from "react-router-dom";

const GroupMembershipRow = (props) => {
  const [status, changeStatus] = useState(props.status);
  const [role, changeRole] = useState(props.role);

  const _onStatusChange = ({ target: { value } }) => {
    const {
      id,
      api: { UserGroupMemberships },
    } = props;
    changeStatus(value);
    UserGroupMemberships.update(id, { status: value });
  };

  const _onRoleChange = ({ target: { value } }) => {
    const {
      id,
      api: { UserGroupMemberships },
    } = props;
    changeRole(value);
    UserGroupMemberships.update(id, { role: value });
  };

  const {
    group: {
      name: groupName,
      description: groupDescription,
      id: groupId,
      join_mode: groupJoinMode,
    },
    notification_interval_text: notificationInterval,
  } = props;

  const inviteOnly = groupJoinMode === "on_invite";
  const requstedOnly = groupJoinMode === "on_request";

  return (
    <tr>
      <td>
        <Link to={`/groups/${groupId}`}>{groupName}</Link>
        <br />
        {groupDescription}
      </td>
      <td>
        <select value={role} onChange={_onRoleChange}>
          <option>member</option>
          <option>moderator</option>
          <option>creator</option>
        </select>
      </td>
      <td>
        <select value={status} onChange={_onStatusChange}>
          <option>left</option>
          {inviteOnly && <option>invited</option>}
          {requstedOnly && <option>requested</option>}
          <option>joined</option>
          {inviteOnly && <option>declined</option>}
          {(inviteOnly || requstedOnly) && <option>rejected</option>}
          <option>kicked</option>
        </select>
      </td>
      <td>{notificationInterval}</td>
    </tr>
  );
};

export default GroupMembershipRow;

import { useQuery } from "react-query";

import Cookies from "universal-cookie";

const useGuidelines = () => {
  const cookies = new Cookies();
  const xsrf_token = cookies.get("XSRF-TOKEN");

  const fetchGuidelines = async () => {
    const url = new URL(`${process.env.REACT_APP_API_HOST}/admin/guidelines/`);

    const response = await fetch(url, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-XSRF-Token": xsrf_token,
      },
    }).then((response) => {
      if (response.status >= 400 && response.status < 600) {
        throw new Error("Bad response from server");
      }
      return response.json();
    });

    return await response;
  };

  return useQuery(["guidelines"], fetchGuidelines);
};

export default useGuidelines;

import Base from "./Base";

export default class Mvps extends Base {
  index(params) {
    const q = this._query(params);

    return this._fetch(`/admin/mvps?${q}`)
      .then((response) => response.json())
      .then((data) => [data.users]);
  }

  show(id) {
    return this._fetch(`/admin/mvps/${id}`).then((response) => response.json());
  }
}

import React from "react";
import PropTypes from "prop-types";

const UserPaymentRow = ({
  payment: {
    title,
    status,
    purchased_at,
    expires_at,
    platform,
    local_price_cents,
    local_price_currency,
    converted_price_cents,
    converted_price_currency,
    auto_renewing,
    store_transaction_id,
    cancelled_at,
  },
}) => {
  const startedAt = new Date(purchased_at);
  const expiresAt = new Date(expires_at);
  const canceledAt = new Date(cancelled_at);

  const localPriceFormatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: local_price_currency || "USD",
  });

  const usdPriceFormatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });
  return (
    <tr>
      <td style={{ whiteSpace: "nowrap" }}>{title}</td>
      <td>
        {status}
        {cancelled_at && cancelled_at !== null && status === "canceled" && (
          <>
            <br />
            {canceledAt.toLocaleDateString()} {canceledAt.toLocaleTimeString()}
          </>
        )}
      </td>
      <td>
        {startedAt.toLocaleDateString()} {startedAt.toLocaleTimeString()}
      </td>
      <td>
        {expires_at
          ? `${expiresAt.toLocaleDateString()} ${expiresAt.toLocaleTimeString()}`
          : "N/A"}
      </td>
      <td>{platform === "apple_store" ? "iOS" : "Android"}</td>
      <td>{localPriceFormatter.format(local_price_cents / 100)}</td>
      <td>{usdPriceFormatter.format(converted_price_cents / 100)}</td>
      <td>{auto_renewing ? "✅" : ""}</td>
      <td>{store_transaction_id}</td>
    </tr>
  );
};

UserPaymentRow.propTypes = {
  payment: PropTypes.shape(
    {
      title: PropTypes.string.isRequired,
      status: PropTypes.oneOf([
        "verifying",
        "verified",
        "invalid",
        "canceled",
        "on_hold",
        "paused",
        "revoked",
        "expired",
        "failed",
        "refunded",
        "verified_grace_period",
      ]).isRequired,
      local_price_cents: PropTypes.number.isRequired,
      local_price_currency: PropTypes.string.isRequired,
      converted_price_cents: PropTypes.number.isRequired,
      converted_price_currency: PropTypes.string.isRequired,
      platform: PropTypes.oneOf(["apple_store", "google_play"]).isRequired,
      purchased_at: PropTypes.string.isRequired,
      expires_at: PropTypes.string.isRequired,
      cancelled_at: PropTypes.string,
      auto_renewing: PropTypes.bool,
      store_transaction_id: PropTypes.string.isRequired,
    }.isRequired
  ),
};

export default UserPaymentRow;

import React, { Component } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import DeleteImageButton from "../components/DeleteImage";
import Image from "./Image";
import Giphy from "./Giphy";

export default class MessageBody extends Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
  };

  render() {
    const p = this.props;

    if (p.mime_type === "text/plain") {
      return p.data ? p.data.body : "(misformatted)";
    }
    if (p.mime_type === "application/vnd.peanut.movie+json") {
      return <video src={p.data.movie_url} autoPlay />;
    }
    if (p.mime_type === "application/vnd.peanut.sticker+json") {
      return (
        <img
          alt="sticker"
          width={120}
          src={p.data.sticker_url.replace("{width}", 240)}
        />
      );
    }
    if (p.mime_type === "application/vnd.peanut.location+json") {
      return "(location)";
    }
    if (p.mime_type === "application/vnd.peanut.share.post+json") {
      if (p.data.shared_post_unavailable) {
        return <p>(unavailable post)</p>;
      }
      return (
        <Link to={`/posts/${p.data.post.uid}`}>
          Shared Post: {p.data.post.title}
        </Link>
      );
    }
    if (p.mime_type === "application/vnd.peanut.share.group+json") {
      if (p.data.shared_group_unavailable) {
        return <p>(unavailable group)</p>;
      }
      return (
        <Link to={`/groups/${p.data.group.id}`}>
          Shared Group: {p.data.group.name}
        </Link>
      );
    }
    if (p.mime_type.match(/image\/(png|jpeg)/)) {
      return (
        <>
          <Image
            className="MessageBody--image"
            url={p.data.content_url}
            with={240}
            openInNewTab
          />
          <DeleteImageButton imgUId={p.data.uid} />
        </>
      );
      // <img alt='user supplied' width={120} src={p.data.content_url.replace('{width}', 240)}/>
    }
    if (p.mime_type === "application/vnd.peanut.media-call.started+json") {
      return <p>Video chat started</p>;
    }
    if (p.mime_type === "application/vnd.peanut.media-call.ended+json") {
      return <p>Video chat finished | {p.data && p.data.duration} seconds</p>;
    }
    if (p.mime_type === "application/vnd.peanut.media-call.missed+json") {
      return (
        <p>
          Video chat missed. Reason: {p.data && (p.data.reason || "not-set")}
        </p>
      );
    }
    if (p.mime_type === "application/vnd.peanut.giphy+json") {
      return (
        <Giphy
          id={p.data.id}
          width={p.data.images.width}
          height={p.data.images.height}
        />
      );
    }
    if (p.mime_type === "application/vnd.peanut.share.audioroom+json") {
      if (p.data.shared_audio_room_unavailable) {
        return <p>(unavailable audio room)</p>;
      }
      return (
        <Link to={`/live-audio/${p.data.audio_room.id}`}>
          Shared Audio Room: {p.data.audio_room.title}
        </Link>
      );
    }
    if (p.mime_type === "application/vnd.peanut.post.moment+json") {
      return (
        <>
          {p.data.post ? (
            <Link to={`/posts/${p.data.post.uid}`}>{p.data.post.title}</Link>
          ) : (
            <p>(unavailable post)</p>
          )}
          {p.data.reaction && <p>Reaction: {p.data.reaction}</p>}
          {p.data.message && <p>Comment: {p.data.message}</p>}
        </>
      );
    }
    if (
      p.mime_type === "application/vnd.peanut.reaction.photo+json" ||
      p.mime_type === "application/vnd.peanut.comment.photo+json"
    ) {
      return (
        <>
          {p.data.photo ? (
            <Image
              className="MessageBody--image"
              url={p.data.photo.url.replace("{width}x{height}", "240x240")}
              width={240}
              height={240}
              openInNewTab
            />
          ) : (
            <p>(unavailable photo)</p>
          )}
          {p.data.reaction && <p>Reaction: {p.data.reaction}</p>}
          {p.data.message && <p>Comment: {p.data.message}</p>}
        </>
      );
    }
    if (
      p.mime_type === "application/vnd.peanut.reaction.answer+json" ||
      p.mime_type === "application/vnd.peanut.comment.answer+json"
    ) {
      return (
        <>
          {p.data.answer ? (
            <>
              <p>
                Prompt: {p.data.answer.prompt.body}
                <br />
                Answer: {p.data.answer.body}
              </p>
            </>
          ) : (
            <p>(unavailable answer)</p>
          )}
          {p.data.reaction && <p>Reaction: {p.data.reaction}</p>}
          {p.data.message && <p>Comment: {p.data.message}</p>}
        </>
      );
    }

    return (
      <span>
        unsupported message format <tt>{p.mime_type}</tt>
      </span>
    );
  }
}

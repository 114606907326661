import React, { Component } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

export default class BlockedUsers extends Component {
  static propTypes = {
    api: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      userId: props.user.id,
      blockedUsers: props.user.blocked_users,
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.user.id !== prevProps.user.id) {
      this.setState({
        userId: this.props.user.id,
        blockedUsers: this.props.user.blocked_users,
      });
    }
  }

  onClick(blocked_user_id) {
    this.props.api.Users.unblock({
      user_id: this.state.userId,
      suggested_user_id: blocked_user_id,
    }).then(() => {
      this.setState({
        blockedUsers: this.state.blockedUsers.filter(
          (bu) => bu.user_id !== blocked_user_id
        ),
      });
    });
  }

  render() {
    if (this.state.blockedUsers.length === 0) {
      return (
        <div>
          <h3>Blocked users</h3>
          <p>No blocked users</p>
        </div>
      );
    }

    return (
      <div>
        <h3>Blocked users</h3>

        <table>
          <tbody>
            {this.state.blockedUsers.map((bu) => (
              <tr key={bu.user_id}>
                <td width="200">
                  <Link
                    className="UserLink UserLink--inline"
                    to={`/users/${bu.user_id}#log`}
                  >
                    {bu.first_name} {bu.last_name}
                  </Link>
                </td>
                <td>
                  <button
                    className="BlockedUsers-button"
                    onClick={() => this.onClick(bu.user_id)}
                  >
                    Unblock
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }
}

import React, { Component } from "react";
import PropTypes from "prop-types";
import VisibilitySensor from "react-visibility-sensor";

import isEqual from "react-fast-compare";

// Handles querying and infinite scrolling
export default class SearchResults extends Component {
  static propTypes = {
    api: PropTypes.object.isRequired,
    query: PropTypes.object.isRequired,
    limit: PropTypes.number,
    onEmpty: PropTypes.func.isRequired,
    displayOnEmpty: PropTypes.func,
  };

  static defaultProps = {
    limit: 50,
    onEmpty: () => {},
    displayOnEmpty: () => <p>No more results, sorry</p>,
  };

  static defaultState = {
    cursor: null,
    offset: 0,
    items: [],
    hasMore: false,
    loading: true,
    counter: 0,
  };

  constructor(props) {
    super(props);

    this.state = Object.assign({}, SearchResults.defaultState);

    // passed to children to render dependent components
    this._handle = {
      AutoLoad: this._renderPagination.bind(this),
      doRefresh: this._doSearch.bind(this, { refresh: true }),
      doReload: this._doSearch.bind(this),
      items: () => this.state.items,
    };

    this._doSearch = this._doSearch.bind(this);
    this._onPaginate = this._onPaginate.bind(this);
  }

  render() {
    return <React.Fragment>{this.props.children(this._handle)}</React.Fragment>;
  }

  componentDidMount() {
    clearTimeout(this._timer);
    this._timer = setTimeout(this._doSearch, 50);
  }

  componentDidUpdate(prevProps) {
    if (isEqual(prevProps.query, this.props.query)) return;

    const newState = Object.assign({}, SearchResults.defaultState);
    this.setState(newState, this._doSearch);
  }

  _renderPagination() {
    const { displayOnEmpty } = this.props;
    return this.state.hasMore ? (
      <VisibilitySensor
        onChange={this._onPaginate}
        partialVisibility={true}
        offset={{ bottom: -500 }}
      >
        <p key={this.state.offset}>More results loading...</p>
      </VisibilitySensor>
    ) : (
      displayOnEmpty(this.state.items)
    );
  }

  // perform a search and append results to the current state, or
  // (if `refresh` is set) reload the number of items we already have present.
  _doSearch(options = {}) {
    console.log(`SearchResults#_doSearch: ${JSON.stringify(options)}`);
    clearTimeout(this._timer);

    let { cursor, offset } = this.state;
    let limit = this.props.limit;
    console.log(
      `SearchResults#_doSearch: ${JSON.stringify({ cursor, offset, limit })}`
    );

    if (options.refresh) {
      console.log(`SearchResults#_doSearch: refreshing!`);
      cursor = null;
      offset = 0;
      if (this.state.items && options.limit === undefined) {
        limit = this.state.items.length;
        console.log(`SearchResults#_doSearch: setting limit to ${limit}`);
      }
    }

    const q = Object.assign({}, this.props.query, { offset, limit });
    if (cursor) {
      q.cursor = cursor;
    }
    console.log(`SearchResults#_doSearch: q=${JSON.stringify(q)}`);

    this.props.api.index(q).then(([data, cursor]) => {
      const firstPage = q.offset === 0 && q.cursor === undefined;
      const newItems = firstPage ? data : this.state.items.concat(data);

      this.setState(
        {
          items: newItems,
          hasMore: data.length >= q.limit,
          offset: cursor ? offset : offset + data.length,
          cursor: cursor,
          loading: false,
        },
        () => {
          if (firstPage && newItems.length === 0) {
            this.props.onEmpty();
          }
        }
      );
    });
  }

  _onPaginate(flag) {
    if (!flag) {
      return;
    }
    if (this.state.loading) {
      return;
    }

    this.setState(
      { loading: true },
      this._doSearch.bind(this, { paginate: true })
    );
  }
}

import React, { Component } from "react";
import PropTypes from "prop-types";

class CaseClaimButton extends Component {
  constructor(props) {
    super(props);

    this.state = {
      displayModal: false,
    };

    this._isClaimed = this._isClaimed.bind(this);
    this._isClaimedByCurrentUser = this._isClaimedByCurrentUser.bind(this);
    this._onButtonClick = this._onButtonClick.bind(this);
  }

  _isClaimed() {
    const { selectedUserId } = this.props;
    return selectedUserId === null;
  }

  _isClaimedByCurrentUser() {
    const {
      api: {
        Session: {
          user: { id: currentUserId },
        },
      },
      selectedUserId,
    } = this.props;
    return selectedUserId === currentUserId;
  }

  async _onButtonClick(event) {
    const {
      api: {
        Cases: casesApi,
        Session: {
          user: { id: currentUserId },
        },
      },
      id,
      onUpdate,
    } = this.props;
    const claimed_by_id = this._isClaimedByCurrentUser() ? null : currentUserId;
    const data = await casesApi.update(id, { claimed_by_id });
    onUpdate({ ...data });
    if (claimed_by_id) {
      const { subjectUserId } = this.props;
      window.open(`users/${subjectUserId}`, "_blank").focus();
    }
  }

  render() {
    return (
      <button
        className={`CaseClaimButton-${
          this._isClaimedByCurrentUser() ? "unclaim" : "claim"
        }`}
        onClick={this._onButtonClick}
      >
        {this._isClaimedByCurrentUser() ? "Unclaim" : "Claim"}
      </button>
    );
  }
}

CaseClaimButton.propTypes = {
  id: PropTypes.number.isRequired,
  selectedUserId: PropTypes.number,
  subjectUserId: PropTypes.number.isRequired,
  api: PropTypes.object.isRequired,
  onUpdate: PropTypes.func.isRequired,
};

export default CaseClaimButton;

import Base from "../Base";

export default class Tags extends Base {
  index() {
    return this._fetch(`/admin/seo/tags`)
      .then((response) => response.json())
      .then((data) => [data.tags]);
  }

  update(id, params) {
    return this._fetch(`/admin/seo/tags/${id}`, {
      method: "PATCH",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(params),
    }).then((response) => response.json());
  }
}

import React, { Component } from "react";
import PropTypes from "prop-types";

import SearchResults from "./SearchResults";
import ReferralRow from "./ReferralRow";
import ReferralStatsRow from "./ReferralStatsRow";
import ReferralCashOutForm from "./ReferralCashOutForm";
import PaymentRow from "./PaymentRow";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import { IconButton } from "@material-ui/core";

export default class UserTabMVP extends Component {
  static propTypes = {
    api: PropTypes.object.isRequired,
    userId: PropTypes.string.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = { payments: null, stats: null };
  }

  componentDidMount() {
    this._refresh();
  }

  _refresh() {
    console.log("UserTabMVP#_refresh()");

    this.props.api.Referrals.indexStats({ user_id: this.props.userId }).then(
      (data) => this.setState({ stats: data })
    );

    this.props.api.Payments.index({ user_id: this.props.userId }).then((data) =>
      this.setState({ payments: data })
    );

    this.props.api.Users.show(this.props.userId).then((data) =>
      this.setState({ user: data })
    );
  }

  render() {
    const referralAPI = {
      index: this.props.api.Referrals.indexUser.bind(this.props.api.Referrals),
    };

    return (
      <div className="UserTabMVP">
        <div className="UserTabMVP-referralLink">
          <span>Referral Link:</span>
          <div>
            <p>{this.state.user?.referral_link?.url || "No referral link"}</p>
            <IconButton
              color="primary"
              onClick={() => {
                navigator.clipboard.writeText(
                  this.state.user?.referral_link?.url
                );
              }}
              component="span"
            >
              <FileCopyIcon />
            </IconButton>
          </div>
        </div>

        <ReferralCashOutForm
          api={this.props.api}
          onUpdate={this._refresh.bind(this)}
          userId={this.props.userId}
        />

        {this.state.stats && (
          <table className="UserTabMVP-stats">
            <caption>Referral Stats</caption>
            <thead>
              <ReferralStatsRow.Headers />
            </thead>
            <tbody>
              {this.state.stats.map((r, idx) => (
                <ReferralStatsRow
                  key={idx}
                  api={this.props.api}
                  onUpdate={this._refresh.bind(this)}
                  {...r}
                />
              ))}
            </tbody>
          </table>
        )}

        {this.state.payments && (
          <table className="UserTabMVP-payments">
            <caption>Payments</caption>
            <thead>
              <PaymentRow.Headers />
            </thead>
            <tbody>
              {this.state.payments.map((p) => (
                <PaymentRow key={p.id} api={this.props.api} {...p} />
              ))}
              {this.state.payments.length === 0 && (
                <tr>
                  <td colSpan="6">No payments yet</td>
                </tr>
              )}
            </tbody>
          </table>
        )}

        <SearchResults api={referralAPI} query={{ id: this.props.userId }}>
          {(results) => (
            <table className="UserTabMVP-referrals">
              <caption>Referrals</caption>
              <thead>
                <ReferralRow.Headers />
              </thead>
              <tbody>
                {results.items().map((r) => (
                  <ReferralRow key={r.id} {...r} />
                ))}
              </tbody>
              <tfoot>
                <tr>
                  <td>
                    <results.AutoLoad />
                  </td>
                </tr>
              </tfoot>
            </table>
          )}
        </SearchResults>
      </div>
    );
  }
}

import React, { Component } from "react";
import PropTypes from "prop-types";

import ButtonWithConfirmation from "./ButtonWithConfirmation";
import SearchResults from "./SearchResults";
import EventRow from "./events/Row";

export default class UserTabLogs extends Component {
  static propTypes = {
    api: PropTypes.object.isRequired,
    userID: PropTypes.number.isRequired,
    userStatus: PropTypes.string.isRequired,
    onUpdate: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      query: this._defaultQuery(),
      sortDescending: true,
      showIgnoreAllReportMessage: false,
    };

    this._sortOrder = this._sortOrder.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.userID !== this.props.userID) {
      this.setState({ query: this._defaultQuery() });
    }
  }

  _sortOrder(a, b) {
    if (this.state.sortDescending) {
      return new Date(b.data.created_at) - new Date(a.data.created_at);
    } else {
      return new Date(a.data.created_at) - new Date(b.data.created_at);
    }
  }

  _renderIgnoreAllButton(section, results) {
    if (
      this.props.userStatus !== "banned" ||
      section !== "Open Reports" ||
      !results.length
    )
      return;

    return (
      <ButtonWithConfirmation
        onClick={this._onIgnoreAllClick.bind(this)}
        title="Ignore All Reports"
        message="All currently open reports will be marked as ignored, for this user. Are you sure?"
        className="ReportMarkIgnored-btn"
      >
        Ignore All
      </ButtonWithConfirmation>
    );
  }

  _onIgnoreAllClick() {
    this.props.api.Reports.ignore({
      userID: this.props.userID,
    }).then(() => {
      this.setState({ showIgnoreAllReportMessage: true });
    });
  }

  _renderResults(title, results, searchResults, autoload, sortable) {
    return (
      <>
        <h4>{title}</h4>
        <table className="UserTabLogs-table">
          <thead>
            <tr>
              <th width="1">
                {sortable && (
                  <button
                    className="UserTabLogs-sortLink"
                    onClick={() => {
                      this.setState({
                        sortDescending: !this.state.sortDescending,
                      });
                    }}
                  >
                    Time {this.state.sortDescending ? "▲" : "▼"}
                  </button>
                )}
                {!sortable && "Time"}
              </th>
              <th width="1"></th>
              <th>Activity</th>
              {title === "History" && (
                <th style={{ width: 140 }}>
                  {/* Reports / Actions */}
                  {this._renderIgnoreAllButton(title, results)}
                </th>
              )}
            </tr>
            {this.state.showIgnoreAllReportMessage && title === "Open Reports" && (
              <tr>
                <th colSpan="4">
                  <div
                    className="alert alert-success"
                    role="alert"
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      this.setState({ showIgnoreAllReportMessage: false })
                    }
                  >
                    Your request was processed successfully. The reports will be
                    marked as ignored but it might take a while.
                  </div>
                </th>
              </tr>
            )}
          </thead>
          {results.map((d, idx) => (
            <EventRow
              key={idx}
              api={this.props.api}
              userID={this.props.userID}
              onUpdate={(value) => this._onUpdate(searchResults, value)}
              {...d}
            />
          ))}
          {results.length === 0 && (
            <tr>
              <td colSpan="4">
                <em>No {title.toLowerCase()}</em>
              </td>
            </tr>
          )}
          {autoload && (
            <tfoot>
              <tr>
                <td colSpan="4">
                  <searchResults.AutoLoad />
                </td>
              </tr>
            </tfoot>
          )}
        </table>
      </>
    );
  }

  render() {
    return (
      <div className="UserTabLogs">
        <SearchResults
          api={this.props.api.UserLogs}
          query={{ ...this.state.query, types: "admin_notes" }}
          limit={100}
        >
          {(results) => {
            return this._renderResults(
              "Notes",
              results.items().sort(this._sortOrder),
              results,
              false,
              true
            );
          }}
        </SearchResults>

        <SearchResults
          api={this.props.api.UserLogs}
          query={{
            ...this.state.query,
            report_status: "open",
            types: "reports",
          }}
          limit={100}
        >
          {(results) => {
            return this._renderResults(
              "Open Reports",
              results.items().sort(this._sortOrder),
              results,
              false,
              true
            );
          }}
        </SearchResults>

        <SearchResults
          api={this.props.api.UserLogs}
          query={this.state.query}
          limit={100}
        >
          {(results) => {
            return this._renderResults(
              "History",
              results.items().sort(this._sortOrder),
              results,
              true
            );
          }}
        </SearchResults>
      </div>
    );
  }

  _historyFilter = (x) => {
    return !this._isOpenCase(x);
  };

  _onUpdate(results, value) {
    results.doReload({ refresh: true, limit: 100 });
    this.props.onUpdate(value); // propagate refresh request up
  }

  _isOpenCase(caseObject) {
    return (
      caseObject.type &&
      caseObject.type.includes("report") &&
      (caseObject.data.status === "open" || caseObject.data.open === true)
    );
  }

  _defaultQuery() {
    return {
      id: this.props.userID,
      types: "reports,history",
    };
  }
}

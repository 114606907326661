import Base from "./Base";

export default class CampaignFilters extends Base {
  create(id, params) {
    return this._fetch(`/admin/campaigns/${id}/filters`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(params),
    });
  }

  run(id) {
    return this._fetch(`/admin/campaigns/${id}/filters`, {
      method: "PUT",
    });
  }

  update(campaign_id, id, params) {
    return this._fetch(`/admin/campaigns/${campaign_id}/filters/${id}`, {
      method: "PATCH",
      headers: { "content-type": "application/json" },
      body: JSON.stringify(params),
    });
  }

  destroy(campaign_id, id) {
    return this._fetch(`/admin/campaigns/${campaign_id}/filters/${id}`, {
      method: "DELETE",
    });
  }
}

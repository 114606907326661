import React, { useState, useEffect } from "react";
import Switch from "@material-ui/core/Switch";
import submitFreeTrial from "../hooks/api/useFreeTrial";
import { useMutation } from "react-query";

function SwitchComponent({ id }) {
  const [isChecked, setIsChecked] = useState(false);

  const useFreeTrialMutation = useMutation(submitFreeTrial);

  useEffect(() => {
    if (isChecked) {
      useFreeTrialMutation.mutate(id, {
        onSuccess: async (response, variables, context) => {
          alert("Free Trial activation success");
        },
        onError: (error, variables, context) => {
          alert(error);
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isChecked, id]);

  const handleChange = () => {
    setIsChecked(!isChecked);
  };

  return (
    <Switch
      defaultChecked={false}
      defaultValue={false}
      checked={isChecked}
      onChange={handleChange}
      inputProps={{ "aria-label": "secondary checkbox" }}
    />
  );
}

export default SwitchComponent;

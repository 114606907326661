import React, { Component } from "react";
import PropTypes from "prop-types";

import UserRow from "../UserRow";
import SearchResults from "../SearchResults";

class GroupTabMembers extends Component {
  static propTypes = {
    api: PropTypes.object,
  };

  render() {
    return (
      <SearchResults
        api={this.props.api.Users}
        query={{ group_id: this.props.group_id }}
      >
        {(results) => (
          <table className="Users-table">
            <thead>
              <UserRow.Headers />
            </thead>
            <tbody>
              {results.items().map((x) => (
                <UserRow key={x.uid} {...x} />
              ))}
            </tbody>
            <tfoot>
              <tr>
                <td colSpan={UserRow.Columns}>
                  <results.AutoLoad />
                </td>
              </tr>
            </tfoot>
          </table>
        )}
      </SearchResults>
    );
  }
}

export default GroupTabMembers;

import Base from "./Base";

export default class ContentLabellingJobs extends Base {
  index(params) {
    return this._fetch(`/admin/content_labelling_jobs?${this._query(params)}`)
      .then((response) => response.json())
      .then((data) => [data]);
  }

  show(id) {
    return this._fetch(`/admin/content_labelling_jobs/${id}`).then((response) =>
      response.json()
    );
  }

  formData() {
    return this._fetch(`/admin/content_labelling_jobs/form_data`).then(
      (response) => response.json()
    );
  }

  create(job) {
    return this._fetch(`/admin/content_labelling_jobs`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ content_labelling_job: job }),
      passErrors: [422],
    }).then((response) => response.json());
  }

  update(job) {
    return this._fetch(`/admin/content_labelling_jobs/${job.id}`, {
      method: "PATCH",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ content_labelling_job: job }),
      passErrors: [422],
    }).then((response) => !response.ok && response.json());
  }
}

import React, { Component } from "react";
import Cookies from "universal-cookie";

import EventTypes from "./EventTypes";
import DefaultRow from "./components/DefaultRow";

import SearchResults from "../../../../components/SearchResults";
import ParticipantAutocomplete from "./components/ParticipantAutocomplete/ParticipantAutocomplete";

import styles from "./EventsTab.module.scss";

const cookies = new Cookies();

export default class EventsTab extends Component {
  constructor(props) {
    super(props);

    const previous_events =
      cookies.get("events_tab_actions", "room_reported") || "";
    const previous_event_keys = previous_events.split(",");

    this.state = {
      data: null,
      query: {
        roomId: props.roomId,
        event: previous_events,
        action_user_id: null,
      },
      actions: this.eventTypes().reduce(
        (result, { action: key }) => {
          result[key] = previous_event_keys.includes(key);
          return result;
        },
        { participant_reported: true, room_reported: true }
      ),
    };

    this._handleParticipantSelect = this._handleParticipantSelect.bind(this);
    this._handleShowAll = this._handleShowAll.bind(this);
    this._handleActionFilter = this._handleActionFilter.bind(this);
    this._handlePlaybackJump = this._handlePlaybackJump.bind(this);
    this._handleEventChange = this._handleEventChange.bind(this);
  }

  eventTypes() {
    return Object.keys(EventTypes)
      .filter((key) => {
        return EventTypes[key].report !== true;
      })
      .map((key) => {
        return {
          action: EventTypes[key].action,
          label: EventTypes[key].label,
        };
      });
  }

  reportTypes() {
    return Object.keys(EventTypes)
      .filter((key) => {
        return EventTypes[key].report === true;
      })
      .map((key) => {
        return {
          action: EventTypes[key].action,
          label: EventTypes[key].label,
        };
      });
  }

  _rowComponent(event) {
    return EventTypes[event.type] || DefaultRow;
  }

  _handleParticipantSelect(value) {
    const { query } = this.state;

    const userId = value > 0 ? value : null;

    this.setState({ query: { ...query, action_user_id: userId } });
  }

  _handleShowAll() {
    const { query, actions } = this.state;
    const eventTypes = this.eventTypes();

    let resultState = !(
      eventTypes.filter((key) => {
        return actions[key.action] === true;
      }).length === eventTypes.length
    );

    let updatedActions = eventTypes.reduce(
      (result, { action: key }) => {
        result[key] = resultState;
        return result;
      },
      { ...actions }
    );

    let event = this._actionsQuery(updatedActions);

    this.setState({
      query: { ...query, event },
      actions: updatedActions,
    });

    cookies.set("events_tab_actions", event);
  }

  _handleActionFilter({ target: { value, checked } }) {
    const { query, actions } = this.state;

    let updatedActions = { ...actions, [value]: checked };
    let event = this._actionsQuery(updatedActions);

    this.setState({
      query: { ...query, event },
      actions: updatedActions,
    });
    cookies.set("events_tab_actions", event);
  }

  _actionsQuery(actions) {
    return Object.keys(actions)
      .reduce((result, key) => {
        if (actions[key]) {
          result.push(key);
        }
        return result;
      }, [])
      .join(",");
  }

  _handlePlaybackJump(seconds) {
    if (this.props.playerRef.current) {
      this.props.playerRef.current.setTime(seconds);
      this.props.playerRef.current.play();
    }
  }

  _handleEventChange(results) {
    results.doReload({ refresh: true, limit: 100 });
  }

  render() {
    const eventTypes = this.eventTypes();
    const reportTypes = this.reportTypes();

    return (
      <div>
        <div className={styles.filtersContainer}>
          <div>
            <div className={styles.eventTypesSection}>
              <div>Reports:</div>
              <div className={styles.eventTypesContainer}>
                {reportTypes.map(({ action, label }) => {
                  return (
                    <div key={`filter_${action}`}>
                      <input
                        id={`filter_${action}`}
                        type="checkbox"
                        value={action}
                        onChange={this._handleActionFilter}
                        checked={this.state.actions[action]}
                      />
                      <label for={`filter_${action}`}>{label}</label>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className={styles.eventTypesSection}>
              <div>
                Other:
                <div className={styles.toggleAll} onClick={this._handleShowAll}>
                  (toggle all)
                </div>
              </div>
              <div className={styles.eventTypesContainer}>
                {eventTypes.map(({ action, label }) => {
                  return (
                    <div key={`filter_${action}`}>
                      <input
                        id={`filter_${action}`}
                        type="checkbox"
                        value={action}
                        onChange={this._handleActionFilter}
                        checked={this.state.actions[action]}
                      />
                      <label for={`filter_${action}`}>{label}</label>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <div className={styles.participantsContainer}>
            Only:{" "}
            <ParticipantAutocomplete
              api={this.props.api}
              roomId={this.props.roomId}
              onChange={this._handleParticipantSelect}
            />
          </div>
        </div>

        <SearchResults
          api={this.props.api.LiveAudioEvents}
          query={this.state.query}
        >
          {(results) => {
            return (
              <table className="UserPhotos-table">
                <thead>
                  <tr>
                    <th style={styles.whenColumn}>When</th>
                    <th style={styles.whoColumn}>Who</th>
                    <th style={styles.actionColumn}>Action</th>
                    <th style={styles.descriptionColumn}>Description</th>
                    <th style={styles.otherColumn}></th>
                  </tr>
                </thead>
                <tbody>
                  {results.items().map((event) => {
                    const EventRow = this._rowComponent(event);
                    return (
                      <EventRow
                        key={event.id}
                        {...event}
                        api={this.props.api}
                        onJumpTo={this._handlePlaybackJump}
                        onChange={() => this._handleEventChange(results)}
                      />
                    );
                  })}
                </tbody>
                <tfoot>
                  <tr>
                    <td>
                      <results.AutoLoad />
                    </td>
                  </tr>
                </tfoot>
              </table>
            );
          }}
        </SearchResults>
      </div>
    );
  }
}

import Base from "./Base";

export default class Conversations extends Base {
  index(params) {
    const q = this._query(params);

    return this._fetch(`/admin/chat/conversations?${q}`)
      .then((response) => response.json())
      .then((data) => [data.conversations]);
  }

  show(id, params = {}) {
    const q = this._query(params);

    return this._fetch(`/admin/chat/conversations/${id}?${q}`)
      .then((response) => response.json())
      .then((data) => data.conversation);
  }

  update(id, cursor) {
    return this._fetch(`/admin/chat/conversations/${id}`, {
      method: "PATCH",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        abuse_checked_cursor: cursor,
      }),
    });
  }

  create(params) {
    return this._fetch(`/admin/chat/conversations`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(params),
    });
  }
}

import React, { Component } from "react";
import SearchResults from "../../components/SearchResults";
import LiveAudioRoomSearchResultRow from "./components/LiveAudioRoomSearchResultRow";
import SearchForm from "../../components/SearchForm";

class LiveAudioRooms extends Component {
  constructor(props) {
    super(props);

    this.state = {
      query: {
        type: "",
        author_uid: "",
        category_ids: "",
        text: "",
        statuses: "",
        peanut_pick: "",
        peanut_demote: "",
        started_since: "",
        started_before: "",
      },
    };
  }

  render() {
    return (
      <div className="Pods">
        <SearchForm
          query={this.state.query}
          onSubmit={(q) => this.setState({ query: q })}
          className="Pods-form"
        >
          {(form) => (
            <React.Fragment>
              <form.Input
                label="Started since"
                name="started_since"
                type="date"
                className="date"
              />
              <form.Input
                label="Started before"
                name="started_before"
                type="date"
                className="date"
              />
              <form.Input label="Text" name="text" className="text" />
              <div>
                <form.CheckGroup
                  name="statuses"
                  title="Status"
                  options={["scheduled", "started", "ended", "cancelled"]}
                  labels={["Scheduled", "Started", "Ended", "Cancelled"]}
                />
              </div>
              <div style={{ marginTop: "24px" }}>
                <form.CheckBox name="peanut_pick" label="Pick" />
                <form.CheckBox name="peanut_demote" label="Demoted" />
              </div>
            </React.Fragment>
          )}
        </SearchForm>

        <SearchResults
          api={this.props.api.LiveAudioRooms}
          query={this.state.query}
        >
          {(results) => {
            return (
              <table className="UserPhotos-table">
                <thead>
                  <tr>
                    <th>Status</th>
                    <th>Title</th>
                    <th>Host</th>
                    <th>Group</th>
                    <th>Recording started / Scheduled</th>
                    <th>Open Reports</th>
                  </tr>
                </thead>
                <tbody>
                  {results.items().map((d) => (
                    <LiveAudioRoomSearchResultRow
                      key={`storedvideo_result_${d.id}`}
                      api={this.props.api}
                      {...d}
                    />
                  ))}
                </tbody>
                <tfoot>
                  <tr>
                    <td>
                      <results.AutoLoad />
                    </td>
                  </tr>
                </tfoot>
              </table>
            );
          }}
        </SearchResults>
      </div>
    );
  }
}

export default LiveAudioRooms;

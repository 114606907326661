import React from "react";

import SearchForm from "../components/SearchForm";
import SearchResults from "../components/SearchResults";
import SearchHistory from "../components/SearchHistory";
import UserPhotoRow from "../components/UserPhotoRow";

export default class UserPhotos extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      query: {
        uid: "",
        type: "selfies",
        mode: "gender",
        confirmed: "no",
        gender: "all",
        nudity: "all",
        account_status: "active,censored",
        offset: 0,
        limit: 50,
        order_direction: "DESC",
      },
    };
  }

  _onUpdateFilters(query) {
    const q = Object.assign({}, query);

    if (q.mode === "gender") {
      q.nudity = "all";
    } else {
      q.gender = "all";
    }

    this.setState({ query: q });
  }

  render() {
    return (
      <div className="UserPhotos">
        <SearchForm
          query={this.state.query}
          onSubmit={this._onUpdateFilters.bind(this)}
        >
          {(form) => (
            <React.Fragment>
              <form.RadioGroup
                title="Type"
                name="type"
                labels={["All", "Selfies", "Profile pics"]}
                options={["all", "selfies", "profiles"]}
              />
              <form.RadioGroup
                title="Mode"
                name="mode"
                labels={["Gender", "Nudity"]}
                options={["gender", "nudity"]}
              />
              <form.RadioGroup
                title="Photo Status"
                name="confirmed"
                labels={["All", "Confirmed", "Unconfirmed"]}
                options={["all", "yes", "no"]}
              />
              {this.state.query.mode === "gender" && (
                <form.RadioGroup
                  title="Detected Gender"
                  name="gender"
                  labels={["All", "Male", "Female"]}
                  options={["all", "M", "F"]}
                />
              )}
              {this.state.query.mode === "nudity" && (
                <form.RadioGroup
                  title="Detected Nudity"
                  name="nudity"
                  labels={["All", "Nudity", "Safe"]}
                  options={["all", "Y", "N"]}
                />
              )}
              <form.CheckGroup
                title="Account Status"
                name="account_status"
                labels={["Active", "Censored", "Disabled", "Banned"]}
                options={["active", "censored", "disabled", "banned"]}
              />
              <form.RadioGroup
                title="Order"
                name="order_direction"
                labels={["Newest First", "Oldest First"]}
                options={["DESC", "ASC"]}
              />
            </React.Fragment>
          )}
        </SearchForm>

        <SearchHistory
          query={this.state.query}
          onSubmit={(q) => this.setState({ query: q })}
        />

        <SearchResults api={this.props.api.UserPhotos} query={this.state.query}>
          {(results) => (
            <table className="UserPhotos-table">
              <thead>
                <UserPhotoRow.Headers mode={this.state.query.mode} />
              </thead>
              <tbody>
                {results.items().map((d) => (
                  <UserPhotoRow
                    key={d.id}
                    api={this.props.api}
                    data={d}
                    mode={this.state.query.mode}
                  />
                ))}
              </tbody>
              <tfoot>
                <tr>
                  <td>
                    <results.AutoLoad />
                  </td>
                </tr>
              </tfoot>
            </table>
          )}
        </SearchResults>
      </div>
    );
  }
}

import React, { Component } from "react";
import { Link } from "react-router-dom";

import PropTypes from "prop-types";
import { Player, BigPlayButton } from "video-react";

import CommentRow from "../components/CommentRow";
import EventRow from "../components/events/Row";
import Moment from "../components/Moment";
import PostPhoto from "../components/PostPhoto";
import PostSetAbuseForm from "../components/PostSetAbuseForm";
import PostSetStatusForm from "../components/PostSetStatusForm";
import SearchResults from "../components/SearchResults";
import StatusButton from "../components/StatusButton";

import PickForm from "../components/PickForm";

import PostSensitiveFlagForm from "../components/PostSensitiveFlagForm";
import PostCategoryForm from "../components/PostCategoryForm";
import PostTagsForm from "../components/PostTagsForm";
import PublicationStatus from "../components/PublicationStatus";
import PostShareForm from "../components/PostShareForm";

export default class Post extends Component {
  static propTypes = {
    api: PropTypes.object,
  };

  static postTypes = {
    text_post: "Post",
    text_poll: "Poll",
    moment: "Moment",
  };

  constructor(props) {
    super(props);

    this.state = {
      post: {},
      commentsQuery: { post_id: this.props.match.params.id },
      pickOverlay: false,
      abuseOverlay: false,
      statusOverlay: false,
      categoryOverlay: false,
      tagsOverlay: false,
      shareOverlay: false,
    };
  }

  render() {
    const post = this.state.post;

    if (post.id === undefined) {
      return (
        <div className="container">
          <p>Loading...</p>
        </div>
      );
    }

    /* eslint-disable jsx-a11y/accessible-emoji */
    return (
      <div className="Post">
        <section className="Post-details">
          <div className="content">
            <div className="header">
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={post.deeplink}
                className="OpenAppButton"
              >
                Open In App
              </a>

              <h1>{post.title}</h1>
            </div>

            <dl className="details">
              <dt>ID</dt>
              <dd>#{post.id}</dd>
              <dt>Author</dt>
              <dd>
                <Link to={`/users/${post.author.id}`}>
                  {post.author.first_name} {post.author.last_name}
                </Link>
                {post.posted_as === "incognito" && " (incognito)"}
              </dd>

              <dt>Type</dt>
              <dd>
                {Post.postTypes[post.post_type]}
                {post.post_type === "moment" && ` (${post.moment.tag})`}
              </dd>

              <dt>Category</dt>
              <dd>
                <a
                  href="##"
                  onClick={this.setState.bind(
                    this,
                    { categoryOverlay: true },
                    null
                  )}
                >
                  {post.category.name}
                </a>
              </dd>

              <dt>Tags</dt>
              <dd>
                {post.tags.map((t, idx) => (
                  <React.Fragment key={idx}>
                    <button
                      className="badge badge-pill badge-secondary"
                      onClick={this.setState.bind(
                        this,
                        { tagsOverlay: true },
                        null
                      )}
                    >
                      {t.name}
                    </button>{" "}
                  </React.Fragment>
                ))}
                {post.tags.length === 0 && (
                  <button
                    className="badge badge-light"
                    onClick={this.setState.bind(
                      this,
                      { tagsOverlay: true },
                      null
                    )}
                  >
                    none
                  </button>
                )}
              </dd>

              <dt>Group</dt>
              <dd>
                {post.group.id ? (
                  <Link to={`/groups/${post.group.id}`}>{post.group.name}</Link>
                ) : (
                  "None"
                )}
              </dd>

              <dt>Status</dt>
              <dd>
                <StatusButton
                  status={post.status}
                  onClick={this.setState.bind(
                    this,
                    { statusOverlay: true },
                    null
                  )}
                >
                  {post.status}
                </StatusButton>
              </dd>

              <dt>Premium</dt>
              <dd>{post.premium ? "Yes" : "No"}</dd>
              {post.premium && (
                <React.Fragment>
                  <dt>Premium Tier</dt>
                  <dd>{post.premium_tier}</dd>
                </React.Fragment>
              )}

              <dt>Sensitive</dt>
              <dd>
                <StatusButton
                  status={post.sensitive}
                  onClick={this.setState.bind(
                    this,
                    { sensitiveFlagOverlay: true },
                    null
                  )}
                >
                  {post.sensitive === true ? "Yes" : "No"}
                </StatusButton>
              </dd>

              <dt>Original Post</dt>
              <dd>
                {post.shared_post ? (
                  <Link to={`/posts/${post.shared_post.id}`}>
                    Post: {post.shared_post.title}
                  </Link>
                ) : post.shared_group ? (
                  <Link to={`/groups/${post.shared_group.id}`}>
                    Group: {post.shared_group.name}
                  </Link>
                ) : post.shared_link ? (
                  <a
                    href={post.shared_link.url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {post.shared_link.title}
                  </a>
                ) : (
                  "None"
                )}
              </dd>

              <dt>Public Share</dt>
              <dd>
                <React.Fragment>
                  <button
                    className="badge badge-pill"
                    onClick={this.setState.bind(
                      this,
                      { shareOverlay: true },
                      null
                    )}
                  >
                    Share 📤
                  </button>
                </React.Fragment>
              </dd>

              <dt>Abuse</dt>
              <dd>
                {post.abuse.map((b, idx) => (
                  <React.Fragment key={idx}>
                    <button
                      className="badge badge-pill badge-secondary"
                      onClick={this.setState.bind(
                        this,
                        { abuseOverlay: true },
                        null
                      )}
                    >
                      {b.replace(/_/, " ")}
                    </button>{" "}
                  </React.Fragment>
                ))}
                {post.abuse.length === 0 && (
                  <button
                    className="badge badge-light"
                    onClick={this.setState.bind(
                      this,
                      { abuseOverlay: true },
                      null
                    )}
                  >
                    none
                  </button>
                )}
              </dd>

              <dt>Pick</dt>
              <dd>
                <StatusButton
                  status={post.peanut_pick}
                  onClick={this.setState.bind(
                    this,
                    { pickOverlay: true },
                    null
                  )}
                >
                  {post.peanut_pick === true
                    ? "yes"
                    : post.peanut_pick === false
                    ? "no"
                    : "unknown"}
                </StatusButton>
              </dd>

              <dt>Publication</dt>
              <dd>
                <PublicationStatus post={post} />
              </dd>
            </dl>

            <dl className="details">
              <dt>🗓 Posted</dt>
              <dd>
                <Moment time={post.created_at} />
              </dd>
              <dt>👁 Impressions</dt>
              <dd>{post.impression_count}</dd>

              <dt>👆 Taps</dt>
              <dd>{post.view_count}</dd>

              {post.premium && (
                <React.Fragment>
                  <dt>🔓 Unlocks</dt>
                  <dd>{post.unlocks_count}</dd>
                </React.Fragment>
              )}

              <dt>👍 Likes</dt>
              <dd>{post.like_count}</dd>

              <dt>💬 Comments</dt>
              <dd>{post.comment_count}</dd>
            </dl>
          </div>

          <PostPhoto
            post={post}
            api={this.props.api}
            onComplete={this._refresh.bind(this)}
          />

          {post.video_id && (
            <div className="PostVideoContainer">
              <div
                style={{
                  width: post.video.width / 2,
                  height: post.video.height / 2,
                }}
              >
                <Player
                  ref={(player) => {
                    this.player = player;
                  }}
                  playsInline
                  poster={post.video.thumbnail_url}
                  src={post.video.url}
                >
                  <BigPlayButton position="center" />
                </Player>
                <Link to={`/videos/${post.video_id}`}>View Video Details</Link>
              </div>
            </div>
          )}
        </section>

        <section className="Post-content">
          <label>Title</label>
          <p>{post.title}</p>
          <label>Body</label>
          <p>{post.body || "(no body)"}</p>
          {post.post_type === "text_poll" && (
            <React.Fragment>
              <label>Poll Options</label>
              <ul>
                {post.options.map((o, idx) => (
                  <li key={idx}>
                    {o.body} ({o.vote_count})
                  </li>
                ))}
              </ul>
            </React.Fragment>
          )}
        </section>

        <table className="Post-history">
          <caption>History</caption>
          <thead>
            <EventRow.Headers />
          </thead>
          <tbody>
            {post.history.map((ev, idx) => (
              <EventRow
                key={idx}
                type="history/post"
                api={this.props.api}
                userID={post.author.id}
                data={ev}
              />
            ))}
          </tbody>
        </table>

        <SearchResults
          api={this.props.api.Comments}
          query={this.state.commentsQuery}
        >
          {(results) => (
            <table className="Post-comments">
              <caption>Comments</caption>
              <thead>
                <CommentRow.Headers />
              </thead>
              <tbody>
                {results.items().map((n) => (
                  <CommentRow key={n.uid} api={this.props.api} data={n} />
                ))}
              </tbody>
              <tfoot>
                <tr>
                  <td>
                    <results.AutoLoad />
                  </td>
                </tr>
              </tfoot>
            </table>
          )}
        </SearchResults>

        {this.state.pickOverlay && (
          <PickForm
            api={this.props.api.Posts}
            apiObject={"post"}
            subject={"Post"}
            id={post.id}
            isPicked={post.peanut_pick}
            onCancel={this.setState.bind(this, { pickOverlay: false }, null)}
            onComplete={this._refresh.bind(this)}
          />
        )}

        {this.state.abuseOverlay && (
          <PostSetAbuseForm
            api={this.props.api}
            postID={post.id}
            abuse={this.state.post.abuse}
            onCancel={this.setState.bind(this, { abuseOverlay: false }, null)}
            onComplete={this._refresh.bind(this)}
          />
        )}

        {this.state.statusOverlay && (
          <PostSetStatusForm
            api={this.props.api}
            postID={post.id}
            status={this.state.post.status}
            onCancel={this.setState.bind(this, { statusOverlay: false }, null)}
            onComplete={this._refresh.bind(this)}
          />
        )}
        {this.state.sensitiveFlagOverlay && (
          <PostSensitiveFlagForm
            api={this.props.api}
            postID={post.id}
            sensitive={this.state.post.sensitive}
            onCancel={this.setState.bind(
              this,
              { sensitiveFlagOverlay: false },
              null
            )}
            onComplete={this._refresh.bind(this)}
          />
        )}
        {this.state.categoryOverlay && (
          <PostCategoryForm
            api={this.props.api}
            postID={post.id}
            categoryID={this.state.post.category.id}
            onCancel={this.setState.bind(
              this,
              { categoryOverlay: false },
              null
            )}
            onComplete={this._refresh.bind(this)}
          />
        )}
        {this.state.tagsOverlay && (
          <PostTagsForm
            api={this.props.api}
            selectedTagIds={this.state.post.tags.map((tag) => tag.id).join(",")}
            onCancel={this.setState.bind(this, { tagsOverlay: false }, null)}
            onSubmit={this._onSubmitTags.bind(this)}
          />
        )}
        {this.state.shareOverlay && (
          <PostShareForm
            api={this.props.api}
            postID={post.id}
            onCancel={this.setState.bind(this, { shareOverlay: false }, null)}
          />
        )}
      </div>
    );
  }

  componentDidMount() {
    this._refresh();
  }

  componentDidUpdate(prevProps) {
    if (this.props.match.params.id !== prevProps.match.params.id)
      this._refresh();
  }

  _refresh() {
    this._fetchPost(this.props.match.params.id);
  }

  _fetchPost(postId) {
    this.props.api.Posts.show(postId).then((data) => {
      this.setState({ post: data });
    });
  }

  _onSubmitTags(selectedTagIds) {
    this.props.api.Posts.update({
      id: this.state.post.id,
      note: "updating tags",
      post: {
        tag_ids: selectedTagIds,
      },
    }).then(() => {
      this.setState({ tagsOverlay: false });
      this._refresh();
    });
  }
}

import React, { Component } from "react";
import PropTypes from "prop-types";

class SelectAdminUsers extends Component {
  constructor(props) {
    super(props);

    this.state = {
      adminUsers: [],
    };

    this._onSelectChange = this._onSelectChange.bind(this);
  }

  async componentDidMount() {
    const {
      api: { Users: usersApi },
      excludedUserIds,
    } = this.props;

    usersApi.index({ role: "admin" }).then(([users]) => {
      const excluded = Array.isArray(excludedUserIds)
        ? excludedUserIds
        : [excludedUserIds];
      const filteredUsers = users
        .filter((user) => user.first_name && user.last_name)
        .filter((user) => !excluded.includes(user.id));
      this.setState({
        adminUsers: filteredUsers.sort((a, b) =>
          a.first_name.localeCompare(b.first_name)
        ),
      });
    });
  }

  _onSelectChange(selectedUserId) {
    const { onChange } = this.props;
    onChange(selectedUserId);
  }

  render() {
    const { value, children } = this.props;
    const { adminUsers } = this.state;

    return (
      <select
        value={value}
        onChange={this._onSelectChange}
        style={this.props.style}
        className="SelectAdminUsers"
      >
        {children}
        {adminUsers.map(({ id, first_name, last_name, email }) => {
          return (
            <option key={`adminUser${id}`} value={id}>
              {first_name} {last_name} ({email})
            </option>
          );
        })}
      </select>
    );
  }
}

SelectAdminUsers.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  excludedUserIds: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.number),
    PropTypes.number,
  ]),
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  api: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  style: PropTypes.object,
};

export default SelectAdminUsers;

import Base from "./Base";

export default class Warnings extends Base {
  show(version = "current") {
    return this._fetch(`/admin/guidelines/${version}/`, {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    }).then((response) => response.json());
  }
}

import React, { Component } from "react";
import PropTypes from "prop-types";

import ModalForm from "./ModalForm";

export default class UserEditPayPalForm extends Component {
  static propTypes = {
    api: PropTypes.object.isRequired,
    userID: PropTypes.number.isRequired,
    paypal: PropTypes.string.isRequired,
    onCancel: PropTypes.func.isRequired,
    onComplete: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      paypal: this.props.paypal,
    };
  }

  render() {
    return (
      <ModalForm
        className="UserEditPayPalForm"
        title="Change user PayPal"
        onCancel={this.props.onCancel}
        form={
          <input
            type="email"
            name="paypal"
            value={this.state.paypal}
            onChange={this._onFieldChange.bind(this)}
          />
        }
        actions={
          <button
            type="button"
            className="btn btn-primary"
            onClick={this._onSubmit.bind(this)}
          >
            Save
          </button>
        }
      />
    );
  }

  _onFieldChange(event) {
    const value = event.target.value;
    this.setState({ paypal: value });
  }

  _onSubmit(action) {
    this.props.api.Users.updatePayPal({
      id: this.props.userID,
      paypal: this.state.paypal,
    }).then(() => {
      this.props.onCancel();
      this.props.onComplete();
    });
  }
}

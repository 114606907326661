import React, { Component } from "react";
import PropTypes from "prop-types";
import queryString from "query-string";
import isEqual from "react-fast-compare";

// Handles syncing a search query with the current URL
export default class SearchHistory extends Component {
  static propTypes = {
    api: PropTypes.object,
    query: PropTypes.object,
    onSubmit: PropTypes.func,
    onNoop: PropTypes.func,
  };

  render() {
    console.log(`SearchHistory.render`);
    return <React.Fragment />;
  }

  componentDidMount() {
    console.log(`SearchHistory.componentDidMount`);
    // set query from url
    const params = queryString.parse(window.location.search);
    const urlQuery = Object.assign({}, this.props.query, params);
    if (isEqual(urlQuery, this.props.query)) {
      if (this.props.onNoop) this.props.onNoop();
    } else {
      console.log(` -> updating query to ${JSON.stringify(urlQuery)}`);
      this.props.onSubmit(urlQuery);
    }
  }

  componentWillUnmount() {
    console.log(`SearchHistory.componentWillUnmount`);
  }

  componentDidUpdate(prevProps) {
    console.log(`SearchHistory.componentDidUpate`);

    const q = this._queryString(this.props.query);
    const old = this._queryString(prevProps.query);
    if (q === old) return;

    console.log(` -> updating URL to ${q}`);
    const parts = [window.location.origin, window.location.pathname];
    if (q.length > 0) {
      parts.push("?", q);
    }
    window.history.replaceState(null, null, parts.join(""));
  }

  // return params without blank fields
  _compact(params) {
    const res = {};
    Object.keys(params).forEach((k) => {
      if (params[k].length === 0) {
        return;
      }
      res[k] = params[k];
    });
    return res;
  }

  // given the query state, return a query string usable for the browser's
  // url bar.
  _queryString(params) {
    const esc = encodeURIComponent;
    return Object.keys(params)
      .filter((k) => String(params[k]).length > 0)
      .map((k) => `${esc(k)}=${esc(params[k])}`)
      .join("&");
  }
}

import React from "react";
import PropTypes from "prop-types";

import BooleanSwitch from "./BooleanSwitch";

export default class CampaignFilterTag extends React.Component {
  static propTypes = {
    api: PropTypes.object.isRequired,
    campaign_id: PropTypes.number.isRequired,
    id: PropTypes.number.isRequired,
    negated: PropTypes.bool.isRequired,
    value_string: PropTypes.string.isRequired,
    onSave: PropTypes.func.isRequired,
    disabled: PropTypes.bool.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = { tags: null };
  }

  render() {
    if (!this.state.tags) return <React.Fragment />;

    const p = this.props;

    return (
      <span className="CampaignFilterTag">
        user{" "}
        <BooleanSwitch
          api={p.api}
          campaign_id={p.campaign_id}
          id={p.id}
          value={p.negated}
          onSave={p.onSave}
          disabled={p.disabled}
        >
          {this.props.negated ? "is not" : "is"}
        </BooleanSwitch>
        {" in pack "}
        <select
          className="CampaignFilterTag-select"
          value={p.value_int}
          disabled={p.disabled}
          onChange={this._onChange.bind(this)}
        >
          {this.state.tags.map((t, idx) => (
            <option value={t.id}>{t.name}</option>
          ))}
        </select>
      </span>
    );
  }

  componentDidMount() {
    this.props.api.Tags.index().then((data) => this.setState({ tags: data }));
  }

  _onChange(e) {
    e.preventDefault();
    const value = e.target.value;
    const p = this.props;
    this.props.api.CampaignFilters.update(p.campaign_id, p.id, {
      filter: { value_int: value },
    }).then(p.onSave);
  }
}

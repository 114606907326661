import React, { Component } from "react";
import PropTypes from "prop-types";

import h from "./helpers";

const phrases = {
  account_status: [
    [/.*,disabled/, "disables the account"],
    [/disabled,active/, "reactivates the account"],
    [/censored,active/, "uncensors the account"],
    [/.*,censored/, "censors the account"],
  ],

  email_status: [
    [/.*,ok/, "confirms email address"],
    [/.*,bounced/, "detects bouncing email"],
    [/.*,unsubscribed/, "detects email unsubscribed"],
    [/.*,complained/, "detects email spam report"],
  ],

  first_name: [
    [/null,/, 'sets first name to "{to}"'],
    [/.*/, 'changes first name from "{from}" to "{to}"'],
  ],

  birthday: [
    [/null,/, 'sets birthday to "{to}"'],
    [/.*/, 'changes birthday from "{from}" to "{to}"'],
  ],

  unresolved_warning_count: [
    [/.*,0/, "resets warnings"],
    [/.*/, "issues warning, count {to}"],
  ],

  _badge_codes: function (_, obj) {
    if (obj["$remove"]) return `remove badge: ${obj["$remove"].join(", ")}`;

    if (obj["$add"]) return `add badge: ${obj["$add"].join(", ")}`;
  },
};

export default class HistoryUser extends Component {
  static propTypes = {
    userID: PropTypes.number,

    id: PropTypes.number.isRequired,
    created_at: PropTypes.string.isRequired,
    changeset: PropTypes.object.isRequired,
    notes: PropTypes.string,
    created_by: PropTypes.shape({
      id: PropTypes.number.isRequired,
      first_name: PropTypes.string.isRequired,
    }),
  };

  static type = "history/user";
  static icon = "👤";

  render() {
    const p = this.props;

    return (
      <tr className="EventHistoryUser">
        {h.sharedColumns(this)}
        <td>
          {h.renderActor(p.created_by, p.userID)}{" "}
          {h.renderActions(p.changeset, phrases)} {h.renderNotes(p.notes)}
        </td>
        <td></td>
      </tr>
    );
  }
}

import React, { Component } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import ModalForm from "./ModalForm";

export default class PostSetStatusForm extends Component {
  static propTypes = {
    api: PropTypes.object.isRequired,
    commentID: PropTypes.number.isRequired,
    status: PropTypes.string.isRequired,
    onCancel: PropTypes.func.isRequired,
    onComplete: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      messageBody: "",
    };
  }

  render() {
    return (
      <ModalForm
        className="PostSetStatusForm"
        title="Change post status"
        onCancel={this.props.onCancel}
        form={
          <React.Fragment>
            <label>Reason</label>
            <input
              className="form-control"
              value={this.state.messageBody}
              onChange={this._onFieldChange.bind(this)}
              ref={(e) => {
                e && e.focus();
              }}
            />
          </React.Fragment>
        }
        actions={
          <React.Fragment>
            {this._listActions().map(([title, action, active], idx) => (
              <button
                key={idx}
                type="button"
                className={classnames(
                  "btn",
                  active ? "btn-primary" : "btn-outline-primary"
                )}
                disabled={!active || this.state.messageBody.length < 1}
                onClick={this._onSubmit.bind(this, action)}
              >
                {title}
              </button>
            ))}
          </React.Fragment>
        }
      />
    );
  }

  // Return title, status, boolean if available.
  _listActions() {
    return [
      ["Delete", "disabled", this.props.status !== "disabled"],
      ["Hide", "hidden", this.props.status !== "hidden"],
      ["Enable", "active", this.props.status !== "active"],
    ];
  }

  _onFieldChange(event) {
    const value = event.target.value;
    this.setState({ messageBody: value });
  }

  _onSubmit(action) {
    this.props.api.Posts.update({
      id: this.props.postID,
      note: this.state.messageBody,
      post: {
        status: action,
      },
    }).then(() => {
      this.setState({ messageBody: "" });
      this.props.onCancel();
      this.props.onComplete();
    });
  }
}

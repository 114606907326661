import Base from "./Base";

export default class Referrals extends Base {
  indexStats(params = {}) {
    const q = this._query(params);

    return this._fetch(`/admin/referrals?${q}`)
      .then((response) => response.json())
      .then((data) => data.stats);
  }

  indexUser({ id, ...params }) {
    const q = this._query(params);

    return this._fetch(`/admin/users/${id}/referrals?${q}`)
      .then((response) => response.json())
      .then((data) => [data.referrals, data.cursor]);
  }
}
